import Http from './http';

export class Produto {

    static get = function(start, limit, filtro, sort, filtrarPor) {
        return Http.get('/Serv/Produtos/Produtos.php', {
            params: {
                'operacao': 'listar',
                'start':start,
                'limit':limit,
                'filtro':filtro,
                'sort':sort,
                'filtrarPor': filtrarPor,
            }
        });
    };

    static save = function(dados) {
        return Http.post('/Serv/Produtos/Produtos.php?operacao=gravar', dados);
    };

    static getById = function(dados) {
        return Http.post('/Serv/Produtos/Produtos.php?operacao=getbyid', dados);
    };

    static excluir = function(dados) {
        return Http.post('/Serv/Produtos/Produtos.php?operacao=excluir', dados);
    };

    static ultimoCodigo = function() {
        return Http.get('/Serv/Produtos/Produtos.php?operacao=ultimocodigo');
    };

    static codigoExiste = function(codigo, idProduto) {
        let dados = new FormData();
        dados.append('codigo', codigo);
        dados.append('idProduto', idProduto);
        return Http.post('/Serv/Produtos/Produtos.php?operacao=codigoexiste', dados);
    };

    static getByCodigoCodigoBarras = function(dados) {
        return Http.post('/Serv/Produtos/Produtos.php?operacao=getbycodigocodigobarras', dados);
    };

}
