import { defineStore } from 'pinia';

export const useLogado = defineStore('logado', {
    state: () => {
        return {
            logado: false,
        }        
    },
    actions : {
        setLogado(logado) {
            this.logado = logado;
        },
    }
});