<script setup>
import { inject, ref } from 'vue';
import { VTreeview } from 'vuetify/labs/VTreeview';
import {Relatorio} from '../../resources/relatoriosResource';
import config from '../../services/config';
import MsgConfirm from "../Utils/MsgConfirm";
import TelaDatas from "../Telas/TelaDatas";
import TelaDatasCliente from "../Telas/TelaDatasCliente";
import TelaDatasFornecedor from "../Telas/TelaDatasFornecedor";
import TelaDatasVendedor from "../Telas/TelaDatasVendedor";
import TelaDatasCartao from "../Telas/TelaDatasCartao";
import TelaDatasConta from "../Telas/TelaDatasConta";
import { useRelatorio } from "../../store/relatorio";

const relatorio = ref('');
const snackBarApp = inject('snackBarApp');
const relatorioStore = useRelatorio();

let msgConfirm = ref(null);

const dialogTelaDatas = ref(false);
const dialogTelaDatasCliente = ref(false);
const dialogTelaDatasFornecedor = ref(false);
const dialogTelaDatasVendedor = ref(false);
const dialogTelaDatasCartao = ref(false);
const dialogTelaDatasConta = ref(false);

const relatorios = ref([
    {
        id: 1,
        title: 'Ordens de Serviços',
        children: [
            { id: 101, title: 'Ordens de Serviços Finalizadas(Filtrado/Ordenado por Data)', value: 'OS Finalizadas Data' },
            { id: 102, title: 'Ordens de Serviços Finalizadas(Filtrado/Ordenado por Data de Finalização)', value: 'OS Finalizadas Data Finalizacao' },
            { id: 103, title: 'Ordens de Serviços Em Aberto(Filtrado/Ordenado por Data)', value: 'OS Abertas Data' },
            { id: 104, title: 'Ordens de Serviços Em Aberto(Filtrado/Ordenado por Prazo)', value: 'OS Abertas Prazo' },
            { id: 105, title: 'Ordens de Serviços Canceladas(Filtrado/Ordenado por Data)', value: 'OS Canceladas Data' },
            { id: 106, title: 'Ordens de Serviços Finalizadas de um Cliente(Filtrado/Ordenado por Data)', value: 'OS Finalizadas Data Um Cliente' },
            { id: 107, title: 'Ordens de Serviços Finalizadas de um Cliente(Filtrado/Ordenado por Data de Finalização)', value: 'OS Finalizadas Data Finalizacao Um Cliente' },
            { id: 108, title: 'Ordens de Serviços Em Aberto de um Cliente(Filtrado/Ordenado por Data)', value: 'OS Abertas Data Um Cliente' },
            { id: 109, title: 'Ordens de Serviços Em Aberto de um Cliente(Filtrado/Ordenado por Prazo)', value: 'OS Abertas Prazo Um Cliente' },
        ],
    },
    {
        id: 2,
        title: 'Itens no Estoque',
        children: [
            { id: 201, title: 'Itens no Estoque Geral(Ordenado por Descrição)', value: 'RItem Descricao I' },
            { id: 202, title: 'Itens Abaixo do Estoque Mínimo(Ordenado por Descrição)', value: 'RAbaixoEstoqueMinimo I' },
        ],
    },
    {
        id: 3,
        title: 'Movimentação de Estoque',
        children: [
            { id: 301, title: 'Movimentação de Estoque Geral por Datas', value: 'RMovEstoqueGeral' },
            { id: 302, title: 'Movimentação de Estoque Somente Saídas por Datas', value: 'RMovEstoqueSaidas' },
            { id: 303, title: 'Movimentação de Estoque Somente Entradas por Datas', value: 'RMovEstoqueEntradas' },
            { id: 304, title: 'Movimentação de Estoque de um Fornecedor', value: 'RMovEstoqueUmFornecedor' },
        ],
    },
    {
        id: 4,
        title: 'Caixa',
        children: [
            { id: 401, title: 'Caixa Geral', value: 'Caixa' },
            { id: 402, title: 'Caixa Geral(Com Saldo Anterior)', value: 'CaixaSaldoAnterior' },
        ],
    },
    {
        id: 5,
        title: 'Contas a Receber',
        children: [
            { id: 501, title: 'Contas a Receber(Ordenado/Filtrado por Data de Vencimento)', value: 'ContasReceber' },
            { id: 502, title: 'Contas a Receber de um Cliente(Ordenado/Filtrado por Data de Vencimento)', value: 'ContasReceber Um Cliente' },
            { id: 503, title: 'Contas Recebidas(Ordenado/Filtrado por Data do Recebimento)', value: 'ContasRecebidas' },
            { id: 504, title: 'Contas Recebidas de um Cliente(Ordenado/Filtrado por Data do Recebimento)', value: 'ContasRecebidas Um Cliente' },
            
        ],
    },

    {
        id: 6,
        title: 'Contas a Pagar',
        children: [
            { id: 601, title: 'Contas a Pagar(Ordenado/Filtrado por Data de Vencimento)', value: 'ContasPagar' },
            { id: 602, title: 'Contas a Pagar de um Fornecedor(Ordenado/Filtrado por Data de Vencimento)', value: 'ContasPagar Um Fornecedor' },
            { id: 603, title: 'Contas Pagas(Ordenado/Filtrado por Data do Pagamento)', value: 'ContasPagas' },
            { id: 604, title: 'Contas Pagas para um Fornecedor(Ordenado/Filtrado por Data do Pagamento)', value: 'ContasPagas Um Fornecedor' },
        ],
    },
    {
        id: 7,
        title: 'Orçamentos de Ordens de Serviços',
        children: [
            { id: 701, title: 'Orçamentos(Ordenado/Filtrado por Data)', value: 'ORS Data' },
            { id: 702, title: 'Orçamentos(Ordenado/Filtrado por Validade)', value: 'ORS Validade' },
            { id: 703, title: 'Orçamentos em Aberto(Ordenado/Filtrado por Data)', value: 'ORS Abertos Data' },
            { id: 704, title: 'Orçamentos em Aberto(Ordenado/Filtrado por Validade)', value: 'ORS Abertos Validade' },
            { id: 705, title: 'Orçamentos Cancelados(Ordenado/Filtrado por Data)', value: 'ORS Cancelados Data' },
            { id: 706, title: 'Orçamentos Cancelados(Ordenado/Filtrado por Validade)', value: 'ORS Cancelados Validade' },
            { id: 707, title: 'Orçamentos Transformados em Ordem de Serviços(Ordenado/Filtrado por Data)', value: 'ORS Transformados Data' },
            { id: 708, title: 'Orçamentos Transformados em Ordem de Serviços(Ordenado/Filtrado por Validade)', value: 'ORS Transformados Validade' },
        ],
    },
    {
        id: 8,
        title: 'Pagamentos a Vendedores/Funcionários',
        children: [
            { id: 801, title: 'Pagamentos por Data', value: 'PagamentosVendedores' },
            { id: 802, title: 'Pagamentos a um Vendedor/Funcionário', value: 'PagamentosVendedores Um Vendedor' },
            { id: 803, title: 'Pagamentos por Vendedores', value: 'PagamentosVendedores Vendedores' },
        ],
    },

    {
        id: 9,
        title: 'Comissões de Vendedores/Funcionários',
        children: [
            {
                title: 'Por Datas',
                id: 901,
                children: [
                    { id: 90101, title: 'Comissões(Ordenadas/Filtradas por Data)', value: 'Comissoes Data' },
                    { id: 90102, title: 'Comissões Em Aberto(Ordenadas/Filtradas por Data)', value: 'Comissoes Data A' },
                    { id: 90103, title: 'Comissões Pendentes(Ordenadas/Filtradas por Data)', value: 'Comissoes Data P' },
                    { id: 90104, title: 'Comissões Canceladas(Ordenadas/Filtradas por Data)', value: 'Comissoes Data C' },
                    { id: 90105, title: 'Comissões Pagas(Ordenadas/Filtradas por Data de Pagamento)', value: 'Comissoes DataPagamento Q' },
                ],
            },                
            {
                title: 'Por Vendedores/Funcionários',
                id: 902,
                children: [
                    { id: 90201, title: 'Comissões(Ordenadas/Filtradas por Data)', value: 'Comissoes Vendedores' },
                    { id: 90202, title: 'Comissões Em Aberto(Ordenadas/Filtradas por Data)', value: 'Comissoes Vendedores A' },
                    { id: 90203, title: 'Comissões Pendentes(Ordenadas/Filtradas por Data)', value: 'Comissoes Vendedores P' },
                    { id: 90204, title: 'Comissões Canceladas(Ordenadas/Filtradas por Data)', value: 'Comissoes Vendedores C' },
                    { id: 90205, title: 'Comissões Pagas(Ordenadas/Filtradas por Data de Pagamento)', value: 'Comissoes Vendedores Q' },
                ],
            },
            {
                title: 'De Um Vendedor/Funcionário',
                id: 903,
                children: [
                    { id: 90301, title: 'Comissões de um Vendedor/Funcionário(Ordenadas/Filtradas por Data)', value: 'Comissoes Um Vendedor' },
                    { id: 90302, title: 'Comissões Em Aberto de um Vendedor/Funcionário(Ordenadas/Filtradas por Data)', value: 'Comissoes Um Vendedor A' },
                    { id: 90303, title: 'Comissões Pendentes de um Vendedor/Funcionário(Ordenadas/Filtradas por Data)', value: 'Comissoes Um Vendedor P' },
                    { id: 90304, title: 'Comissões Canceladas de um Vendedor/Funcionário(Ordenadas/Filtradas por Data)', value: 'Comissoes Um Vendedor C' },
                    { id: 90305, title: 'Comissões Pagas de um Vendedor/Funcionário(Ordenadas/Filtradas por Data de Pagamento)', value: 'Comissoes Um Vendedor Q' },
                ],
            }
        ],
    },
    {
        id: 10,
        title: 'Cheques Emitidos',
        children: [
            { id: 1001, title: 'Cheques Emitidos(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesEmitidos Data' },
            { id: 1002, title: 'Cheques Emitidos(Ordenados/Filtrados por Bom Para)', value: 'ChequesEmitidos BomPara' },
            { id: 1003, title: 'Cheques Emitidos em Aberto(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesEmitidos Data A' },
            { id: 1004, title: 'Cheques Emitidos em Aberto(Ordenados/Filtrados por Bom Para)', value: 'ChequesEmitidos BomPara A' },
            { id: 1005, title: 'Cheques Emitidos Baixados(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesEmitidos Data B' },
            { id: 1006, title: 'Cheques Emitidos Baixados(Ordenados/Filtrados por Bom Para)', value: 'ChequesEmitidos BomPara B' },
            { id: 1007, title: 'Cheques Emitidos Baixados(Ordenados/Filtrados por Data da Baixa)', value: 'ChequesEmitidos DataBaixa B' },
            { id: 1008, title: 'Cheques Emitidos Cancelados(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesEmitidos Data C' },
            { id: 1009, title: 'Cheques Emitidos Cancelados(Ordenados/Filtrados por Bom Para)', value: 'ChequesEmitidos BomPara C' },
            { id: 1010, title: 'Cheques Emitidos que Voltaram(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesEmitidos Data V' },
            { id: 1011, title: 'Cheques Emitidos que Voltaram(Ordenados/Filtrados por Bom Para)', value: 'ChequesEmitidos BomPara V' },
            {
                title: 'Para um Fornecedor',
                id: 1012,
                children: [
                    { id: 101201, title: 'Cheques Emitidos para um Fornecedor(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesEmitidos Um Fornecedor Data' },
                    { id: 101202, title: 'Cheques Emitidos para um Fornecedor(Ordenados/Filtrados por Bom Para)', value: 'ChequesEmitidos Um Fornecedor BomPara' },
                    { id: 101203, title: 'Cheques Emitidos em Aberto para um Fornecedor(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesEmitidos Um Fornecedor Data A' },
                    { id: 101204, title: 'Cheques Emitidos em Aberto para um Fornecedor(Ordenados/Filtrados por Bom Para)', value: 'ChequesEmitidos Um Fornecedor BomPara A' },
                    { id: 101205, title: 'Cheques Emitidos Baixados para um Fornecedor(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesEmitidos Um Fornecedor Data B' },
                    { id: 101206, title: 'Cheques Emitidos Baixados para um Fornecedor(Ordenados/Filtrados por Bom Para)', value: 'ChequesEmitidos Um Fornecedor BomPara B' },
                    { id: 101207, title: 'Cheques Emitidos Baixados para um Fornecedor(Ordenados/Filtrados por Data da Baixa)', value: 'ChequesEmitidos Um Fornecedor DataBaixa B' },
                    { id: 101208, title: 'Cheques Emitidos Cancelados para um Fornecedor(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesEmitidos Um Fornecedor Data C' },
                    { id: 101209, title: 'Cheques Emitidos Cancelados para um Fornecedor(Ordenados/Filtrados por Bom Para)', value: 'ChequesEmitidos Um Fornecedor BomPara C' },
                    { id: 101210, title: 'Cheques Emitidos para um Fornecedor que Voltaram(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesEmitidos Um Fornecedor Data V' },
                    { id: 101211, title: 'Cheques Emitidos para um Fornecedor que Voltaram(Ordenados/Filtrados por Bom Para)', value: 'ChequesEmitidos Um Fornecedor BomPara V' },
                ],
            },
            {
                title: 'De uma Conta',
                id: 1013,
                children: [
                    { id: 101301, title: 'Cheques Emitidos de uma Conta(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesEmitidos Uma Conta Data' },
                    { id: 101302, title: 'Cheques Emitidos de uma Conta(Ordenados/Filtrados por Bom Para)', value: 'ChequesEmitidos Uma Conta BomPara' },
                    { id: 101303, title: 'Cheques Emitidos em Aberto de uma Conta(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesEmitidos Uma Conta Data A' },
                    { id: 101304, title: 'Cheques Emitidos em Aberto de uma Conta(Ordenados/Filtrados por Bom Para)', value: 'ChequesEmitidos Uma Conta BomPara A' },
                    { id: 101305, title: 'Cheques Emitidos Baixados de uma Conta(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesEmitidos Uma Conta Data B' },
                    { id: 101306, title: 'Cheques Emitidos Baixados de uma Conta(Ordenados/Filtrados por Bom Para)', value: 'ChequesEmitidos Uma Conta BomPara B' },
                    { id: 101307, title: 'Cheques Emitidos Baixados de uma Conta(Ordenados/Filtrados por Data da Baixa)', value: 'ChequesEmitidos Uma Conta DataBaixa B' },
                    { id: 101308, title: 'Cheques Emitidos Cancelados de uma Conta(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesEmitidos Uma Conta Data C' },
                    { id: 101309, title: 'Cheques Emitidos Cancelados de uma Conta(Ordenados/Filtrados por Bom Para)', value: 'ChequesEmitidos Uma Conta BomPara C' },
                    { id: 101310, title: 'Cheques Emitidos de uma Conta que Voltaram(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesEmitidos Uma Conta Data V' },
                    { id: 101311, title: 'Cheques Emitidos de uma Conta que Voltaram(Ordenados/Filtrados por Bom Para)', value: 'ChequesEmitidos Uma Conta BomPara V' },
                ],
            },
        ],
    },
    {
        id: 11,
        title: 'Cheques Recebidos',
        children: [
            { id: 1101, title: 'Cheques Recebidos(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesRecebidos Data' },
            { id: 1102, title: 'Cheques Recebidos(Ordenados/Filtrados por Bom Para)', value: 'ChequesRecebidos BomPara' },
            { id: 1103, title: 'Cheques Recebidos em Aberto(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesRecebidos Data A' },
            { id: 1104, title: 'Cheques Recebidos em Aberto(Ordenados/Filtrados por Bom Para)', value: 'ChequesRecebidos BomPara A' },
            { id: 1105, title: 'Cheques Recebidos Baixados(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesRecebidos Data B' },
            { id: 1106, title: 'Cheques Recebidos Baixados(Ordenados/Filtrados por Bom Para)', value: 'ChequesRecebidos BomPara B' },
            { id: 1107, title: 'Cheques Recebidos Baixados(Ordenados/Filtrados por Data da Baixa)', value: 'ChequesRecebidos DataBaixa B' },
            { id: 1108, title: 'Cheques Recebidos Cancelados(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesRecebidos Data C' },
            { id: 1109, title: 'Cheques Recebidos Cancelados(Ordenados/Filtrados por Bom Para)', value: 'ChequesRecebidos BomPara C' },
            { id: 1110, title: 'Cheques Recebidos que Voltaram(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesRecebidos Data V' },
            { id: 1111, title: 'Cheques Recebidos que Voltaram(Ordenados/Filtrados por Bom Para)', value: 'ChequesRecebidos BomPara V' },
            {
                title: 'De um Cliente',
                id: 1112,
                children: [
                    { id: 111201, title: 'Cheques Recebidos de um Cliente(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesRecebidos Um Cliente Data' },
                    { id: 111202, title: 'Cheques Recebidos de um Cliente(Ordenados/Filtrados por Bom Para)', value: 'ChequesRecebidos Um Cliente BomPara' },
                    { id: 111203, title: 'Cheques Recebidos em Aberto de um Cliente(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesRecebidos Um Cliente Data A' },
                    { id: 111204, title: 'Cheques Recebidos em Aberto de um Cliente(Ordenados/Filtrados por Bom Para)', value: 'ChequesRecebidos Um Cliente BomPara A' },
                    { id: 111205, title: 'Cheques Recebidos Baixados de um Cliente(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesRecebidos Um Cliente Data B' },
                    { id: 111206, title: 'Cheques Recebidos Baixados de um Cliente(Ordenados/Filtrados por Bom Para)', value: 'ChequesRecebidos Um Cliente BomPara B' },
                    { id: 111207, title: 'Cheques Recebidos Baixados de um Cliente(Ordenados/Filtrados por Data da Baixa)', value: 'ChequesRecebidos Um Cliente DataBaixa B' },
                    { id: 111208, title: 'Cheques Recebidos Cancelados de um Cliente(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesRecebidos Um Cliente Data C' },
                    { id: 111209, title: 'Cheques Recebidos Cancelados de um Cliente(Ordenados/Filtrados por Bom Para)', value: 'ChequesRecebidos Um Cliente BomPara C' },
                    { id: 111210, title: 'Cheques Recebidos de um Cliente que Voltaram(Ordenados/Filtrados por Data de Emissão)', value: 'ChequesRecebidos Um Cliente Data V' },
                    { id: 111211, title: 'Cheques Recebidos de um Cliente que Voltaram(Ordenados/Filtrados por Bom Para)', value: 'ChequesRecebidos Um Cliente BomPara V' },
                ],
            },
        ],
    },
    {
        id: 12,
        title: 'Lançamentos de Cartões/Convênios',
        children: [
            {
                title: 'Por Datas',
                id: 1201,
                children: [
                    { id: 120101, title: 'Lançamentos(Ordenados/Filtrados por Data de Emissão)', value: 'LancamentosCartoes Data' },
                    { id: 120102, title: 'Lançamentos Em Aberto(Ordenados/Filtrados por Data de Emissão)', value: 'LancamentosCartoes Data A' },
                    { id: 120103, title: 'Lançamentos Recebidos(Ordenados/Filtrados por Data de Emissão)', value: 'LancamentosCartoes Data P' },
                    { id: 120104, title: 'Lançamentos Cancelados(Ordenados/Filtrados por Data de Emissão)', value: 'LancamentosCartoes Data C' },
                ],
            },
            {
                title: 'Geral por Data de Recebimento',
                id: 1202,
                children: [
                    { id: 120201, title: 'Lançamentos(Ordenados/Filtrados por Data de Recebimento)', value: 'LancamentosCartoes DataRecebimento P' },
                ],
            },
            {
                title: 'Por Cartões/Convênios por Datas',
                id: 1203,
                children: [
                    { id: 120301, title: 'Lançamentos(Ordenados/Filtrados por Data de Emissão)', value: 'LancamentosCartoes Cartoes Data' },
                    { id: 120302, title: 'Lançamentos Em Aberto(Ordenados/Filtrados por Data de Emissão)', value: 'LancamentosCartoes Cartoes Data A' },
                    { id: 120303, title: 'Lançamentos Recebidos(Ordenados/Filtrados por Data de Emissão)', value: 'LancamentosCartoes Cartoes Data P' },
                    { id: 120304, title: 'Lançamentos Cancelados(Ordenados/Filtrados por Data de Emissão)', value: 'LancamentosCartoes Cartoes Data C' },
                ],
            },
            {
                title: 'Por Cartões/Convênios por Data de Recebimento',
                id: 1204,
                children: [
                    { id: 120401, title: 'Lançamentos Recebidos(Ordenados/Filtrados por Data de Recebimento)', value: 'LancamentosCartoes Cartoes DataRecebimento P' },
                ],
            },
            {
                title: 'De Um Cartão/Convênio por Datas',
                id: 1205,
                children: [
                    { id: 120501, title: 'Lançamentos(Ordenados/Filtrados por Data de Emissão)', value: 'LancamentosCartoes Um Cartao Data' },
                    { id: 120502, title: 'Lançamentos Em Aberto(Ordenados/Filtrados por Data de Emissão)', value: 'LancamentosCartoes Um Cartao Data A' },
                    { id: 120503, title: 'Lançamentos Recebidos(Ordenados/Filtrados por Data de Emissão)', value: 'LancamentosCartoes Um Cartao Data P' },
                    { id: 120504, title: 'Lançamentos Cancelados(Ordenados/Filtrados por Data de Emissão)', value: 'LancamentosCartoes Um Cartao Data C' },
                ],
            },
            {
                title: 'De Um Cartão/Convênio por Data de Recebimento',
                id: 1206,
                children: [
                    { id: 120601, title: 'Lançamentos Recebidos(Ordenados/Filtrados por Data de Recebimento)', value: 'LancamentosCartoes Um Cartao DataRecebimento P' },
                ],
            },
        ],
    },
    {
        id: 13,
        title: 'Agendamentos de Visitas',
        children: [
            {
                title: 'Por Datas',
                id: 1301,
                children: [
                    { id: 130101, title: 'Agendamentos de Visitas', value: 'Agendamento Visita Datas' },
                    { id: 130102, title: 'Agendamentos de Visitas(Pendentes)', value: 'Agendamento Visita Datas P' },
                    { id: 130103, title: 'Agendamentos de Visitas(Cancelados)', value: 'Agendamento Visita Datas C' },
                    { id: 130104, title: 'Agendamentos de Visitas(Visitados)', value: 'Agendamento Visita Datas V' },
                ],
            },
            {
                title: 'Por Cliente',
                id: 1302,
                children: [
                    { id: 130201, title: 'Agendamentos de Visitas de um Cliente', value: 'Agendamento Visita Um Cliente' },
                    { id: 130202, title: 'Agendamentos de Visitas de um Cliente(Pendentes)', value: 'Agendamento Visita Um Cliente P' },
                    { id: 130203, title: 'Agendamentos de Visitas de um Cliente(Cancelados)', value: 'Agendamento Visita Um Cliente C' },
                    { id: 130204, title: 'Agendamentos de Visitas de um Cliente(Visitados)', value: 'Agendamento Visita Um Cliente V' },
                ],
            },
            {
                title: 'Por Vendedor/Funcionário',
                id: 1303,
                children: [
                    { id: 130301, title: 'Agendamentos de Visitas de um Vendedor/Funcionário', value: 'Agendamento Visita Um Vendedor' },
                    { id: 130302, title: 'Agendamentos de Visitas de um Vendedor/Funcionário(Pendentes)', value: 'Agendamento Visita Um Vendedor P' },
                    { id: 130303, title: 'Agendamentos de Visitas de um Vendedor/Funcionário(Cancelados)', value: 'Agendamento Visita Um Vendedor C' },
                    { id: 130304, title: 'Agendamentos de Visitas de um Vendedor/Funcionário(Visitados)', value: 'Agendamento Visita Um Vendedor V' },
                ],
            },
        ],
    },
    {
        id: 14,
        title: 'Pedidos de Compras',
        children: [
            { id: 1401, title: 'Pedidos Recebidos(Filtrado/Ordenado por Data de Recebimento)', value: 'Pedidos Compras Recebidos' },
            { id: 1402, title: 'Pedidos Abertos(Filtrado/Ordenado por Data de Emissão)', value: 'Pedidos Compras Abertos Data' },
            { id: 1403, title: 'Pedidos Abertos(Filtrado/Ordenado por Prazo de Entrega)', value: 'Pedidos Compras Abertos Prazo' },
            { id: 1404, title: 'Pedidos Abertos(Filtrado/Ordenado por Data do Faturamento)', value: 'Pedidos Compras Abertos Data Faturamento' },
            { id: 1405, title: 'Pedidos Cancelados(Filtrado/Ordenado por Data de Emissão)', value: 'Pedidos Compras Cancelados' },
            {
                title: 'De um Fornecedor',
                id: 1406,
                children: [
                    { id: 140601, title: 'Pedidos Recebidos de um Fornecedor(Filtrado/Ordenado por Data de Recebimento)', value: 'Pedidos Compras Recebidos Um Fornecedor' },
                    { id: 140602, title: 'Pedidos Abertos de um Fornecedor(Filtrado/Ordenado por Data de Emissão)', value: 'Pedidos Compras Abertos Um Fornecedor Data' },
                    { id: 140603, title: 'Pedidos Abertos de um Fornecedor(Filtrado/Ordenado por Prazo de Entrega)', value: 'Pedidos Compras Abertos Um Fornecedor Prazo' },
                    { id: 140604, title: 'Pedidos Abertos de um Fornecedor(Filtrado/Ordenado por Data do Faturamento)', value: 'Pedidos Compras Abertos Um Fornecedor Data Faturamento' },
                    { id: 140605, title: 'Pedidos Cancelados de um Fornecedor(Filtrado/Ordenado por Data de Emissão)', value: 'Pedidos Compras Cancelados Um Fornecedor' },
                ],
            },
        ],
    },

]);

function handleRelatorios(rel) {
    let nomeRelatorio = rel.id;
    console.log('handleActiveUpdate chamado com:', nomeRelatorio);
    // Ordens de Serviço
    if(nomeRelatorio == 'OS Finalizadas Data') {
        abreTelaDatas('OS Finalizadas Data');
    }
    if(nomeRelatorio == 'OS Finalizadas Data Finalizacao') {
        abreTelaDatas('OS Finalizadas Data Finalizacao');
    }
    if(nomeRelatorio == 'OS Abertas Data') {
        abreTelaDatas('OS Abertas Data');
    }
    if(nomeRelatorio == 'OS Abertas Prazo') {
        abreTelaDatas('OS Abertas Prazo');    
    }
    if(nomeRelatorio == 'OS Canceladas Data') {
        abreTelaDatas('OS Canceladas Data');    
    }
    if(nomeRelatorio == 'OS Finalizadas Data Um Cliente') {
        abreTelaDatasCliente('OS Finalizadas Data Um Cliente');
    }
    if(nomeRelatorio == 'OS Finalizadas Data Finalizacao Um Cliente') {
        abreTelaDatasCliente('OS Finalizadas Data Finalizacao Um Cliente');
    }
    if(nomeRelatorio == 'OS Abertas Data Um Cliente') {
        abreTelaDatasCliente('OS Abertas Data Um Cliente');
    }
    if(nomeRelatorio == 'OS Abertas Prazo Um Cliente') {
        abreTelaDatasCliente('OS Abertas Prazo Um Cliente');
    }

    // Itens no Estoque
    if(nomeRelatorio == 'RItem Descricao I') {
        imprimirItemEstoqueGeral('RItem Descricao I');    
    }
    if(nomeRelatorio == 'RAbaixoEstoqueMinimo I') {
        imprimirRelatorio('RAbaixoEstoqueMinimo I');
    }

    // Movimentaçao de Estoque
    if(nomeRelatorio == 'RMovEstoqueGeral') {
        abreTelaDatas('RMovEstoqueGeral');    
    }

    if(nomeRelatorio == 'RMovEstoqueSaidas') {
        abreTelaDatas('RMovEstoqueSaidas');    
    }

    if(nomeRelatorio == 'RMovEstoqueEntradas') {
        abreTelaDatas('RMovEstoqueEntradas');        
    }

    if(nomeRelatorio == 'RMovEstoqueUmFornecedor') {
        abreTelaDatasFornecedor('RMovEstoqueUmFornecedor');
    }

    // Caixa
    if(nomeRelatorio == 'Caixa') {
        abreTelaDatas('Caixa');    
    }

    if(nomeRelatorio == 'CaixaSaldoAnterior') {
        abreTelaDatas('CaixaSaldoAnterior');    
    }

    // Contas a Receber
    if(nomeRelatorio == 'ContasReceber') {
        abreTelaDatas('ContasReceber');    
    }

    if(nomeRelatorio == 'ContasReceber Um Cliente') {
        abreTelaDatasCliente('ContasReceber Um Cliente');
    }

    if(nomeRelatorio == 'ContasRecebidas') {
        abreTelaDatas('ContasRecebidas');    
    }

    if(nomeRelatorio == 'ContasRecebidas Um Cliente') {
        abreTelaDatasCliente('ContasRecebidas Um Cliente');
    }

    // Contas a Pagar
    if(nomeRelatorio == 'ContasPagar') {
        abreTelaDatas('ContasPagar');
    }

    if(nomeRelatorio == 'ContasPagar Um Fornecedor') {
        abreTelaDatasFornecedor('ContasPagar Um Fornecedor');
    }

    if(nomeRelatorio == 'ContasPagas') {
        abreTelaDatas('ContasPagas');    
    }

    if(nomeRelatorio == 'ContasPagas Um Fornecedor') {
        abreTelaDatasFornecedor('ContasPagas Um Fornecedor');
    }

    // Orçamentos de Ordens de Serviços
    if(nomeRelatorio == 'ORS Data') {
        abreTelaDatas('ORS Data');
    }

    if(nomeRelatorio == 'ORS Validade') {
        abreTelaDatas('ORS Validade');    
    }

    if(nomeRelatorio == 'ORS Abertos Data') {
        abreTelaDatas('ORS Abertos Data');    
    }

    if(nomeRelatorio == 'ORS Abertos Validade') {
        abreTelaDatas('ORS Abertos Validade');    
    }

    if(nomeRelatorio == 'ORS Cancelados Data') {
        abreTelaDatas('ORS Cancelados Data');    
    }

    if(nomeRelatorio == 'ORS Cancelados Validade') {
        abreTelaDatas('ORS Cancelados Validade');    
    }

    if(nomeRelatorio == 'ORS Transformados Data') {
        abreTelaDatas('ORS Transformados Data');    
    }

    if(nomeRelatorio == 'ORS Transformados Validade') {
        abreTelaDatas('ORS Transformados Validade');    
    }

    // Pagamentos a Vendedores/Funcionários
    if(nomeRelatorio == 'PagamentosVendedores') {
        abreTelaDatas('PagamentosVendedores');    
    }

    if(nomeRelatorio == 'PagamentosVendedores Um Vendedor') {
        abreTelaDatasVendedor('PagamentosVendedores Um Vendedor');    
    }

    if(nomeRelatorio == 'PagamentosVendedores Vendedores') {
        abreTelaDatas('PagamentosVendedores Vendedores');    
    }

    if(nomeRelatorio == 'Comissoes Data') {
        abreTelaDatas('Comissoes Data');
    }

    if(nomeRelatorio == 'Comissoes Data A') {
        abreTelaDatas('Comissoes Data A');
    }

    if(nomeRelatorio == 'Comissoes Data P') {
        abreTelaDatas('Comissoes Data P');
    }

    if(nomeRelatorio == 'Comissoes Data C') {
        abreTelaDatas('Comissoes Data C');
    }

    if(nomeRelatorio == 'Comissoes DataPagamento Q') {
        abreTelaDatas('Comissoes DataPagamento Q');
    }

    if(nomeRelatorio == 'Comissoes Vendedores') {
        abreTelaDatas('Comissoes Vendedores');
    }

    if(nomeRelatorio == 'Comissoes Vendedores A') {
        abreTelaDatas('Comissoes Vendedores A');
    }

    if(nomeRelatorio == 'Comissoes Vendedores P') {
        abreTelaDatas('Comissoes Vendedores P');
    }

    if(nomeRelatorio == 'Comissoes Vendedores C') {
        abreTelaDatas('Comissoes Vendedores C');
    }

    if(nomeRelatorio == 'Comissoes Vendedores Q') {
        abreTelaDatas('Comissoes Vendedores Q');
    }

    if(nomeRelatorio == 'Comissoes Um Vendedor') {
        abreTelaDatasVendedor('Comissoes Um Vendedor');
    }

    if(nomeRelatorio == 'Comissoes Um Vendedor A') {
        abreTelaDatasVendedor('Comissoes Um Vendedor A');
    }

    if(nomeRelatorio == 'Comissoes Um Vendedor P') {
        abreTelaDatasVendedor('Comissoes Um Vendedor P');
    }

    if(nomeRelatorio == 'Comissoes Um Vendedor C') {
        abreTelaDatasVendedor('Comissoes Um Vendedor C');
    }

    if(nomeRelatorio == 'Comissoes Um Vendedor Q') {
        abreTelaDatasVendedor('Comissoes Um Vendedor Q');
    }
    
    // Cheques Emitidos
    if(nomeRelatorio == 'ChequesEmitidos Data') {
        abreTelaDatas('ChequesEmitidos Data');
    }

    if(nomeRelatorio == 'ChequesEmitidos BomPara') {
        abreTelaDatas('ChequesEmitidos BomPara');
    }

    if(nomeRelatorio == 'ChequesEmitidos Data A') {
        abreTelaDatas('ChequesEmitidos Data A');
    }

    if(nomeRelatorio == 'ChequesEmitidos BomPara A') {
        abreTelaDatas('ChequesEmitidos BomPara A');
    }

    if(nomeRelatorio == 'ChequesEmitidos Data B') {
        abreTelaDatas('ChequesEmitidos Data B');
    }

    if(nomeRelatorio == 'ChequesEmitidos BomPara B') {
        abreTelaDatas('ChequesEmitidos BomPara B');
    }

    if(nomeRelatorio == 'ChequesEmitidos DataBaixa B') {
        abreTelaDatas('ChequesEmitidos DataBaixa B');
    }

    if(nomeRelatorio == 'ChequesEmitidos Data C') {
        abreTelaDatas('ChequesEmitidos Data C');
    }

    if(nomeRelatorio == 'ChequesEmitidos BomPara C') {
        abreTelaDatas('ChequesEmitidos BomPara C');
    }

    if(nomeRelatorio == 'ChequesEmitidos Data V') {
        abreTelaDatas('ChequesEmitidos Data V');
    }

    if(nomeRelatorio == 'ChequesEmitidos BomPara V') {
        abreTelaDatas('ChequesEmitidos BomPara V');
    }

    if(nomeRelatorio == 'ChequesEmitidos Um Fornecedor Data') {
        abreTelaDatasFornecedor('ChequesEmitidos Um Fornecedor Data');
    }

    if(nomeRelatorio == 'ChequesEmitidos Um Fornecedor BomPara') {
        abreTelaDatasFornecedor('ChequesEmitidos Um Fornecedor BomPara');
    }

    if(nomeRelatorio == 'ChequesEmitidos Um Fornecedor Data A') {
        abreTelaDatasFornecedor('ChequesEmitidos Um Fornecedor Data A');
    }

    if(nomeRelatorio == 'ChequesEmitidos Um Fornecedor BomPara A') {
        abreTelaDatasFornecedor('ChequesEmitidos Um Fornecedor BomPara A');
    }

    if(nomeRelatorio == 'ChequesEmitidos Um Fornecedor Data B') {
        abreTelaDatasFornecedor('ChequesEmitidos Um Fornecedor Data B');
    }

    if(nomeRelatorio == 'ChequesEmitidos Um Fornecedor BomPara B') {
        abreTelaDatasFornecedor('ChequesEmitidos Um Fornecedor BomPara B');
    }

    if(nomeRelatorio == 'ChequesEmitidos Um Fornecedor DataBaixa B') {
        abreTelaDatasFornecedor('ChequesEmitidos Um Fornecedor DataBaixa B');
    }
    
    if(nomeRelatorio == 'ChequesEmitidos Um Fornecedor Data C') {
        abreTelaDatasFornecedor('ChequesEmitidos Um Fornecedor Data C');
    }

    if(nomeRelatorio == 'ChequesEmitidos Um Fornecedor BomPara C') {
        abreTelaDatasFornecedor('ChequesEmitidos Um Fornecedor BomPara C');
    }

    if(nomeRelatorio == 'ChequesEmitidos Um Fornecedor Data V') {
        abreTelaDatasFornecedor('ChequesEmitidos Um Fornecedor Data V');
    }

    if(nomeRelatorio == 'ChequesEmitidos Um Fornecedor BomPara V') {
        abreTelaDatasFornecedor('ChequesEmitidos Um Fornecedor BomPara V');
    }   
    

    if(nomeRelatorio == 'ChequesEmitidos Uma Conta Data') {
        abreTelaDatasConta('ChequesEmitidos Uma Conta Data');
    }

    if(nomeRelatorio == 'ChequesEmitidos Uma Conta BomPara') {
        abreTelaDatasConta('ChequesEmitidos Uma Conta BomPara');
    }

    if(nomeRelatorio == 'ChequesEmitidos Uma Conta Data A') {
        abreTelaDatasConta('ChequesEmitidos Uma Conta Data A');
    }

    if(nomeRelatorio == 'ChequesEmitidos Uma Conta BomPara A') {
        abreTelaDatasConta('ChequesEmitidos Uma Conta BomPara A');
    }

    if(nomeRelatorio == 'ChequesEmitidos Uma Conta Data B') {
        abreTelaDatasConta('ChequesEmitidos Uma Conta Data B');
    }

    if(nomeRelatorio == 'ChequesEmitidos Uma Conta BomPara B') {
        abreTelaDatasConta('ChequesEmitidos Uma Conta BomPara B');
    }

    if(nomeRelatorio == 'ChequesEmitidos Uma Conta DataBaixa B') {
        abreTelaDatasConta('ChequesEmitidos Uma Conta DataBaixa B');
    }

    if(nomeRelatorio == 'ChequesEmitidos Uma Conta Data C') {
        abreTelaDatasConta('ChequesEmitidos Uma Conta Data C');
    }

    if(nomeRelatorio == 'ChequesEmitidos Uma Conta BomPara C') {
        abreTelaDatasConta('ChequesEmitidos Uma Conta BomPara C');
    }

    if(nomeRelatorio == 'ChequesEmitidos Uma Conta Data V') {
        abreTelaDatasConta('ChequesEmitidos Uma Conta Data V');
    }

    if(nomeRelatorio == 'ChequesEmitidos Uma Conta BomPara V') {
        abreTelaDatasConta('ChequesEmitidos Uma Conta BomPara V');
    }

    // Cheques Recebidos
    if(nomeRelatorio == 'ChequesRecebidos Data') {
        abreTelaDatas('ChequesRecebidos Data');
    }
    if(nomeRelatorio == 'ChequesRecebidos BomPara') {
        abreTelaDatas('ChequesRecebidos BomPara');
    }
    if(nomeRelatorio == 'ChequesRecebidos Data A') {
        abreTelaDatas('ChequesRecebidos Data A');
    }
    if(nomeRelatorio == 'ChequesRecebidos BomPara A') {
        abreTelaDatas('ChequesRecebidos BomPara A');
    }
    if(nomeRelatorio == 'ChequesRecebidos Data B') {
        abreTelaDatas('ChequesRecebidos Data B');
    }
    if(nomeRelatorio == 'ChequesRecebidos BomPara B') {
        abreTelaDatas('ChequesRecebidos BomPara B');
    }
    if(nomeRelatorio == 'ChequesRecebidos DataBaixa B') {
        abreTelaDatas('ChequesRecebidos DataBaixa B');
    }
    if(nomeRelatorio == 'ChequesRecebidos Data C') {
        abreTelaDatas('ChequesRecebidos Data C');
    }
    if(nomeRelatorio == 'ChequesRecebidos BomPara C') {
        abreTelaDatas('ChequesRecebidos BomPara C');
    }
    if(nomeRelatorio == 'ChequesRecebidos Data V') {
        abreTelaDatas('ChequesRecebidos Data V');
    }
    if(nomeRelatorio == 'ChequesRecebidos BomPara V') {
        abreTelaDatas('ChequesRecebidos BomPara V');
    }
    if(nomeRelatorio == 'ChequesRecebidos Um Cliente Data') {
        abreTelaDatasCliente('ChequesRecebidos Um Cliente Data');
    }
    if(nomeRelatorio == 'ChequesRecebidos Um Cliente BomPara') {
        abreTelaDatasCliente('ChequesRecebidos Um Cliente BomPara');
    }
    if(nomeRelatorio == 'ChequesRecebidos Um Cliente Data A') {
        abreTelaDatasCliente('ChequesRecebidos Um Cliente Data A');
    }
    if(nomeRelatorio == 'ChequesRecebidos Um Cliente BomPara A') {
        abreTelaDatasCliente('ChequesRecebidos Um Cliente BomPara A');
    }
    if(nomeRelatorio == 'ChequesRecebidos Um Cliente Data B') {
        abreTelaDatasCliente('ChequesRecebidos Um Cliente Data B');
    }
    if(nomeRelatorio == 'ChequesRecebidos Um Cliente BomPara B') {
        abreTelaDatasCliente('ChequesRecebidos Um Cliente BomPara B');
    }
    if(nomeRelatorio == 'ChequesRecebidos Um Cliente DataBaixa B') {
        abreTelaDatasCliente('ChequesRecebidos Um Cliente DataBaixa B');
    }
    if(nomeRelatorio == 'ChequesRecebidos Um Cliente Data C') {
        abreTelaDatasCliente('ChequesRecebidos Um Cliente Data C');
    }
    if(nomeRelatorio == 'ChequesRecebidos Um Cliente BomPara C') {
        abreTelaDatasCliente('ChequesRecebidos Um Cliente BomPara C');
    }
    if(nomeRelatorio == 'ChequesRecebidos Um Cliente Data V') {
        abreTelaDatasCliente('ChequesRecebidos Um Cliente Data V');
    }
    if(nomeRelatorio == 'ChequesRecebidos Um Cliente BomPara V') {
        abreTelaDatasCliente('ChequesRecebidos Um Cliente BomPara V');
    }

    // Lançamentos de Cartões
    if(nomeRelatorio == 'LancamentosCartoes Data') {
        abreTelaDatas('LancamentosCartoes Data');
    }
    if(nomeRelatorio == 'LancamentosCartoes Data A') {
        abreTelaDatas('LancamentosCartoes Data A');
    }
    if(nomeRelatorio == 'LancamentosCartoes Data P') {
        abreTelaDatas('LancamentosCartoes Data P');
    }
    if(nomeRelatorio == 'LancamentosCartoes Data C') {
        abreTelaDatas('LancamentosCartoes Data C');
    }
    if(nomeRelatorio == 'LancamentosCartoes DataRecebimento P') {
        abreTelaDatas('LancamentosCartoes DataRecebimento P');
    }
    if(nomeRelatorio == 'LancamentosCartoes Cartoes Data') {
        abreTelaDatas('LancamentosCartoes Cartoes Data');
    }
    if(nomeRelatorio == 'LancamentosCartoes Cartoes Data A') {
        abreTelaDatas('LancamentosCartoes Cartoes Data A');
    }
    if(nomeRelatorio == 'LancamentosCartoes Cartoes Data P') {
        abreTelaDatas('LancamentosCartoes Cartoes Data P');
    }
        if(nomeRelatorio == 'LancamentosCartoes Cartoes Data C') {
        abreTelaDatas('LancamentosCartoes Cartoes Data C');
    }
    if(nomeRelatorio == 'LancamentosCartoes Cartoes DataRecebimento P') {
        abreTelaDatas('LancamentosCartoes Cartoes DataRecebimento P');
    }
    if(nomeRelatorio == 'LancamentosCartoes Um Cartao Data') {
        abreTelaDatasCartao('LancamentosCartoes Um Cartao Data')
    }
    if(nomeRelatorio == 'LancamentosCartoes Um Cartao Data A') {
        abreTelaDatasCartao('LancamentosCartoes Um Cartao Data A')
    }
    if(nomeRelatorio == 'LancamentosCartoes Um Cartao Data P') {
        abreTelaDatasCartao('LancamentosCartoes Um Cartao Data P')
    }
    if(nomeRelatorio == 'LancamentosCartoes Um Cartao Data C') {
        abreTelaDatasCartao('LancamentosCartoes Um Cartao Data C');
    }
    if(nomeRelatorio == 'LancamentosCartoes Um Cartao DataRecebimento P') {
        abreTelaDatasCartao('LancamentosCartoes Um Cartao DataRecebimento P');
    }

    // Agendamentos de Visitas
    if(nomeRelatorio == 'Agendamento Visita Datas') {
        abreTelaDatas('Agendamento Visita Datas');
    }
    if(nomeRelatorio == 'Agendamento Visita Datas P') {
        abreTelaDatas('Agendamento Visita Datas P');
    }
    if(nomeRelatorio == 'Agendamento Visita Datas C') {
        abreTelaDatas('Agendamento Visita Datas C');
    }
    if(nomeRelatorio == 'Agendamento Visita Datas V') {
        abreTelaDatas('Agendamento Visita Datas V');
    }
    if(nomeRelatorio == 'Agendamento Visita Um Cliente') {
        abreTelaDatasCliente('Agendamento Visita Um Cliente');
    }
    if(nomeRelatorio == 'Agendamento Visita Um Cliente P') {
        abreTelaDatasCliente('Agendamento Visita Um Cliente P');
    }
    if(nomeRelatorio == 'Agendamento Visita Um Cliente C') {
        abreTelaDatasCliente('Agendamento Visita Um Cliente C');
    }
    if(nomeRelatorio == 'Agendamento Visita Um Cliente V') {
        abreTelaDatasCliente('Agendamento Visita Um Cliente V');
    }
    if(nomeRelatorio == 'Agendamento Visita Um Vendedor') {
        abreTelaDatasVendedor('Agendamento Visita Um Vendedor');
    }
    if(nomeRelatorio == 'Agendamento Visita Um Vendedor P') {
        abreTelaDatasVendedor('Agendamento Visita Um Vendedor P');
    }
    if(nomeRelatorio == 'Agendamento Visita Um Vendedor C') {
        abreTelaDatasVendedor('Agendamento Visita Um Vendedor C');
    }
    if(nomeRelatorio == 'Agendamento Visita Um Vendedor V') {
        abreTelaDatasVendedor('Agendamento Visita Um Vendedor V');
    }
    
    // Pedidos de Compras
    if(nomeRelatorio == 'Pedidos Compras Recebidos') {
        abreTelaDatas('Pedidos Compras Recebidos');
    }
    if(nomeRelatorio == 'Pedidos Compras Abertos Data') {
        abreTelaDatas('Pedidos Compras Abertos Data');
    }
    if(nomeRelatorio == 'Pedidos Compras Abertos Prazo') {
        abreTelaDatas('Pedidos Compras Abertos Prazo');
    }
    if(nomeRelatorio == 'Pedidos Compras Abertos Data Faturamento') {
        abreTelaDatas('Pedidos Compras Abertos Data Faturamento');
    }
    if(nomeRelatorio == 'Pedidos Compras Cancelados') {
        abreTelaDatas('Pedidos Compras Cancelados');
    }
    if(nomeRelatorio == 'Pedidos Compras Recebidos Um Fornecedor') {
        abreTelaDatasFornecedor('Pedidos Compras Recebidos Um Fornecedor');
    }
    if(nomeRelatorio == 'Pedidos Compras Abertos Um Fornecedor Data') {
        abreTelaDatasFornecedor('Pedidos Compras Abertos Um Fornecedor Data');
    }
    if(nomeRelatorio == 'Pedidos Compras Abertos Um Fornecedor Prazo') {
        abreTelaDatasFornecedor('Pedidos Compras Abertos Um Fornecedor Prazo');
    }
    if(nomeRelatorio == 'Pedidos Compras Abertos Um Fornecedor Data Faturamento') {
        abreTelaDatasFornecedor('Pedidos Compras Abertos Um Fornecedor Data Faturamento');
    }
    if(nomeRelatorio == 'Pedidos Compras Cancelados Um Fornecedor') {
        abreTelaDatasFornecedor('Pedidos Compras Cancelados Um Fornecedor');
    }

}


async function imprimirRelatorio(nomeRel) {
        try {
            let response = await Relatorio.get(nomeRel);
            if (response.status == 200) {
                if (response.data.success == true) {
                    let nomeArquivo = response.data.nomeArquivo;
                    let url = config.baseUrlRel + nomeArquivo;

                    window.open(url, '_blank');
                    return;

                }

                if(response.data.success == false) {
                    snackBarApp.value.open(response.data.message);
                    return;
                }

                snackBarApp.value.open('Não foi possível fazer a impressão do relatório, tente novamente.');    
            }
        }
        catch(err) {
            snackBarApp.value.open('Não foi possível fazer a impressão do relatório, tente novamente.');
            console.log(err);
        }
    }
     
    
    async function imprimirItemEstoqueGeral() {
        msgConfirm.value
        .open(
            "Confirme",
            "Deseja imprimir os produtos que estão com estoque com quantidade igual ou abaixo de 0 ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                imprimirRelatorio('RItem Descricao Zero I')
            }
            else {
                imprimirRelatorio('RItem Descricao I')
            }
        });
    }

    function abreTelaDatas(nomeRel) {
        relatorioStore.setRelatorio(nomeRel);
        dialogTelaDatas.value  = true;
    }

    function abreTelaDatasCliente(nomeRel) {
        relatorioStore.setRelatorio(nomeRel);
        dialogTelaDatasCliente.value  = true;
    }
    
    function abreTelaDatasFornecedor(nomeRel) {
        relatorioStore.setRelatorio(nomeRel);
        dialogTelaDatasFornecedor.value  = true;
    }

    function abreTelaDatasVendedor(nomeRel) {
        relatorioStore.setRelatorio(nomeRel);
        dialogTelaDatasVendedor.value  = true;
    }

    function abreTelaDatasCartao(nomeRel) {
        relatorioStore.setRelatorio(nomeRel);
        dialogTelaDatasCartao.value  = true;
    }

    function abreTelaDatasConta(nomeRel) {
        relatorioStore.setRelatorio(nomeRel);
        dialogTelaDatasConta.value  = true;
    }


</script>

<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>

    <h2>Relatórios</h2>
    
    <v-treeview
        label= "Relatórios"
        v-model:selected="relatorio"
        :items="relatorios"
        open-on-click
        density="compact"
        elevation="16"
        @click:select="handleRelatorios"
    ></v-treeview>

    <v-dialog v-model="dialogTelaDatas" transition="dialog-top-transition" max-width="600">
        <TelaDatas @close-dialog="dialogTelaDatas = false" />
    </v-dialog>

    <v-dialog v-model="dialogTelaDatasCliente" transition="dialog-top-transition" max-width="800">
        <TelaDatasCliente @close-dialog="dialogTelaDatasCliente = false" />
    </v-dialog>

    <v-dialog v-model="dialogTelaDatasFornecedor" transition="dialog-top-transition" max-width="800">
        <TelaDatasFornecedor @close-dialog="dialogTelaDatasFornecedor = false" />
    </v-dialog>

    <v-dialog v-model="dialogTelaDatasVendedor" transition="dialog-top-transition" max-width="800">
        <TelaDatasVendedor @close-dialog="dialogTelaDatasVendedor = false" />
    </v-dialog>

    <v-dialog v-model="dialogTelaDatasCartao" transition="dialog-top-transition" max-width="800">
        <TelaDatasCartao @close-dialog="dialogTelaDatasCartao = false" />
    </v-dialog>

    <v-dialog v-model="dialogTelaDatasConta" transition="dialog-top-transition" max-width="800">
        <TelaDatasConta @close-dialog="dialogTelaDatasConta = false" />
    </v-dialog>

</template>
