<script setup>
    import { ref, onMounted, defineEmits } from 'vue';
    import { useRoute } from 'vue-router';
    import {GrupoContasReceber} from '../../resources/grupoContasReceberResource';
    import { useField, useForm } from 'vee-validate'
    import {object, string} from 'yup';
    import SnackBar from '../Utils/SnackBar';
    import { usePermissoes } from '../../store/permissoes';
    
    const emit = defineEmits(['close-dialog', 'cadastrou-grupo-contas-receber']);
    const snackBar = ref(null);
    const route = useRoute();
    let  showBtnGravar = ref(true);
    const permissoesStore = usePermissoes();

    const validationSchema  = object({
        grupoContasReceber: object({
            idGrupoContasReceber: undefined,
            descricao: string().required('A descrição é obrigatória').max(50)
        }).required()
    });
    const { handleSubmit, errors, setValues } = useForm({validationSchema});

    const grupoContasReceber =  ref({ 
        idGrupoContasReceber: useField('grupoContasReceber.idGrupoContasReceber').value,
        descricao: useField('grupoContasReceber.descricao').value 
    });

    const submit = handleSubmit((values, actions) => {
        gravar(values, actions);
    });

    onMounted(() => {
        if(route.name == 'GrupoContasReceber.Update') {
            getGrupoContasReceber(route.params.id);
        }
        else {
            grupoContasReceber.value.idGrupoContasReceber = null;
        }

        let tipo = permissoesStore.tipo;
        let validade = permissoesStore.validade;

        if((tipo == 'D' || tipo == 'P') && validade == 'N') {
            showBtnGravar.value = false;
        }
    });
    async function gravar(values, actions) {
        let dados = new FormData();
        dados.append('data', JSON.stringify(grupoContasReceber.value));

        try {
            const response = await GrupoContasReceber.save(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBar.value.open('Grupo de contas a receber não pode ser cadastrado, mensagem de erro: '+ response.data.message, 6000);
                    return;
                }
                if(route.name == 'GrupoContasReceber.Update') {
                    snackBar.value.open('Grupo de contas a receber alterado com sucesso.');
                }
                else{
                    snackBar.value.open('Novo grupo de contas a receber cadastrado com sucesso.');
                    actions.resetForm();
                    grupoContasReceber.value.idGrupoContasReceber = null;
                    document.getElementById("descricao").focus();
                    emit('cadastrou-grupo-contas-receber');
                }
            }
        }  catch(err) {
            snackBar.value.open('Erro, mensagem de erro: '+ err);
        }
    }
    function closeModalCadastroGrupoContasReceber() {
        emit('close-dialog');
    }
    async function getGrupoContasReceber(id) {
        let dados = new FormData();
        dados.append('codigoModulo', '01.17.02');
        dados.append('idGrupoContasReceber', id);
        try {
            const response = await GrupoContasReceber.getById(dados);
            if (response.status == 200) {
                setValues({'grupoContasReceber': response.data.data});
                document.getElementById("descricao").focus();
            }
        } catch(err) {
            console.log(err);
        }
    }

</script>

<template>
    <SnackBar ref="snackBar"></SnackBar>
        <v-card elevation="16">
            <v-card-title>
            <h2 v-show="$route.name !=='GrupoContasReceber.Update'">Novo Grupo de Contas a Receber</h2>
                <h2 v-show="$route.name =='GrupoContasReceber.Update'">Alteração de Grupo de Contas a Receber</h2>          
            </v-card-title>
            
            <form ref="formGrupoContasReceber" @submit.prevent="submit">
            <v-card-text>
            <v-row>
                <v-col>
                    <v-text-field label="Descrição"
                            id="descricao"
                            type="text"
                            maxlength="50"
                            v-model='grupoContasReceber.descricao'
                            :error-messages="errors['grupoContasReceber.descricao']"/>
                </v-col>
            </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnGrupoContasReceberGravar" >Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" 
                    variant="elevated" 
                    @click.prevent='$router.go(-1)' 
                    v-if="showBtnGravar" v-show="$route.name =='GrupoContasReceber.Create' || $route.name == 'GrupoContasReceber.Update' ">Voltar</v-btn>
                <v-btn color="primary" variant="elevated" v-if="showBtnGravar" 
                    v-show="$route.name !=='GrupoContasReceber.Create' && $route.name !== 'GrupoContasReceber.Update' " 
                    @click.prevent='closeModalCadastroGrupoContasReceber()'>Fechar</v-btn>
            </v-card-actions>
            </form>
        </v-card>
</template>