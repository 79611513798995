<script setup>
import { ref, onMounted, inject } from "vue";
import { Log } from "@/resources/logResource";
import config from "../../services/config";
import { useLog } from "../../store/log";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";

const snackBarApp = inject("snackBarApp");
const logStore = useLog();
let lancamentos = ref({});
const total = ref(0);
const pageSize = ref(config.maxPerPage);
const currentPage = ref(logStore.currentPage);
const dataInicial = ref(logStore.dataInicial);
const dataFinal = ref(logStore.dataFinal);
const idUsuario = ref(logStore.idUsuario);

const usuarios = ref([]);

onMounted(() => {
    getUsuarios();
    filtrar();
});

async function filtrar(mudouPagina = false) {
    let start = logStore.start;
    let limit = logStore.limit;

    logStore.setDataInicial(dataInicial.value);
    logStore.setDataFinal(dataFinal.value);
    logStore.setIdUsuario(idUsuario.value);

    let auxDataInicial = null;
    let auxDataFinal = null;

    if(dataInicial.value != '') {
        auxDataInicial = Converte.datePtBrToIso(dataInicial.value); 
    }

    if(dataFinal.value != '') {
        auxDataFinal = Converte.datePtBrToIso(dataFinal.value); 
    }


    if(mudouPagina == false) {
        handleCurrentChange(1);
        return;
    }

    try {
        const response = await Log.filtrar(start, limit, idUsuario.value, auxDataInicial, auxDataFinal);
        lancamentos.value = response.data.data;
        total.value = parseInt(response.data.total);
    }
    catch(err) {
        console.log(err);
    }
}

function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    logStore.setCurrentPage(val);
    logStore.setStart(start);
    filtrar(true);
}


async function getUsuarios() {
    let primeiroRegistro = { idUsuario: null, nome: "" };

    try {
        let response = await Log.getUsuarios();
        usuarios.value  = response.data.data;
        usuarios.value.unshift(primeiroRegistro);

        if(idUsuario.value == '') {
             idUsuario.value = response.data.data[0].idUsuario;
             return;
        }
    }
    catch(err) {
        snackBarApp.value.open('Erro ao buscar os usuários');
        console.log(err);
    }   
}

</script>

<template>
    <v-row>
        <v-col>
            <h1>Consulta de Log de Usuários</h1>
        </v-col>
    </v-row>

    <v-row >
        <v-col cols="12">
            <v-form>
                <v-row>
                    <v-col cols="3">
                        <Data label="Data Inicial" type="text" v-model="dataInicial"></Data>
                    </v-col>
                    <v-col cols="3">
                        <Data label="Data Final" type="text" v-model="dataFinal"></Data>
                    </v-col>
                    <v-col cols="6">
                        <v-select
                            :items="usuarios"
                            item-title="nome"
                            item-value="idUsuario"
                            label="Usuário"
                            v-model="idUsuario"
                        >
                            <template v-slot:append>
                                <v-btn color="primary" @click.prevent="filtrar()" prepend-icon="mdi-magnify">Filtrar</v-btn>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </v-row>
    
    <v-card elevation="16" class="mt-3">
        <v-table density="compact">
            <thead>
                <tr>
                    <th>Data</th>
                    <th>Hora</th>
                    <th>Login</th>
                    <th>Nome do Usuário</th>
                    <th>Descrição</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="o in lancamentos" :key="o">
                    <td>{{ o.data }}</td>
                    <td>{{ o.hora }}</td>
                    <th>{{ o.loginUsuario }}</th>
                    <td>{{ o.nomeUsuario }}</td>
                    <td>{{ o.descricao }}</td>
                </tr>
            </tbody>
        </v-table>
    </v-card>

    <div class="block text-right">
        <v-pagination @update:modelValue="handleCurrentChange" v-model="currentPage" :length="Math.ceil(total / pageSize)" :total-visible="7" :show-first-last-page="true">
        </v-pagination>
    </div>
</template>