<script setup>
import { onMounted, inject, defineProps, defineEmits, ref } from "vue";

import Moeda from "../Utils/moeda.vue";
import Data from "../Utils/data.vue";
import Porcentagem from "../Utils/porcentagem.vue";
import { string, number, object, addMethod } from "yup";
import { useRoute } from "vue-router";
import { PedidoCompra } from "../../resources/pedidoCompraResource";
import { Converte } from "../../utils/converte";
import { GrupoContasPagar } from "../../resources/grupoContasPagarResource";
import { SubgrupoContasPagar } from "../../resources/subgrupoContasPagarResource";
import { TipoDocumento} from "../../resources/tipoDocumentoResource";
import GrupoContasPagarCreate from "../GruposContasPagar/GrupoContasPagarCreate.vue";
import SubgrupoContasPagarCreate from "../SubgruposContasPagar/SubgrupoContasPagarCreate.vue";
import TipoDocumentoCreate from "../TiposDocumentos/TipoDocumentoCreate.vue";
import AlterarParcela from "./PedidoCompraUpdateParcela.vue"
import { useField, useForm } from "vee-validate";
import { PermissaoService } from "../../services/permissaoService";
import { format, parse, addDays, isValid } from "date-fns";
import { valorFormat } from "../../services/filtros";

const snackBarApp = inject("snackBarApp");

const props = defineProps({
    propPedidoCompra: {
        type: Object,
    },
    operacao: {
        type: String,
    },
});

const realPorcentagem = ref([
    { text: "Reais", value: "R" },
    { text: "Porcentagem", value: "P" },
]);

const itemsND = ref([
    { text: "Número de Dias", value: "N" },
    { text: "Todo Dia", value: "D" },
]);

addMethod(string, "valor_maior_zero", function valorMaiorZero(message) {
    return string().test(
        "valor-maior-zero", // Name
        message, // Msg
        function (valor) {
            if(Converte.stringToFloat(valor) <= 0) {
                return false;
            }
            return true;
        }
    );
});

addMethod(number, "numero_dias", function numero_dias(message) {
    return number().test(
        "numero_dias", // Name
        message, // Msg
        function (dias) {
            if(pedidoCompra.value.nroDiasTodoDia == "D" && (dias < 1 || dias > 31)) {
                return false;
            }
            return true;
        }
    );
});

const operacao = ref(props.operacao);
const route = useRoute();
const isReadOnly = ref(false);

let dialogGrupoContasPagar = ref(false);
let dialogSubgrupoContasPagar = ref(false);
let dialogAlterarParcela = ref(false);
const dialogTiposDocumentos = ref(false);

const gruposContasPagar = ref([]);
const subgruposContasPagar = ref([]);
const tiposDocumentos = ref([]);

let primeiraCarga = true;
let labelNroDiasTodoDia = ref('Número de Dias');

let lancamentoAlterar = ref(null);

const emit = defineEmits(['close-dialog', 'faturou']);

const validationSchema = object({
    pedidoCompra: object({
        idPedidoCompra: undefined,
        documento: string().max(10).label("O documento"),
        dataEmissao: undefined,
        dataEntrega: undefined,
        dataFaturamento: string().required("A data de faturamento é obrigatória").typeError("A data de faturamento é obrigatória").label("A data de faturamento"),  
        valorTotalProdutos: undefined,
        descRP: undefined,
        desconto: undefined,
        acreRP: undefined,
        acrescimo: undefined,
        valorTotalComDesconto: undefined,
        nroDiasTodoDia: undefined,
        dias:number().numero_dias("O dia deve ser entre 1 e 31").required("O número de dias/todo dia é obrigatório").typeError("O número de dias/todo dia é obrigatório").label("O número de dias/todo dia"),
        carencia:number().max(99999).typeError("O carência deve ser um número inteiro.").label("A carência"),
        numeroParcelas: number().max(999).min(1).typeError("O número de parcelas deve ser maior ou igual a 1.").required("O número de parcelas é obrigatório").label("O número de parcelas"),
        entrada: undefined,
        valorParcela: undefined,         
        idTipoDocumento: undefined,
        idGrupoContasPagar: undefined,
        idSubgrupoContasPagar: undefined,
        lancamentos: undefined,
    }).required(),
});

const { handleSubmit, errors } = useForm({ validationSchema });

const pedidoCompra = ref({
    idPedidoCompra: useField("pedidoCompra.idPedidoCompra").value,
    documento: useField("pedidoCompra.documento").value,
    dataEmissao: useField("pedidoCompra.dataEmissao").value,
    dataEntrega: useField("pedidoCompra.dataEntrega").value,
    dataFaturamento: useField("pedidoCompra.dataFaturamento").value,
    valorTotalProdutos: useField("pedidoCompra.valorTotalProdutos").value,
    descRP: useField("pedidoCompra.descRP").value,
    desconto: useField("pedidoCompra.desconto").value,
    acreRP: useField("pedidoCompra.acreRP").value,
    acrescimo: useField("pedidoCompra.acrescimo").value,
    valorTotalComDesconto: useField("pedidoCompra.valorTotalComDesconto").value,
    nroDiasTodoDia: useField("pedidoCompra.nroDiasTodoDia").value,
    dias: useField("pedidoCompra.dias").value,
    carencia: useField("pedidoCompra.carencia").value,
    numeroParcelas: useField("pedidoCompra.numeroParcelas").value,
    entrada: useField("pedidoCompra.entrada").value,
    valorParcela: useField("pedidoCompra.valorParcela").value,
    idTipoDocumento: useField("pedidoCompra.idTipoDocumento").value,
    idGrupoContasPagar: useField("pedidoCompra.idGrupoContasPagar").value,
    idSubgrupoContasPagar: useField("pedidoCompra.idSubgrupoContasPagar").value,
    lancamentos: useField("pedidoCompra.lancamentos").value,
});

const submit = handleSubmit((values, actions) => {
    gerarParcelas(values, actions);
});

onMounted(() => {
    cleanData();
    document.getElementById("documento").focus();

    getGruposContasPagar();
    getTiposDocumentos();


    if(operacao.value == "C") {
       isReadOnly.value = true;
        getPedidoCompra(props.propPedidoCompra.idPedidoCompra);
    }
});


function cleanData() {
    pedidoCompra.value.idPedidoCompra = props.propPedidoCompra.idPedidoCompra;
    pedidoCompra.value.documento = props.propPedidoCompra.documento;
    pedidoCompra.value.dataEntrega = null;
    if(operacao.value == 'R') {
        pedidoCompra.value.dataEntrega = new Date().toLocaleDateString("pt-BR");
    }
    pedidoCompra.value.dataFaturamento = new Date().toLocaleDateString("pt-BR");
    pedidoCompra.value.dataEmissao = props.propPedidoCompra.data;
    pedidoCompra.value.valorTotalProdutos = 'R$ 0,00';
    pedidoCompra.value.descRP = 'R';
    pedidoCompra.value.desconto = 'R$ 0,00';
    pedidoCompra.value.acreRP = 'R';
    pedidoCompra.value.acrescimo = 'R$ 0,00';
    pedidoCompra.value.numeroParcelas = 1;
    pedidoCompra.value.nroDiasTodoDia = 'N';
    pedidoCompra.value.dias = 30;
    pedidoCompra.value.carencia = 0;
    pedidoCompra.value.entrada = 'R$ 0,00';
    pedidoCompra.value.valorParcela = 'R$ 0,00';
    pedidoCompra.value.idTipoDocumento = null;
    pedidoCompra.value.idGrupoContasPagar = null;
    pedidoCompra.value.idSubgrupoContasPagar = null;

    pedidoCompra.value.valorTotalAPagar = props.valorTotal;

    pedidoCompra.value.lancamentos = [];

    getTotais();

}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}

async function getTotais() {
    try {
        let response = await PedidoCompra.totais(pedidoCompra.value.idPedidoCompra);
        if (response.status == 200) {
            pedidoCompra.value.valorTotalProdutos = Converte.numberToCurrency(response.data.data.totalProdutos);
            if (route.name !== 'PedidosCompras.Show') {
                pedidoCompra.value.valorTotalAPagar = Converte.numberToCurrency(response.data.data.valorTotalAPagar);
            }
            calculaValorAPagar();
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar os totais do pedido de compra.');
        console.log(err);
    }
}


async function gravar() {
    let valorTotalPComDesconto = Converte.stringToFloat(pedidoCompra.value.valorTotalComDesconto);
    if(valorTotalPComDesconto < 0 ) {
        snackBarApp.value.open('O valor total dos produtos com desconto mais acréscimo deve ser maior ou igual a 0. Corrija o valor dos descontos dos produtos.', 6000);
        return;
    }


    let obj =  {};
    obj.idPedidoCompra = pedidoCompra.value.idPedidoCompra;
    obj.documento = pedidoCompra.value.documento;
    obj.descRP = pedidoCompra.value.descRP;
    obj.acreRP = pedidoCompra.value.acreRP;
    obj.desconto = Converte.stringToFloat(pedidoCompra.value.desconto);
    obj.acrescimo = Converte.stringToFloat(pedidoCompra.value.acrescimo);
    obj.valorTotalComDesconto = Converte.stringToFloat(pedidoCompra.value.valorTotalComDesconto);
    obj.entrada = Converte.stringToFloat(pedidoCompra.value.entrada);
    obj.carencia = parseInt(pedidoCompra.value.carencia);
    obj.numeroParcelas = pedidoCompra.value.numeroParcelas;
    obj.lancamentos = pedidoCompra.value.lancamentos;
    obj.dataEmissao = Converte.datePtBrToIso(pedidoCompra.value.dataEmissao);
    obj.nroTodoDia = pedidoCompra.value.nroDiasTodoDia;
    obj.dias = pedidoCompra.value.dias;
    obj.idTipoDocumento = pedidoCompra.value.idTipoDocumento;
    obj.idGrupoContasPagar = pedidoCompra.value.idGrupoContasPagar;
    obj.idSubgrupoContasPagar = pedidoCompra.value.idSubgrupoContasPagar;

    if(pedidoCompra.value.dataEntrega == '' || pedidoCompra.value.dataEntrega == 'Invalid date' || pedidoCompra.value.dataEntrega == null) {
        obj.dataEntrega = null;
    } else{
        obj.dataEntrega = Converte.datePtBrToIso(pedidoCompra.value.dataEntrega);
    }

    if(operacao.value == 'R' && obj.dataEntrega == null) {
        snackBarApp.value.open('Para fazer o recebimento do pedido é necessário preencher a data de entrega.', 6000);
        return;
    }

    if(pedidoCompra.value.dataFaturamento == '' || pedidoCompra.value.dataFaturamento == 'Invalid date') {
        obj.dataFaturamento = null;
    } else{
        obj.dataFaturamento = Converte.datePtBrToIso(pedidoCompra.value.dataFaturamento);
    }

    let dados = new FormData();
    dados.append('dados', JSON.stringify(obj));

    try {
        let response = await PedidoCompra.faturaAPrazo(dados);
        if (response.status == 200) {

            if (response.data.success == false) {
                snackBarApp.value.open('Não foi possível faturar pedido de compra a prazo, '+response.data.message, 6000);
                return;
            }

            if(operacao.value == 'F') {
                snackBarApp.value.open('Pedido de compra faturado a prazo com sucesso.');
            }
            else {
                snackBarApp.value.open('Pedido de compra faturado e recebido com sucesso.');
            }
            

            emit('faturouPedidoCompra', 'PRA');
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao faturar pedido de compra a prazo.');
        console.log(err);
    }
}
     
function changeDescRP() {
    if(pedidoCompra.value.descRP == 'P') {
        pedidoCompra.value.desconto = Converte.numberToPorcentagem(Converte.stringToFloat(pedidoCompra.value.desconto),2);
    }
    if(this.descRP == 'R') {
        pedidoCompra.value.desconto = Converte.numberToCurrency(Converte.stringToFloat(pedidoCompra.value.desconto));
    }

    calculaValorAPagar();
}

function changeAcreRP() {
    if(pedidoCompra.value.acreRP == 'P') {
        pedidoCompra.value.acrescimo = Converte.numberToPorcentagem(Converte.stringToFloat(pedidoCompra.value.acrescimo),2);
    }
    if(this.acreRP == 'R') {
        pedidoCompra.value.acrescimo = Converte.numberToCurrency(Converte.stringToFloat(pedidoCompra.value.acrescimo));
    }

    calculaValorAPagar();
}

function calculaValorAPagar() {

    let valorAPagar = 0;
    let desconto = Converte.stringToFloat(pedidoCompra.value.desconto);
    let acrescimo = Converte.stringToFloat(pedidoCompra.value.acrescimo);
    let descRP = pedidoCompra.value.descRP;
    let acreRP = pedidoCompra.value.acreRP;
    let valorTotalP = Converte.stringToFloat(pedidoCompra.value.valorTotalProdutos);
    let entrada = Converte.stringToFloat(pedidoCompra.value.entrada);
    let valorParcela = 0;

    if(descRP == 'R') {
        valorAPagar = valorTotalP - desconto;
    } else {
        valorAPagar = valorTotalP - (valorTotalP * desconto / 100);
    }

    if(acreRP == 'R') {
        valorAPagar = valorAPagar + acrescimo;
    } else {
        valorAPagar = valorAPagar +  (valorTotalP * acrescimo / 100);
    }

    pedidoCompra.value.valorTotalComDesconto = Converte.numberToCurrency(valorAPagar);

    valorParcela = (valorAPagar - entrada) / pedidoCompra.value.numeroParcelas;
    pedidoCompra.value.valorParcela = Converte.numberToCurrency(valorParcela);

}

async function getPedidoCompra(idPedidoCompra) {
    let dados = new FormData();
    dados.append('codigoModulo', '03.13.02');
    dados.append('idPedidoCompra', idPedidoCompra);

    try {

        let response = await PedidoCompra.getById(dados);
        if (response.status == 200) {

            pedidoCompra.value.documento = response.data.data.documento;

            if (route.name == 'PedidosCompras.Show' || response.data.data.formaPagamento !== null) {

                pedidoCompra.value.dataFaturamento = Converte.dateIsoToPtBr(response.data.data.dataFaturamento);
                pedidoCompra.value.dataEntrega = Converte.dateIsoToPtBr(response.data.data.dataEntrega);
                pedidoCompra.value.descRP = response.data.data.descRP;
                if (response.data.data.descRP == 'R') {
                    pedidoCompra.value.desconto = Converte.numberToCurrency(response.data.data.desconto);
                } else {
                    pedidoCompra.value.desconto = Converte.numberToPorcentagem(response.data.data.desconto, 2);
                }

                pedidoCompra.value.acreRP = response.data.data.acreRP;
                if (response.data.data.acreRP == 'R') {
                    pedidoCompra.value.acrescimo = Converte.numberToCurrency(response.data.data.acrescimo);
                } else {
                    pedidoCompra.value.acrescimo = Converte.numberToPorcentagem(response.data.data.acrescimo, 2);
                }
                pedidoCompra.value.entrada  = Converte.numberToCurrency(response.data.data.entrada);
                pedidoCompra.value.carencia = response.data.data.carencia;
                pedidoCompra.value.numeroParcelas = response.data.data.numeroParcelas;
                pedidoCompra.value.nroDiasTodoDia = response.data.data.nroTodoDia;
                pedidoCompra.value.dias = response.data.data.dias;
                pedidoCompra.value.idTipoDocumento = response.data.data.idTipoDocumento;
                pedidoCompra.value.idGrupoContasPagar = response.data.data.idGrupoContasPagar;
                pedidoCompra.value.idSubgrupoContasPagar = response.data.data.idSubgrupoContasPagar;
            }

            pedidoCompra.value.documento = response.data.data.documento;
            if(route.name !== 'PedidosCompras.Show' && operacao.value == 'R') {
                pedidoCompra.value.dataEntrega = new Date().toLocaleDateString("pt-BR");
            }

            calculaValorAPagar();

            getParcelas();
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar o pedido de compra.');
        console.log(err);
    }
}

async function getGruposContasPagar() {
    let start = 0;
    let limit = 999999;
    let sort = null;
    let primeiroRegistro = { idGrupoContasPagar: null, descricao: "" };

    const response = await GrupoContasPagar.get(start, limit, "", sort);
    if (response.status == 200) {
        gruposContasPagar.value = response.data.data;
        gruposContasPagar.value.unshift(primeiroRegistro);

        if (pedidoCompra.value.idGrupoContasPagar == null) {
           pedidoCompra.value.idGrupoContasPagar = response.data.data[0].idGrupoContasPagar;
            return;
        }
        getSubgruposContasPagar();
    }
}

async function getSubgruposContasPagar() {
    let dados = new FormData();
    dados.append("idGrupoContasPagar", pedidoCompra.value.idGrupoContasPagar);
    subgruposContasPagar.value = [];
    if (primeiraCarga == false) {
        pedidoCompra.value.idSubgrupoContasPagar = null;
    }

    const response = await SubgrupoContasPagar.getSubgrupos(dados);
    if (response.status == 200) {
        let primeiroRegistro = {
            idSubgrupoContasPagar: null,
            descricao: "",
            idGrupoContasPagar: null,
        };
        subgruposContasPagar.value = response.data.data;
        subgruposContasPagar.value.unshift(primeiroRegistro);
        if ((route.name !== "ContasPagar.Update" && route.name !== "ContasPagar.Show") || primeiraCarga == false) {
            pedidoCompra.value.idSubgrupoContasPagar = response.data.data[0].idSubgrupoContasPagar;
        }

        primeiraCarga = false;
    }
}

async function getTiposDocumentos() {
    let start = 0;
    let limit = 999999;
    let sort = null;
    let primeiroRegistro = { idTipoDocumento: null, descricao: "" };

    const response = await TipoDocumento.get(start, limit, "", sort);
    if (response.status == 200) {
        tiposDocumentos.value = response.data.data;
        tiposDocumentos.value.unshift(primeiroRegistro);

        if (pedidoCompra.value.idTipoDocumento == null) {
            pedidoCompra.value.idTipoDocumento = response.data.data[0].idTipoDocumento;
            return;
        }
    }
}

function gerarParcelas() {

    if (Converte.stringToFloat(pedidoCompra.value.valorTotalComDesconto) == 0) {
        snackBarApp.value.open('Para gerar as parecelas o campo valor total com descontos deve ser maior que R$ 0,00.', 6000);
        return;
    }

    let valorTotalComDesconto = Converte.stringToFloat(pedidoCompra.value.valorTotalComDesconto)
    let entrada = Converte.stringToFloat(pedidoCompra.value.entrada);
    let numeroParcelas = parseInt(pedidoCompra.value.numeroParcelas);
    let valorParcela = Converte.stringToFloat(pedidoCompra.value.valorParcela);
    let nroDiasTodoDia = pedidoCompra.value.nroDiasTodoDia;
    let dias = parseInt(pedidoCompra.value.dias);
    let carencia = parseInt(pedidoCompra.value.carencia);
    let dataVencimento = parse(Converte.datePtBrToIso(pedidoCompra.value.dataEmissao), "yyyy-MM-dd", new Date());
    dataVencimento = addDays(dataVencimento, carencia);

    let valorTotalParcelas = valorTotalComDesconto - entrada;

    pedidoCompra.value.lancamentos = [];

    let auxValorParcela = 0;

    let i = 1;
    let mes = format(dataVencimento, "MM");
    let ano = format(dataVencimento, "yyyy");

    while (numeroParcelas >= i) {
        if (numeroParcelas == i) {
            auxValorParcela = valorTotalParcelas;

        } else {
            auxValorParcela = valorParcela;

        }

        if (nroDiasTodoDia == 'N') {
            dataVencimento = addDays(dataVencimento, dias);
        }
        else {
            let dia = parseInt(format(dataVencimento, "dd"));

            let auxString = '';

            let n = 0;

            if ((dia < dias) && (i == 1)) {
                auxString = dias + '/' + mes + '/' + ano;

                n = 1;
                while (isDate(auxString) == false) {
                    auxString = (parseInt(dias) - n) + '/' + mes + '/' + ano;
                    n = n + 1;
                }
            }
            else {
                if (i == 1) {
                    mes = parseInt(mes) + 1;
                    if (mes > 12) {
                        mes = 1;
                    }
                    mes = mes.toString();
                }

                if (parseInt(mes) == 12) {
                    auxString = dias + '/' + mes + '/' + ano;

                    n = 1;
                    while (isDate(auxString) == false) {
                        let auxD = parseInt(dias) - n;
                        auxD.toString();
                        auxString = auxD + '/12/' + ano.toString();
                        n = n + 1;
                    }
                    ano = parseInt(ano) + 1;
                    mes = '0';

                }
                else {
                    auxString = dias + '/' + mes + '/' + ano;
                    n = 1;
                    while (isDate(auxString) == false) {
                        let auxD = parseInt(dias) - n;
                        auxD.toString();
                        auxString = auxD + '/' + mes + '/' + ano;
                        n = n + 1;

                    }
                }
            }
            dataVencimento = auxString;
            mes = parseInt(mes) + 1;
            if (mes > 12) {
                mes = 1;
            }
            mes = mes.toString();
            
            dataVencimento = parse(dataVencimento, "dd/MM/yyyy", new Date());

        }

        let auxDataVencimento = dataVencimento;

        valorTotalParcelas = valorTotalParcelas - valorParcela;

        pedidoCompra.value.lancamentos.push({
            'parcela': i,
            'valor': auxValorParcela.toString(),
            'dataVencimento': format(auxDataVencimento, "dd/MM/yyyy"),
            'documento': pedidoCompra.value.documento,
            'observacoes': ''
        });
        i++;
    }

    dataVencimento = null;
}

function isDate(date) {
    return isValid(parse(date, "dd/MM/yyyy", new Date()));
}

function changeNroDiasTodoDia() {
    if (pedidoCompra.value.nroDiasTodoDia == "N") {
        labelNroDiasTodoDia.value = "Número de Dias";
    } else {
        labelNroDiasTodoDia.value = "Todo Dia";
    }
}

function alterouLancamento(lanc) {
    if(lanc == null) {
        return;
    }
    
    pedidoCompra.value.lancamentos.forEach((item) => {
        if(item.parcela == lanc.parcela) {
            item.documento = lanc.documento;
            item.valor = Converte.stringToFloat(lanc.valor).toFixed(2);
            item.dataVencimento = lanc.dataVencimento;
            item.observacoes = lanc.observacoes;
        }
    });

    dialogAlterarParcela.value = false;
}

function  openModalAlterarParcela(lanc) {
    lancamentoAlterar.value = Object.assign({}, lanc);
    dialogAlterarParcela.value = true;
}

async function getParcelas() {
    try {
        let response = await PedidoCompra.getParcelas(pedidoCompra.value.idPedidoCompra);
        pedidoCompra.value.lancamentos = response.data.data;
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar as parcelas do pedido de compra.');
        console.log(err);
    }
}


</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2 v-show="operacao !== 'C'">Faturamento do Pedido de Compra a Prazo</h2>
            <h2 v-show="operacao == 'C'">Pedido de Compra Faturado a Prazo</h2>
        </v-card-title>
        <form ref="formFaturaPedidoCompraAPrazo" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <v-text-field label="Documento" id="documento" 
                            v-model="pedidoCompra.documento" maxlength="10" :readonly="isReadOnly"
                            :error-messages="errors['pedidoCompra.documento']" />
                    </v-col>
                    <v-col cols="3">
                        <Data label="Data do Faturamento" id="dataFaturamento" v-model="pedidoCompra.dataFaturamento" :readonly="isReadOnly"/>
                    </v-col>    
                    <v-col cols="3">
                        <v-text-field label="Valor Total Produtos" id="valorTotalProdutos" v-model="pedidoCompra.valorTotalProdutos" readOnly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-select
                                :items="realPorcentagem"
                                item-title="text"
                                item-value="value"
                                label="Desconto em R$ ou em %"
                                v-model="pedidoCompra.descRP"
                                :readonly="isReadOnly"
                                @update:modelValue="changeDescRP()"
                         ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3" v-show="pedidoCompra.descRP == 'R'">
                        <Moeda label="Desconto em (R$)" id="desconto" v-model="pedidoCompra.desconto" @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3" v-show="pedidoCompra.descRP == 'P'">
                        <Porcentagem label="Desconto em (%)" id="desconto" v-model="pedidoCompra.desconto" @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <v-select
                                :items="realPorcentagem"
                                item-title="text"
                                item-value="value"
                                label="Acréscimo em R$ ou em %"
                                v-model="pedidoCompra.acreRP"
                                :readonly="isReadOnly"
                                @update:modelValue="changeAcreRP()"
                         ></v-select>
                    </v-col>
                    <v-col cols="3" v-show="pedidoCompra.acreRP == 'R'">
                        <Moeda label="Acréscimo em (R$)" id="acrescimo" v-model="pedidoCompra.acrescimo" @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3" v-show="pedidoCompra.acreRP == 'P'">
                        <Porcentagem label="Acréscimo em (%)" id="acrescimo" v-model="pedidoCompra.acrescimo" @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor Total com Descontos e Acréscimos" id="valorTotalComDesconto" v-model="pedidoCompra.valorTotalComDesconto" readonly tabindex="-1" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-text-field
                            label="Número de Parcelas"
                            id="numeroParcelas"
                            type="text"
                            v-model="pedidoCompra.numeroParcelas"
                            maxlength="9"
                            :readonly="isReadOnly"
                            :error-messages="errors['pedidoCompra.numeroParcelas']"
                            @blur="calculaValorAPagar()"
                        />
                    </v-col>
                    <v-col cols="3">
                        <v-select
                                :items="itemsND"
                                item-title="text"
                                item-value="value"
                                label="Nro.Dias/Todo Dia"
                                v-model="pedidoCompra.nroDiasTodoDia"
                                :readonly="isReadOnly"
                                :change = "changeNroDiasTodoDia()"
                         ></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            :label= labelNroDiasTodoDia
                            id="dias"
                            type="text"
                            v-model="pedidoCompra.dias"
                            maxlength="9"
                            :readonly="isReadOnly"
                            :error-messages="errors['pedidoCompra.dias']"
                        />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            label="Carência de Dias 1ªParcela"
                            id="carencia"
                            type="text"
                            v-model="pedidoCompra.carencia"
                            maxlength="4"
                            :readonly="isReadOnly"
                            :error-messages="errors['pedidoCompra.carencia']"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <Moeda label="Entrada(R$)" id="entrada" v-model="pedidoCompra.entrada" @blur="calculaValorAPagar()" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor de Cada Parcela" id="valorParcela" v-model="pedidoCompra.valorParcela" readonly tabindex="-1" />
                    </v-col>
                    <v-col cols="6" v-show="!isReadOnly">
                        <v-select
                                :items="tiposDocumentos"
                                item-title="descricao"
                                item-value="idTipoDocumento"
                                label="Tipo de Documento"
                                v-model="pedidoCompra.idTipoDocumento"
                                :readonly="isReadOnly"
                         >
                         <template v-slot:append>
                                <v-tooltip text="Cadastra um novo tipo de documento.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            v-bind="props"
                                            color="primary"
                                            @click="dialogTiposDocumentos = true"
                                            id="btnNovoTipoDocumento"
                                            v-show="temPermissao('01.04.01') && route.name !== 'PedidosCompras.Show'"
                                        >
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogTiposDocumentos" transition="dialog-top-transition">
                                    <TipoDocumentoCreate @cadastrouTipoDocumento="getTiposDocumentos()" @close-dialog="dialogTiposDocumentos = false" />
                                </v-dialog>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row v-show="!isReadOnly">
                    <v-col cols="6">
                        <v-select
                                :items="gruposContasPagar"
                                item-title="descricao"
                                item-value="idGrupoContasPagar"
                                label="Grupo de Contas a Pagar"
                                v-model="pedidoCompra.idGrupoContasPagar"
                                :readonly="isReadOnly"
                                @update:modelValue="getSubgruposContasPagar()">
                         >
                         <template v-slot:append>
                                <v-tooltip text="Cadastra um novo grupo de contas a pagar.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            v-bind="props"
                                            color="primary"
                                            @click="dialogGrupoContasPagar = true"
                                            id="btnNovoGrupoContasPagar"
                                            v-show="temPermissao('01.15.01') && route.name !== 'PedidosCompras.Show'">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogGrupoContasPagar" transition="dialog-top-transition">
                                    <GrupoContasPagarCreate @cadastrouGrupoContasPagar="getGruposContasPagar()" @close-dialog="dialogGrupoContasPagar = false" />
                                </v-dialog>
                            </template>

                        
                        </v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-select
                                :items="subgruposContasPagar"
                                item-title="descricao"
                                item-value="idSubgrupoContasPagar"
                                label="Subgrupo de Contas a Pagar"
                                v-model="pedidoCompra.idSubgrupoContasPagar"
                                :readonly="isReadOnly"
                         >
                         <template v-slot:append>
                                <v-tooltip text="Cadastra um novo subgrupo de contas a pagar.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            v-bind="props"
                                            color="primary"
                                            @click="dialogSubgrupoContasPagar = true"
                                            id="btnNovoSubgrupoContasPagar"
                                            v-show="temPermissao('01.16.01') && route.name !== 'PedidosCompras.Show'">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogSubgrupoContasPagar" transition="dialog-top-transition">
                                    <SubgrupoContasPagarCreate @cadastrouSubgrupoContasPagar="getGruposContasPagar();" @close-dialog="dialogSubgrupoContasPagar = false" />
                                </v-dialog>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="3" v-show="operacao == 'R' || route.name == 'PedidosCompras.Show'">
                        <Data label="Data da Entrega" id="dataEntrega" v-model="pedidoCompra.dataEntrega" :readonly="isReadOnly"/>
                    </v-col>
                    <v-col>
                        <v-btn v-show="operacao !== 'C'" color="primary" variant="elevated" @click.prevent="gerarParcelas()" id="btnGerarParcelas">Gerar Parcelas</v-btn>
                    </v-col>
                </v-row>
                <v-card class="mt-8" v-show="pedidoCompra.lancamentos && pedidoCompra.lancamentos.length">
                    <v-card-text>

                        <v-table density="compact">
                            <thead>
                                <tr>
                                    <th class="text-center" v-show="!isReadOnly">Ações</th>
                                    <th>Parcela</th>    
                                    <th>Data Vencimento</th>
                                    <th>Valor da Parcela</th>
                                    <th>Documento</th>
                                    <th>Observações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="o in pedidoCompra.lancamentos" :key="o">
                                    <td v-show="!isReadOnly">
                                        <v-tooltip text="Altera o lançamento.">
                                            <template v-slot:activator="{ props }">
                                                <v-btn
                                                    size="small"
                                                    v-bind="props"
                                                    variant="text"
                                                    icon="mdi-pencil"
                                                    @click.prevent="openModalAlterarParcela(o)">
                                                </v-btn>
                                            </template>
                                        </v-tooltip>
                                    </td>
                                    <td>{{ o.parcela }}</td>
                                    <td>{{ o.dataVencimento }}</td>
                                    <td>{{ valorFormat(o.valor) }}</td>
                                    <td>{{ o.documento }}</td>
                                    <td>{{ o.observacoes }}</td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-card-text>
                </v-card>
                <v-dialog v-model="dialogAlterarParcela" transition="dialog-top-transition">
                    <AlterarParcela :propLancamento="lancamentoAlterar" @close-dialog="dialogAlterarParcela = false"  @alterou-lancamento="alterouLancamento"/>
                </v-dialog>
            </v-card-text>
            <v-card-actions>
                <v-btn v-show="operacao !== 'C'&& pedidoCompra.lancamentos && pedidoCompra.lancamentos.length" color="primary" variant="elevated" 
                    @click.prevent="gravar()" id="btnConfirmarAPrazo">Confirmar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="elevated"
                    @click.prevent="emit('close-dialog')">
                    Fechar
                    </v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>