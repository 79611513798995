<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { AgendamentoVisita } from "../../resources/agendamentoVisitaResource";
import { Cliente } from "../../resources/clienteResource";
import { Vendedor } from "../../resources/vendedorResource";
import SnackBar from "../Utils/SnackBar";
import { useField, useForm } from "vee-validate";
import { string, number, addMethod, object } from "yup";
import { usePermissoes } from "../../store/permissoes";
import ClienteBusca from "../Clientes/ClienteBusca.vue";
import VendedorBusca from "../Vendedores/VendedorBusca.vue";
import ClienteCreate from "../Clientes/ClienteCreate.vue";
import { Converte } from "../../utils/converte";
import Data from "../Utils/data.vue";


const isRealValidation = ref(null);
const validationCodigo = ref(null);
const snackBar = ref(null);
const route = useRoute();
const isReadOnly = ref(false);
let dialogCliente = ref(false);
let dialogBuscaCliente = ref(false);
let dialogBuscaVendedor = ref(false);
let showBtnGravar = ref(true);

const permissoesStore = usePermissoes();
const itemSituacao = ref([
    { text: "Pendente", value: "P" },
    { text: "Cancelado", value: "C" },
    { text: "Visitado", value: "V" },
]);

addMethod(number, "codigo_agendamento_visita_existe", function codigoAgendamentoVisitaExiste(message) {
    return number().test(
        "codigo-agendamento-visita-existe",
        message, // Msg
        function (codigo_agendamento_visita) {
            if (isRealValidation.value == null) {
                return true;
            }
            if (!isRealValidation.value) {
                return validationCodigo.value;
            }
            isRealValidation.value = false;
            validationCodigo.value = false;

            return new Promise((resolve) => {
                AgendamentoVisita.codigoExiste(codigo_agendamento_visita, agendamentoVisita.value.idAgendamentoVisita).then((res) => {
                    if (res.data == false) {
                        validationCodigo.value = true;
                        resolve(true);
                    }

                    resolve(false);
                });
            });
        }
    );
});

const validationSchema = object({
    agendamentoVisita: object({
        idAgendamentoVisita: undefined,
        codigo: number()
            .min(1)
            .max(999999999)
            .codigo_agendamento_visita_existe("Esse código já está sendo usado por outro agendamento, tente outro código.")
            .typeError("O código deve ser um número")
            .label("O código"),
        data: string().required().label("O campo da data do cadastramento "),
        hora: string().max(18).label("O horário"),
        codigoVendedor: number().typeError("O código do vendedor deve ser um número").label("O código do vendedor"),
        nomeVendedor: undefined,
        codigoCliente: number().required("O código do cliente é obrigatório").typeError("O código do cliente deve ser um número").label("O código do cliente"),
        nomeCliente: undefined,
        situacao: string().required("A situação é obrigatória").label("A situação"),
        idCliente: undefined,
        idVendedor: undefined,
        endereco: undefined,
        numero: undefined,
        complemento: undefined,
        bairro: undefined,
        cidade: undefined,
        estado: undefined,
        cep: undefined,
        fone1: undefined,
        fone2: undefined,
        fone3: undefined,
        email: undefined,
        contato: undefined,
        observacoes: undefined,
    }).required(),
});

const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const agendamentoVisita = ref({
    idAgendamentoVisita: useField("agendamentoVisita.idAgendamentoVisita").value,
    codigo: useField("agendamentoVisita.codigo").value,
    data: useField("agendamentoVisita.data").value,
    hora: useField("agendamentoVisita.hora").value,
    codigoVendedor: useField("agendamentoVisita.codigoVendedor").value,
    nomeVendedor: useField("agendamentoVisita.nomeVendedor").value,
    codigoCliente: useField("agendamentoVisita.codigoCliente").value,
    nomeCliente: useField("agendamentoVisita.nomeCliente").value,
    situacao: useField("agendamentoVisita.situacao").value,
    idCliente: useField("agendamentoVisita.idCliente").value,
    idVendedor: useField("agendamentoVisita.idVendedor").value,
    endereco: useField("agendamentoVisita.endereco").value,
    numero: useField("agendamentoVisita.numero").value,
    complemento: useField("agendamentoVisita.complemento").value,
    bairro: useField("agendamentoVisita.bairro").value,
    cidade: useField("agendamentoVisita.cidade").value,
    estado: useField("agendamentoVisita.estado").value,
    cep: useField("agendamentoVisita.cep").value,
    fone1: useField("agendamentoVisita.fone1").value,
    fone2: useField("agendamentoVisita.fone2").value,
    fone3: useField("agendamentoVisita.fone3").value,
    email: useField("agendamentoVisita.email").value,
    contato: useField("agendamentoVisita.contato").value,
    observacoes: useField("agendamentoVisita.observacoes").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    cleanForm();
    if (route.name == "AgendamentoVisita.Update" || route.name == "AgendamentoVisita.Show") {
        getAgendamentoVisita(route.params.id);
    }

    if (route.name == "AgendamentoVisita.Show") {
        isReadOnly.value = true;

        document.getElementById("btnBuscaVendedor").style.display = "none";
        document.getElementById("btnBuscaCliente").style.display = "none";
        document.getElementById("btnNovoCliente").style.display = "none";

        showBtnGravar.value = false;
    }

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    if (route.name == "AgendamentoVisita.Create") {
        agendamentoVisita.value.idAgendamentoVisita = null;
        proximoCodigo();
    }

    (agendamentoVisita.value.data = new Date().toLocaleDateString("pt-BR")),
        (agendamentoVisita.value.hora = new Date().toLocaleTimeString("pt-BR").slice(0, 5)),
        (agendamentoVisita.value.codigoVendedor = "");
    agendamentoVisita.value.nomeVendedor = "";
    agendamentoVisita.value.codigoCliente = "";
    agendamentoVisita.value.nomeCliente = "";
    agendamentoVisita.value.situacao = "P";
    agendamentoVisita.value.idCliente = null;
    agendamentoVisita.value.idVendedor = null;
    agendamentoVisita.value.endereco = "";
    agendamentoVisita.value.numero = "";
    agendamentoVisita.value.complemento = "";
    agendamentoVisita.value.bairro = "";
    agendamentoVisita.value.cidade = "";
    agendamentoVisita.value.estado = "";
    agendamentoVisita.value.cep = "";
    agendamentoVisita.value.fone1 = "";
    agendamentoVisita.value.fone2 = "";
    agendamentoVisita.value.fone3 = "";
    agendamentoVisita.value.email = "";
    agendamentoVisita.value.contato = "";
    agendamentoVisita.value.observacoes = "";
}

async function gravar(values, actions) {
    let dados = new FormData();

    if (values.agendamentoVisita.data == "") {
        values.agendamentoVisita.data = null;
    } else {
        values.agendamentoVisita.data = Converte.datePtBrToIso(values.agendamentoVisita.data); //format(parse(values.cliente.dataCadastramento, "dd/MM/yyyy", new Date()), "yyyy-MM-dd");
    }

    dados.append("data", JSON.stringify(values.agendamentoVisita));

    try {
        const response = await AgendamentoVisita.gravar(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBar.value.open("O agendamento não pode ser cadastrado, mensagem de erro: " + response.data.message, 6000);
                return;
            }
            if (route.name == "AgendamentoVisita.Update") {
                snackBar.value.open("Agendamento alterado com sucesso.");
            } else {
                snackBar.value.open("Novo agendamento cadastrado com sucesso.");
                actions.resetForm();
                cleanForm();
                document.getElementById("codigo").focus();
            }
        }
    } catch (err) {
        snackBar.value.open("Erro, mensagem de erro: " + err);
    }
}

async function getAgendamentoVisita(id) {
    let dados = new FormData();
    if (route.name == "AgendamentoVisita.Update") {
        dados.append("codigoModulo", "01.19.02");
    } else {
        dados.append("codigoModulo", "01.19.06");
    }

    dados.append("idAgendamentoVisita", id);
    try {
        const response = await AgendamentoVisita.getById(dados);
        if (response.status == 200) {
            response.data.data.data = Converte.dateIsoToPtBr(response.data.data.data);
            setValues({ agendamentoVisita: response.data.data });

            document.getElementById("codigo").focus();
        }
    } catch (err) {
        console.log(err);
    }
}

async function proximoCodigo() {
    try {
        const response = await AgendamentoVisita.proximoCodigo();
        if (response.status == 200) {
            agendamentoVisita.value.codigo = response.data;
        }
    } catch (err) {
        console.log(err);
    }
}

async function getCliente() {
    agendamentoVisita.value.idCliente = null;
    agendamentoVisita.value.nomeCliente = "";
    agendamentoVisita.value.endereco = "";
    agendamentoVisita.value.numero = "";
    agendamentoVisita.value.complemento = "";
    agendamentoVisita.value.bairro = "";
    agendamentoVisita.value.cidade = "";
    agendamentoVisita.value.estado = "";
    agendamentoVisita.value.cep = "";
    agendamentoVisita.value.fone1 = "";
    agendamentoVisita.value.fone2 = "";
    agendamentoVisita.value.fone3 = "";
    agendamentoVisita.value.contato = "";
    agendamentoVisita.value.email = "";

    if (agendamentoVisita.value.codigoCliente.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", agendamentoVisita.value.codigoCliente);
    dados.append("getInativos", false);
    dados.append("codigoModulo", "01.19");

    try {
        let response = await Cliente.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                if (response.data.message == "inativo") {
                    snackBar.value.open("Um cliente inativo não pode gerar agendamento de visitas.", 6000);
                    document.getElementById("codigoCliente").focus();
                    agendamentoVisita.value.codigoCliente = "";
                    return;
                }
                if (response.data.message == "bloqueado") {
                    snackBar.value.open("Um cliente bloqueado não pode gerar agendamento de visitas.", 6000);
                    document.getElementById("codigoCliente").focus();
                    agendamentoVisita.value.codigoCliente = "";
                    return;
                }
                document.getElementById("codigoCliente").focus();

                snackBar.value.open("Cliente não encontrado, tente outro código ou faça uma busca.", 6000);

                agendamentoVisita.value.codigoCliente = "";
                return;
            }
            agendamentoVisita.value.idCliente = response.data.data.idCliente;
            agendamentoVisita.value.nomeCliente = response.data.data.nome;
            agendamentoVisita.value.endereco = response.data.data.endereco;
            agendamentoVisita.value.numero = response.data.data.numero;
            agendamentoVisita.value.complemento = response.data.data.complemento;
            agendamentoVisita.value.bairro = response.data.data.bairro;
            agendamentoVisita.value.cidade = response.data.data.cidade;
            agendamentoVisita.value.estado = response.data.data.estado;
            agendamentoVisita.value.cep = response.data.data.cep;
            agendamentoVisita.value.fone1 = response.data.data.fone1;
            agendamentoVisita.value.fone2 = response.data.data.fone2;
            agendamentoVisita.value.fone3 = response.data.data.fone3;
            agendamentoVisita.value.contato = response.data.data.contato;
            agendamentoVisita.value.email = response.data.data.email;
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouCliente(cliente) {
    if (cliente == null) {
        return;
    }

    agendamentoVisita.value.codigoCliente = cliente.codigo;
    agendamentoVisita.value.nomeCliente = cliente.nome;
    agendamentoVisita.value.idCliente = cliente.idCliente;

    document.getElementById("codigoCliente").focus();
    document.getElementById("codigoCliente").blur();
    dialogBuscaCliente.value = false;
}

async function getVendedor() {
    agendamentoVisita.value.idVendedor = null;
    agendamentoVisita.value.nomeVendedor = "";

    if (agendamentoVisita.value.codigoVendedor.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", agendamentoVisita.value.codigoVendedor);
    dados.append("getInativosBloqueados", false);
    dados.append("codigoModulo", "01.19");

    try {
        let response = await Vendedor.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                if (response.data.message == "inativo") {
                    snackBar.value.open("Um vendedor inativo não pode fazer agendamento de visitas.", 6000);
                    document.getElementById("codigoVendedor").focus();
                    agendamentoVisita.value.codigoVendedor = "";
                    return;
                }
                if (response.data.message == "bloqueado") {
                    snackBar.value.open("Um vendedor bloqueado não pode fazer agendamento de visitas.", 6000);
                    document.getElementById("codigoVendedor").focus();
                    agendamentoVisita.value.codigoVendedor = "";
                    return;
                }

                document.getElementById("codigoVendedor").focus();

                snackBar.value.open("Vendedor não encontrado, tente outro código ou faça uma busca.", 6000);

                agendamentoVisita.value.codigoVendedor = "";
                return;
            }
            agendamentoVisita.value.idVendedor = response.data.data.idVendedor;
            agendamentoVisita.value.nomeVendedor = response.data.data.nome;
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouVendedor(vendedor) {
    if (vendedor == null) {
        return;
    }

    agendamentoVisita.value.codigoVendedor = vendedor.codigo;
    agendamentoVisita.value.nomeVendedor = vendedor.nome;
    agendamentoVisita.value.idVendedor = vendedor.idVendedor;

    document.getElementById("codigoVendedor").focus();
    document.getElementById("codigoVendedor").blur();
    dialogBuscaVendedor.value = false;
}

function cadastrouCliente(codigoCliente) {
    if (codigoCliente == null) {
        return;
    }

    agendamentoVisita.value.codigoCliente = codigoCliente;

    document.getElementById("codigoCliente").focus();
    document.getElementById("codigoCliente").blur();
}
</script>

<template>
    <SnackBar ref="snackBar"></SnackBar>
    <v-card elevation="16">
        <form ref="formAgendamentoVisita" @submit.prevent="submit">
            <v-card-title>
                <h2 v-show="$route.name == 'AgendamentoVisita.Create'">Novo Agendamento de Visita</h2>
                <h2 v-show="$route.name == 'AgendamentoVisita.Update'">Alteração de Agendamento de Visita</h2>
                <h2 v-show="$route.name == 'AgendamentoVisita.Show'">Consulta de Agendamento de Visita</h2>
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col cols="2">
                        <v-text-field
                            label="Código"
                            id="codigo"
                            type="text"
                            v-model="agendamentoVisita.codigo"
                            :error-messages="errors['agendamentoVisita.codigo']"
                            maxlength="9"
                            @blur="
                                (e) => {
                                    isRealValidation = true;
                                    agendamentoVisita.codigo = e.target.value;
                                }
                            "
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="2">
                        <Data label="Data" id="data" type="text" v-model="agendamentoVisita.data" :error-messages="errors['agendamentoVisita.data']" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="2">
                        <v-text-field
                            label="Horário"
                            id="hora"
                            type="time"
                            v-model="agendamentoVisita.hora"
                            :error-messages="errors['agendamentoVisita.hora']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="2">
                        <v-text-field
                            label="Cód.Vendedor"
                            id="codigoVendedor"
                            type="text"
                            v-model="agendamentoVisita.codigoVendedor"
                            :error-messages="errors['agendamentoVisita.codigoVendedor']"
                            maxlength="9"
                            @blur="getVendedor()"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field label="Nome do Vendedor" id="nomeVendedor" type="text" v-model="agendamentoVisita.nomeVendedor" readonly tabindex="-1">
                            <template v-slot:append>
                                <v-tooltip text="Faz a busca de um vendedor por nome.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogBuscaVendedor = true" id="btnBuscaVendedor">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogBuscaVendedor" transition="dialog-top-transition">
                                    <VendedorBusca @selecionouVendedor="selecionouVendedor" @close-dialog="dialogBuscaVendedor = false" />
                                </v-dialog>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="2">
                        <v-select
                            :items="itemSituacao"
                            label="Situação"
                            v-model="agendamentoVisita.situacao"
                            item-title="text"
                            item-value="value"
                            id="cbSituacao"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="2">
                        <v-text-field
                            label="Cód.Cliente"
                            id="codigoCliente"
                            type="text"
                            v-model="agendamentoVisita.codigoCliente"
                            :error-messages="errors['agendamentoVisita.codigoCliente']"
                            maxlength="9"
                            @blur="getCliente()"
                            :readonly="isReadOnly"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field label="Nome do Cliente" id="nomeCliente" type="text" v-model="agendamentoVisita.nomeCliente" readonly tabindex="-1">
                            <template v-slot:append>
                                <v-tooltip text="Faz a busca de um cliente por nome/cpf.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogBuscaCliente = true" id="btnBuscaCliente">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogBuscaCliente" transition="dialog-top-transition">
                                    <ClienteBusca @selecionouCliente="selecionouCliente" @close-dialog="dialogBuscaCliente = false" />
                                </v-dialog>
                                &nbsp;
                                <v-tooltip text="Cadastra um novo cliente.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogCliente = true" id="btnNovoCliente">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogCliente" transition="dialog-top-transition">
                                    <ClienteCreate @cadastrouCliente="cadastrouCliente" @close-dialog="dialogCliente = false" />
                                </v-dialog>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="9">
                        <v-text-field label="Endereço" id="endereco" type="text" v-model="agendamentoVisita.endereco" maxlength="50" readonly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Número" id="numero" type="text" v-model="agendamentoVisita.numero" maxlength="10" readonly tabindex="-1" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-text-field label="Complemento" id="complemento" type="text" v-model="agendamentoVisita.complemento" maxlength="30" readonly tabindex="-1" />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field label="Bairro" id="bairro" type="text" v-model="agendamentoVisita.bairro" maxlength="30" readonly tabindex="-1" />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field label="Cidade" id="cidade" type="text" v-model="agendamentoVisita.cidade" maxlength="30" readonly tabindex="-1" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="2">
                        <v-text-field label="Estado" id="estado" type="text" v-model="agendamentoVisita.estado" maxlength="2" readonly tabindex="-1" />
                    </v-col>
                    <v-col cols="2">
                        <v-text-field label="CEP" id="cep" type="text" v-model="agendamentoVisita.cep" maxlength="10" readonly tabindex="-1" />
                    </v-col>
                    <v-col cols="2">
                        <v-text-field label="Telefone 1" id="fone1" type="text" v-model="agendamentoVisita.fone1" maxlength="15" readonly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Telefone 2" id="fone2" type="text" v-model="agendamentoVisita.fone2" maxlength="15" readonly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Telefone 3" id="fone3" type="text" v-model="agendamentoVisita.fone3" maxlength="15" readonly tabindex="-1" />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="3">
                        <v-text-field label="Contato" id="contato" type="text" v-model="agendamentoVisita.contato" maxlength="30" readonly tabindex="-1" />
                    </v-col>
                    <v-col cols="9">
                        <v-text-field label="Email" id="email" type="text" v-model="agendamentoVisita.email" maxlength="100" readonly tabindex="-1" />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-textarea label="Observações" id="observacoes" type="text" v-model="agendamentoVisita.observacoes" :readonly="isReadOnly" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn v-show="showBtnGravar && $route.name !== 'AgendamentoVisita.Show'" color="primary" variant="elevated" type="submit" id="btnAgendamentoVisitaGravar"
                    >Salvar</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="elevated"
                    @click.prevent="$router.go(-1)"
                    v-show="$route.name == 'AgendamentoVisita.Create' || $route.name == 'AgendamentoVisita.Update' || $route.name == 'AgendamentoVisita.Show'"
                    >Voltar</v-btn
                >
            </v-card-actions>
        </form>
    </v-card>
</template>
