<script setup>
import { ref, onMounted, defineEmits } from "vue";
import { useRoute } from "vue-router";
import { Cliente } from "../../resources/clienteResource";
import { PermissaoService } from "../../services/permissaoService";
import { GrupoClientes } from "../../resources/grupoClientesResource";
import SnackBar from "../Utils/SnackBar";
import { useField, useForm } from "vee-validate";
import { string, addMethod, object, number } from "yup";
import { usePermissoes } from "../../store/permissoes";
import GrupoClientesCreate from "../GruposClientes/GrupoClientesCreate.vue";
import Moeda from "../Utils/moeda.vue";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";
import { parse, format } from "date-fns";

const isRealValidation = ref(null);
const validationCodigo = ref(null);
const snackBar = ref(null);
const route = useRoute();
const emit = defineEmits(["close-dialog", "cadastrou-cliente"]);
const isReadOnly = ref(false);
const inputCodigoCliente = ref(null)

let dialogGrupoClientes = ref(false);
let showBtnGravar = ref(true);
const permissoesStore = usePermissoes();
const itemsAtivoInativoBloqueado = ref([
    { text: "Ativo", value: "A" },
    { text: "Inativo", value: "I" },
    { text: "Bloqueado", value: "B" },
]);

addMethod(number, "codigo_cliente_existe", function codigoClienteExiste(message) {
    return number().test(
        "codigo-cliente-existe", // Name
        message, // Msg
        function (codigo_cliente) {
            if (isRealValidation.value == null) {
                return true;
            }
            if (!isRealValidation.value) {
                return validationCodigo.value;
            }
            isRealValidation.value = false;
            validationCodigo.value = false;

            return new Promise((resolve) => {
                Cliente.codigoExiste(codigo_cliente, cliente.value.idCliente).then((res) => {
                    if (res.data == false) {
                        validationCodigo.value = true;
                        resolve(true);
                    }
                    resolve(false);
                });
            });
        }
    );
});

const validationSchema = object({
    cliente: object({
        idCliente: undefined,
        codigo: number().codigo_cliente_existe("Esse código já está sendo usado por outro cliente, tente outro código.").required("O código é obrigatório"),
        nome: string().max(50).label("O nome do cliente").required("O nome do cliente é obrigatório"),
        apelido: string().max(50).label("O apelido do cliente"),
        endereco: string().max(50).label("O endereço "),
        numero: string().max(10).label("O número "),
        complemento: string().max(30).label("O complemento "),
        bairro: string().max(30).label("O bairro "),
        cidade: string().max(30).label("A cidade "),
        estado: string().max(2).label("O estado "),
        cep: string().max(10).label("O CEP "),
        cnpj: string().max(18).label("O CPF/CNPJ "),
        inscricao: string().max(20).label("A inscrição estadual/RG "),
        fone1: string().max(16).label("O telefone 1 "),
        fone2: string().max(16).label("O telefone 2 "),
        fone3: string().max(16).label("O telefone 3 "),
        email: string().max(100).label("O e-mail "),
        observacoes: undefined,
        dataCadastramento: string().required().label("O campo da data do cadastramento "),

        // date().
        //     transform((value, originalValue) => parse(originalValue, 'dd/MM/yyyy', new Date())).
        //     typeError('A data de cadastramento deve ser uma data válida').
        //     label('A data de cadastramento '),
        ativoInativoBloqueado: undefined,
        motivoBloqueado: string().max(50).label("O motivo do bloqueio"),
        motivoInatividade: string().max(50).label("O motivo da inatividade"),
        fone4: string().max(16).label("O telefone 4 "),
        contato: string().max(30).label("O contato "),
        idGrupoClientes: undefined,
        dataNascimento: undefined,

        // date().nullable().transform((value, originalValue) => {
        //     if(originalValue == '' || originalValue == null) {
        //         return null;
        //     }
        //     return parse(originalValue, 'dd-MM-yyyy', new Date())
        // }).typeError('Data inválida. Deixe em branco ou digite uma data válida').default(''),
        dataExpedicaoRG: undefined,
        // .nullable().transform((value, originalValue) => {
        //     // if(originalValue == '' || originalValue == null) {
        //     //     return null;
        //     // }
        //     const input = document.getElementById("dataExpedicaoRG");
        //     if (input.validity.badInput == false && input.value == '') {
        //         console.log("Content of input OK.");
        //         return null;
        //     }

        //     if(input.validity.badInput == true) {
        //         console.log('aqui');
        //         input.value = '';
        //         return null;
        //     }

        //     return parse(originalValue, 'yyyy-MM-dd', new Date())
        // }).typeError('Data inválida. Deixe em branco ou digite uma data válida').default(''),
        mesmoEnderecoCobranca: undefined,
        enderecoCobranca: string().max(50).label("O endereço de cobrança "),
        numeroCobranca: string().max(10).label("O número de cobrança "),
        complementoCobranca: string().max(30).label("O complemento de cobrança "),
        bairroCobranca: string().max(30).label("O bairro de cobrança "),
        cidadeCobranca: string().max(30).label("A cidade de cobrança "),
        estadoCobranca: string().max(2).label("O estado de cobrança "),
        cepCobranca: string().max(10).label("O CEP de cobrança "),
        trabalho: string().max(50).label("O trabalho "),
        cargo: string().max(30).label("O cargo "),
        enderecoEmprego: string().max(50).label("O endereço do emprego "),
        bairroEmprego: string().max(30).label("O bairro do emprego "),
        numeroEmprego: string().max(10).label("O número do emprego "),
        complementoEmprego: string().max(30).label("O complemento do emprego "),
        cidadeEmprego: string().max(30).label("A cidade do emprego "),
        estadoEmprego: string().max(2).label("O estado do emprego "),
        responsavelEmprego: string().max(50).label("O responsável do emprego "),
        telefoneEmprego: string().max(16).label("O telefone do emprego "),
        rendaEmprego: undefined,
        dataAdmissaoEmprego: undefined,
        // date().nullable().transform((value, originalValue) => {
        //     if(originalValue == '' || originalValue == null) {
        //         return null;
        //     }
        //     return parse(originalValue, 'dd/MM/yyyy', new Date())
        // }).typeError('Data inválida. Deixe em branco ou digite uma data válida').default(''),
        referencia1: string().max(50).label("A referência 1 "),
        telefoneReferencia1: string().max(16).label("O telefone da referência 1 "),
        referencia2: string().max(50).label("A referência 2 "),
        telefoneReferencia2: string().max(16).label("O telefone da referência 2 "),
        referencia3: string().max(50).label("A referência 3 "),
        telefoneReferencia3: string().max(16).label("O telefone da referência 3 "),
        temLimiteCredito: undefined,
        limite: undefined,
    }).required(),
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const gruposClientes = ref([]);

const cliente = ref({
    idCliente: useField("cliente.idCliente").value,
    codigo: useField("cliente.codigo").value,
    nome: useField("cliente.nome").value,
    apelido: useField("cliente.apelido").value,
    endereco: useField("cliente.endereco").value,
    numero: useField("cliente.numero").value,
    complemento: useField("cliente.complemento").value,
    bairro: useField("cliente.bairro").value,
    cidade: useField("cliente.cidade").value,
    estado: useField("cliente.estado").value,
    cep: useField("cliente.cep").value,
    cnpj: useField("cliente.cnpj").value,
    inscricao: useField("cliente.inscricao").value,
    fone1: useField("cliente.fone1").value,
    fone2: useField("cliente.fone2").value,
    fone3: useField("cliente.fone3").value,
    email: useField("cliente.email").value,
    observacoes: useField("cliente.observacoes").value,
    dataCadastramento: useField("cliente.dataCadastramento").value,
    ativoInativoBloqueado: useField("cliente.ativoInativoBloqueado").value,
    motivoInatividade: useField("cliente.motivoInatividade").value,
    motivoBloqueado: useField("cliente.motivoBloqueado").value,
    fone4: useField("cliente.fone4").value,
    contato: useField("cliente.contato").value,
    idGrupoClientes: useField("cliente.idGrupoClientes").value,
    dataNascimento: useField("cliente.dataNascimento").value,
    dataExpedicaoRG: useField("cliente.dataExpedicaoRG").value,
    mesmoEnderecoCobranca: useField("cliente.mesmoEnderecoCobranca").value,
    enderecoCobranca: useField("cliente.enderecoCobranca").value,
    numeroCobranca: useField("cliente.numeroCobranca").value,
    complementoCobranca: useField("cliente.complementoCobranca").value,
    cidadeCobranca: useField("cliente.cidadeCobranca").value,
    estadoCobranca: useField("cliente.estadoCobranca").value,
    cepCobranca: useField("cliente.cepCobranca").value,
    trabalho: useField("cliente.trabalho").value,
    cargo: useField("cliente.cargo").value,
    enderecoEmprego: useField("cliente.enderecoEmprego").value,
    bairroEmprego: useField("cliente.bairroEmprego").value,
    numeroEmprego: useField("cliente.numeroEmprego").value,
    complementoEmprego: useField("cliente.complementoEmprego").value,
    cidadeEmprego: useField("cliente.cidadeEmprego").value,
    estadoEmprego: useField("cliente.estadoEmprego").value,
    responsavelEmprego: useField("cliente.responsavelEmprego").value,
    telefoneEmprego: useField("cliente.telefoneEmprego").value,
    rendaEmprego: useField("cliente.rendaEmprego").value,
    dataAdmissaoEmprego: useField("cliente.dataAdmissaoEmprego").value,
    referencia1: useField("cliente.referencia1").value,
    telefoneReferencia1: useField("cliente.telefoneReferencia1").value,
    referencia2: useField("cliente.referencia2").value,
    telefoneReferencia2: useField("cliente.telefoneReferencia2").value,
    referencia3: useField("cliente.referencia3").value,
    telefoneReferencia3: useField("cliente.telefoneReferencia3").value,
    bairroCobranca: useField("cliente.bairroCobranca").value,
    temLimiteCredito: useField("cliente.temLimiteCredito").value,
    limite: useField("cliente.limite").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    cleanForm();
    if (route.name == "Cliente.Update" || route.name == "Cliente.Show") {
        getCliente(route.params.id);
    } else {
        proximoCodigo();
    }

    if (route.name == "Cliente.Show") {
        isReadOnly.value = true;
    }

    getGruposClientes();

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    if (route.name !== "Cliente.Show" && route.name !== "Cliente.Update") {
        cliente.value.idCliente = null;
    }

    cliente.value.codigo = null;
    cliente.value.nome = "";
    cliente.value.apelido = "";
    cliente.value.endereco = "";
    cliente.value.numero = "";
    cliente.value.complemento = "";
    cliente.value.bairro = "";
    cliente.value.cidade = "";
    cliente.value.estado = "";
    cliente.value.cep = "";
    cliente.value.cnpj = "";
    cliente.value.inscricao = "";
    cliente.value.fone1 = "";
    cliente.value.fone2 = "";
    cliente.value.fone3 = "";
    cliente.value.email = "";
    cliente.value.observacoes = "";
    cliente.value.dataCadastramento = new Date().toLocaleDateString("pt-BR");
    cliente.value.ativoInativoBloqueado = "A";
    cliente.value.motivoInatividade = "";
    cliente.value.motivoBloqueado = "";
    cliente.value.fone4 = "";
    cliente.value.contato = "";
    cliente.value.idGrupoClientes = null;
    cliente.value.dataNascimento = "";
    cliente.value.dataExpedicaoRG = "";
    cliente.value.mesmoEnderecoCobranca = false;
    cliente.value.enderecoCobranca = "";
    cliente.value.numeroCobranca = "";
    cliente.value.complementoCobranca = "";
    cliente.value.cidadeCobranca = "";
    cliente.value.estadoCobranca = "";
    cliente.value.cepCobranca = "";
    cliente.value.trabalho = "";
    cliente.value.cargo = "";
    cliente.value.enderecoEmprego = "";
    cliente.value.bairroEmprego = "";
    cliente.value.numeroEmprego = "";
    cliente.value.complementoEmprego = "";
    cliente.value.cidadeEmprego = "";
    cliente.value.estadoEmprego = "";
    cliente.value.responsavelEmprego = "";
    cliente.value.telefoneEmprego = "";
    cliente.value.rendaEmprego = "R$ 0,00";
    cliente.value.dataAdmissaoEmprego = "";
    cliente.value.referencia1 = "";
    cliente.value.telefoneReferencia1 = "";
    cliente.value.referencia2 = "";
    cliente.value.telefoneReferencia2 = "";
    cliente.value.referencia3 = "";
    cliente.value.telefoneReferencia3 = "";
    cliente.value.bairroCobranca = "";
    cliente.value.temLimiteCredito = false;
    cliente.value.limite = "R$ 0,00";
}

async function gravar(values, actions) {
    let dados = new FormData();

    if (values.cliente.dataCadastramento == "") {
        values.cliente.dataCadastramento = null;
    } else {
        values.cliente.dataCadastramento = format(parse(values.cliente.dataCadastramento, "dd/MM/yyyy", new Date()), "yyyy-MM-dd");
    }
    if (values.cliente.dataNascimento == "") {
        values.cliente.dataNascimento = null;
    } else {
        values.cliente.dataNascimento = format(parse(values.cliente.dataNascimento, "dd/MM/yyyy", new Date()), "yyyy-MM-dd");
    }
    if (values.cliente.dataExpedicaoRG == "") {
        values.cliente.dataExpedicaoRG = null;
    } else {
        values.cliente.dataExpedicaoRG = format(parse(values.cliente.dataExpedicaoRG, "dd/MM/yyyy", new Date()), "yyyy-MM-dd");
    }
    if (values.cliente.dataAdmissaoEmprego == "") {
        values.cliente.dataAdmissaoEmprego = null;
    } else {
        values.cliente.dataAdmissaoEmprego = format(parse(values.cliente.dataAdmissaoEmprego, "dd/MM/yyyy", new Date()), "yyyy-MM-dd");
    }

    dados.append("data", JSON.stringify(values.cliente));

    try {
        const response = await Cliente.save(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBar.value.open("O cliente não pode ser gravado, mensagem de erro: " + response.data.message, 6000);
                return;
            }
            if (route.name == "Cliente.Update") {
                snackBar.value.open("Cliente alterado com sucesso.");
            } else {
                snackBar.value.open("Novo cliente cadastrado com sucesso.");
                //  Emite o evento para que quando o cliente é cadastrado fora do módulo de clientes possa já trazer o código cadastrado.
                emit("cadastrou-cliente", cliente.value.codigo);

                actions.resetForm();
                cleanForm();
                proximoCodigo();
                 inputCodigoCliente.value.focus();
                // document.getElementById("codigo").focus();
            }
        }
    } catch (err) {
        snackBar.value.open("Erro, mensagem de erro: " + err);
    }
}
function closeModalCadastroCliente() {
    emit("close-dialog");
}
async function getGruposClientes() {
    let start = 0;
    let limit = 999999;
    let sort = null;
    let primeiroRegistro = { idGrupoClientes: null, descricao: "" };

    const response = await GrupoClientes.get(start, limit, "", sort);
    gruposClientes.value = response.data.data;
    gruposClientes.value.unshift(primeiroRegistro);

    if (cliente.value.idGrupoClientes == null) {
        cliente.value.idGrupoClientes = response.data.data[0].idGrupoClientes;
        return;
    }
}

async function getCliente(id) {
    let dados = new FormData();
    if(route.name == 'Cliente.Update') {
        dados.append("codigoModulo", "01.01.02");
    }
    else {
        dados.append("codigoModulo", "01.01.04");
    }
    
    dados.append("idCliente", id);
    try {
        const response = await Cliente.getById(dados);
        if (response.status == 200) {
            setValues({ cliente: response.data.data });

            cliente.value.rendaEmprego = Converte.numberToCurrency(response.data.data.rendaEmprego);
            cliente.value.limite = Converte.numberToCurrency(response.data.data.limite);
            cliente.value.dataCadastramento = format(parse(cliente.value.dataCadastramento, "yyyy-MM-dd", new Date()), "dd/MM/yyyy");
            if (cliente.value.dataNascimento == null) {
                cliente.value.dataNascimento = "";
            }
            if (cliente.value.dataExpedicaoRG == null) {
                cliente.value.dataExpedicaoRG = "";
            }
            if (cliente.value.dataAdmissaoEmprego == null) {
                cliente.value.dataAdmissaoEmprego = "";
            }

            if (cliente.value.dataNascimento != "") {
                cliente.value.dataNascimento = format(parse(cliente.value.dataNascimento, "yyyy-MM-dd", new Date()), "dd/MM/yyyy");
            }
            if (cliente.value.dataExpedicaoRG != "") {
                cliente.value.dataExpedicaoRG = format(parse(cliente.value.dataExpedicaoRG, "yyyy-MM-dd", new Date()), "dd/MM/yyyy");
            }
            if (cliente.value.dataAdmissaoEmprego != "") {
                cliente.value.dataAdmissaoEmprego = format(parse(cliente.value.dataAdmissaoEmprego, "yyyy-MM-dd", new Date()), "dd/MM/yyyy");
            }

            if (cliente.value.mesmoEnderecoCobranca == "S") {
                cliente.value.mesmoEnderecoCobranca = true;
            } else {
                cliente.value.mesmoEnderecoCobranca = false;
            }

            if (cliente.value.temLimiteCredito == "S") {
                cliente.value.temLimiteCredito = true;
            } else {
                cliente.value.temLimiteCredito = false;
            }

            //document.getElementById("codigo").focus();
            inputCodigoCliente.value.focus();

            changeEnderecoCobranca();
        }
    } catch (err) {
        console.log(err);
    }
}

function proximoCodigo() {
    Cliente.proximoCodigo().then(function (response) {
        cliente.value.codigo = response.data;
    });
}

function changeEnderecoCobranca() {
    if (cliente.value.mesmoEnderecoCobranca == true) {
        cliente.value.enderecoCobranca = cliente.value.endereco;
        cliente.value.numeroCobranca = cliente.value.numero;
        cliente.value.complementoCobranca = cliente.value.complemento;
        cliente.value.bairroCobranca = cliente.value.bairro;
        cliente.value.cidadeCobranca = cliente.value.cidade;
        cliente.value.estadoCobranca = cliente.value.estado;
        cliente.value.cepCobranca = cliente.value.cep;

        document.getElementById("enderecoCobranca").disabled = true;
        document.getElementById("numeroCobranca").disabled = true;
        document.getElementById("complementoCobranca").disabled = true;
        document.getElementById("bairroCobranca").disabled = true;
        document.getElementById("cidadeCobranca").disabled = true;
        document.getElementById("estadoCobranca").disabled = true;
        document.getElementById("cepCobranca").disabled = true;
    } else {
        document.getElementById("enderecoCobranca").disabled = false;
        document.getElementById("numeroCobranca").disabled = false;
        document.getElementById("complementoCobranca").disabled = false;
        document.getElementById("bairroCobranca").disabled = false;
        document.getElementById("cidadeCobranca").disabled = false;
        document.getElementById("estadoCobranca").disabled = false;
        document.getElementById("cepCobranca").disabled = false;
    }
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}
</script>

<template>
    <SnackBar ref="snackBar"></SnackBar>
    <v-card elevation="16">
        <form ref="formCliente" @submit.prevent="submit">
            <v-card-title>
                <h2 v-show="$route.name !== 'Cliente.Update' && $route.name !== 'Cliente.Show'">Novo Cliente</h2>
                <h2 v-show="$route.name == 'Cliente.Update'">Alteração de Cliente</h2>
                <h2 v-show="$route.name == 'Cliente.Show'">Consulta de Cliente</h2>
            </v-card-title>

            <v-card-text>
                <v-card class="mb-8" elevation="16">
                    <v-card-title>
                        <h3>Dados do Cliente</h3>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="2">
                                <v-text-field
                                    label="Código"
                                    ref="inputCodigoCliente"
                                    id="codigo"
                                    type="text"
                                    v-model="cliente.codigo"
                                    :error-messages="errors['cliente.codigo']"
                                    maxlength="9"
                                    @blur="
                                        (e) => {
                                            isRealValidation = true;
                                            cliente.codigo = e.target.value;
                                        }
                                    "
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="5">
                                <v-text-field
                                    label="Nome/Razão Social"
                                    id="nome"
                                    type="text"
                                    v-model="cliente.nome"
                                    maxlength="50"
                                    :error-messages="errors['cliente.nome']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="5">
                                <v-text-field
                                    label="Apelido/Nome Fantasia"
                                    id="apelido"
                                    type="text"
                                    v-model="cliente.apelido"
                                    maxlength="50"
                                    :error-messages="errors['cliente.apelido']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    label="Endereço"
                                    id="endereco"
                                    type="text"
                                    maxlength="50"
                                    v-model="cliente.endereco"
                                    @blur="changeEnderecoCobranca()"
                                    :error-messages="errors['cliente.endereco']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                    label="Número"
                                    id="numero"
                                    type="text"
                                    maxlength="10"
                                    v-model="cliente.numero"
                                    @blur="changeEnderecoCobranca()"
                                    :error-messages="errors['cliente.numero']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    label="Complemento"
                                    id="complemento"
                                    type="text"
                                    maxlength="30"
                                    v-model="cliente.complemento"
                                    @blur="changeEnderecoCobranca()"
                                    :error-messages="errors['cliente.complemento']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="4">
                                <v-text-field
                                    label="Bairro"
                                    id="bairro"
                                    type="text"
                                    maxlength="30"
                                    v-model="cliente.bairro"
                                    @blur="changeEnderecoCobranca()"
                                    :error-messages="errors['cliente.bairro']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    label="Cidade"
                                    id="cidade"
                                    type="text"
                                    maxlength="30"
                                    v-model="cliente.cidade"
                                    @blur="changeEnderecoCobranca()"
                                    :error-messages="errors['cliente.cidade']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                    label="Estado"
                                    id="estado"
                                    type="text"
                                    maxlength="2"
                                    v-model="cliente.estado"
                                    @blur="changeEnderecoCobranca()"
                                    :error-messages="errors['cliente.estado']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                    label="CEP"
                                    id="cep"
                                    type="text"
                                    maxlength="10"
                                    v-model="cliente.cep"
                                    @blur="changeEnderecoCobranca()"
                                    :error-messages="errors['cliente.cep']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="3">
                                <v-text-field
                                    label="CPF/CNPJ"
                                    id="cnpj"
                                    type="text"
                                    maxlength="18"
                                    v-model="cliente.cnpj"
                                    :error-messages="errors['cliente.cnpj']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    label="RG/Inscrição Estadual"
                                    id="inscricao"
                                    type="text"
                                    maxlength="20"
                                    v-model="cliente.inscricao"
                                    :error-messages="errors['cliente.inscricao']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="3">
                                <Data
                                    label="Data Expedição RG"
                                    id="dataExpedicaoRG"
                                    type="text"
                                    maxlength="10"
                                    v-model="cliente.dataExpedicaoRG"
                                    :error-messages="errors['cliente.dataExpedicaoRG']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="3">
                                <Data
                                    label="Data Nascimento"
                                    id="dataNascimento"
                                    type="text"
                                    maxlength="10"
                                    v-model="cliente.dataNascimento"
                                    :error-messages="errors['cliente.dataNascimento']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="3">
                                <v-text-field
                                    label="Telefone 1"
                                    id="telefone1"
                                    type="text"
                                    maxlength="16"
                                    v-model="cliente.fone1"
                                    :error-messages="errors['cliente.telefone1']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    label="Telefone 2"
                                    id="telefone2"
                                    type="text"
                                    maxlength="16"
                                    v-model="cliente.fone2"
                                    :error-messages="errors['cliente.telefone2']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    label="Telefone 3"
                                    id="telefone3"
                                    type="text"
                                    maxlength="16"
                                    v-model="cliente.fone3"
                                    :error-messages="errors['cliente.telefone3']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    label="Telefone 4"
                                    id="telefone4"
                                    type="text"
                                    maxlength="16"
                                    v-model="cliente.fone4"
                                    :error-messages="errors['cliente.telefone4']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="3">
                                <v-text-field
                                    label="Contato"
                                    id="contato"
                                    type="text"
                                    maxlength="30"
                                    v-model="cliente.contato"
                                    :error-messages="errors['cliente.contato']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="9">
                                <v-text-field
                                    label="Email"
                                    id="email"
                                    type="text"
                                    maxlength="100"
                                    v-model="cliente.email"
                                    :error-messages="errors['cliente.email']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="3">
                                <Data
                                    label="Data do Cadastramento"
                                    id="dataCadastramento"
                                    type="date-local"
                                    maxlength="10"
                                    v-model="cliente.dataCadastramento"
                                    :error-messages="errors['cliente.dataCadastramento']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <v-card class="mb-8" elevation="16">
                    <v-card-title>
                        <h3>Endereço de Cobrança</h3>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-checkbox
                                label="Mesmo Endereço de Cobrança dos Dados Cadastrais"
                                id="mesmoEnderecoCobranca"
                                v-model="cliente.mesmoEnderecoCobranca"
                                @change="changeEnderecoCobranca()"
                                :readonly="isReadOnly"
                            />
                        </v-row>

                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    label="Endereço"
                                    id="enderecoCobranca"
                                    type="text"
                                    maxlength="50"
                                    v-model="cliente.enderecoCobranca"
                                    :error-messages="errors['cliente.enderecoCobranca']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                    label="Número"
                                    id="numeroCobranca"
                                    type="text"
                                    maxlength="10"
                                    v-model="cliente.numeroCobranca"
                                    :error-messages="errors['cliente.numeroCobranca']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    label="Complemento"
                                    id="complementoCobranca"
                                    type="text"
                                    maxlength="30"
                                    v-model="cliente.complementoCobranca"
                                    :error-messages="errors['cliente.complementoCobranca']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="4">
                                <v-text-field
                                    label="Bairro"
                                    id="bairroCobranca"
                                    type="text"
                                    maxlength="30"
                                    v-model="cliente.bairroCobranca"
                                    :error-messages="errors['cliente.bairroCobranca']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    label="Cidade"
                                    id="cidadeCobranca"
                                    type="text"
                                    maxlength="30"
                                    v-model="cliente.cidadeCobranca"
                                    :error-messages="errors['cliente.cidadeCobranca']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                    label="Estado"
                                    id="estadoCobranca"
                                    type="text"
                                    maxlength="2"
                                    v-model="cliente.estadoCobranca"
                                    :error-messages="errors['cliente.estadoCobranca']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                    label="CEP"
                                    id="cepCobranca"
                                    type="text"
                                    maxlength="10"
                                    v-model="cliente.cepCobranca"
                                    :error-messages="errors['cliente.cepCobranca']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <v-card class="mb-8" elevation="16">
                    <v-card-title>
                        <h3>Emprego</h3>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="7">
                                <v-text-field
                                    label="Local Onde Trabalha"
                                    id="trabalho"
                                    type="text"
                                    maxlength="50"
                                    v-model="cliente.trabalho"
                                    :error-messages="errors['cliente.trabalho']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="5">
                                <v-text-field
                                    label="Cargo"
                                    id="cargo"
                                    type="text"
                                    maxlength="30"
                                    v-model="cliente.cargo"
                                    :error-messages="errors['cliente.cargo']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="9">
                                <v-text-field
                                    label="Endereço"
                                    id="enderecoEmprego"
                                    type="text"
                                    maxlength="50"
                                    v-model="cliente.enderecoEmprego"
                                    :error-messages="errors['cliente.enderecoEmprego']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    label="Número"
                                    id="numeroEmprego"
                                    type="text"
                                    maxlength="10"
                                    v-model="cliente.numeroEmprego"
                                    :error-messages="errors['cliente.numeroEmprego']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="4">
                                <v-text-field
                                    label="Complemento"
                                    id="complementoEmprego"
                                    type="text"
                                    maxlength="30"
                                    v-model="cliente.complementoEmprego"
                                    :error-messages="errors['cliente.complementoEmprego']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>

                            <v-col cols="4">
                                <v-text-field
                                    label="Bairro"
                                    id="bairroEmprego"
                                    type="text"
                                    maxlength="30"
                                    v-model="cliente.bairroEmprego"
                                    :error-messages="errors['cliente.bairroEmprego']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    label="Cidade"
                                    id="cidadeEmprego"
                                    type="text"
                                    maxlength="30"
                                    v-model="cliente.cidadeEmprego"
                                    :error-messages="errors['cliente.cidadeEmprego']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="2">
                                <v-text-field
                                    label="Estado"
                                    id="estadoEmprego"
                                    type="text"
                                    maxlength="2"
                                    v-model="cliente.estadoEmprego"
                                    :error-messages="errors['cliente.estadoEmprego']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>

                            <v-col cols="6">
                                <v-text-field
                                    label="Responsável"
                                    id="responsavelEmprego"
                                    type="text"
                                    maxlength="50"
                                    v-model="cliente.responsavelEmprego"
                                    :error-messages="errors['cliente.responsavelEmprego']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    label="Telefone"
                                    id="telefoneEmprego"
                                    type="text"
                                    maxlength="16"
                                    v-model="cliente.telefoneEmprego"
                                    :error-messages="errors['cliente.telefoneEmprego']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3">
                                <moeda
                                    label="Renda"
                                    id="rendaEmprego"
                                    maxlength="16"
                                    v-model="cliente.rendaEmprego"
                                    :error-messages="errors['cliente.rendaEmprego']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="4">
                                <Data
                                    label="Data Admissão"
                                    id="dataAdmissaoEmprego"
                                    type="text"
                                    maxlength="10"
                                    v-model="cliente.dataAdmissaoEmprego"
                                    :error-messages="errors['cliente.dataAdmissaoEmprego']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class="mb-8" elevation="16">
                    <v-card-title>
                        <h3>Referências</h3>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="9">
                                <v-text-field
                                    label="Referência 1"
                                    id="referencia1"
                                    type="text"
                                    maxlength="50"
                                    v-model="cliente.referencia1"
                                    :error-messages="errors['cliente.referencia1']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    label="Telefone"
                                    id="telefoneReferencia1"
                                    type="text"
                                    maxlength="16"
                                    v-model="cliente.telefoneReferencia1"
                                    :error-messages="errors['cliente.telefoneReferencia1']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="9">
                                <v-text-field
                                    label="Referência 2"
                                    id="referencia2"
                                    type="text"
                                    maxlength="50"
                                    v-model="cliente.referencia2"
                                    :error-messages="errors['cliente.referencia2']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    label="Telefone"
                                    id="telefoneReferencia2"
                                    type="text"
                                    maxlength="16"
                                    v-model="cliente.telefoneReferencia2"
                                    :error-messages="errors['cliente.telefoneReferencia2']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="9">
                                <v-text-field
                                    label="Referência 3"
                                    id="referencia3"
                                    type="text"
                                    maxlength="50"
                                    v-model="cliente.referencia3"
                                    :error-messages="errors['cliente.referencia3']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    label="Telefone"
                                    id="telefoneReferencia3"
                                    type="text"
                                    maxlength="16"
                                    v-model="cliente.telefoneReferencia3"
                                    :error-messages="errors['cliente.telefoneReferencia3']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class="mb-8" elevation="16">
                    <v-card-title>
                        <h3>Dados Adicionais</h3>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-select
                                    :items="gruposClientes"
                                    item-title="descricao"
                                    item-value="idGrupoClientes"
                                    label="Grupo de Clientes"
                                    v-model="cliente.idGrupoClientes"
                                    :readonly="isReadOnly"
                                >
                                    <template v-slot:append>
                                        <v-tooltip text="Cadastra um novo grupo de clientes.">
                                            <template v-slot:activator="{ props }">
                                                <v-btn v-bind="props"  color="primary"  @click="dialogGrupoClientes = true" 
                                                    id="btnNovoGrupoClientes"
                                                    v-show="temPermissao('01.14.01') && route.name !== 'Cliente.Show'">
                                                    <v-icon>mdi-invoice-text-plus</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-tooltip>
                                        <v-dialog
                                            v-model="dialogGrupoClientes"  transition="dialog-top-transition">
                                            <GrupoClientesCreate @cadastrouGrupoClientes="getGruposClientes()" 
                                                @close-dialog="dialogGrupoClientes = false"/>
                                        </v-dialog>
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea label="Observações" id="observacoes" type="text" v-model="cliente.observacoes" :readonly="isReadOnly" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3">
                                <v-checkbox label="Tem Limite de Crédito" id="temLimiteCredito" v-model="cliente.temLimiteCredito" :readonly="isReadOnly" />
                            </v-col>
                            <v-col cols="3" v-show="cliente.temLimiteCredito == true">
                                <moeda
                                    label="Limite de Crédito"
                                    id="limite"
                                    type="text"
                                    v-model="cliente.limite"
                                    :error-messages="errors['cliente.limite']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                        </v-row>
                        <v-row cols="2">
                            <v-col>
                                <v-select
                                    :items="itemsAtivoInativoBloqueado"
                                    label="Ativo/Inativo/Bloqueado"
                                    v-model="cliente.ativoInativoBloqueado"
                                    item-title="text"
                                    item-value="value"
                                    :readonly="isReadOnly"
                                ></v-select>
                            </v-col>
                            <v-col cols="9" v-show="cliente.ativoInativoBloqueado == 'I'">
                                <v-text-field
                                    label="Motivo da inatividade"
                                    id="motivoInatividade"
                                    type="text"
                                    maxlength="50"
                                    v-model="cliente.motivoInatividade"
                                    :error-messages="errors['cliente.motivoInatividade']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                            <v-col cols="9" v-show="cliente.ativoInativoBloqueado == 'B'">
                                <v-text-field
                                    label="Motivo do Bloqueio"
                                    id="motivoBloqueado"
                                    maxlength="50"
                                    type="text"
                                    v-model="cliente.motivoBloqueado"
                                    :error-messages="errors['cliente.motivoBloqueado']"
                                    :readonly="isReadOnly"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnClienteGravar" v-show="$route.name !== 'Cliente.Show'">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="elevated"
                    @click.prevent="$router.go(-1)"
                    v-if="showBtnGravar"
                    v-show="$route.name == 'Cliente.Create' || $route.name == 'Cliente.Update' || $route.name == 'Cliente.Show'"
                    >Voltar</v-btn
                >
                <v-btn
                    color="primary"
                    variant="elevated"
                    v-if="showBtnGravar"
                    v-show="$route.name !== 'Cliente.Create' && $route.name !== 'Cliente.Update' && $route.name !== 'Cliente.Show'"
                    @click.prevent="closeModalCadastroCliente()"
                    >Fechar</v-btn
                >
            </v-card-actions>
        </form>
    </v-card>
</template>
