import config from '../services/config';
import { defineStore } from 'pinia';

export const useCaixa = defineStore('caixa', {
    state: () => {
        return {
            start:0,
            limit: config.maxPerPage,
            currentPage: 1,
            credito: true, 
            debito: true,
            dataInicial: new Date().toLocaleDateString("pt-BR"), 
            dataFinal: new Date().toLocaleDateString("pt-BR"),   
            comSaldoAnterior:true,
            sort: [{
                'property':'data',
                'direction':'ASC'
            }],
        }
    },
    actions : {
        setStart(start) {
            this.start = start;
        },
        setDataInicial(dataInicial) {
            this.dataInicial = dataInicial;
        },
        setDataFinal(dataFinal) {
            this.dataFinal = dataFinal;
        },
        setCurrentPage(currentPage) {
            this.currentPage = currentPage;
        },
        setSort(sort) {
            this.sort = sort;
        },        
        setCredito(credito) {
            this.credito = credito;
        },
        setDebito(debito) {
            this.debito = debito;
        },
        setComSaldoAnterior(comSaldoAnterior) {
            this.comSaldoAnterior = comSaldoAnterior;
        },
    }
});