import Http from './http';

export class ContaPagar {
    static listar = function(start, limit, sort, filtrarPor, dataInicial, dataFinal, abertos, cancelados, quitados, idFornecedor, filtro, tipoFiltro) {
        return Http.get('/Serv/ContasPagar/ContasPagar.php', {
            params: {
                'operacao': 'listar',
                'start':start,
                'limit':limit,
                'sort':sort,
                'filtrarPor': filtrarPor,
                'dataInicial': dataInicial,
                'dataFinal': dataFinal,
                'abertos': abertos,
                'cancelados': cancelados,
                'quitados': quitados,
                'idFornecedor': idFornecedor,
                'filtro': filtro,
                'tipoFiltro': tipoFiltro,
            }
        });
    };

    static novoLancamento = function(dados) {
        return Http.post('/Serv/ContasPagar/ContasPagar.php?operacao=novolancamento', dados);
    };

    static proximoLancamento = function() {
        return Http.get('/Serv/ContasPagar/ContasPagar.php?operacao=proximolancamento');
    };

    static lancamentoExiste = function(lancamento, idContaPagar) {
        let dados = new FormData();
        dados.append('lancamento', lancamento);
        dados.append('idContaPagar', idContaPagar);
        return Http.post('/Serv/ContasPagar/ContasPagar.php?operacao=lancamentoexiste', dados);
    };

    static gerarParcelas = function(dados) {
        return Http.post('/Serv/ContasPagar/ContasPagar.php?operacao=gerarparcelas', dados);
    };

    static novosLancamentos = function(dados) {
        return Http.post('/Serv/ContasPagar/ContasPagar.php?operacao=novoslancamentos', dados);
    };

    static getById = function(dados) {
        return Http.post('/Serv/ContasPagar/ContasPagar.php?operacao=getbyid', dados);
    };

    static alteraLancamento = function(dados) {
        return Http.post('/Serv/ContasPagar/ContasPagar.php?operacao=alteralancamento', dados);
    };

    static quitaLancamento = function(dados) {
        return Http.post('/Serv/ContasPagar/ContasPagar.php?operacao=quitalancamento', dados);
    };

    static getPagamentosById = function(dados) {
        return Http.post('/Serv/ContasPagar/ContasPagar.php?operacao=getpagamentosbyid', dados);
    };

    static excluir = function(dados) {
        return Http.post('/Serv/ContasPagar/ContasPagar.php?operacao=excluir', dados);
    };

    static cancelar = function(dados) {
        return Http.post('/Serv/ContasPagar/ContasPagar.php?operacao=cancelar', dados);
    };

    static excluirPagamento = function(dados) {
        return Http.post('/Serv/ContasPagar/ContasPagar.php?operacao=excluirpagamento', dados);
    };
}