import Http from './http';

export class PagamentoVendedor {

    static get = function(start, limit, sort, idVendedor, dataInicial, dataFinal) {
        return Http.get('/Serv/PagamentoVendedor/PagamentoVendedor.php', {
            params: {
                'operacao': 'listar',
                'start':start,
                'limit':limit,
                'sort':sort,
                'idVendedor': idVendedor,
                'dataInicial':dataInicial,
                'dataFinal':dataFinal,
            }
        });
    };

    static save = function(dados) {
        return Http.post('/Serv/PagamentoVendedor/PagamentoVendedor.php?operacao=gravar', dados);
    };

    static excluir = function(dados) {
        return Http.post('/Serv/PagamentoVendedor/PagamentoVendedor.php?operacao=excluir', dados);
    };

    static getById = function(dados) {
        return Http.post('/Serv/PagamentoVendedor/PagamentoVendedor.php?operacao=getbyid', dados);
    };

}

