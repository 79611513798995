import Http from './http';

export class NovoCadastro {

    static criarCadastro = function(dados) {
        return Http.post('/Serv/NovoCadastro/NovoCadastro.php?operacao=novocadastro', dados);
    };

    // static update = function(id, data) {
    //     return Http.put('/supplier-group/'+id, data);
    // };
    //
    // static destroy = function(id) {
    //     return Http.delete('/supplier-group/'+id);
    // };
    //
    // static all = function() {
    //     return Http.get('/supplier-group/all');
    // }
}
