<script setup>
import { ref, onMounted } from "vue";
import { SaidaEstoque } from "../../resources/saidaEstoqueResource";
import { Produto } from "../../resources/produtoResource";
import SnackBar from "../Utils/SnackBar";
import { useField, useForm } from "vee-validate";
import { string, addMethod, object } from "yup";
import { usePermissoes } from "../../store/permissoes";
import { useCasasDecimais } from "../../store/casasDecimais";
import ProdutoBusca from "../Produtos/ProdutoBusca.vue";
import { Converte } from "../../utils/converte";
import Data from "../Utils/data.vue";
import Moeda from "../Utils/moeda.vue";
import Quantidade from "../Utils/quantidade.vue";
import CodigoProduto from "../Utils/codigoProduto.vue";

const snackBar = ref(null);
let dialogBuscaProduto = ref(false);
let showBtnGravar = true;

const permissoesStore = usePermissoes();
const casasDecimaisStore = useCasasDecimais();

addMethod(string, "quantidade_maior_um", function quantidadeMaiorUm(message) {
    return string().test(
        "quantidade-maior-um", // Name
        message, // Msg
        function (quantidade) {
            if(Converte.stringToFloat(quantidade) <= 0) {
                return false;
            }
            return true;
        }
    );
});

addMethod(string, "quantidade_suficiente", function quantidadeSuficiente(message) {
    return string().test(
        "quantidade-suficiente", // Name
        message, // Msg
        function (quantidade) {
            if(Converte.stringToFloat(quantidade) <= 0) {
                return false;
            }
            if(Converte.stringToFloat(quantidade) > Converte.stringToFloat(saidaEstoque.value.quantidadeAtual)) {
                return false;
            }
            return true;
        }
    );
});


const validationSchema = object({
    saidaEstoque: object({
        idMovimentacaoEstoque: undefined,
        codigo: string().required("O código do produto é obrigatório").label("O código do produto"),
        idProduto: string(),
        codigoBarras: undefined,
        descricao: undefined,
        unidade: undefined,
        quantidadeAtual: undefined,
        quantidade: string()
            .quantidade_suficiente("Quantidade insuficiente, a quantidade que está saindo deve ser maior que zero e igual ou menor que a quantidade atual em estoque."),
        valorUnitario: undefined,
        valorTotal: undefined,
        data: string().required("A data é obrigatória").label("A data"),
        documento: string().max(10),
        gerarLancamentoCaixa: undefined,
    }).required(),
});

const { handleSubmit, errors } = useForm({ validationSchema });

const saidaEstoque = ref({
    idMovimentacaoEstoque: useField("saidaEstoque.idMovimentacaoEstoque").value,
    codigo: useField("saidaEstoque.codigo").value,
    idProduto: useField("saidaEstoque.idProduto").value,
    codigoBarras: useField("saidaEstoque.codigoBarras").value,
    descricao: useField("saidaEstoque.descricao").value,
    unidade: useField("saidaEstoque.unidade").value,
    quantidadeAtual: useField("saidaEstoque.quantidadeAtual").value,
    quantidade: useField("saidaEstoque.quantidade").value,
    valorUnitario: useField("saidaEstoque.valorUnitario").value,
    valorTotal: useField("saidaEstoque.valorTotal").value,
    data: useField("saidaEstoque.data").value,
    documento: useField("saidaEstoque.documento").value,
    gerarLancamentoCaixa: useField("saidaEstoque.gerarLancamentoCaixa").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    cleanForm();

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {

    saidaEstoque.value.idMovimentacaoEstoque = null;
    saidaEstoque.value.codigo = "";
    saidaEstoque.value.idProduto = null;
    saidaEstoque.value.codigoBarras = "";
    saidaEstoque.value.descricao = "";
    saidaEstoque.value.unidade = "";
    saidaEstoque.value.valorUnitario = 'R$ 0,00';
    saidaEstoque.value.valorTotal = 'R$ 0,00';
    saidaEstoque.value.data = new Date().toLocaleDateString("pt-BR");
    saidaEstoque.value.documento = "";
    saidaEstoque.value.gerarLancamentoCaixa = true;    

    if(casasDecimaisStore.jaCarregado) {
        saidaEstoque.value.quantidadeAtual = casasDecimaisStore.quantidadeProdutoZero;
        saidaEstoque.value.quantidade = casasDecimaisStore.quantidadeProdutoUm;
    }
    else 
    {
        setTimeout(() => {
            saidaEstoque.value.quantidadeAtual = casasDecimaisStore.quantidadeProdutoZero;
            saidaEstoque.value.quantidade = casasDecimaisStore.quantidadeProdutoUm;
        }, 500);
    }

}

async function gravar(values, actions) {
    let dados = new FormData();

    values.saidaEstoque.data = Converte.datePtBrToIso(values.saidaEstoque.data); 
    values.saidaEstoque.valor = saidaEstoque.value.valorTotal;

    dados.append("dados", JSON.stringify(values.saidaEstoque));
    dados.modulo = 'SAIDA';

    try {
        const response = await SaidaEstoque.save(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBar.value.open("A saída do estoque não pode ser feita, mensagem de erro: " + response.data.message, 6000);
                return;
            }
            snackBar.value.open("Saída do estoque realizada com sucesso.");
            actions.resetForm();
            cleanForm();
            document.getElementById("codigo").focus();
        }
    } catch (err) {
        snackBar.value.open("Erro, mensagem de erro: " + err);
    }
}

async function getProduto(campo) {

    let valor = "";

    if(campo == 'codigoBarras') {
        valor = saidaEstoque.value.codigoBarras;
        if(valor == "") {
            return;
        }
    } else
    {
        valor = saidaEstoque.value.codigo;
    }

    if(valor.trim() == '') {
        // entradaEstoque.value.idProduto = null;
        saidaEstoque.value.codigoBarras = '';
        saidaEstoque.value.descricao = '';
        saidaEstoque.value.unidade = '';
        saidaEstoque.value.quantidadeAtual = casasDecimaisStore.quantidadeProdutoZero;
        if(campo == 'codigo') {
            saidaEstoque.value.idProduto =  null;
        }
        calculaTotal();
        return;
    }

    if(campo == 'codigo') {
        valor = Converte.codigoProduto(valor);
    }

    saidaEstoque.value.idProduto = null;
    saidaEstoque.value.descricao = "";
    saidaEstoque.value.unidade = "";
    if(campo == "codigo") {
        saidaEstoque.value.codigoBarras = "";
    }
    else {
        saidaEstoque.value.codigo = "";
    }

    
    saidaEstoque.value.quantidadeAtual = casasDecimaisStore.produtoQuantidadeZero;
    

    let dados = new FormData();
    dados.append('codigoCodigoBarras', valor);
    dados.append('campo', campo);
    dados.append("codigoModulo", "02.02");

    try {
        let response = await Produto.getByCodigoCodigoBarras(dados);
        if (response.status == 200) {
            if(!response.data.data) {
                if(campo == 'codigo') {
                    document.getElementById("codigo").focus();
                }
                if(campo == 'codigoBarras') {
                    document.getElementById("codigoBarras").focus();
                }
                snackBar.value.open("Produto não encontrado, tente outro código ou faça uma busca.", 6000);
                calculaTotal();
                return;
            }

            if(response.data.data.ativoInativoBloqueado == 'I') {
                    snackBar.value.open("Produto inativo. Um produto inativo não pode gerar novas saídas do estoque.", 6000);
                    document.getElementById("codigo").focus();
                    calculaTotal();
                    return;
            }
            if(response.data.data.ativoInativoBloqueado == 'B') {

                snackBar.value.open("Produto bloqueado. Um produto bloqueado não pode gerar novas saídas do estoque.", 6000);
                document.getElementById("codigo").focus();
                calculaTotal();
                return;
            }

            saidaEstoque.value.idProduto = response.data.data.idProduto;
            saidaEstoque.value.codigo = response.data.data.codigo;
            saidaEstoque.value.descricao = response.data.data.descricao;
            saidaEstoque.value.codigoBarras = response.data.data.codigoBarras;

            saidaEstoque.value.unidade = response.data.data.unidade;
            saidaEstoque.value.quantidadeAtual = Converte.numberToQuantidadeProduto(response.data.data.quantidade);
            saidaEstoque.value.valorUnitario = Converte.numberToPCustoProduto(response.data.data.pCusto);
            calculaTotal();
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouProduto(produto) {
    if (produto == null) {
        return;
    }
    
    saidaEstoque.value.codigo = produto.codigo ;
    getProduto('codigo');

    dialogBuscaProduto.value = false;
    
}

function calculaTotal() {
    let quantidade = Converte.stringToFloat(saidaEstoque.value.quantidade);
    let valorUnitario = Converte.stringToFloat(saidaEstoque.value.valorUnitario);
    let total = quantidade * valorUnitario;

    saidaEstoque.value.valorTotal = Converte.numberToCurrency(total);
}


</script>

<template>
    <SnackBar ref="snackBar"></SnackBar>
    <v-card elevation="16">
        <form ref="formSaidaEstoque" @submit.prevent="submit">
            <v-card-title>
                <h2>Saída do Estoque</h2>
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col cols="2">
                        <CodigoProduto
                            label="Código do Produto"
                            id="codigo"
                            type="text"
                            v-model="saidaEstoque.codigo"
                            :error-messages="errors['saidaEstoque.codigo']"
                            maxlength="15"
                            @blur="getProduto('codigo')"
                        />
                    </v-col>
                    <v-col cols="2">
                        <v-text-field
                            label="Código de Barras"
                            id="codigoBarras"
                            type="text"
                            v-model="saidaEstoque.codigoBarras"
                            :error-messages="errors['saidaEstoque.codigoBarras']"
                            maxlength="14"
                            @blur="getProduto('codigoBarras')"
                        />
                    </v-col>
                    <v-col cols="8">
                        <v-text-field label="Descrição do Produto" id="descricao" type="text" 
                                v-model="saidaEstoque.descricao" readonly tabindex="-1">
                            <template v-slot:append>
                                <v-tooltip text="Faz a busca de um produto pela descrição, referência.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogBuscaProduto = true" id="btnBuscaProduto">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogBuscaProduto" transition="dialog-top-transition">
                                    <ProdutoBusca @selecionouProduto="selecionouProduto" @close-dialog="dialogBuscaProduto = false;" />
                                </v-dialog>
                            </template>                    
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="2">
                        <v-text-field label="Unid." id="unidade" type="text" v-model="saidaEstoque.unidade" readonly tabindex="-1">
                    
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Quantidade Atual" id="quantidadeAtual" 
                            type="text" v-model="saidaEstoque.quantidadeAtual" 
                            tipo="quantidadeProduto" readonly tabindex="-1">
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <Quantidade
                            label="Quantidade"
                            id="quantidade"
                            type="text"
                            v-model="saidaEstoque.quantidade"
                            :error-messages="errors['saidaEstoque.quantidade']"
                            tipo="quantidadeProduto"
                            maxlength="18"
                            @blur="calculaTotal();"
                        />
                    </v-col>
                    <v-col cols="4">
                        <Moeda label="Valor Unitário" id="valorUnitario" type="text" 
                                v-model="saidaEstoque.valorUnitario" 
                                maxlength="18"
                                @blur="calculaTotal();" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <Moeda label="Valor Total" id="valorTotal" type="text" 
                            v-model="saidaEstoque.valorTotal" maxlength="18" readonly tabindex="-1" />
                    </v-col>
                    <v-col cols="4">
                        <Data
                            label="Data"
                            id="data"
                            type="text"
                            v-model="saidaEstoque.data"
                            :error-messages="errors['saidaEstoque.data']"
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field label="Documento" id="documento" type="text" v-model="saidaEstoque.documento" maxlength="10" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-checkbox
                            v-model="saidaEstoque.gerarLancamentoCaixa"
                            label="Gerar crédito no caixa"
                            id="gerarLancamentoCaixa"
                        ></v-checkbox>
                    </v-col>
                </v-row>

            </v-card-text>
            <v-card-actions>
                <v-btn v-show="showBtnGravar" color="primary" variant="elevated" type="submit" id="btnSaidaEstoqueGravar">
                    Salvar
                </v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>
