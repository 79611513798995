import config from '../services/config';
import { defineStore } from 'pinia';

export const useChequeRecebido = defineStore('chequeRecebido', {
    state: () => {
        return {
            start:0,
            limit: config.maxPerPage,
            currentPage: 1,
            abertos: true,
            cancelados: true,
            baixados:true,
            voltaram:true,
            dataInicial: new Date().toLocaleDateString("pt-BR"), 
            dataFinal: new Date().toLocaleDateString("pt-BR"), 
            idCliente:null,
            sort: [{
                'property':'dataEmissao',
                'direction':'ASC'
            }],
            filtrarPor:'bomPara',
            codigo:'',
            titular:'',
            codigoCliente:'',
            nomeCliente:'',
            tipoFiltro: 'geral',
        }
    },
    actions : {
        setStart(start) {
            this.start = start;
        },
        setCurrentPage(currentPage) {
            this.currentPage = currentPage;
        },
        setAbertos(abertos) {
            this.abertos = abertos;
        },
        setCancelados(cancelados) {
            this.cancelados = cancelados;
        },
        setBaixados(baixados) {
            this.baixados = baixados;
        },
        setVoltaram(voltaram) {
            this.voltaram = voltaram;
        },
        setDataInicial(dataInicial) {
            this.dataInicial = dataInicial;
        },
        setDataFinal(dataFinal) {
            this.dataFinal = dataFinal;
        },
        setIdCliente(idCliente) {
            this.idCliente = idCliente;
        },
        setSort(sort) {
            this.sort = sort;
        },        
        setFiltrarPor(filtrarPor) {
            this.filtrarPor = filtrarPor;
        },
        setCodigo (codigo) {
            this.codigo = codigo;
        },
        setCodigoCliente(codigoCliente) {
            this.codigoCliente = codigoCliente;
        },
        setNomeCliente(nomeCliente) {
            this.nomeCliente = nomeCliente
        },
        setTipoFiltro(tipoFiltro) {
            this.tipoFiltro = tipoFiltro;
        },
        setTitular(titular) {
            this.titular = titular;
        }
    }
});