import Http from './http';

export class Log {

    static filtrar = function(start, limit, idUsuario, dataInicial, dataFinal) {
        return Http.get('/Serv/Logs/Logs.php', {
            params: {
                'operacao': 'filtrar',
                'start':start,
                'limit':limit,
                'idUsuario':idUsuario,
                'dataInicial':dataInicial,
                'dataFinal': dataFinal,
            }
        });
    };

    static getUsuarios = function() {
        return Http.get('/Serv/Logs/Logs.php?operacao=listarusuarios');
    };

}
