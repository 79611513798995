import { defineStore } from 'pinia';

export const usePermissoesNegadas = defineStore('permissoesNegadas', {
    state: () => {
        return {
            permissoesNegadas:[]
        }
    },
    actions : {
        setPermissoesNegadas(permissoesNegadas) {
            this.permissoesNegadas = permissoesNegadas;
        },  
    },
});


