<script setup>
import { ref, onMounted, inject, defineEmits } from "vue";
import { useRoute } from "vue-router";
import { OrcamentoOrdemServicos } from "../../resources/orcamentoOrdemServicosResource";
import { usePermissoes } from "../../store/permissoes";

const emit = defineEmits(['close-dialog']);
const isReadOnly = ref(false);
const observacoes = ref(null);
const route = useRoute();
const showBtnGravar = ref(true);
const permissoesStore = usePermissoes();
const snackBarApp = inject("snackBarApp");

onMounted(() => {
    getObservacoes();

    if(route.name == 'OrcamentosOrdensServicos.Show') {
        isReadOnly.value = true;
    }

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

async function getObservacoes() {
    let dados = new FormData();
    dados.append('codigoModulo', '03.05.02');
    dados.append('idOrcamentoOrdemServicos', route.params.id);
    try {
        let response = await OrcamentoOrdemServicos.getObservacoesInternas(dados)
        if (response.status == 200) {
            observacoes.value  = response.data.data.observacoes;

        }

    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar as observações internas do orçamento da ordem de serviços.');
        console.log(err);
    }
}

async function gravar() {
    let dados = new FormData();
    dados.append('idOrcamentoOrdemServicos', route.params.id);
    dados.append('codigoModulo', '03.05.02');
    dados.append('observacoes', observacoes.value);

    try { 
        let response = await OrcamentoOrdemServicos.gravarObservacoesInternas(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp('As observações internas não puderam ser gravadas, mensagem: '+response.data.message);
                return;
            }

            snackBarApp.value.open('Observações internas gravadas com sucesso.');

        }

    }
    catch (err) {
        snackBarApp.value.open('Erro ao gravar as observações internas do orçamento de ordem de serviços.');
        console.log(err);
    }
}

function closeModalObservacoesOrcamentoOrdemServicos() {
    emit('close-dialog');
}

</script>

<template>
    <v-card elevetion="16">
        <v-card-title>
            <h2>Observações Internas do Orçamento de Ordem de Serviços</h2>
        </v-card-title>
        <form ref="formObservacoesInternasOrcamentoOrdemServicos" @submit.prevent="gravar">
        <v-card-text>
            <v-row >
                <v-col>
                    <v-textarea rows="15"
                        label="Observações"
                        id="observacoesOrcamentoOrdemServicos"
                        v-model="observacoes"
                        type="text"
                        :readonly="isReadOnly"
                        />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-btn color="primary" v-show="showBtnGravar && route.name == 'OrcamentosOrdensServicos.Continuar'" 
                variant="elevated" 
                type="submit" 
                id="btnGravarObservacoesOrcamentoOrdemServicos"
                >Gravar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="elevated" @click.prevent='closeModalObservacoesOrcamentoOrdemServicos'>Fechar</v-btn>
        </v-card-actions>
        </form>
    </v-card>
</template>
