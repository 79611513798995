<script setup>
import { ref, onMounted, inject, defineEmits, defineProps } from "vue";
import { useRoute } from "vue-router";
import { OrdemServicos } from "../../resources/ordemServicosResource";
import { Servico } from "../../resources/servicoResource";
import { useForm, useField } from "vee-validate";
import { string, addMethod, object, number } from "yup";
import { usePermissoes } from "../../store/permissoes";
import ServicoCreate from "../Servicos/ServicoCreate.vue";
import ServicoBusca from "../Servicos/ServicoBusca.vue";
import { useCasasDecimais } from "../../store/casasDecimais";
import Moeda from "../Utils/moeda.vue";
import Quantidade from "../Utils/quantidade.vue";
import { Converte } from "../../utils/converte";

const props = defineProps({
    servicoOrdemServicos: {
        type: Object,
    },
});

const emit = defineEmits(['close-dialog', 'alterou-servico']);
const route = useRoute();
const dialogServico = ref(false);
const dialogBuscaServico = ref(false);
let showBtnGravar = ref(true);
const casasDecimaisStore = useCasasDecimais();

const snackBarApp = inject("snackBarApp");

const permissoesStore = usePermissoes();


addMethod(string, "quantidade_maior_zero", function quantidadeMaiorZero(message) {
    return string().test(
        "quantidade-maior_zero", // Name
        message, // Msg
        function (quantidade) {
            if (Converte.stringToFloat(quantidade) > 0) {
                return true;
            }
            return false;
        }
    );
});

const validationSchema = object({
    updateServicoOrdemServicos: object({
        idServicoOrdemServicos: undefined,
        idOrdemServicos: undefined,
        idServico: undefined,
        codigoServico: number().required("O código do serviço é obrigatório").typeError("O código do serviço deve ser um número").label("O código do servico"),
        descricaoServico: undefined,
        unidadeServico: undefined,
        quantidade: string().quantidade_maior_zero("A quantidade deve ser maior que 0."),
        valorUnitario: string().required("O valor unitário é obrigatório").label("O valor unitário"),
        valorTotal: string().required("O valor total é obrigatório").label("O valor total"),
    }).required(),
});

const { handleSubmit, errors } = useForm({ validationSchema });

const updateServicoOrdemServicos = ref({
    idServicoOrdemServicos: useField("updateServicoOrdemServicos.idServicoOrdemServicos").value,
    idOrdemServicos: useField("updateServicoOrdemServicos.idOrdemServicos").value,
    idServico: useField("updateServicoOrdemServicos.idServico").value,
    codigoServico: useField("updateServicoOrdemServicos.codigoServico").value,
    descricaoServico: useField("updateServicoOrdemServicos.descricaoServico").value,
    unidadeServico: useField("updateServicoOrdemServicos.unidadeServico").value,
    quantidade: useField("updateServicoOrdemServicos.quantidade").value,
    valorUnitario: useField("updateServicoOrdemServicos.valorUnitario").value,
    valorTotal: useField("updateServicoOrdemServicos.valorTotal").value,
});

const submitUpdateServico = handleSubmit((values, actions) => {
    updateServico(values, actions);
});

onMounted(() => {
    cleanForm();

    updateServicoOrdemServicos.value.idServicoOrdemServicos = props.servicoOrdemServicos.idServicoOrdemServicos;
    updateServicoOrdemServicos.value.idServico = props.servicoOrdemServicos.idServico;
    updateServicoOrdemServicos.value.codigoServico = props.servicoOrdemServicos.codigoServico;
    updateServicoOrdemServicos.value.descricaoServico = props.servicoOrdemServicos.descricaoServico;
    updateServicoOrdemServicos.value.unidadeServico = props.servicoOrdemServicos.unidadeServico;
    updateServicoOrdemServicos.value.quantidade = Converte.numberToQuantidadeServico(props.servicoOrdemServicos.quantidade);
    updateServicoOrdemServicos.value.valorUnitario = Converte.numberToCurrency(props.servicoOrdemServicos.valorUnitario);

    getServico(true);

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    updateServicoOrdemServicos.value.idServicoOrdemServicos = null;
    updateServicoOrdemServicos.value.idOrdemServicos = route.params.id;
    updateServicoOrdemServicos.value.idServico = null;
    updateServicoOrdemServicos.value.codigoServico = "";
    updateServicoOrdemServicos.value.descricaoServico = "";
    updateServicoOrdemServicos.value.unidadeServico = "";
    updateServicoOrdemServicos.value.quantidade = casasDecimaisStore.quantidadeServicoUm;
    updateServicoOrdemServicos.value.valorUnitario = "R$ 0,00";
    updateServicoOrdemServicos.value.valorTotal = "R$ 0,00";
}
async function updateServico(values) {
    let dados = new FormData();
    dados.append("dados", JSON.stringify(values.updateServicoOrdemServicos));

    try {
        const response = await OrdemServicos.updateServico(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("O serviço não pode ser alterado na ordem de serviços, mensagem: " + response.data.message, 6000);
                return;
            }

            snackBarApp.value.open("Serviço alterado com sucesso na ordem de serviços.", 1500);
            emit('alterou-servico');

        }
    } catch (err) {
        console.log(err);
    }
}

async function getServico(mounted = false) {

    if(updateServicoOrdemServicos.value.codigoServico.toString().trim() == '') {
        updateServicoOrdemServicos.value.descricaoServico = '';
        updateServicoOrdemServicos.value.unidadeServico = '';
        updateServicoOrdemServicos.value.idServico =  null;

        return;
    }

    let dados = new FormData();
    dados.append('codigo', updateServicoOrdemServicos.value.codigoServico);
    dados.append('codigoModulo', '03.03.02');

    try {
        let response = await Servico.getByCodigo(dados);
        if (response.status == 200) {
            updateServicoOrdemServicos.value.idServico = null;
            updateServicoOrdemServicos.value.descricaoServico = '';
            updateServicoOrdemServicos.value.unidadeServico = '';

            if(response.data.data == false) {
                document.getElementById('codigoServico').focus();
                snackBarApp.value.open('Serviço não encontrado, tente outro código ou faça uma busca.', 6000);
                updateServicoOrdemServicos.value.codigoServico = '';
                return;
            }

            if(response.data.data.ativoInativoBloqueado == 'I' || response.data.data.ativoInativoBloqueado == 'B') {
                if(response.data.data.ativoInativoBloqueado == 'I') {
                    snackBarApp.value.open('Serviço inativo. O serviço '+response.data.data.codigo+'/'+response.data.data.descricao+
                        ' está inativo, um serviço inativo não pode ser incluído em ordens de serviços.', 6000);
                }
                if(response.data.data.ativoInativoBloqueado == 'B') {
                    snackBarApp.value.open('Serviço bloqueado. O serviço '+response.data.data.codigo+'/'+response.data.data.descricao+
                        ' está bloqueado, um serviço bloqueado não pode ser incluído em ordens de serviços.', 6000);
                }

                updateServicoOrdemServicos.value.codigoServico = '';
                return;

            }
            else {
                updateServicoOrdemServicos.value.idServico = response.data.data.idServico;
                updateServicoOrdemServicos.value.codigoServico = response.data.data.codigo;
                updateServicoOrdemServicos.value.descricaoServico = response.data.data.descricao;
                updateServicoOrdemServicos.value.unidadeServico = response.data.data.unidade;
                if (!mounted) {
                    updateServicoOrdemServicos.value.valorUnitario = Converte.numberToCurrency(response.data.data.valor);
                }
                calculaTotal();
            }
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar o serviço.');
        console.log(err);
    }

}

function selecionouServico(servico) {
    if (servico == null) {
        return;
    }

    document.getElementById("quantidadeUpdate").focus();

    updateServicoOrdemServicos.value.codigoServico = servico.codigo;
    getServico();


    dialogBuscaServico.value = false;
}


function calculaTotal() {
    let quantidade = Converte.stringToFloat(updateServicoOrdemServicos.value.quantidade);
    let valorUnitario = Converte.stringToFloat(updateServicoOrdemServicos.value.valorUnitario);
    let total = quantidade * valorUnitario;
    updateServicoOrdemServicos.value.valorTotal = Converte.numberToCurrency(total);
}

function cadastrouServico(codigoServico) {
    if (codigoServico == null) {
        return;
    }
    updateServicoOrdemServicos.value.codigoServico = codigoServico;
    getServico();

}

function closeModalUpdateServicoOrdemServicos() {
    emit('close-dialog');
}

</script>

<template>
    <v-card elevetion="16">
        <v-card-title>
            <h2>Alteração de Item da Ordem de Serviços</h2>
        </v-card-title>
        <form ref="formServicoOrdemServicos" @submit.prevent="submitUpdateServico">
            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <v-text-field label="Código do Serviço" id="codigoServicoUpdate" type="text"
                            v-model="updateServicoOrdemServicos.codigoServico"
                            :error-messages="errors['updateServicoOrdemServicos.codigoServico']" maxlength="15"
                            @blur="getServico()" />
                    </v-col>
                    <v-col cols="9">
                        <v-text-field label="Descrição do Serviço" id="descricaoServicoUpdate" type="text"
                            v-model="updateServicoOrdemServicos.descricaoServico" readonly tabindex="-1">
                            <template v-slot:append>
                                <v-tooltip text="Faz a busca de um serviço pela descrição, referência.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogBuscaServico = true"
                                            id="btnBuscaServico">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogBuscaServico" transition="dialog-top-transition">
                                    <ServicoBusca @selecionouServico="selecionouServico"
                                        @close-dialog="dialogBuscaServico = false;" />
                                </v-dialog>
                                &nbsp;
                                <v-tooltip text="Cadastra um novo serviço.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogServico = true"
                                            id="btnNovoServico">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogServico" transition="dialog-top-transition">
                                    <ServicoCreate @cadastrouServico="cadastrouServico"
                                        @close-dialog="dialogServico = false" />
                                </v-dialog>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-text-field label="Unid." id="unidadeUpdate" type="text"
                            v-model="updateServicoOrdemServicos.unidadeServico" readonly tabindex="-1">
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <Quantidade label="Quantidade" id="quantidadeUpdate" type="text"
                            v-model="updateServicoOrdemServicos.quantidade" tipo="quantidadeServico"
                            :error-messages="errors['updateServicoOrdemServicos.quantidade']" maxlength="15"
                            @blur="calculaTotal()" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor Unitário" id="valorUnitario" type="text"
                            v-model="updateServicoOrdemServicos.valorUnitario"
                            :error-messages="errors['updateServicoOrdemServicos.valorUnitario']" maxlength="15"
                            @blur="calculaTotal()" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor Total" id="valorTotal" type="text" v-model="updateServicoOrdemServicos.valorTotal"
                            :error-messages="errors['updateServicoOrdemServicos.valorTotal']" maxlength="15" readonly
                            tabindex="-1" />
                    </v-col>
                </v-row>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" v-show="showBtnGravar" variant="elevated" type="submit"
                    id="btnUpdateServicoGravar">Alterar Serviço</v-btn>
                
                <v-btn color="primary" variant="elevated"
                    @click.prevent='closeModalUpdateServicoOrdemServicos'>Fechar</v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>
