export class ProdutoService {
    static calculaPVenda = function(pCusto, margem) {
        let pVenda = 1 / ((100 - margem) / 100) * pCusto;
        return pVenda;
    };

    static calculaMargem = function(pCusto, pVenda) {
        let margem;

        if(pCusto == pVenda || pVenda == 0) {
            return 0;
        }

        margem = ((pVenda - pCusto ) / pVenda) * 100;

        return margem;
    }

}


