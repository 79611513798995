<script setup>
    import { ref, onMounted, inject } from 'vue';
    import {DadosEmpresa} from '../../resources/dadosEmpresaResource';
    import { useField, useForm } from 'vee-validate'
    import {object} from 'yup';
    
    const snackBarApp = inject('snackBarApp');

    const validationSchema  = object({
        dadosEmpresa: object({
            nome: undefined,
            cidade: undefined,
        }).required()
    });
    const { handleSubmit, errors, setValues } = useForm({validationSchema});

    const dadosEmpresa =  ref({ 
        nome: useField('dadosEmpresa.nome').value,
        cidade: useField('dadosEmpresa.cidade').value,
    });

    const submit = handleSubmit(() => {
        gravar();
    });

    onMounted(() => {
        getDadosEmpresa();
    });

    async function gravar() {
        let dados = new FormData();
        dados.append('data', JSON.stringify(dadosEmpresa.value));

        try {
            const response = await DadosEmpresa.save(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBarApp.value.open('Não foi possível alterar os dados da empresa, mensagem de erro: '+ response.data.message, 6000);
                    return;
                }

                snackBarApp.value.open('Configurações dos dados da empresa alterados com sucesso.');
            }
        }  catch(err) {
            snackBarApp.value.open('Erro, mensagem de erro: '+ err);
        }
    }
    async function getDadosEmpresa() {
        try {
            const response = await DadosEmpresa.get();
            if (response.status == 200) {
                setValues({'dadosEmpresa': response.data.data});
                document.getElementById("nome").focus();            
            }
        } catch(err) {
            console.log(err);
        }
    }

</script>


<template>
    <v-container>
        <v-card class="align-center" elevation="16">
            <v-card-title>
            <h2>Dados da Empresa</h2>
            </v-card-title>
            
            <form ref="formDadosEmpresa" @submit.prevent="submit">
            <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-text-field label="Nome da Empresa"
                            id="nome"
                            type="text"
                            maxlength="100"
                            v-model='dadosEmpresa.nome'
                            :error-messages="errors['dadosEmpresa.nome']"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-text-field label="Cidade"
                            id="cidade"
                            type="text"
                            maxlength="50"
                            v-model='dadosEmpresa.cidade'
                            :error-messages="errors['dadosEmpresa.cidade']"/>
                </v-col>
            </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnDadosEmpresaGravar" >Salvar</v-btn>
            </v-card-actions>
            </form>
        </v-card>
    </v-container>
</template>
