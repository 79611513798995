<script setup>
import { ref, onMounted, inject, defineEmits } from "vue";
import { useRoute } from "vue-router";
import { OrdemServicos } from "../../resources/ordemServicosResource";
import { usePermissoes } from "../../store/permissoes";

const emit = defineEmits(['close-dialog']);
const isReadOnly = ref(false);
const observacoesInternas = ref(null);
const route = useRoute();
const showBtnGravar = ref(true);
const permissoesStore = usePermissoes();
const snackBarApp = inject("snackBarApp");

onMounted(() => {
    getObservacoes();

    if(route.name == 'OrdensServicos.Show') {
        isReadOnly.value = true;
    }

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

async function getObservacoes() {
    let dados = new FormData();
    dados.append('codigoModulo', '03.03.02');
    dados.append('idOrdemServicos', route.params.id);
    try {
        let response = await OrdemServicos.getObservacoesInternas(dados)
        if (response.status == 200) {
            observacoesInternas.value  = response.data.data.observacoesInternas;

        }

    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar as observações da ordem de serviços.');
        console.log(err);
    }
}

async function gravar() {
    let dados = new FormData();
    dados.append('idOrdemServicos', route.params.id);
    dados.append('codigoModulo', '03.03.02');
    dados.append('observacoesInternas', observacoesInternas.value);

    try { 
        let response = await OrdemServicos.gravarObservacoesInternas(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp('As observações internas não puderam ser gravadas, mensagem: '+response.data.message);
                return;
            }

            snackBarApp.value.open('Observações internas gravadas com sucesso.');

        }

    }
    catch (err) {
        snackBarApp.value.open('Erro ao gravar as observações internas da ordem de serviços.');
        console.log(err);
    }
}

function closeModalObservacoesOrdemServicos() {
    emit('close-dialog');
}

</script>

<template>
    <v-card elevetion="16">
        <v-card-title>
            <h2>Observações Internas da Ordem de Serviços</h2>
        </v-card-title>
        <form ref="formObservacoesInternasOrdemServicos" @submit.prevent="gravar">
        <v-card-text>
            <v-row >
                <v-col>
                    <v-textarea rows="15"
                        label="Observações Internas"
                        id="observacoesInternasOrdemServicos"
                        v-model="observacoesInternas"
                        type="text"
                        :readonly="isReadOnly"
                        />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-btn color="primary" v-show="showBtnGravar && route.name == 'OrdensServicos.Continuar'" 
                variant="elevated" 
                type="submit" 
                id="btnGravarObservacoesInternasOrdemServicos"
                >Gravar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="elevated" @click.prevent='closeModalObservacoesOrdemServicos'>Fechar</v-btn>
        </v-card-actions>
        </form>
    </v-card>
</template>
