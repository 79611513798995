import Http from './http';
import { useCasasDecimais } from '../store/casasDecimais';


export class CasasDecimais {

    static get = function() {
        return Http.get('/Serv/CasasDecimais/CasasDecimais.php', {
            params: {
                'operacao': 'retornar',
            }
        });
    };

    static save = function(dados) {
        return Http.post('/Serv/CasasDecimais/CasasDecimais.php?operacao=gravar', dados);
    };

    static inicializa = function() {
        const casasDecimaisStore = useCasasDecimais();
        
        Http.get('/Serv/CasasDecimais/CasasDecimais.php', {
            params: {
                'operacao': 'retornar',
            }
        }).then(function (response) {
            if (response.status == 200) {
                let quantidadeProduto = response.data.data.quantidadeProduto;
                let pCustoProduto = response.data.data.pCustoProduto;
                let quantidadeServico = response.data.data.quantidadeServico;
                casasDecimaisStore.setQuantidadeProduto(quantidadeProduto);
                casasDecimaisStore.setQuantidadeServico(quantidadeServico);
                casasDecimaisStore.setPCustoProduto(pCustoProduto);
                let quantidadeProdutoZero = '0,000';
                let quantidadeProdutoUm = '1,000';
                let pCustoProdutoZero = 'R$ 0,00';
                let quantidadeServicoZero = '0,000';
                let quantidadeServicoUm = '1,000';

                if (quantidadeProduto == '0') {
                    quantidadeProdutoZero = '0';
                    quantidadeProdutoUm = '1';
                }
                if (quantidadeProduto == '1') {
                    quantidadeProdutoZero = '0,0';
                    quantidadeProdutoUm = '1,0';
                }
                if (quantidadeProduto == '2') {
                    quantidadeProdutoZero = '0,00';
                    quantidadeProdutoUm = '1,00';
                }
                if (pCustoProduto == '3') {
                    pCustoProdutoZero = 'R$ 0,000'
                }
                if (pCustoProduto == '4') {
                    pCustoProdutoZero = 'R$ 0,0000'
                }

                if (quantidadeServico == '0') {
                    quantidadeServicoZero = '0';
                    quantidadeServicoUm = '1';
                }
                if (quantidadeServico == '1') {
                    quantidadeServicoZero = '0,0';
                    quantidadeServicoUm = '1,0';
                }
                if (quantidadeServico == '2') {
                    quantidadeServicoZero = '0,00';
                    quantidadeServicoUm = '1,00';
                }

                casasDecimaisStore.setQuantidadeProdutoZero(quantidadeProdutoZero);
                casasDecimaisStore.setQuantidadeProdutoUm(quantidadeProdutoUm);
                casasDecimaisStore.setPCustoProdutoZero(pCustoProdutoZero);
                casasDecimaisStore.setQuantidadeServicoZero(quantidadeServicoZero);
                casasDecimaisStore.setQuantidadeServicoUm(quantidadeServicoUm);
                casasDecimaisStore.setJaCarregado(true);
            }
        })
        .catch(function () {
        });
    }

}
