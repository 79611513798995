<template>
<div>

</div>

</template>

<script>

 //   import {Permissoes} from "../resources/permissoesResource";

    export default {

        mounted() {

            // ConfigInicializacao.get()
            //     .then(function (response) {
            //         if (response.status == 200) {
            //             let mostrarVencidos = response.data.data.mostrarVencidos;

            //             console.log('Mostrar Vencidos:'+mostrarVencidos);

            //             if(mostrarVencidos == 'S') {

            //                this.$('#modalProdutosVencidos').modal('show')
            //             }

            //         }
            //     })
            //     .catch(function () {
            //     });
        }

    }

</script>

