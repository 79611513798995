import config from '../services/config';
import { defineStore } from 'pinia';

export const useLancamentoCartao = defineStore('lancamentoCartao', {
    state: () => {
        return {
            start:0,
            limit: config.maxPerPage,
            currentPage: 1,
            abertos: true,
            cancelados: true,
            recebidos:true,
            dataInicial: new Date().toLocaleDateString("pt-BR"), 
            dataFinal: new Date().toLocaleDateString("pt-BR"),   
            idCartao:null,
            sort: [{
                'property':'data',
                'direction':'ASC'
            }],
            filtrarPor:'data',
        }
    },
    actions : {
        setStart(start) {
            this.start = start;
        },
        setAbertos(abertos) {
            this.abertos = abertos;
        },
        setCancelados(cancelados) {
            this.cancelados = cancelados;
        },
        setRecebidos(recebidos) {
            this.recebidos = recebidos;
        },
        setDataInicial(dataInicial) {
            this.dataInicial = dataInicial;
        },
        setDataFinal(dataFinal) {
            this.dataFinal = dataFinal;
        },
        setCurrentPage(currentPage) {
            this.currentPage = currentPage;
        },
        setSort(sort) {
            this.sort = sort;
        },        
        setIdCartao(idCartao) {
            this.idCartao = idCartao;
        },
        setFiltrarPor(filtrarPor) {
            this.filtrarPor = filtrarPor;
        },        
    }
});