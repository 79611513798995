import axios from 'axios';
import router from '../router'
import config from '../services/config';
import {ElNotification} from 'element-plus';

const client = axios.create({
    baseURL: config.baseUrl ,
    timeout: 5000,
    withCredentials: true, // para enviar os dados do coockie de sessão.
    headers: { 'Content-Type': 'multipart/form-data' }
// headers: {'X-Custom-Header': 'foobar'}
});

client.interceptors.response.use(function (response) {
    return response;

}, function (error) {

    if(error.response.status == 403) {
        router.push({ name:'Login'});

    }
    if(error.response.status == 401) {
        router.push({ name:'Home'});
        ElNotification({
            title:'Sem Permissão',
            message: 'Você não tem permissão para acessar esse módulo.',
            type: 'error'
        });

    }

    return Promise.reject(error);

});

export default client;