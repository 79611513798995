import {Permissoes} from '../resources/permissoesResource';
import { usePermissoes } from '../store/permissoes';
import { usePermissoesNegadas } from '../store/permissoesNegadas';


export class PermissaoService {
    
    static temPermissao = function(codigoModulo) {
        let permissoesStore = usePermissoes();
        const permissoesNegadasStore = usePermissoesNegadas();

        let tem = true;
        let permissoesNegadas = permissoesNegadasStore.permissoesNegadas;

        permissoesNegadas.forEach(function(auxPermissoesNegadas){
            if(auxPermissoesNegadas.codigoModulo == codigoModulo) {
                tem = false;
           }
        });

        let tipo = permissoesStore.tipo;
        let validade = permissoesStore.validade;

        if((tipo == 'D' || tipo == 'P') && validade == 'N') {
            tem = false;
        }

        return tem;
    };

    static setaPermissoes = function() {
        let permissoesStore = usePermissoes();
        const permissoesNegadasStore = usePermissoesNegadas();
        
        Permissoes.tipo().then(function(response) {
            let tipo = response.data;
       //     document.getElementById('mRelatorios').hidden = true;
       //     document.getElementById('mConfiguracoesCasasDecimais').hidden = false;
       //     document.getElementById('mConfiguracoesOrdensServicos').hidden = false;
       //     document.getElementById('mCabecalhoRelatorios').hidden = false;
       //     document.getElementById('mPermissoes').hidden = false;
       //     document.getElementById('mValidadeServico').hidden = false;

            if(tipo !== 'D' && tipo !== 'P') {

                // Cadastros
         //       document.getElementById('mBancos').hidden = true;
         //       document.getElementById('mContas').hidden = true;
         //       document.getElementById('mCartoes').hidden = true;
         //       document.getElementById('mAgendamentosVisitas').hidden = true;

                // Faturamento
                // document.getElementById('mOrcamentosOrdensServicos').hidden = true;
                // document.getElementById('mOrcamentosVendas').hidden = true;
                // document.getElementById('mPagamentosVendedores').hidden = true;
                // document.getElementById('mLancamentosContas').hidden = true;
                // document.getElementById('mChequesEmitidos').hidden = true;
                // document.getElementById('mChequesRecebidos').hidden = true;
                // document.getElementById('mLancamentosCartoes').hidden = true;
                // document.getElementById('mPedidosCompras').hidden = true;
                // document.getElementById('mVendasConsignadas').hidden = true;

                // Relatórios
//                document.getElementById('mRelatorios').hidden = true;

                // Utilitários
            //     document.getElementById('mConfiguracoesCasasDecimais').hidden = true;
            //     document.getElementById('mConfiguracoesOrdensServicos').hidden = true;
            //     document.getElementById('mPermissoes').hidden = true;
            //     document.getElementById('mValidadeServico').hidden = true;
            //     document.getElementById('mDadosEmpresa').hidden = true;
             }

            permissoesStore.setTipo(response.data);
        });

        Permissoes.listarNegadas().then(function( response) {
            let permissoes = response.data.data;
            permissoesNegadasStore.setPermissoesNegadas(permissoes);

            permissoes.forEach(function(codigoModulo){

                // Cadastros
                if(codigoModulo.codigoModulo == '01') {
                    document.getElementById('mCadastros').hidden = true;
                }
                if(codigoModulo.codigoModulo == '01.01') {
                    document.getElementById('mClientes').hidden = true;
                }
                if(codigoModulo.codigoModulo == '01.02') {
                    document.getElementById('mServicos').hidden = true;
                }
                if(codigoModulo.codigoModulo == '01.03') {
                    document.getElementById('mProdutos').hidden = true;
                }
                if(codigoModulo.codigoModulo == '01.04') {
                    document.getElementById('mTiposDocumentos').hidden = true;
                }
                if(codigoModulo.codigoModulo == '01.05') {
                    document.getElementById('mFornecedores').hidden = true;
                }
                if(codigoModulo.codigoModulo == '01.06') {
                    document.getElementById('mVendedores').hidden = true;
                }
                if(codigoModulo.codigoModulo == '01.07') {
                    document.getElementById('mBancos').hidden = true;
                }
                if(codigoModulo.codigoModulo == '01.08') {
                    document.getElementById('mContas').hidden = true;
                }
                if(codigoModulo.codigoModulo == '01.09') {
                    document.getElementById('mCartoes').hidden = true;
                }
                if(codigoModulo.codigoModulo == '01.10') {
                    document.getElementById('mGruposProdutos').hidden = true;
                }
                if(codigoModulo.codigoModulo == '01.11') {
                    document.getElementById('mSubgruposProdutos').hidden = true;
                }
                if(codigoModulo.codigoModulo == '01.12') {
                    document.getElementById('mGruposServicos').hidden = true;
                }
                if(codigoModulo.codigoModulo == '01.13') {
                    document.getElementById('mSubgruposServicos').hidden = true;
                }
                if(codigoModulo.codigoModulo == '01.14') {
                    document.getElementById('mGruposClientes').hidden = true;
                }
                if(codigoModulo.codigoModulo == '01.15') {
                    document.getElementById('mGruposContasPagar').hidden = true;
                }
                if(codigoModulo.codigoModulo == '01.16') {
                    document.getElementById('mSubgruposContasPagar').hidden = true;
                }
                if(codigoModulo.codigoModulo == '01.17') {
                    document.getElementById('mGruposContasReceber').hidden = true;
                }
                if(codigoModulo.codigoModulo == '01.18') {
                    document.getElementById('mSubgruposContasReceber').hidden = true;
                }
                if(codigoModulo.codigoModulo == '01.19') {
                    document.getElementById('mAgendamentosVisitas').hidden = true;
                }

                // Movimentação de Estoque
                if(codigoModulo.codigoModulo == '02') {
                    document.getElementById('mMovimentacaoEstoque').hidden = true;
                }
                if(codigoModulo.codigoModulo == '02.01') {
                    document.getElementById('mEntradaEstoque').hidden = true;
                }
                if(codigoModulo.codigoModulo == '02.02') {
                    document.getElementById('mSaidaEstoque').hidden = true;
                }
                if(codigoModulo.codigoModulo == '02.03') {
                    document.getElementById('mExcluirReverter').hidden = true;
                }

                // Faturamento
                if(codigoModulo.codigoModulo == '03') {
                    document.getElementById('mFaturamento').hidden = true;
                }
                if(codigoModulo.codigoModulo == '03.01') {
                    document.getElementById('mCaixa').hidden = true;
                }
                if(codigoModulo.codigoModulo == '03.02') {
                    document.getElementById('mContasReceber').hidden = true;
                }
                if(codigoModulo.codigoModulo == '03.03') {
                    document.getElementById('mOrdensServicos').hidden = true;
                }
                if(codigoModulo.codigoModulo == '03.04') {
                    document.getElementById('mContasPagar').hidden = true;
                }
                if(codigoModulo.codigoModulo == '03.05') {
                    document.getElementById('mOrcamentosOrdensServicos').hidden = true;
                }
                if(codigoModulo.codigoModulo == '03.06') {
                    document.getElementById('mOrcamentosVendas').hidden = true;
                }
                if(codigoModulo.codigoModulo == '03.07') {
                    document.getElementById('mPagamentosVendedores').hidden = true;
                }
                if(codigoModulo.codigoModulo == '03.08') {
                    document.getElementById('mComissoes').hidden = true;
                }
                if(codigoModulo.codigoModulo == '03.09') {
                    document.getElementById('mLancamentosContas').hidden = true;
                }
                if(codigoModulo.codigoModulo == '03.10') {
                    document.getElementById('mChequesEmitidos').hidden = true;
                }
                if(codigoModulo.codigoModulo == '03.11') {
                    document.getElementById('mChequesRecebidos').hidden = true;
                }
                if(codigoModulo.codigoModulo == '03.12') {
                    document.getElementById('mLancamentosCartoes').hidden = true;
                }
                if(codigoModulo.codigoModulo == '03.13') {
                    document.getElementById('mPedidosCompras').hidden = true;
                }

                // Relatorios
                if(codigoModulo.codigoModulo == '04') {
                    document.getElementById('mRelatorios').hidden = true;
                }

                // Utilitários
                if(codigoModulo.codigoModulo == '05') {
                    document.getElementById('mUtilitarios').hidden = true;
                }
                if(codigoModulo.codigoModulo == '05.01') {
                    document.getElementById('mUsuarios').hidden = true;
                }
                if(codigoModulo.codigoModulo == '05.02') {
                    document.getElementById('mConfiguracoesCasasDecimais').hidden = true;
                }
                if(codigoModulo.codigoModulo == '05.03') {
                    document.getElementById('mConfiguracoesContasReceber').hidden = true;
                }
                if(codigoModulo.codigoModulo == '05.04') {
                    document.getElementById('mConfiguracoesOrdensServicos').hidden = true;
                }
                if(codigoModulo.codigoModulo == '05.05') {
                    document.getElementById('mConsultaLogs').hidden = true;
                }
                if(codigoModulo.codigoModulo == '05.06') {
                    document.getElementById('mCabecalhoRelatorios').hidden = true;
                }
                if(codigoModulo.codigoModulo == '05.07') {
                    document.getElementById('mPermissoes').hidden = true;
                }
                if(codigoModulo.codigoModulo == '05.08') {
                    document.getElementById('mDadosEmpresa').hidden = true;
                }

            });
        });

    }

}


