<script setup>
    import { ref, onMounted, defineEmits } from 'vue';
    import { useRoute } from 'vue-router';
    import {Conta} from '../../resources/contaResource';
    import {PermissaoService} from '../../services/permissaoService';
    import {Banco} from '../../resources/bancoResource';
    import SnackBar from '../Utils/SnackBar';
    import { useField, useForm } from 'vee-validate';
    import {string, number, addMethod, object} from 'yup';
    import { usePermissoes } from '../../store/permissoes';
    import BancoCreate from '../Bancos/BancoCreate.vue';
    import Moeda from '../Utils/moeda.vue';
    import {Converte} from "../../utils/converte";   


    const isRealValidation = ref(null);
    const validationCodigo = ref(null);
    const snackBar = ref(null);
    const route = useRoute();
    let dialogBanco = ref(false);
    let  showBtnGravar = ref(true);
    const emit = defineEmits(['close-dialog']);
    const isReadOnly = ref(false);

    const permissoesStore = usePermissoes();

    addMethod(number, 'codigo_conta_existe', function codigoContaExiste(message) {
       return number().test('codigo-conta-existe',  // Name
            message,               // Msg
            function(codigo_conta) { 
                if(isRealValidation.value == null) {
                    return true;
                }
                if (!isRealValidation.value) {
                    return validationCodigo.value;
                }
                isRealValidation.value = false;
                validationCodigo.value = false;

                return new Promise((resolve) => {
                Conta.codigoExiste(codigo_conta, conta.value.idConta)
                .then(res => {if(res.data == false){
                    validationCodigo.value = true;
                    resolve(true)
                } 
                
                resolve(false)})
                })
        })
    });

  
    const validationSchema  = object({
        conta: object({
            idConta: undefined,
            codigo: number().
                min(1).
                max(999999999).
                codigo_conta_existe('Esse código já está sendo usado por outra conta bancária, tente outro código.').
                typeError('O código deve ser um número').
                label('O código'),
            apelido: string().required('O apelido da conta é obrigatório').max(20).label('O apelido da conta'),
            idBanco: number().required('O banco é obrigatório').label('O banco'),
            agencia: string().max(15).label('A agência'),
            conta: string().max(15).label('A conta'),
            saldo: string(),
            contato1: string().max(50).label('O contato 1'),
            contato2: string().max(50).label('O contato 2'),
            contato3: string().max(50).label('O contato 3'),
            telefone1: string().max(16).label('O telefone 1'),
            telefone2: string().max(16).label('O telefone 2'),
            telefone3: string().max(16).label('O telefone 3'),
            celular: string().max(16).label('O celular'),
            email: string().max(80).label('O email'),
            observacoes: undefined,
        }).required()
    });
    const { handleSubmit, errors, setValues } = useForm({validationSchema});

    const bancos = ref([]);
        
    const conta =  ref({ 
        idConta: useField('conta.idConta').value,
        codigo: useField('conta.codigo').value,
        apelido: useField('conta.apelido').value,
        idBanco: useField('conta.idBanco').value,
        agencia: useField('conta.agencia').value,
        conta: useField('conta.conta').value,
        saldo: useField('conta.saldo').value,
        contato1: useField('conta.contato1').value,
        contato2: useField('conta.contato2').value,
        contato3: useField('conta.contato3').value,
        telefone1: useField('conta.telefone1').value,
        telefone2: useField('conta.telefone2').value,
        telefone3: useField('conta.telefone3').value,
        celular: useField('conta.celular').value,
        email: useField('conta.email').value,
        observacoes: useField('conta.observacoes').value,
      
    });

    const submit = handleSubmit((values, actions) => {
        gravar(values, actions);
    });

    onMounted(() => {
        cleanForm();
        if(route.name == 'Conta.Update' || route.name == 'Conta.Show') {
            getConta(route.params.id);
        }

        if(route.name == 'Conta.Show') {
            isReadOnly.value = true;
        }
    
        getBancos();

        let tipo = permissoesStore.tipo;
        let validade = permissoesStore.validade;

        if((tipo == 'D' || tipo == 'P') && validade == 'N') {
            showBtnGravar.value = false;
        }

    });

    function cleanForm() {
        if(route.name == 'Conta.Create') {
            conta.value.idConta = null;
            proximoCodigo();
        }
        conta.value.apelido = '';
        if(bancos.value != null && bancos.value != undefined && bancos.value != '') {
            conta.value.idBanco = bancos.value[0].idBanco;
        }
        conta.value.agencia = '';
        conta.value.conta = '';
        conta.value.saldo = 'R$ 0,00';
        conta.value.contato1 = '';
        conta.value.contato2 = '';
        conta.value.contato3 = '';
        conta.value.telefone1 = '';
        conta.value.telefone2 = '';
        conta.value.telefone3 = '';
        conta.value.celular = '';
        conta.value.email = '';
        conta.value.observacoes = '';
    }

    async function gravar(values, actions) {
        let dados = new FormData();
        dados.append('data', JSON.stringify(values.conta));

        try {
            const response = await Conta.save(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBar.value.open('A conta bancária não pode ser cadastrada, mensagem de erro: '+ response.data.message, 6000);
                    return;
                }
                if(route.name == 'Conta.Update') {
                    snackBar.value.open('Conta bancária alterada com sucesso.');
                }
                else{
                    snackBar.value.open('Nova conta bancária cadastrada com sucesso.');
                    actions.resetForm();
                    cleanForm();
                    document.getElementById("codigo").focus();
                }
            }
        }  catch(err) {
            snackBar.value.open('Erro, mensagem de erro: '+ err);
        }
    }
    function closeModalCadastroConta() {
        emit('close-dialog');
    }
    
    
    async function getBancos() {
        const response = await Banco.getBancos();

        bancos.value = response.data.data;
        
        if(conta.value.idBanco == null) {
            conta.value.idBanco = response.data.data[0].idBanco;
        }
    }

    async function getConta(id) {
        let dados = new FormData();
        if(route.name == 'Conta.Update') {
            dados.append('codigoModulo', '01.08.02');
        } 
        else {
            dados.append('codigoModulo', '01.08.04');
        }
    
        dados.append('idConta', id);
        try {
            const response = await Conta.getById(dados);
            if (response.status == 200) {
                setValues({'conta': response.data.data});
                
                conta.value.saldo = Converte.numberToCurrency(conta.value.saldo);

                document.getElementById("codigo").focus();
            }
        } catch(err) {
            console.log(err);
        }
    }

    async function proximoCodigo() {
        try {
            const response  = await Conta.proximoCodigo()
            if(response.status == 200) {
                conta.value.codigo = response.data;
            }
        } catch(err) {
                console.log(err);
        }
    }        

    function temPermissao(codigoModulo) {
        return PermissaoService.temPermissao(codigoModulo);
    }

</script>

<template>
    <SnackBar ref="snackBar"></SnackBar>
        <v-card elevation="16">
            <v-card-title>
            <h2 v-show="$route.name !=='Conta.Update'">Nova Conta Bancária</h2>
                <h2 v-show="$route.name =='Conta.Update'">Alteração de Conta Bancária</h2>          
            </v-card-title>
            <form ref="formConta" @submit.prevent="submit">
            <v-card-text>
            <v-row>
                <v-col cols="2">
                    <v-text-field label="Código"
                            id="codigo"
                            type="text"
                            v-model='conta.codigo'
                            :error-messages="errors['conta.codigo']"
                            maxlength="9"
                            @blur="(e) => { isRealValidation= true; conta.codigo = e.target.value; }"
                            :readonly="isReadOnly"/>
                </v-col>
                <v-col cols="10">
                    <v-text-field label="Apelido"
                            id="apelido"
                            type="text"
                            v-model='conta.apelido'
                            :error-messages="errors['conta.apelido']"
                            maxlength="20"
                            :readonly="isReadOnly"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <v-select
                        :items = "bancos"
                        item-title="nome"
                        item-value="idBanco"
                        label="Banco"
                        v-model='conta.idBanco'
                        :error-messages="errors['conta.idBanco']"
                        :readonly="isReadOnly"
                    >
                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo banco.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            v-bind="props"
                                            color="primary"
                                            @click="dialogBanco = true"
                                            id="btnNovoBanco"
                                            v-show="temPermissao('01.07.01') && route.name !== 'Conta.Show'"
                                        >
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogBanco" transition="dialog-top-transition">
                                    <BancoCreate @cadastrouBanco="getBancos()" @close-dialog="dialogBanco = false" />
                                </v-dialog>
                            </template>
                    </v-select>
                </v-col>
                <v-col cols="3">
                    <v-text-field label="Agência"
                            id="agencia"
                            type="text"
                            v-model='conta.agencia'
                            :error-messages="errors['conta.agencia']"
                            maxlength="15"
                            :readonly="isReadOnly"/>
                </v-col>
                <v-col cols="3">
                    <v-text-field label="Conta"
                            id="conta"
                            type="text"
                            v-model='conta.conta'
                            :error-messages="errors['conta.conta']"
                            maxlength="15"
                            :readonly="isReadOnly"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <v-text-field label="Contato 1"
                            id="contato1"
                            type="text"
                            v-model='conta.contato1'
                            :error-messages="errors['conta.contato1']"
                            maxlength="50"
                            :readonly="isReadOnly"/>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Contato 2"
                            id="contato2"
                            type="text"
                            v-model='conta.contato2'
                            :error-messages="errors['conta.contato2']"
                            maxlength="50"
                            :readonly="isReadOnly"/>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Contato 3"
                            id="contato3"
                            type="text"
                            v-model='conta.contato3'
                            :error-messages="errors['conta.contato3']"
                            maxlength="50"
                            :readonly="isReadOnly"/>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="3">
                    <v-text-field label="Telefone 1"
                            id="telefone1"
                            type="text"
                            v-model='conta.telefone1'
                            :error-messages="errors['conta.telefone1']"
                            maxlength="16"
                            :readonly="isReadOnly"/>
                </v-col>
                <v-col cols="3">
                    <v-text-field label="Telefone 2"
                            id="telefone2"
                            type="text"
                            v-model='conta.telefone2'
                            :error-messages="errors['conta.telefone2']"
                            maxlength="16"
                            :readonly="isReadOnly"/>
                </v-col>
                <v-col cols="3">
                    <v-text-field label="Telefone 3"
                            id="telefone3"
                            type="text"
                            v-model='conta.telefone3'
                            :error-messages="errors['conta.telefone3']"
                            maxlength="16"
                            :readonly="isReadOnly"/>
                </v-col>
                <v-col cols="3">
                    <v-text-field label="Celular"
                            id="celular"
                            type="text"
                            v-model='conta.celular'
                            :error-messages="errors['conta.celular']"
                            maxlength="16"
                            :readonly="isReadOnly"/>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field label="Email"
                            id="email"
                            type="text"
                            v-model='conta.email'
                            :error-messages="errors['conta.email']"
                            maxlength="80"
                            :readonly="isReadOnly"/>
                </v-col>
                <v-col cols="2" v-show="route.name == 'Conta.Update' || route.name == 'Conta.Show'">
                    <moeda label="Saldo"
                            id="saldo"
                            type="text"
                            readOnly    
                            v-model='conta.saldo'
                            :error-messages="errors['conta.saldo']"
                            maxlength="15"
                            />
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-textarea label="Observações"
                            id="observacoes"
                            type="text"
                            v-model='conta.observacoes'
                            :readonly="isReadOnly"/>
                </v-col>
            </v-row>

            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnContaGravar" v-show="route.name !== 'Conta.Show'" >Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" 
                    variant="elevated" 
                    @click.prevent='$router.go(-1)' 
                    v-if="showBtnGravar" v-show="$route.name =='Conta.Create' || $route.name == 'Conta.Update' || $route.name == 'Conta.Show' ">Voltar</v-btn>
                <v-btn color="primary" variant="elevated" v-if="showBtnGravar" 
                    v-show="$route.name !=='Conta.Create' && $route.name !== 'Conta.Update' && $route.name !== 'Conta.Show' " 
                    @click.prevent='closeModalCadastroConta()'>Fechar</v-btn>
            </v-card-actions>
            </form>
        </v-card>
</template>