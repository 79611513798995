import Http from './http';
import { useConfiguracoesContasReceber } from '../store/configuracoesContasReceber';

export class ConfiguracoesContasReceber {

    static get = function() {
        return Http.get('/Serv/ConfiguracoesContasReceber/ConfiguracoesContasReceber.php', {
            params: {
                'operacao': 'retornar',
            }
        });
    };

    static save = function(dados) {
        return Http.post('/Serv/ConfiguracoesContasReceber/ConfiguracoesContasReceber.php?operacao=gravar', dados);
    };

    static async inicializa()  {
        const configuracoesContasReceberStore = useConfiguracoesContasReceber();
        let response = await Http.get('/Serv/ConfiguracoesContasReceber/ConfiguracoesContasReceber.php', {
            params: {
                'operacao': 'retornar',
            }});
        if (response.status == 200) {
            configuracoesContasReceberStore.setMulta(response.data.data.multa);
            configuracoesContasReceberStore.setJurosDiario(response.data.data.jurosDiario);
            configuracoesContasReceberStore.setCarenciaDiasJuros(response.data.data.carenciaDiasJuros);
            configuracoesContasReceberStore.setJaCarregado(true);
        }
        return true;
    }
}
