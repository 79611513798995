<script setup>
import { ref, onMounted, computed } from 'vue';
import { GrupoClientes } from '../../resources/grupoClientesResource';
import config from '../../services/config';
import { PermissaoService } from '../../services/permissaoService';
import MsgConfirm from '../Utils/MsgConfirm';
import SnackBar from '../Utils/SnackBar';
import { useGrupoClientes } from '../../store/grupoClientes'

let msgConfirm = ref(null);
let snackBar = ref(null);

const grupoClientesStore = useGrupoClientes();
let grupoClientes = ref({});
let gruposClientes = ref({});
const total = ref(0);
const pageSize = ref(config.maxPerPage);
const currentPage = ref(grupoClientesStore.currentPage);
const filtro = ref(grupoClientesStore.filtro);

onMounted(() => {
    search()
});
const direction = computed(() => {
    return grupoClientesStore.sort[0].direction;
});
async function destroy() {
    let dados = new FormData();
    dados.append('data', JSON.stringify(grupoClientes.value));

    try {
        const response = await GrupoClientes.excluir(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBar.value.open('Grupo de clientes excluído com sucesso.');
                search();
            }
            if (response.data.success == false) {
                snackBar.value.open(response.data.vinculos, 1000000);
            }
        }
    } catch (err) {
        console.log(err);
    }
}
function openModalDelete(grupoCli) {
    grupoClientes.value = grupoCli;
    msgConfirm.value.open('Confirma Exclusão ?', 'Confirma a exclusão do grupo de clientes: ' + grupoClientes.value.descricao + ' ?', { color: 'primary' }).then((confirm) => {
        if (confirm) {
            destroy();
        }
    });
}
async function search() {
    let start = grupoClientesStore.start;
    let limit = grupoClientesStore.limit;
    let sort = JSON.stringify(grupoClientesStore.sort);

    grupoClientesStore.setFiltro(filtro.value);

    try {
        const response = await GrupoClientes.get(start, limit, filtro.value, sort);
        gruposClientes.value = response.data.data;
        total.value = parseInt(response.data.total);
    } catch (err) {
        console.log(err);
    }
}
function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    grupoClientesStore.setCurrentPage(val);
    grupoClientesStore.setStart(start);
    currentPage.value = val;
    search();
}
function setSort(property) {
    let sort = grupoClientesStore.sort;
    let directionAtual = sort[0].direction;
    let direction = '';

    if (property !== sort[0].property) {

        direction = 'ASC';
    }
    else {
        if (directionAtual == 'ASC') {
            direction = 'DESC'
        }
        else {
            direction = 'ASC'
        }
    }

    let aSort = [{
        'property': property,
        'direction': direction
    }];
    grupoClientesStore.setSort(aSort);
    search();
}
function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}
</script>

<template>
    <SnackBar ref="snackBar"></SnackBar>

    <MsgConfirm ref="msgConfirm"></MsgConfirm>

    <v-row>
        <v-col>
            <h1>Grupos de Clientes</h1>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols=4>
            <v-btn v-show="temPermissao('01.14.01')" color="primary" :to="{ name: 'GrupoClientes.Create' }">Novo Grupo de
                Clientes</v-btn>
        </v-col>
        <v-col cols=8>
            <v-form>
                <v-row>
                    <v-col cols=9>
                        <v-text-field label="Filtro" type="text" v-model="filtro"
                            prepend-inner-icon="mdi-magnify"></v-text-field>
                    </v-col>
                    <v-col cols=3>
                        <v-btn color="primary" @click.prevent="search()" prepend-icon="mdi-magnify">Filtrar</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>

    </v-row>

    <p></p>
    <v-card elevation="16">
        <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-center">Ações</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3"
                            @click.prevent="setSort('descricao')">
                            Descrição
                            <v-icon
                                :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    
                </tr>
            </thead>
            <tbody>
                <tr v-for="(o) in gruposClientes" :key="o">
                    <td class="text-center">
                        <v-tooltip text="Altera o grupo de clientes.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-bind="props" variant="text" icon="mdi-pencil" v-show="temPermissao('01.14.02')"
                                    :to="{ name: 'GrupoClientes.Update', params: { id: o.idGrupoClientes } }"
                                    size="small">
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Exclui o grupo de clientes.">
                            <template v-slot:activator="{ props }">

                                <v-btn v-show="temPermissao('01.14.03')" v-bind="props" variant="text"
                                    @click.prevent="openModalDelete(o)" icon="mdi-trash-can"
                                    size="small">
                                </v-btn>
                            </template>
                        </v-tooltip>
                    </td>
                    <td>{{ o.descricao }}</td>

                </tr>

            </tbody>
        </v-table>
    </v-card>

    <div class="block text-right">
        <v-pagination @update:modelValue="handleCurrentChange" v-model="currentPage"
            :length="Math.ceil(total / pageSize)" :total-visible="7" :show-first-last-page="true">

        </v-pagination>
    </div>

    <p></p>


    <v-row>
        <v-col cols="4">
            <v-btn Primary v-show="temPermissao('01.14.01')" color="primary" :to="{ name: 'GrupoClientes.Create' }">Novo Grupo de  Clientes</v-btn>
        </v-col>
    </v-row>
</template>
