<script setup>
    import { ref, computed, onMounted, inject } from 'vue';
    import {Usuario} from '../../resources/usuarioResource';
    import config from '../../services/config';
    import {PermissaoService} from '../../services/permissaoService';
    import MsgConfirm from '../Utils/MsgConfirm';
        import { useUsuario } from '../../store/usuario';
    
    let msgConfirm = ref(null);
    let snackBarApp = inject('snackBarApp');
    
    const usuarioStore = useUsuario();
    let usuario = ref({});
    let usuarios = ref({});
    const total = ref(0);
    const pageSize = ref(config.maxPerPage);
    const currentPage = ref(usuarioStore.currentPage);
    const filtro = ref(usuarioStore.filtro);
    const filtrarPor = ref(usuarioStore.filtrarPor)
    const itemsFiltrarPor = ref([
        { value: 'codigo', label: 'Código' },
        { value: 'nome', label: 'Nome' },
        { value: 'login', label: 'Login' },
    ]);

    const direction = computed(() => {
        return usuarioStore.sort[0].direction;
    });

    const orderBy = computed(() => {
        return usuarioStore.sort[0].property;
    });
    
    onMounted(() => {
        search();
    });

    async function destroy() {
        let dados = new FormData();
        dados.append('data', JSON.stringify(usuario.value));

        try {
            const response = await Usuario.excluir(dados);
            if (response.status == 200) {
                if(response.data.success == true) {
                    snackBarApp.value.open('Usuário excluído com sucesso.');
                    search();
                }
                if(response.data.success == false) {
                    snackBarApp.value.open(response.data.message, 1000000);
                }
            }
        } catch(err) {
            console.log(err);
        }
    }
    
    function  openModalDelete(usua) {
        usuario.value = usua;
        msgConfirm.value.open('Confirma Exclusão ?', 'Confirma a exclusão do usuário: '+ 
        usuario.value.codigo+' '+usuario.value.nome+' ?', { color: 'primary' }).then((confirm) => {
            if(confirm) {
                destroy();
            }
        });
    }
    
    async function search() {
        let start = usuarioStore.start;
        let limit = usuarioStore.limit;
        let sort = JSON.stringify(usuarioStore.sort);
        
        usuarioStore.setFiltro(filtro.value);
        usuarioStore.setFiltrarPor(filtrarPor.value);
        try {
            const response = await Usuario.get(start, limit, filtro.value, sort, filtrarPor.value);
            usuarios.value = response.data.data;
            total.value = parseInt(response.data.total);
        } catch(err) {
            console.log(err);
        }
    }
    
    function handleCurrentChange(val) {
        let start = ( val - 1) * pageSize.value;
        usuarioStore.setCurrentPage(val);
        usuarioStore.setStart(start);
        currentPage.value = val;
        search();
    }
    
    function setSort(property) {
        let sort = usuarioStore.sort;
        let directionAtual = sort[0].direction;
        let direction = '';

        if (property !== sort[0].property) {
            direction = 'ASC';
        }
        else
        {
            if(directionAtual == 'ASC') {
                direction = 'DESC'
            }
            else
            {
                direction = 'ASC'
            }
        }

        let aSort = [{
            'property':property,
            'direction':direction
        }];
        usuarioStore.setSort(aSort);
        search();
    }

    function  temPermissao(codigoModulo) {
        return PermissaoService.temPermissao(codigoModulo);
    }
</script>

<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>
    <v-row>
        <v-col>
            <h1>Usuários</h1>
        </v-col>
    </v-row>

    <v-row>
        <v-col cols=3>
            <v-btn v-show="temPermissao('05.01')" color="primary" :to="{name: 'Usuario.Create'}">Novo Usuário</v-btn>
        </v-col>
        <v-col cols=9>
            <v-form>
                <v-row>
                    <v-col cols=4>
                        <v-select
                            v-model="filtrarPor"
                            :items="itemsFiltrarPor"
                            item-title="label"
                            item-value="value"
                            label="Filtrar Por"
                        ></v-select>
                    </v-col>
                    <v-col cols=6>
                        <v-text-field label="Filtro" type="text" v-model="filtro" prepend-inner-icon="mdi-magnify"></v-text-field>
                    </v-col>
                    <v-col cols=2>
                        <v-btn color="primary" @click.prevent="search()" prepend-icon="mdi-magnify">Filtrar</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </v-row>

    <p></p>
    <v-card  elevation="16">
        <v-table density="compact">
            <thead>
            <tr>
                <th class="text-center">Ações</th>
                <th>
                    <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigo')">
                        Código
                        <v-icon v-if="orderBy == 'codigo'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                    </a>
                </th>
                <th>
                    <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('nome')">
                        Nome do Usuário
                        <v-icon v-if="orderBy == 'nome'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                    </a>
                </th>
                <th>
                    <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('login')">
                        Login
                        <v-icon v-if="orderBy == 'login'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                    </a>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(o) in usuarios" :key="o">
                <td class="text-center">
                    <v-tooltip text="Altera o usuário.">
                        <template v-slot:activator="{ props }">
                            <v-btn 
                                v-bind="props"
                                variant="text" 
                                icon="mdi-pencil" 
                                :to="{ name: 'Usuario.Update', params: {id:o.idUsuario} }"
                                size="small">
                            </v-btn>
                        </template>
                    </v-tooltip>
                    <v-tooltip text="Consulta o usuário.">
                        <template v-slot:activator="{ props }">
                            <v-btn 
                                v-bind="props"
                                variant="text" 
                                icon="mdi-tab-search" 
                                :to="{ name: 'Usuario.Show', params: {id:o.idUsuario} }"
                                size="small">
                            </v-btn>
                        </template>
                    </v-tooltip>
                    <v-tooltip text="Exclui o usuário.">
                        <template v-slot:activator="{ props }">
                            <v-btn 
                                v-bind = "props"
                                variant="text"     
                                @click.prevent="openModalDelete(o)" 
                                icon="mdi-trash-can"
                                size="small">
                            </v-btn>
                        </template>
                    </v-tooltip>
                </td>

                <td>{{ o.codigo }}</td>
                <td>{{ o.nome }}</td>
                <td>{{ o.login }}</td>
            </tr>

            </tbody>
        </v-table>
    </v-card>

    <div class="block text-right">
        <v-pagination
            @update:modelValue="handleCurrentChange"        
            v-model="currentPage"
            :length="Math.ceil(total / pageSize)"
            :total-visible="7"
            :show-first-last-page="true">
        </v-pagination>
    </div>
    <p></p>
    <v-btn Primary v-show="temPermissao('05.01')" color="primary" :to="{name: 'Usuario.Create'}">Novo Usuário</v-btn>
</template>