import Http from './http';

export class Permissoes {

    static get = function(idUsuario) {
        return Http.get('/Serv/Permissoes/Permissoes.php', {
            params: {
                'operacao': 'listar',
                'idUsuario':idUsuario,
            }
        });
    };

    static save = function(dados) {
        return Http.post('/Serv/Permissoes/Permissoes.php?operacao=gravar', dados);
    };

    static getUsuarios = function() {
        return Http.get('/Serv/Permissoes/Permissoes.php?operacao=listausuarios');
    };

    static listarNegadas = function() {
        return Http.post('/Serv/Permissoes/Permissoes.php?operacao=listarnegadas');
    };

    static tipo = function() {
        return Http.get('/Serv/Permissoes/Permissoes.php?operacao=tipo');
    };

}
