<script setup>
    import { ref, computed, onMounted, defineEmits } from 'vue';
    import {Vendedor} from '../../resources/vendedorResource';
    import config from '../../services/config';
    import { useVendedor } from '../../store/vendedor';
    
    const emit = defineEmits(['selecionou-vendedor', 'close-dialog']);

    const vendedorStore = useVendedor();
    let vendedores = ref({});
    const total = ref(0);
    const pageSize = ref(config.maxPerPage);

    const currentPage = ref(vendedorStore.currentPage);
    const filtro = ref(vendedorStore.filtro);
    const filtrarPor = ref(vendedorStore.filtrarPor)
    const itemsFiltrarPor = ref([
        { value: 'codigo', label: 'Código' },
        { value: 'nome', label: 'Nome' },
        { value: 'cpf', label: 'CPF' },
    ]);

    const direction = computed(() => {
        return vendedorStore.sort[0].direction;
    });

    const orderBy = computed(() => {
        return vendedorStore.sort[0].property;
    });
    
    onMounted(() => {
        search();
    });

    async function search() {
        let start = vendedorStore.start;
        let limit = vendedorStore.limit;
        let sort = JSON.stringify(vendedorStore.sort);
        
        vendedorStore.setFiltro(filtro.value);
        vendedorStore.setFiltrarPor(filtrarPor.value);
        try {
            const response = await Vendedor.get(start, limit, filtro.value, sort, filtrarPor.value);
            vendedores.value = response.data.data;
            total.value = parseInt(response.data.total);
        } catch(err) {
            console.log(err);
        }
    }
    function handleCurrentChange(val) {
        let start = ( val - 1) * pageSize.value;
        vendedorStore.setCurrentPage(val);
        vendedorStore.setStart(start);
        search();
    }
    function setSort(property) {
        let sort = vendedorStore.sort;
        let directionAtual = sort[0].direction;
        let direction = '';

        if (property !== sort[0].property) {
            direction = 'ASC';
        }
        else
        {
            if(directionAtual == 'ASC') {
                direction = 'DESC'
            }
            else
            {
                direction = 'ASC'
            }
        }

        let aSort = [{
            'property':property,
            'direction':direction
        }];
        vendedorStore.setSort(aSort);
        search();
    }

    function selecionar(vendedor) {
        emit('selecionou-vendedor', vendedor);
    }

    function closeModalBuscaVendedores() {
        emit('close-dialog');
    }
 
</script>

<template>
    <v-card>
        <v-card-title>
            <h3>Busca de Vendedores/Funcionários</h3>
        </v-card-title>
        <v-card-text>
        <v-card  elevation="16" class="mb-5">
            <v-card-title>
                Filtro
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col cols=4>
                            <v-select
                                v-model="filtrarPor"
                                :items="itemsFiltrarPor"
                                item-title="label"
                                item-value="value"
                                label="Filtrar Por"
                            ></v-select>
                        </v-col>
                        <v-col cols=6>
                                <v-text-field label="Filtro" type="text" v-model="filtro" prepend-inner-icon="mdi-magnify"></v-text-field>
                        </v-col>
                        <v-col cols=2>
                            <v-btn color="primary" @click.prevent="search()" prepend-icon="mdi-magnify">Filtrar</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
            
            <v-card  elevation="16">
            <v-table density="compact">
                <thead>
                <tr>
                    <th class="text-center">Seleciona</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigo')">
                            Código
                            <v-icon v-if="orderBy == 'codigo'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('nome')">
                            Nome
                            <v-icon v-if="orderBy == 'nome'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>CPF</th>
                    <th>Celular</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(o) in vendedores" :key="o">
                    <td class="text-center">
                        <v-tooltip text="Seleciona esse vendedor/funcionário.">
                            <template v-slot:activator="{ props }">
                                <v-btn 
                                    v-bind="props"
                                    variant="text" 
                                    icon="mdi-check-bold" 
                                    @click.prevent="selecionar(o)">
                                </v-btn>
                            </template>
                        </v-tooltip>
                    </td>

                    <td><a @click.prevent="selecionar(o)">{{ o.codigo }}</a></td>
                    <td><a @click.prevent="selecionar(o)">{{ o.nome }}</a></td>
                    <td><a @click.prevent="selecionar(o)">{{ o.cpf }}</a></td>
                    <td><a @click.prevent="selecionar(o)">{{ o.celular }}</a></td>
                </tr>

                </tbody>
            </v-table>
        </v-card>

            <div class="block text-right">
                <v-pagination
                    @update:modelValue="handleCurrentChange"        
                    v-model="currentPage"
                    :length="Math.ceil(total / pageSize)"
                    :total-visible="7"
                    :show-first-last-page="true">
                </v-pagination>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="elevated" @click.prevent='closeModalBuscaVendedores'>Fechar</v-btn>
        </v-card-actions>
    </v-card>
</template>

