<script setup>
import { ref, onMounted, inject } from "vue";
import { useRoute } from "vue-router";
import { PagamentoVendedor} from "../../resources/pagamentoVendedorResource";
import { Vendedor } from "../../resources/vendedorResource";
import { useField, useForm } from "vee-validate";
import { string, number, object } from "yup";
import { usePermissoes } from "../../store/permissoes";
import VendedorBusca from "../Vendedores/VendedorBusca.vue";
import { Converte } from "../../utils/converte";
import Data from "../Utils/data.vue";
import Moeda from "../Utils/moeda.vue";

const snackBarApp = inject("snackBarApp");
const route = useRoute();
const isReadOnly = ref(false);
let dialogBuscaVendedor = ref(false);
let showBtnGravar = ref(true);

const permissoesStore = usePermissoes();

const validationSchema = object({
    pagamentoVendedor: object({
        idPagamentoVendedor: undefined,
        data: string().required("A data é obrigatória").label("Data"),
        idVendedor: undefined,
        codigoVendedor: number().typeError("O código do vendedor deve ser um número").label("O código do vendedor"),
        nomeVendedor: undefined,
        historico: string().max(80, "O histórico deve ter no máximo 80 caracteres").label("Histórico"),
        valor: undefined,
        observacoes: undefined,
    }).required(),
});

const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const pagamentoVendedor = ref({
    idPagamentoVendedor: useField("pagamentoVendedor.idPagamentoVendedor").value,
    data: useField("pagamentoVendedor.data").value,
    idVendedor: useField("pagamentoVendedor.idVendedor").value,
    codigoVendedor: useField("pagamentoVendedor.codigoVendedor").value,
    nomeVendedor: useField("pagamentoVendedor.nomeVendedor").value,
    historico: useField("pagamentoVendedor.historico").value,
    valor: useField("pagamentoVendedor.valor").value,
    observacoes: useField("pagamentoVendedor.observacoes").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    cleanForm();
    if (route.name == "PagamentosVendedores.Show") {
        getPagamentoVendedor(route.params.id);
        isReadOnly.value = true;

        document.getElementById("btnBuscaVendedor").style.display = "none";

        showBtnGravar.value = false;
    }

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    if (route.name == "PagamentosVendedores.Create") {
        pagamentoVendedor.value.idPagamentoVendedor = null;
    }

    pagamentoVendedor.value.data = new Date().toLocaleDateString("pt-BR"),
    pagamentoVendedor.value.idVendedor = null;
    pagamentoVendedor.value.codigoVendedor = "";
    pagamentoVendedor.value.nomeVendedor = "";
    pagamentoVendedor.value.historico = "";
    pagamentoVendedor.value.valor = "R$ 0,00";
    pagamentoVendedor.value.observacoes = "";
}
    

async function gravar(values, actions) {
    let dados = new FormData();

    if (values.pagamentoVendedor.data == "") {
        values.pagamentoVendedor.data = null;
    } else {
        values.pagamentoVendedor.data = Converte.datePtBrToIso(values.pagamentoVendedor.data); 
    }

    dados.append("data", JSON.stringify(values.pagamentoVendedor));

    try {
        const response = await PagamentoVendedor.save(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("O pagamento não pode ser cadastrado, mensagem de erro: " + response.data.message, 6000);
                return;
            }
            snackBarApp.value.open("Novo pagamento a vendedores/funcionários cadastrado com sucesso.");
            actions.resetForm();
            cleanForm();
            document.getElementById("data").focus();
        }
    } catch (err) {
        snackBarApp.value.open("Erro, mensagem de erro: " + err);
    }
}

async function getPagamentoVendedor(id) {
    let dados = new FormData();
    dados.append("codigoModulo", "03.07.02");
    
    dados.append("idPagamentoVendedor", id);
    try {
        const response = await PagamentoVendedor.getById(dados);
        if (response.status == 200) {
            response.data.data.data = Converte.dateIsoToPtBr(response.data.data.data);
            setValues({ pagamentoVendedor: response.data.data });
            pagamentoVendedor.value.valor = Converte.numberToCurrency(response.data.data.valor);

            document.getElementById("codigo").focus();
        }
    } catch (err) {
        console.log(err);
    }
}

async function getVendedor() {
    pagamentoVendedor.value.idVendedor = null;
    pagamentoVendedor.value.nomeVendedor = "";

    if (pagamentoVendedor.value.codigoVendedor.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", pagamentoVendedor.value.codigoVendedor);
    dados.append("getInativosBloqueados", false);
    dados.append("codigoModulo", "03.07");

    try {
        let response = await Vendedor.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                if (response.data.message == "inativo") {
                    snackBarApp.value.open("Um vendedor inativo não pode ter novos lançamentos de pagamentos a vendedores/funcionários.", 6000);
                    document.getElementById("codigoVendedor").focus();
                    pagamentoVendedor.value.codigoVendedor = "";
                    return;
                }
                if (response.data.message == "bloqueado") {
                    snackBarApp.value.open("Um vendedor bloqueado não pode ter novos lançamentos de pagamentos a vendedores/funcionários.", 6000);
                    document.getElementById("codigoVendedor").focus();
                    pagamentoVendedor.value.codigoVendedor = "";
                    return;
                }

                document.getElementById("codigoVendedor").focus();

                snackBarApp.value.open("Vendedor não encontrado, tente outro código ou faça uma busca.", 6000);

                pagamentoVendedor.value.codigoVendedor = "";
                return;
            }
            pagamentoVendedor.value.idVendedor = response.data.data.idVendedor;
            pagamentoVendedor.value.nomeVendedor = response.data.data.nome;
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouVendedor(vendedor) {
    if (vendedor == null) {
        return;
    }

    pagamentoVendedor.value.codigoVendedor = vendedor.codigo;
    pagamentoVendedor.value.nomeVendedor = vendedor.nome;
    pagamentoVendedor.value.idVendedor = vendedor.idVendedor;

    document.getElementById("codigoVendedor").focus();
    document.getElementById("codigoVendedor").blur();
    dialogBuscaVendedor.value = false;
}

</script>

<template>
    <v-card elevation="16">
        <form ref="formPagamentoVendedor" @submit.prevent="submit">
            <v-card-title>
                <h2 v-show="$route.name == 'PagamentosVendedores.Create'">Novo Pagamento a Vendedor/Funcionário</h2>
                <h2 v-show="$route.name == 'PagamentosVendedores.Show'">Consulta de Pagamento a Vendedor/Funcionário</h2>
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col cols="2">
                        <Data label="Data" id="data" type="text" v-model="pagamentoVendedor.data" :error-messages="errors['pagamentoVendedor.data']" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="2">
                        <v-text-field
                            label="Cód.Vendedor"
                            id="codigoVendedor"
                            type="text"
                            v-model="pagamentoVendedor.codigoVendedor"
                            :error-messages="errors['pagamentoVendedor.codigoVendedor']"
                            maxlength="9"
                            @blur="getVendedor()"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="8">
                        <v-text-field label="Nome do Vendedor" id="nomeVendedor" type="text" v-model="pagamentoVendedor.nomeVendedor" readonly tabindex="-1">
                            <template v-slot:append>
                                <v-tooltip text="Faz a busca de um vendedor por nome.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogBuscaVendedor = true" id="btnBuscaVendedor">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogBuscaVendedor" transition="dialog-top-transition">
                                    <VendedorBusca @selecionouVendedor="selecionouVendedor" @close-dialog="dialogBuscaVendedor = false" />
                                </v-dialog>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="9">
                        <v-text-field label="Histórico" id="historico" type="text" maxLength="80" v-model="pagamentoVendedor.historico" :error-messages="errors['pagamentoVendedor.historico']" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor" id="valor" type="text" v-model="pagamentoVendedor.valor" :error-messages="errors['pagamentoVendedor.valor']" :readonly="isReadOnly" />
                    </v-col>
                </v-row>    
                <v-row>
                    <v-col>
                        <v-textarea label="Observações" id="observacoes" type="text" v-model="pagamentoVendedor.observacoes" :readonly="isReadOnly" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn v-show="showBtnGravar && $route.name !== 'PagamentosVendedores.Show'" color="primary" variant="elevated" type="submit" id="btnPagamentoVendedorGravar"
                    >Salvar</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="elevated"
                    @click.prevent="$router.go(-1)"
                    v-show="$route.name == 'PagamentosVendedores.Create' || $route.name == 'PagamentosVendedores.Show'"
                    >Voltar</v-btn
                >
            </v-card-actions>
        </form>
    </v-card>
</template>