<script setup>
    import { ref, onMounted, defineEmits } from 'vue';
    import { useRoute } from 'vue-router';
    import {SubgrupoContasPagar} from '../../resources/subgrupoContasPagarResource';
    import {PermissaoService} from '../../services/permissaoService';
    import {GrupoContasPagar} from '../../resources/grupoContasPagarResource';
    import { useField, useForm } from 'vee-validate'
    import {object, string} from 'yup';
    import SnackBar from '../Utils/SnackBar';
    import { usePermissoes } from '../../store/permissoes';
    import GrupoContasPagarCreate from '../GruposContasPagar/GrupoContasPagarCreate.vue';
    
    const emit = defineEmits(['close-dialog', 'cadastrou-subgrupo-contas-pagar']);
    const snackBar = ref(null);
    const route = useRoute();
    let dialog = ref(false);
    let  showBtnGravar = ref(true);
    const permissoesStore = usePermissoes();

    const validationSchema  = object({
        subgrupoContasPagar: object({
            idSubgrupoContasPagar: undefined,
            idGrupoContasPagar: string(),
            descricao: string().required('A descrição é obrigatória').max(50)
        }).required()
    });
    const { handleSubmit, errors, setValues } = useForm({validationSchema});

    const gruposContasPagar = ref([]);
    
    const subgrupoContasPagar =  ref({ 
        idSubgrupoContasPagar: useField('subgrupoContasPagar.idSubgrupoContasPagar').value,
        idGrupoContasPagar: useField('subgrupoContasPagar.idGrupoContasPagar').value,
        descricao: useField('subgrupoContasPagar.descricao').value 
    });

    const submit = handleSubmit((values, actions) => {
        gravar(values, actions);
    });

    onMounted(() => {
        getGruposContasPagar();
        if(route.name == 'SubgrupoContasPagar.Update') {
            getSubgrupoContasPagar(route.params.id);
        }
        else {
            subgrupoContasPagar.value.idSubgrupoContasPagar = null;
        }


        let tipo = permissoesStore.tipo;
        let validade = permissoesStore.validade;

        if((tipo == 'D' || tipo == 'P') && validade == 'N') {
            showBtnGravar.value = false;
        }
    });
    async function gravar(values, actions) {
        let dados = new FormData();
        dados.append('data', JSON.stringify(subgrupoContasPagar.value));

        try {
            const response = await SubgrupoContasPagar.save(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBar.value.open('Subgrupo de contas a pagar não pode ser cadastrado, mensagem de erro: '+ response.data.message, 6000);
                    return;
                }
                if(route.name == 'SubgrupoContasPagar.Update') {
                    snackBar.value.open('Subgrupo de contas a pagar alterado com sucesso.');
                }
                else{
                    snackBar.value.open('Novo subgrupo de contas a pagar cadastrado com sucesso.');
                    let idGrupoContasPagar = subgrupoContasPagar.value.idGrupoContasPagar;
                    actions.resetForm();
                    subgrupoContasPagar.value.idGrupoContasPagar = idGrupoContasPagar;
                    subgrupoContasPagar.value.idSubgrupoContasPagar = null;
                    document.getElementById("descricao").focus();
                    emit('cadastrou-subgrupo-contas-pagar');
                }
            }
        }  catch(err) {
            snackBar.value.open('Erro, mensagem de erro: '+ err);
        }
    }
    function closeModalCadastroSubgrupoContasPagar() {
        emit('close-dialog');
    }
    async function getGruposContasPagar() {
        let start = 0;
        let limit = 999999;
        let sort = null;

        await GrupoContasPagar.get(start, limit, '', sort)
            .then(function (response) {
                gruposContasPagar.value = response.data.data;

                if(subgrupoContasPagar.value.idGrupoContasPagar == null) {
                    subgrupoContasPagar.value.idGrupoContasPagar = response.data.data[0].idGrupoContasPagar;
                    return;
                }
            })
            .catch(function () {
            });
    }

    async function getSubgrupoContasPagar(id) {
        let dados = new FormData();
        dados.append('codigoModulo', '01.16.02');
        dados.append('idSubgrupoContasPagar', id);
        try {
            const response = await SubgrupoContasPagar.getById(dados);
            if (response.status == 200) {
                setValues({'subgrupoContasPagar': response.data.data});
                document.getElementById("descricao").focus();
            }
        } catch(err) {
            console.log(err);
        }
    }

    function temPermissao(codigoModulo) {
        return PermissaoService.temPermissao(codigoModulo);
    }


</script>


<template>
    <SnackBar ref="snackBar"></SnackBar>
        <v-card elevation="16">
            <v-card-title>
            <h2 v-show="$route.name !=='SubgrupoContasPagar.Update'">Novo Subgrupo de Contas a Pagar</h2>
                <h2 v-show="$route.name =='SubgrupoContasPagar.Update'">Alteração de Subgrupo de Contas a Pagar</h2>          
            </v-card-title>
            <form ref="formSubgrupoContasPagar" @submit.prevent="submit">
            <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-select
                        :items = "gruposContasPagar"
                        item-title="descricao"
                        item-value="idGrupoContasPagar"
                        label="Grupo de Contas a Pagar"
                        v-model='subgrupoContasPagar.idGrupoContasPagar'
                        :error-messages="errors['subgrupoContasPagar.idGrupoContasPagar']"
                    >
                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo grupo de contas a pagar.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialog = true"
                                            id="btnNovoGrupoContasPagar" v-show="temPermissao('01.16.01')">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialog" transition="dialog-top-transition" >
                                    <GrupoContasPagarCreate @close-dialog="dialog = false"  @cadastrouGrupoContasPagar="getGruposContasPagar()" />
                                </v-dialog>
                            </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-text-field label="Descrição"
                            id="descricao"
                            type="text"
                            maxlength="50"
                            v-model='subgrupoContasPagar.descricao'
                            :error-messages="errors['subgrupoContasPagar.descricao']"/>
                </v-col>
            </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnSubgrupoContasPagarGravar" >Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" 
                    variant="elevated" 
                    @click.prevent='$router.go(-1)' 
                    v-if="showBtnGravar" v-show="$route.name =='SubgrupoContasPagar.Create' || $route.name == 'SubgrupoContasPagar.Update' ">Voltar</v-btn>
                <v-btn color="primary" variant="elevated" v-if="showBtnGravar" 
                    v-show="$route.name !=='SubgrupoContasPagar.Create' && $route.name !== 'SubgrupoContasPagar.Update' " 
                    @click.prevent='closeModalCadastroSubgrupoContasPagar()'>Fechar</v-btn>
            </v-card-actions>
            </form>
        </v-card>
</template>