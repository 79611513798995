import Http from './http';

export class EntradaEstoque {

    static save = function(dados) {
        return Http.post('/Serv/MovimentacoesEstoque/Entradas.php?operacao=gravar', dados);
    };

    static ultimasEntradas = function(dados) {
        return Http.post('/Serv/MovimentacoesEstoque/Entradas.php?operacao=ultimasentradas', dados);
    };


}
