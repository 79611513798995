import Http from './http';

export class AgendamentoVisita {
    static listar = function(start, limit, sort, filtrarPor, dataInicial, dataFinal, pendentes, cancelados, visitados, idCliente, idVendedor, codigo) {
        return Http.get('/Serv/AgendamentoVisita/AgendamentoVisita.php', {
            params: {
                'operacao': 'listar',
                'start':start,
                'limit':limit,
                'sort':sort,
                'filtrarPor': filtrarPor,
                'dataInicial': dataInicial,
                'dataFinal': dataFinal,
                'pendentes': pendentes,
                'cancelados': cancelados,
                'visitados': visitados,
                'idCliente': idCliente,
                'idVendedor': idVendedor,
                'codigo': codigo,
            }
        });
    };

    static gravar = function(dados) {
        return Http.post('/Serv/AgendamentoVisita/AgendamentoVisita.php?operacao=gravar', dados);
    };

    static proximoCodigo = function() {
        return Http.get('/Serv/AgendamentoVisita/AgendamentoVisita.php?operacao=proximocodigo');
    };

    static codigoExiste = function(codigo, idAgendamentoVisita) {
        let dados = new FormData();
        dados.append('codigo', codigo);
        dados.append('idAgendamentoVisita', idAgendamentoVisita);
        return Http.post('/Serv/AgendamentoVisita/AgendamentoVisita.php?operacao=codigoexiste', dados);
    };

    static getById = function(dados) {
        return Http.post('/Serv/AgendamentoVisita/AgendamentoVisita.php?operacao=getbyid', dados);
    };

    static excluir = function(dados) {
        return Http.post('/Serv/AgendamentoVisita/AgendamentoVisita.php?operacao=excluir', dados);
    };

    static cancelar = function(dados) {
        return Http.post('/Serv/AgendamentoVisita/AgendamentoVisita.php?operacao=cancelar', dados);
    };

    static visitado = function(dados) {
        return Http.post('/Serv/AgendamentoVisita/AgendamentoVisita.php?operacao=visitado', dados);
    };
}