<script setup>
import { ref, computed, onMounted, inject } from "vue";
import { OrcamentoOrdemServicos } from "@/resources/orcamentoOrdemServicosResource";
import { Relatorio } from "@/resources/relatoriosResource";
import { Cliente } from "@/resources/clienteResource";
import config from "../../services/config";
import { PermissaoService } from "../../services/permissaoService";
import MsgConfirm from "../Utils/MsgConfirm";
import { useOrcamentosOrdensServicos } from "../../store/orcamentosOrdensServicos";
import { useConfiguracoesOrdensServicos } from "../../store/configuracoesOrdensServicos";
import { valorFormat } from "../../services/filtros";
import ClienteBusca from "../Clientes/ClienteBusca.vue";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";

let msgConfirm = ref(null);
let snackBarApp = inject("snackBarApp");

let dialogCliente = ref(false);

const orcamentosOrdensServicosStore = useOrcamentosOrdensServicos();
const configuracoesOrdensServicosStore = useConfiguracoesOrdensServicos();
let orcamentosOrdensServicos = ref({});
const total = ref(0);
const pageSize = ref(config.maxPerPage);
const currentPage = ref(orcamentosOrdensServicosStore.currentPage);
const abertos = ref(orcamentosOrdensServicosStore.abertos);
const cancelados = ref(orcamentosOrdensServicosStore.cancelados);
const transformados = ref(orcamentosOrdensServicosStore.transformados);
const dataInicial = ref(orcamentosOrdensServicosStore.dataInicial);
const dataFinal = ref(orcamentosOrdensServicosStore.dataFinal);
const idCliente = ref(orcamentosOrdensServicosStore.idCliente);
const codigoCliente = ref(orcamentosOrdensServicosStore.codigoCliente);
const nomeCliente = ref(orcamentosOrdensServicosStore.nomeCliente);
const filtrarPor = ref(orcamentosOrdensServicosStore.filtrarPor);
const codigo = ref(orcamentosOrdensServicosStore.codigo);
const campo = ref(orcamentosOrdensServicosStore.campo);
const buscaCampo = ref(orcamentosOrdensServicosStore.buscaCampo);
const labelCampo1 = ref(configuracoesOrdensServicosStore.labelCampo1);
const labelCampo2 = ref(configuracoesOrdensServicosStore.labelCampo2);
const labelCampo3 = ref(configuracoesOrdensServicosStore.labelCampo3);
const labelCampo4 = ref(configuracoesOrdensServicosStore.labelCampo4);
const labelCampo5 = ref(configuracoesOrdensServicosStore.labelCampo5);
const jaCarregado = ref(configuracoesOrdensServicosStore.jaCarregado);

const itemsFiltrarPor = ref([
    { value: 'data', label: 'Data' },
    { value: 'validade', label: 'Validade' },
]);

const labelCampos = ref([
    { value: 'campo1', label: labelCampo1.value },
    { value: 'campo2', label: labelCampo2.value },
    { value: 'campo3', label: labelCampo3.value },
    { value: 'campo4', label: labelCampo4.value },
    { value: 'campo5', label: labelCampo5.value },
]);

const direction = computed(() => {
    return orcamentosOrdensServicosStore.sort[0].direction;
});

const orderBy = computed(() => {
    return orcamentosOrdensServicosStore.sort[0].property;
});

onMounted(() => {
    if(jaCarregado.value == false) {
        setTimeout(() => {
            labelCampo1.value = configuracoesOrdensServicosStore.labelCampo1;
            labelCampo2.value = configuracoesOrdensServicosStore.labelCampo2;
            labelCampo3.value = configuracoesOrdensServicosStore.labelCampo3;
            labelCampo4.value = configuracoesOrdensServicosStore.labelCampo4;
            labelCampo5.value = configuracoesOrdensServicosStore.labelCampo5;
            labelCampos.value = [
                { value: 'campo1', label: configuracoesOrdensServicosStore.labelCampo1 },
                { value: 'campo2', label: configuracoesOrdensServicosStore.labelCampo2 },
                { value: 'campo3', label: configuracoesOrdensServicosStore.labelCampo3 },
                { value: 'campo4', label: configuracoesOrdensServicosStore.labelCampo4 },
                { value: 'campo5', label: configuracoesOrdensServicosStore.labelCampo5 },
            ];

        }, 500);

    }

    filtrar('geral');
});

async function destroy(idOrcamentoOrdemServicos) {
    let dados = new FormData();
    dados.append("idOrcamentoOrdemServicos", JSON.stringify(idOrcamentoOrdemServicos));

    try {
        const response = await OrcamentoOrdemServicos.excluir(dados);
        if (response.status == 200) {
            if (response.data.success == true) {
                snackBarApp.value.open("Orçamento de ordem de serviços excluído com sucesso.");
                filtrar();
            }
        }
    } catch (err) {
        console.log(err);
    }
}

function openModalDelete(lancamento) {
    msgConfirm.value
        .open(
            "Confirma Exclusão ?",
            "Confirma a exclusão desse orçamento de ordem de serviços ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                destroy(lancamento.idOrcamentoOrdemServicos);
            }
        });
}

async function filtrar(tipoFiltro, mudouPagina = false) {
    let start = orcamentosOrdensServicosStore.start;
    let limit = orcamentosOrdensServicosStore.limit;
    let filtro = '';
    let sort = JSON.stringify(orcamentosOrdensServicosStore.sort);

    if(tipoFiltro == null || tipoFiltro == "" || tipoFiltro == undefined) {
        tipoFiltro = orcamentosOrdensServicosStore.tipoFiltro;
    }
    orcamentosOrdensServicosStore.setIdCliente(idCliente.value);
    orcamentosOrdensServicosStore.setCodigo(codigo.value);
    orcamentosOrdensServicosStore.setCodigoCliente(codigoCliente.value);
    orcamentosOrdensServicosStore.setNomeCliente(nomeCliente.value);
    orcamentosOrdensServicosStore.setDataInicial(dataInicial.value);
    orcamentosOrdensServicosStore.setDataFinal(dataFinal.value);
    orcamentosOrdensServicosStore.setAbertos(abertos.value);
    orcamentosOrdensServicosStore.setCancelados(cancelados.value);
    orcamentosOrdensServicosStore.setTransformados(transformados.value);
    orcamentosOrdensServicosStore.setTipoFiltro(tipoFiltro);
    orcamentosOrdensServicosStore.setFiltrarPor(filtrarPor.value);
    orcamentosOrdensServicosStore.setCampo(campo.value);
    orcamentosOrdensServicosStore.setBuscaCampo(buscaCampo.value);

    let auxDataInicial = null;
    let auxDataFinal = null;
    if (dataInicial.value != "") {
        auxDataInicial = Converte.datePtBrToIso(dataInicial.value); 
    }

    if (dataFinal.value != "") {
        auxDataFinal = Converte.datePtBrToIso(dataFinal.value); 
    }
    if(tipoFiltro == 'codigo') {
        filtro = codigo.value;
    }

    if(tipoFiltro == 'campo') {
        filtro = buscaCampo.value;
    }

    if(mudouPagina == false) {
        handleCurrentChange(1);
        return;
    }

    try {
        const response = await OrcamentoOrdemServicos.listar(start, limit, sort, filtrarPor.value, auxDataInicial, auxDataFinal, abertos.value, 
            cancelados.value, transformados.value, idCliente.value, filtro, tipoFiltro, campo.value);
        orcamentosOrdensServicos.value = response.data.data;
        total.value = parseInt(response.data.total);
    } catch (err) {
        console.log(err);
    }
}
function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    orcamentosOrdensServicosStore.setCurrentPage(val);
    orcamentosOrdensServicosStore.setStart(start);
    currentPage.value = val;
    filtrar(null, true);
}
function setSort(property) {
    let sort = orcamentosOrdensServicosStore.sort;
    let directionAtual = sort[0].direction;
    let direction = "";

    if (property !== sort[0].property) {
        direction = "ASC";
    } else {
        if (directionAtual == "ASC") {
            direction = "DESC";
        } else {
            direction = "ASC";
        }
    }

    let aSort = [
        {
            property: property,
            direction: direction,
        },
    ];

    orcamentosOrdensServicosStore.setSort(aSort);
    filtrar();
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}

function selecionouCliente(cliente) {
    if (cliente == null) {
        return;
    }

    codigoCliente.value = cliente.codigo;
    getCliente();
    dialogCliente.value = false;
}

async function getCliente() {
    idCliente.value = null;
    nomeCliente.value = "";
    if (codigoCliente.value.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", codigoCliente.value);
    dados.append("getInativos", true);
    dados.append("codigoModulo", "03.05");

    try {
        let response = await Cliente.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                document.getElementById("codigoCliente").focus();
                snackBarApp.value.open("O cliente não foi encontrado, tente outro código ou faça uma busca.");
                codigoCliente.value = "";
                return;
            }
            idCliente.value = response.data.data.idCliente;
            codigoCliente.value = response.data.data.codigo;
            nomeCliente.value = response.data.data.nome;
        }
    } catch (err) {
        console.log("Erro ao buscar o cliente: " + err);
    }
}

function openModalCancelar(lancamento) {
    msgConfirm.value
        .open(
            "Confirma Cancelamento ?",
                "Confirma o cancelamento desse orçamento de ordem de serviços ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                cancelar(lancamento.idOrcamentoOrdemServicos);
            }
        });
}

async function cancelar(idOrcamentoOrdemServicos) {
    let dados = new FormData();
    dados.append("idOrcamentoOrdemServicos", idOrcamentoOrdemServicos);

    try {
        const response = await OrcamentoOrdemServicos.cancelar(dados);
        if (response.status == 200) {
            if (response.data.success == true) {
                snackBarApp.value.open("Orçamento de ordem de serviços cancelado.");
                filtrar();
            } else {
                snackBarApp.value.open("Não foi possível cancelar o orçamento de ordem de serviços.");
            }
        }
    } catch (err) {
        console.log(err);
    }
}

async function imprimir(idOrcamentoOrdemServicos) {
    let nomeRel = 'OrcamentoOrdemServicos'; 

    let response = await Relatorio.get(nomeRel, idOrcamentoOrdemServicos);
    if (response.status == 200) {
        if(response.data.success == true) {
            let nomeArquivo = response.data.nomeArquivo;
            let url = config.baseUrlRel+nomeArquivo;

            window.open(url, '_blank');
            return;

        }
        if(response.data.success == false ) {
            snackBarApp.value.open(response.data.message);
            return;
        }

        snackBarApp.value.open("Não foi possível fazer a impressão do orçamento de ordem de serviços, tente novamente.");
    }
}

</script>

<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>
    <v-row>
        <v-col>
            <h1>Orçamentos de Ordens de Serviços</h1>
        </v-col>
    </v-row>

    <v-row>
        <v-col>
            <v-btn v-show="temPermissao('03.05.01')" color="primary" :to="{ name: 'OrcamentosOrdensServicos.Create' }">Novo Orçamento de Ordem de Serviços</v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="4">
            <v-card elevation="16" density="compact">
                <v-card-title>Filtrar Por</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-select density="compact" label="Filtrar Por" v-model="filtrarPor" :items="itemsFiltrarPor"
                                item-title="label" item-value="value"></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="8">
            <v-card elevation="16" density="compact">
                <v-card-title color="primary">Mostrar</v-card-title>
                <v-card-text density="compact">
                    <v-row>
                        <v-col cols="4">
                            <v-checkbox density="compact" v-model="abertos" label="Abertos" @change="filtrar()"></v-checkbox>
                        </v-col>
                        <v-col cols="4">
                            <v-checkbox density="compact" v-model="cancelados" label="Cancelados" @change="filtrar()"></v-checkbox>
                        </v-col>
                        <v-col cols="4">
                            <v-checkbox density="compact" v-model="transformados" label="Transformados em O.S." @change="filtrar()"></v-checkbox>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-card elevation="16">
                <v-card-title>Filtro</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="2">
                            <Data compact label="Data Inicial" type="text" v-model="dataInicial"></Data>
                        </v-col>
                        <v-col cols="2">
                            <Data label="Data Final" type="text" v-model="dataFinal"></Data>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field id="codigoCliente" label="Cód.Cliente" type="text" v-model="codigoCliente" @blur="getCliente()"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Nome do Cliente" type="text" v-model="nomeCliente" readonly tabindex="-1">
                                <template v-slot:append>
                                    <v-tooltip text="Faz a busca de um cliente por nome apelido/CPF.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn v-bind="props" color="primary" @click="dialogCliente = true">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                    <v-dialog v-model="dialogCliente" transition="dialog-top-transition">
                                        <ClienteBusca @selecionouCliente="selecionouCliente" @close-dialog="dialogCliente = false" />
                                    </v-dialog>

                                    &nbsp;&nbsp;
                                    <v-btn color="primary" @click.prevent="filtrar('geral')" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-card elevation="16">
                <v-card-title>Busca Rápida</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="4">
                            <v-text-field label="Código" type="text" v-model="codigo">
                                <template v-slot:append>
                                    <v-btn color="primary" @click.prevent="filtrar('codigo')" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-select label="" v-model="campo" :items="labelCampos"
                                item-title="label" item-value="value">
                            </v-select>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field label="" type="text" v-model="buscaCampo">
                                <template v-slot:append>
                                    <v-btn color="primary" @click.prevent="filtrar('campo')" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <p></p>
    <v-card elevation="16" class="mt-4">
        <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-center">Ações</th>
                    <th>Situação</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigo')">
                            Código
                            <v-icon v-if="orderBy == 'codigo'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigoCliente')">
                            Cód.Cliente
                            <v-icon v-if="orderBy == 'codigoCliente'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('nomeCliente')">
                            Nome do Cliente
                            <v-icon v-if="orderBy == 'nomeCliente'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('data')">
                            D.Emissão
                            <v-icon v-if="orderBy == 'data'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('validade')">
                            Prazo Entrega
                            <v-icon v-if="orderBy == 'validade'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>Valor Total</th>
                    <th>{{ labelCampo1 }}</th>
                    <th>{{ labelCampo2 }}</th>
                    <th>{{ labelCampo3 }}</th>
                    <th>{{ labelCampo4 }}</th>
                    <th>{{ labelCampo5 }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="o in orcamentosOrdensServicos" :key="o">
                    <td>
                        <v-tooltip text="Continua o orçamento de ordem de serviços.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-invoice-edit-outline"
                                    v-show="temPermissao('03.05.02') && o.situacao !== 'T' && o.situacao !== 'C'"
                                    :to="{ name: 'OrcamentosOrdensServicos.Continuar', params: { id: o.idOrcamentoOrdemServicos } }">
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Cancela o orçamento de ordem de serviços.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-cancel"
                                    v-show="temPermissao('03.05.04') && (o.situacao == 'T' || o.situacao == 'A' )"
                                    @click.prevent="openModalCancelar(o)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Exclui o orçamento de ordem de serviços.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-trash-can"
                                    v-show="temPermissao('03.05.05')"
                                    @click.prevent="openModalDelete(o)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Consulta o orçamento de ordem de serviços.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-tab-search"
                                    v-show="temPermissao('03.13.03')"
                                    :to="{ name: 'OrcamentosOrdensServicos.Show', params: { id: o.idOrcamentoOrdemServicos } }"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Imprime o orçamento de ordem de serviços.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-printer-outline"
                                    v-show="temPermissao('03.05.07') && o.situacao !== 'C'"
                                    @click.prevent="imprimir(o.idOrcamentoOrdemServicos)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                    </td>
                    <td>{{ o.situacaoExtenso }}</td>
                    <td>{{ o.codigo }}</td>
                    <td>{{ o.codigoCliente }}</td>
                    <td>{{ o.nomeCliente }}</td>
                    <td>{{ o.data }}</td>
                    <td>{{ o.validade }}</td>
                    <td>{{ valorFormat(o.valorTotal) }}</td>
                    <td>{{ o.campo1 }}</td>
                    <td>{{ o.campo2 }}</td>
                    <td>{{ o.campo3 }}</td>
                    <td>{{ o.campo4 }}</td>
                    <td>{{ o.campo5 }}</td>
                </tr>
            </tbody>
        </v-table>
    </v-card>

    <div class="block text-right">
        <v-pagination @update:modelValue="handleCurrentChange" v-model="currentPage" :length="Math.ceil(total / pageSize)" :total-visible="7" :show-first-last-page="true">
        </v-pagination>
    </div>
    <p></p>
    <v-row>
        <v-col>
            <v-btn v-show="temPermissao('03.05.01')" color="primary" :to="{ name: 'OrcamentosOrdensServicos.Create' }">Novo Orçamento de Ordem de Serviços</v-btn>
        </v-col>
    </v-row>
</template>

