<template>
    <!-- Caso tenha problema é só tirar essa função do focus -->
    <v-text-field
        @input="updatevalue"
        @blur="formatValue"
         @focus="converte"   
        reverse
        v-bind="$attrs"
    />
    </template>


    <script setup>


        import {defineEmits} from 'vue';

        const emit = defineEmits(['update:modelValue']);

        const updatevalue = (value) => {
            emit('update:modelValue', value.target.value);
        }

        // onMounted(() => {
        //     formatValue();
        // });

        const formatValue = (value) => {
            let result = '';
            result  = value.target.value;

            if(result  == 0) {
                return;
            }

            while (result.length < 15) {
                result = ' '+result;
            }

            // this.$refs.input.value = result;
            // this.$emit('input',result);
            //this.$emit('onBlur');

            value.target.value = result;
            emit('update:modelValue', result);
        }
         function converte(event) {
        //     setTimeout(function () {
                 event.target.select()
        //     }, 3)
        }
    </script>

<!-- <template>

    <input ref="input"
           class="text-right"
           v-bind:value={value}
           v-on:input="updateValue($event.target.value)"
           v-on:focus="converte"
           @blur="formatValue"

           >
</template> -->

<!-- <script>
    export default {
        props: {
            value: {
                type:String,
                default: '',
            },
        },
        mounted: function () {
            this.formatValue()
        },
        methods: {
            updateValue: function (value) {
                this.$emit('input',value)
            },
            formatValue: function () {


                let result = '';

                result  = this.value;

                if(result  == 0) {
                    return;
                }

                while (result.length < 15) {
                    result = ' '+result;
                }

                this.$refs.input.value = result;
                this.$emit('input',result);
                this.$emit('onBlur');

            },
            converte: function(event) {
                setTimeout(function () {
                    event.target.select()
                }, 0)

            }
        },
    }

</script>
 -->
