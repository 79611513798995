<script setup>
import { ref, computed, onMounted } from "vue";
import { Conta } from "../../resources/contaResource";
import config from "../../services/config";
import { PermissaoService } from "../../services/permissaoService";
import MsgConfirm from "../Utils/MsgConfirm";
import SnackBar from "../Utils/SnackBar";
import { useConta } from "../../store/conta";
import { valorFormat } from "../../services/filtros";

let msgConfirm = ref(null);
let snackBar = ref(null);

const contaStore = useConta();
let conta = ref({});
let contas = ref({});
const total = ref(0);
const pageSize = ref(config.maxPerPage);
const currentPage = ref(contaStore.currentPage);
const filtro = ref(contaStore.filtro);
const filtrarPor = ref(contaStore.filtrarPor);
const itemsFiltrarPor = ref([
    { value: "codigo", label: "Código" },
    { value: "apelido", label: "Apelido" },
]);

const direction = computed(() => {
    return contaStore.sort[0].direction;
});

const orderBy = computed(() => {
    return contaStore.sort[0].property;
});

onMounted(() => {
    search();
});

async function destroy() {
    let dados = new FormData();
    dados.append("data", JSON.stringify(conta.value));

    try {
        const response = await Conta.excluir(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBar.value.open("Conta bancária excluída com sucesso.");
                search();
            }
            if (response.data.success == false) {
                snackBar.value.open(response.data.vinculos, 1000000);
            }
        }
    } catch (err) {
        console.log(err);
    }
}
function openModalDelete(co) {
    conta.value = co;
    msgConfirm.value.open("Confirma Exclusão ?", "Confirma a exclusão da conta bancária: " + conta.value.apelido + " ?", { color: "primary" }).then((confirm) => {
        if (confirm) {
            destroy();
        }
    });
}
async function search() {
    let start = contaStore.start;
    let limit = contaStore.limit;
    let sort = JSON.stringify(contaStore.sort);

    contaStore.setFiltro(filtro.value);
    contaStore.setFiltrarPor(filtrarPor.value);
    try {
        const response = await Conta.get(start, limit, filtro.value, sort, filtrarPor.value);
        contas.value = response.data.data;
        total.value = parseInt(response.data.total);
    } catch (err) {
        console.log(err);
    }
}
function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    contaStore.setCurrentPage(val);
    contaStore.setStart(start);
    currentPage.value = val;
    search();
}
function setSort(property) {
    let sort = contaStore.sort;
    let directionAtual = sort[0].direction;
    let direction = "";

    if (property !== sort[0].property) {
        direction = "ASC";
    } else {
        if (directionAtual == "ASC") {
            direction = "DESC";
        } else {
            direction = "ASC";
        }
    }

    let aSort = [
        {
            property: property,
            direction: direction,
        },
    ];
    contaStore.setSort(aSort);
    search();
}
function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}
</script>

<template>
    <SnackBar ref="snackBar"></SnackBar>

    <MsgConfirm ref="msgConfirm"></MsgConfirm>

    <v-row>
        <v-col>
            <h1>Contas Bancárias</h1>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="3">
            <v-btn v-show="temPermissao('01.08.01')" color="primary" :to="{ name: 'Conta.Create' }">Nova Conta Bancária</v-btn>
        </v-col>
        <v-col cols="9">
            <v-form>
                <v-row>
                    <v-col cols="4">
                        <v-select v-model="filtrarPor" :items="itemsFiltrarPor" item-title="label" item-value="value" label="Filtrar Por"></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field label="Filtro" type="text" v-model="filtro" prepend-inner-icon="mdi-magnify"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-btn color="primary" @click.prevent="search()" prepend-icon="mdi-magnify">Filtrar</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </v-row>

    <p></p>
    <v-card elevation="16">
        <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-center">Ações</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigo')">
                            Código
                            <v-icon v-if="orderBy == 'codigo'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('apelido')">
                            Apelido da Conta Bancária
                            <v-icon v-if="orderBy == 'apelido'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('nomeBanco')">
                            Banco
                            <v-icon v-if="orderBy == 'nomeBanco'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>Agência</th>
                    <th>Conta</th>
                    <th class="text-right">Saldo</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr v-for="o in contas" :key="o">
                    <td class="text-center">
                        <v-tooltip text="Altera a conta bancária.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-bind="props" variant="text" icon="mdi-pencil" v-show="temPermissao('01.08.02')" :to="{ name: 'Conta.Update', params: { id: o.idConta } }" size="small">
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Consulta a conta bancária.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-tab-search"
                                    v-show="temPermissao('01.08.04')"
                                    :to="{ name: 'Conta.Show', params: { id: o.idConta } }"
                                    size="small"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Exclui a conta bancária.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-show="temPermissao('01.08.03')" v-bind="props" variant="text" @click.prevent="openModalDelete(o)" icon="mdi-trash-can" size="small"> </v-btn>
                            </template>
                        </v-tooltip>
                    </td>

                    <td>{{ o.codigo }}</td>
                    <td>{{ o.apelido }}</td>
                    <td>{{ o.nomeBanco }}</td>
                    <td>{{ o.agencia }}</td>
                    <td>{{ o.conta }}</td>
                    <td class="text-right">{{ valorFormat(o.saldo) }}</td>
                </tr>
            </tbody>
        </v-table>
    </v-card>

    <div class="block text-right">
        <v-pagination @update:modelValue="handleCurrentChange" v-model="currentPage" :length="Math.ceil(total / pageSize)" :total-visible="7" :show-first-last-page="true">
        </v-pagination>
    </div>
    <p></p>
    <v-btn Primary v-show="temPermissao('01.08.01')" color="primary" :to="{ name: 'Conta.Create' }">Nova Conta Bancária</v-btn>
</template>
