<script setup>
import { ref, onMounted, defineEmits, defineProps, inject } from 'vue';
import { EntradaEstoque } from "../../resources/entradaEstoqueResource";
import { dateFormat, quantidadeProdutoFormat, valorFormat, pCustoProdutoFormat } from "../../services/filtros";

let lancamentos = ref({});
const emit = defineEmits(['close-dialog']);
const snackBarApp = inject("snackBarApp");

onMounted(() => {
    console.log('Id do produto: '+ props.idProduto);
    getUltimasEntradas();
});

const props = defineProps({
    idProduto: {
        type: Number,

    },
    codigoProduto: {
        type: String,
    },
    descricaoProduto: {
        type: String,
    },
});

async function getUltimasEntradas() {
    let dados = new FormData();
    dados.append('idProduto', props.idProduto);
    dados.append('codigoModulo', '03.13.02');

    if (props.idProduto == null || props.idProduto == '') {
        snackBarApp.value.open("Nenhum produto selecionado, preencha o campo do código do produto e tente novamente.");
        emit('close-dialog');
    }

    let response = await EntradaEstoque.ultimasEntradas(dados);
    if (response.status == 200) {
        if (lancamentos.value == null) {
            snackBarApp.value.open("Nenhuma entrada encontrada para o produto selecionado.");
            emit('close-dialog');
            return;
        }

        lancamentos.value = response.data.data;

    }
}


function closeModalUltimasEntradas() {
    emit('close-dialog');
}
</script>

<template>
    <v-card>
        <v-card-title>
            <h3>Últimas Entradas do Produto: {{ codigoProduto }} / {{ descricaoProduto }}</h3>
        </v-card-title>
        <v-card-text>
            <v-card elevation="16" class="mb-5">
                <v-card-text>
                    <v-table density="compact">
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Cód.Fornecedor</th>
                                <th>Razão Social/Nome do Fornecedor</th>
                                <th>Quantidade</th>
                                <th>Valor Unitário</th>
                                <th>Valor Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(o) in lancamentos" :key="o">
                                <td>{{ dateFormat(o.data) }}</td>
                                <td>{{ o.codigoFornecedor }}</td>
                                <td>{{ o.razaoFornecedor }}</td>
                                <td>{{ quantidadeProdutoFormat(o.quantidade) }}</td>
                                <td>{{ pCustoProdutoFormat(o.valorUnitario) }}</td>
                                <td>{{ valorFormat(o.valor) }}</td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card-text>
            </v-card>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="elevated" @click.prevent='closeModalUltimasEntradas'>Fechar</v-btn>
        </v-card-actions>
    </v-card>
</template>
