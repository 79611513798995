import config from '../services/config';
import { defineStore } from 'pinia';

export const useComissao = defineStore('comissao', {
    state: () => {
        return {
            start:0,
            limit: config.maxPerPage,
            currentPage: 1,
            abertas: true,
            pendentes: true,
            canceladas: true,
            pagas:true,
            dataInicial: new Date().toLocaleDateString("pt-BR"), 
            dataFinal: new Date().toLocaleDateString("pt-BR"), 
            idVendedor:null,
            sort: [{
                'property':'data',
                'direction':'ASC'
            }],
            filtrarPor:'data',
            codigoVendedor:'',
            nomeVendedor:'',
        }
    },
    actions : {
        setStart(start) {
            this.start = start;
        },
        setCurrentPage(currentPage) {
            this.currentPage = currentPage;
        },
        setAbertas(abertas) {
            this.abertas = abertas;
        },
        setPendentes(pendentes) {
            this.pendentes = pendentes;
        },
        setCanceladas(canceladas) {
            this.canceladas = canceladas;
        },
        setPagas(pagas) {
            this.pagas = pagas;
        },
        setDataInicial(dataInicial) {
            this.dataInicial = dataInicial;
        },
        setDataFinal(dataFinal) {
            this.dataFinal = dataFinal;
        },
        setIdVendedor(idVendedor) {
            this.idVendedor = idVendedor;
        },
        setSort(sort) {
            this.sort = sort;
        },        
        setFiltrarPor(filtrarPor) {
            this.filtrarPor = filtrarPor;
        },
        setCodigoVendedor(codigoVendedor) {
            this.codigoVendedor = codigoVendedor;
        },
        setNomeVendedor(nomeVendedor) {
            this.nomeVendedor = nomeVendedor;
        },
    }
});