// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import $ from 'jquery';
import { createPinia } from 'pinia';
import { createApp } from 'vue'

import App from './App.vue'
const pinia = createPinia()
const app = createApp(App)
app.use(pinia);
import router from './router'
import store from './store/store';
import axios from 'axios'
import VueAxios from 'vue-axios'
import '@mdi/font/css/materialdesignicons.css'

import {ElNotification, ElMessageBox} from 'element-plus';  // , Menu, MenuItem, Submenu, MenuItemGroup, Container, Aside
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import ptBr from 'element-plus/dist/locale/pt-br.mjs'



import moment from 'moment';
moment.locale('pt-br');

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}


app.config.globalProperties.$=$;
app.config.globalProperties.msg = 'hello'

app.config.globalProperties.$moment=moment;
app.config.globalProperties.$store=store;


app.use(ElementPlus, {
  locale: ptBr,
})
// app.use(Modal);

//App.use(store);


import {pCustoProdutoFormat, 
        valorFormat, quantidadeFormat, simNaoFormat, ativoInativoBloqueadoFormat, valorFormatDuasCasas, quantidadeProdutoFormat, margemFormat, 
        quantidadeServicoFormat, debitoCreditoFormat, dateFormat, porcentagemFormat, localCreditoCartaoFormat
    } from './services/filtros';

app.config.globalProperties.$filters = {
    pCustoProdutosFormat: pCustoProdutoFormat,
    valorFormat: valorFormat,
    quantidadeFormat: quantidadeFormat, 
    simNaoFormat: simNaoFormat, 
    ativoInativoBloqueadoFormat: ativoInativoBloqueadoFormat,
    valorFormatDuasCasas:valorFormatDuasCasas, 
    quantidadeProdutoFormat:quantidadeProdutoFormat, 
    margemFormat: margemFormat, 
    quantidadeServicoFormat: quantidadeServicoFormat, 
    debitoCreditoFormat: debitoCreditoFormat, 
    dateFormat: dateFormat, 
    porcentagemFormat: porcentagemFormat, 
    localCreditoCartaoFormat: localCreditoCartaoFormat
};

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})
  
app.config.globalProperties.$notify = ElNotification;
app.config.globalProperties.$confirm = ElMessageBox.confirm;


app.config.globalProperties.$permissoesNegadas = [];

require('./services/validators');

app.use(VueAxios, axios);
app.use(vuetify);
app.use(store).use(router).mount('#app');