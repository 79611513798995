import { usePermissoes } from '../store/permissoes';
import { useLogado } from '@/store/logado';
import Http from './http';

export class Login {

    static efetuarLogin = function(data) {
        return Http.post('/Serv/Login/Login.php?operacao=efetuarlogin', data);
    };

    static efetuarLogout = function() {
        return Http.post('/Serv/Login/Login.php?operacao=efetuarlogout');
    };

    static status = function() {
        let permissoesStore = usePermissoes();

        return Http.get('/Serv/Login/Login.php?operacao=status', {
            params: {
                'operacao': 'status',
            }
        }).then(function (response) {
            if (response.status == 200) {
                const logadoStore = useLogado();
                logadoStore.setLogado(true);
                permissoesStore.setTipo(response.data.tipo);
                permissoesStore.setValidade(response.data.validade);
                permissoesStore.setDataValidade(response.data.dataValidade);
            }
        })
            .catch(function () {
            });
    }
    
}
