<script setup>
import { ref, onMounted, inject, defineEmits } from "vue";
import { OrdemServicos } from "../../resources/ordemServicosResource";
import { Produto } from "../../resources/produtoResource";
import { useField, useForm } from "vee-validate";
import { string, addMethod, object } from "yup";
import { usePermissoes } from "../../store/permissoes";
import ProdutoCreate from "../Produtos/ProdutoCreate.vue";
import ProdutoBusca from "../Produtos/ProdutoBusca.vue";
import { useCasasDecimais } from "../../store/casasDecimais";
import Moeda from "../Utils/moeda.vue";
import CodigoProduto from "../Utils/codigoProduto.vue";
import Quantidade from "../Utils/quantidade.vue";
import { Converte } from "../../utils/converte";
import { useRoute } from "vue-router";


const dialogProduto = ref(false);
const dialogBuscaProduto = ref(false);
let showBtnGravar = ref(true);
const casasDecimaisStore = useCasasDecimais();
const route = useRoute();
const emit = defineEmits(['add-produto', 'close-dialog']);

const snackBarApp = inject("snackBarApp");
const permissoesStore = usePermissoes();

addMethod(string, "quantidade_maior_zero", function quantidadeMaiorZero(message) {
    return string().test(
        "quantidade-maior_zero", // Name
        message, // Msg
        function (quantidade) {
            if (Converte.stringToFloat(quantidade) > 0) {
                return true;
            }
            return false;
        }
    );
});


const validationSchema = object({
    produtoOrdemServicos: object({
        idOrdemServicos: undefined,
        idProduto: undefined,
        codigoProduto: string().required("O código do produto é obrigatório").label("O código do produto"),
        codigoBarras: undefined,
        descricaoProduto: undefined,
        unidadeProduto: undefined,
        quantidadeAtual: undefined,
        quantidade: string().quantidade_maior_zero("A quantidade deve ser maior que 0."),
        valorUnitario: string().required("O valor unitário é obrigatório").label("O valor unitário"),
        valorTotal: string().required("O valor total é obrigatório").label("O valor total"),
    }).required(),
});
const { handleSubmit, errors } = useForm({ validationSchema });

const produtoOrdemServicos = ref({
    idOrdemServicos: useField("produtoOrdemServicos.idOrdemServicos").value,
    idProduto: useField("produtoOrdemServicos.idProduto").value,
    codigoProduto: useField("produtoOrdemServicos.codigoProduto").value,
    codigoBarras: useField("produtoOrdemServicos.codigoBarras").value,
    descricaoProduto: useField("produtoOrdemServicos.descricaoProduto").value,
    unidadeProduto: useField("produtoOrdemServicos.unidadeProduto").value,
    quantidadeAtual: useField("produtoOrdemServicos.quantidadeAtual").value,
    quantidade: useField("produtoOrdemServicos.quantidade").value,
    valorUnitario: useField("produtoOrdemServicos.valorUnitario").value,
    valorTotal: useField("produtoOrdemServicos.valorTotal").value,
});

const submit = handleSubmit((values, actions) => {
    addProduto(values, actions);
});

onMounted(() => {

    cleanFormProduto();

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});


function cleanFormProduto() {
    produtoOrdemServicos.value.idOrdemServicos = null;
    produtoOrdemServicos.value.idProduto = null;
    produtoOrdemServicos.value.codigoProduto = "";
    produtoOrdemServicos.value.codigoBarras = "";
    produtoOrdemServicos.value.descricaoProduto = "";
    produtoOrdemServicos.value.unidadeProduto = "";
    produtoOrdemServicos.value.quantidadeAtual = casasDecimaisStore.quantidadeProdutoZero;
    produtoOrdemServicos.value.quantidade = casasDecimaisStore.quantidadeProdutoUm;
    produtoOrdemServicos.value.valorUnitario = 'R$ 0,00';
    produtoOrdemServicos.value.valorTotal = 'R$ 0,00';
}


async function addProduto(values) {
    let idOrdemServicos = route.params.id;

    values.produtoOrdemServicos.idOrdemServicos = idOrdemServicos;

    if (values.produtoOrdemServicos.idProduto == null) {
        snackBarApp.value.open("Selecione um produto para incluir na ordem de serviços.", 6000);
        return;
    }

    let dados = new FormData();
    dados.append("dados", JSON.stringify(values.produtoOrdemServicos));

    try {
        const response = await OrdemServicos.addProduto(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("O produto não pode ser incluído na ordem de serviços, mensagem: " + response.data.message, 6000);
                return;
            }

            snackBarApp.value.open("Produto incluído com sucesso na ordem de serviços.", 1500);

            values.produtoOrdemServicos.idProdutoOrdemServicos = response.data.idProdutoOrdemServicos;

            values.produtoOrdemServicos.valorTotal = Converte.stringToFloat(values.produtoOrdemServicos.valorTotal).toString();
            values.produtoOrdemServicos.quantidade = Converte.stringToFloat(values.produtoOrdemServicos.quantidade).toString();
            emit('add-produto', values.produtoOrdemServicos);

            cleanFormProduto();

            document.getElementById("codigoProduto").focus();
        }
    } catch (err) {
        console.log(err);
    }
}


async function getProduto(campo) {

    let valor = "";

    if (campo == 'codigoBarras') {
        valor = produtoOrdemServicos.value.codigoBarras;
        if (valor == "") {
            return;
        }
    } else {
        valor = produtoOrdemServicos.value.codigoProduto;
    }

    if (valor.trim() == '') {

        produtoOrdemServicos.value.codigoBarras = '';
        produtoOrdemServicos.value.descricaoProduto = '';
        produtoOrdemServicos.value.unidadeProduto = '';
        produtoOrdemServicos.value.quantidadeAtual = casasDecimaisStore.quantidadeProdutoZero;
        if (campo == 'codigo') {
            produtoOrdemServicos.value.idProduto = null;
        }
        calculaTotal();
        return;
    }

    if (campo == 'codigo') {
        valor = Converte.codigoProduto(valor);
    }

    produtoOrdemServicos.value.idProduto = null;
    produtoOrdemServicos.value.descricaoProduto = "";
    produtoOrdemServicos.value.unidadeProduto = "";
    if (campo == "codigo") {
        produtoOrdemServicos.value.codigoBarras = "";
    }
    else {
        produtoOrdemServicos.value.codigoProduto = "";
    }

    produtoOrdemServicos.value.quantidadeAtual = casasDecimaisStore.produtoQuantidadeZero;

    let dados = new FormData();
    dados.append('codigoCodigoBarras', valor);
    dados.append('campo', campo);
    dados.append("codigoModulo", "03.03");

    try {
        let response = await Produto.getByCodigoCodigoBarras(dados);
        if (response.status == 200) {
            if (!response.data.data) {
                if (campo == 'codigo') {
                    document.getElementById("codigoProduto").focus();
                }
                if (campo == 'codigoBarras') {
                    document.getElementById("codigoBarras").focus();
                }
                snackBarApp.value.open("Produto não encontrado, tente outro código ou faça uma busca.", 6000);
                calculaTotal();
                return;
            }

            if (response.data.data.ativoInativoBloqueado == 'I') {
                snackBarApp.value.open("Produto inativo. O produto '" + (response.data.data.codigo).trim() + "/" + response.data.data.descricao +
                    "' está inativo, um produto inativo não pode ser incluído em ordens de serviços.", 6000);
                produtoOrdemServicos.value.codigoProduto = '';
                document.getElementById("codigoProduto").focus();
                calculaTotal();
                return;
            }
            if (response.data.data.ativoInativoBloqueado == 'B') {

                snackBarApp.value.open("Produto bloqueado. O produto '" + (response.data.data.codigo).trim() + "/" + response.data.data.descricao +
                    "' está bloqueado, um produto bloqueado não pode ser incluído em ordens de serviços.", 6000);
                produtoOrdemServicos.value.codigoProduto = '';
                document.getElementById("codigoProduto").focus();
                calculaTotal();
                return;
            }

            produtoOrdemServicos.value.idProduto = response.data.data.idProduto;
            produtoOrdemServicos.value.codigoProduto = response.data.data.codigo;
            produtoOrdemServicos.value.descricaoProduto = response.data.data.descricao;
            produtoOrdemServicos.value.codigoBarras = response.data.data.codigoBarras;

            produtoOrdemServicos.value.unidadeProduto = response.data.data.unidade;
            produtoOrdemServicos.value.quantidadeAtual = Converte.numberToQuantidadeProduto(response.data.data.quantidade);
            produtoOrdemServicos.value.valorUnitario = Converte.numberToPCustoProduto(response.data.data.pCusto);
            calculaTotal();
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouProduto(produto) {
    if (produto == null) {
        return;
    }

    produtoOrdemServicos.value.codigoProduto = produto.codigo;
    getProduto('codigo');

    document.getElementById("quantidade").focus();

    dialogBuscaProduto.value = false;
}


function calculaTotal() {
    let quantidade = Converte.stringToFloat(produtoOrdemServicos.value.quantidade);
    let valorUnitario = Converte.stringToFloat(produtoOrdemServicos.value.valorUnitario);
    let total = quantidade * valorUnitario;
    produtoOrdemServicos.value.valorTotal = Converte.numberToCurrency(total);
}

function cadastrouProduto(codigoProduto) {
    if (codigoProduto == null) {
        return;
    }
    produtoOrdemServicos.value.codigoProduto = codigoProduto;
    getProduto('codigo');
}


</script>

<template>
    <v-card class="mb-8" elevation="16">
        <v-card-title>
            <h3>Inclusão de Produtos na Ordem de Serviços</h3>
        </v-card-title>
        <form ref="formProdutoOrdemServicos" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="2">
                        <CodigoProduto label="Código do Produto" id="codigoProduto" type="text"
                            v-model="produtoOrdemServicos.codigoProduto"
                            :error-messages="errors['produtoOrdemServicos.codigoProduto']" maxlength="15"
                            @blur="getProduto('codigo')" />
                    </v-col>
                    <v-col cols="2">
                        <v-text-field label="Código de Barras" id="codigoBarras" type="text"
                            v-model="produtoOrdemServicos.codigoBarras"
                            :error-messages="errors['produtoOrdemServicos.codigoBarras']" maxlength="14"
                            @blur="getProduto('codigoBarras')" />
                    </v-col>
                    <v-col cols="8">
                        <v-text-field label="Descrição do Produto" id="descricaoProduto" type="text"
                            v-model="produtoOrdemServicos.descricaoProduto" readonly tabindex="-1">
                            <template v-slot:append>
                                <v-tooltip text="Faz a busca de um produto pela descrição, referência.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogBuscaProduto = true"
                                            id="btnBuscaProduto">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogBuscaProduto" transition="dialog-top-transition">
                                    <ProdutoBusca @selecionouProduto="selecionouProduto"
                                        @close-dialog="dialogBuscaProduto = false;" />
                                </v-dialog>
                                &nbsp;
                                <v-tooltip text="Cadastra um novo produto.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogProduto = true"
                                            id="btnNovoProduto">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogProduto" transition="dialog-top-transition">
                                    <ProdutoCreate @cadastrouProduto="cadastrouProduto"
                                        @close-dialog="dialogProduto = false" />
                                </v-dialog>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-text-field label="Unid." id="unidade" type="text"
                            v-model="produtoOrdemServicos.unidadeProduto" readonly tabindex="-1">
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Quantidade Atual" id="quantidadeAtual" type="text"
                            v-model="produtoOrdemServicos.quantidadeAtual" tipo="quantidadeProduto" readonly
                            tabindex="-1">
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <Quantidade label="Quantidade" id="quantidade" type="text"
                            v-model="produtoOrdemServicos.quantidade" tipo="quantidadeProduto"
                            :error-messages="errors['produtoOrdemServicos.quantidade']" maxlength="15"
                            @blur="calculaTotal()" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor Unitário" id="valorUnitario" type="text"
                            v-model="produtoOrdemServicos.valorUnitario"
                            :error-messages="errors['produtoOrdemServicos.valorUnitario']" maxlength="15"
                            @blur="calculaTotal()" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <Moeda label="Valor Total" id="valorTotal" type="text" v-model="produtoOrdemServicos.valorTotal"
                            :error-messages="errors['produtoOrdemServicos.valorTotal']" maxlength="15" readonly
                            tabindex="-1" />
                    </v-col>
                </v-row>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" v-show="showBtnGravar" variant="elevated" type="submit"
                    id="btnAddProdutoGravar">Incluir
                    Produto</v-btn>
                <v-btn color="primary" variant="elevated" @click.prevent='emit("close-dialog")'>Fechar</v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>
