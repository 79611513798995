import Http from './http';

export class SubgrupoServicos {

    static get = function(start, limit, filtro, sort) {
        return Http.get('/Serv/SubgruposServicos/SubgruposServicos.php', {
            params: {
                'operacao': 'listar',
                'start':start,
                'limit':limit,
                'filtro':filtro,
                'sort':sort,
            }
        });
    };

    static save = function(dados) {
        return Http.post('/Serv/SubgruposServicos/SubgruposServicos.php?operacao=gravar', dados);
    };

    static getById = function(dados) {
        return Http.post('/Serv/SubgruposServicos/SubgruposServicos.php?operacao=getbyid', dados);
    };

    static excluir = function(dados) {
        return Http.post('/Serv/SubgruposServicos/SubgruposServicos.php?operacao=excluir', dados);
    };

    static getSubgrupos = function(dados) {
        return Http.post('/Serv/SubgruposServicos/SubgruposServicos.php?operacao=getsubgrupos', dados);
    };

}
