<template>
    <v-text-field
        @input="updatevalue"
        @blur="formatvalue"
        @focus="converte"
    />
</template>

<script setup>
    import {ref, defineEmits, defineProps, onMounted} from 'vue';
    import { useCasasDecimais } from '../../store/casasDecimais';

    const props = defineProps({
        tipo: {
            type: String,
            default: '',
        },
    });

    let casasDecimais = ref(3);
    const emit = defineEmits(['update:modelValue']);
    const casasDecimaisStore = useCasasDecimais();

    const updatevalue = (value) => {
        value.target.value = value.target.value.replace('.','');
        emit('update:modelValue', value.target.value);
    }

    onMounted(() => {
        if(props.tipo == 'quantidadeProduto') {
            casasDecimais.value = casasDecimaisStore.quantidadeProduto;
        }
        if(props.tipo == 'quantidadeServico') {
            casasDecimais.value = casasDecimaisStore.quantidadeServico;
        }


     //   this.formatValue()

    });

    const formatvalue = (value) => {
        let number = '';
        let result = '';
        if(value.target.value  && typeof value.target.value !== 'undefined') {
            var numberRegex = value.target.value.match(/^[0-9]+,?[0-9]*$/);
            number = numberRegex ? numberRegex[0] : numberRegex;
            if(number !== null) {
                number = number.replace(',','.');
            }

        }

        result = new Intl.NumberFormat('pt-BR', {
            maximumFractionDigits:casasDecimais.value,
            minimumFractionDigits:casasDecimais.value,
            style: 'decimal',
        }).format(number).replace(/^(\D+)/, '$1 ');

        value.target.value = result;
        emit('update:modelValue', result);  
    }

    function converte(event) {
        setTimeout(function () {
            let valor = '';
            if(event.target.value.length > 0) {
                valor =  event.target.value.replace(/[^\d,]/g,'');
            }
            event.target.value = valor;
            emit('update:modelValue', valor);  
            setTimeout(function () {
                event.target.select()
            }, 3)
        }, 3)
    }
</script>


<!-- <template>
    <input ref="input"
           class="text-right"
           v-bind:value={value}
           v-on:input="updateValue($event.target.value)"
           v-on:focus="converte"
           v-on:blur="formatValue">
</template> -->

<!-- <script>
    import store from '../../store/store';

    export default {
        props: {
            value: {
                type:String,
                default: '',
            },
            tipo: {
                type:String,
                default: '',
            },

        },
        data() {
            return {
                casasDecimais: 3,
            }
        },
        mounted: function () {

            if(this.tipo == 'quantidadeProduto') {
                this.casasDecimais = store.state.casasDecimais.quantidadeProduto;
            }
            if(this.tipo == 'quantidadeServico') {
                this.casasDecimais = store.state.casasDecimais.quantidadeServico;
            }

            this.formatValue()


        },
        methods: {
            updateValue: function (value) {



                value = value.replace('.','');
                this.$emit('input',value);
            },
            formatValue: function () {
                let number = '';
                let result = '';

                if(this.value  && typeof this.value !== undefined) {
                    var numberRegex = this.value.toString().match(/[-+]?\d+(\d{1,3}){0,1}/g);
                    number = numberRegex ? numberRegex[0] : numberRegex;
                    if(number !== null) {
                        number = number.replace(',','.');
                    }

                }

                result = new Intl.NumberFormat('pt-BR', {
                    maximumFractionDigits:this.casasDecimais,
                    minimumFractionDigits:this.casasDecimais,
                    style: 'decimal'
                }).format(number);


                this.$refs.input.value = result;
                this.$emit('input',result);
                this.$emit('onBlur');
            },
            selectAll: function (event) {
                // Workaround for Safari bug
                // http://stackoverflow.com/questions/1269722/selecting-text-on-focus-using-jquery-not-working-in-safari-and-chrome
                setTimeout(function () {
                    event.target.select()
                }, 0)
            },
            converte: function(event) {
                let self = this;
                setTimeout(function () {
                    let valor = '';
                    if (self.value.length > 0) {
                        valor = self.value.replace(/[^\d,]/g, '');
                    }
                    self.$refs.input.value = valor;
                    self.$emit('input', valor);
                    setTimeout(function () {
                        event.target.select()
                    }, 3)
                }, 3)


            }
        },
    }

</script>
 -->
