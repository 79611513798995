import Http from './http';

export class Servico {

    static get = function(start, limit, filtro, sort, filtrarPor) {
        return Http.get('/Serv/Servicos/Servicos.php', {
            params: {
                'operacao': 'listar',
                'start':start,
                'limit':limit,
                'filtro':filtro,
                'sort':sort,
                'filtrarPor': filtrarPor,
            }
        });
    };

    static save = function(dados) {
        return Http.post('/Serv/Servicos/Servicos.php?operacao=gravar', dados);
    };

    static getById = function(dados) {
        return Http.post('/Serv/Servicos/Servicos.php?operacao=getbyid', dados);
    };

    static excluir = function(dados) {
        return Http.post('/Serv/Servicos/Servicos.php?operacao=excluir', dados);
    };

    static proximoCodigo = function() {
        return Http.get('/Serv/Servicos/Servicos.php?operacao=proximocodigo');
    };

    static codigoExiste = function(codigo, idServico) {
        let dados = new FormData();
        dados.append('codigo', codigo);
        dados.append('idServico', idServico);
        return Http.post('/Serv/Servicos/Servicos.php?operacao=codigoexiste', dados);
    };

    static getByCodigo = function(dados) {
        return Http.post('/Serv/Servicos/Servicos.php?operacao=getbycodigo', dados);
    };

}
