import Http from './http';

export class Comissao {
    static listar = function(start, limit, sort, filtrarPor, dataInicial, dataFinal, abertas, canceladas, pendentes, pagas, idVendedor) {
        return Http.get('/Serv/Comissao/Comissao.php', {
            params: {
                'operacao': 'listar',
                'start':start,
                'limit':limit,
                'sort':sort,
                'filtrarPor': filtrarPor,
                'dataInicial': dataInicial,
                'dataFinal': dataFinal,
                'abertas': abertas,
                'canceladas': canceladas,
                'pendentes': pendentes,
                'pagas': pagas,
                'idVendedor': idVendedor,
            }
        });
    };

    static paga = function(dados) {
        return Http.post('/Serv/Comissao/Comissao.php?operacao=paga', dados);
    };

    static excluirCancelar = function(dados) {
        return Http.post('/Serv/Comissao/Comissao.php?operacao=excluircancelar', dados);
    };

    static pagarTodas = function(dados) {
        return Http.post('/Serv/Comissao/Comissao.php?operacao=pagartodas', dados);
    };

}
