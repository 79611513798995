import Http from './http';

export class ValidadeServico {

    static get = function() {
        return Http.get('/Serv/ValidadeServico/ValidadeServico.php', {
            params: {
                'operacao': 'retornar',
            }
        });
    };

}
