<script setup>
import { ref, onMounted, inject } from "vue";
import { LancamentoConta } from "@/resources/lancamentoContaResource";
import TransferenciaContas  from "@/components/LancamentosContas/TransferenciaContas.vue";
import TransferenciaCaixa from "./TransferenciaCaixa.vue";
import { Relatorio } from "@/resources/relatoriosResource";
import { Conta} from "@/resources/contaResource";
import config from "../../services/config";
import { PermissaoService } from "../../services/permissaoService";
import MsgConfirm from "../Utils/MsgConfirm";
import { useLancamentoConta } from "../../store/lancamentoConta";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";
import { dateFormat, valorFormat} from "../../services/filtros";

let msgConfirm = ref(null);
const snackBarApp = inject('snackBarApp');

const dialogTransferenciaContas = ref(false);
const dialogTransferenciaCaixa = ref(false);

const lancamentoContaStore = useLancamentoConta();
const apelidoConta = ref('');
let lancamentoConta = ref({});
let lancamentosConta = ref({});
const total = ref(0);
const pageSize = ref(config.maxPerPage);
const idConta = ref(lancamentoContaStore.idConta);
const detalhesConta = ref(null);
const currentPage = ref(lancamentoContaStore.currentPage);
const dataInicial = ref(lancamentoContaStore.dataInicial);
const dataFinal = ref(lancamentoContaStore.dataFinal);

const totalCreditos = ref('');
const totalDebitos = ref('');
const saldo = ref('');

const contas = ref([]);

onMounted(() => {
    getContas();
    filtrar();
});


async function getContas() {
    const response = await Conta.getContas();
    contas.value = response.data.data;

    if (contas.value.length > 0 && (idConta.value == null || idConta.value == 0)) {
        idConta.value = response.data.data[0].idConta;
    }

    changeConta();
}

async function destroy() {
    let dados = new FormData();
    dados.append("data", JSON.stringify(lancamentoConta.value));

    try {
        const response = await LancamentoConta.excluir(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open("Lançamento de conta excluído com sucesso.");
                filtrar();
            }
        }
    } catch (err) {
        console.log(err);
    }
}
function openModalDelete(lancamento) {
    lancamentoConta.value = lancamento;
    msgConfirm.value
        .open(
            "Confirma Exclusão ?",
            "Confirma a exclusão do lançamento de conta ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                destroy();
            }
        });
}

async function filtrar(mudouPagina = false) {
    let start = lancamentoContaStore.start;
    let limit = lancamentoContaStore.limit;
    
    lancamentoContaStore.setDataInicial(dataInicial.value);
    lancamentoContaStore.setDataFinal(dataFinal.value);

    let auxDataInicial = null;
    let auxDataFinal = null;

    if(dataInicial.value != '') {
        auxDataInicial = Converte.datePtBrToIso(dataInicial.value); 
    }

    if(dataFinal.value != '') {
        auxDataFinal = Converte.datePtBrToIso(dataFinal.value); 
    }

    if(mudouPagina == false) {    
        handleCurrentChange(1);
        return;
    }


    try {
        const response = await  LancamentoConta.get(start, limit, idConta.value, auxDataInicial, auxDataFinal);
        
        lancamentosConta.value = response.data.data;
        total.value = parseInt(response.data.total);

        totalCreditos.value = 'Total de Créditos de '+dataInicial.value+' a '+dataFinal.value+':'+Converte.numberToCurrency(response.data.totalCreditos);
        totalDebitos.value = 'Total de Débitos de '+dataInicial.value+' a '+dataFinal.value+':'+Converte.numberToCurrency(response.data.totalDebitos);
        saldo.value = 'Saldo de '+dataInicial.value+' a '+dataFinal.value+':'+Converte.numberToCurrency(response.data.totalSaldo);
    } catch (err) {
        console.log(err);
    }
}

function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    lancamentoContaStore.setCurrentPage(val);
    lancamentoContaStore.setStart(start);

    filtrar(true);
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}

async function changeConta() {
    lancamentoContaStore.setIdConta(idConta.value);
    let dados = new FormData();
    dados.append('codigoModulo', '03.09');
    dados.append('idConta', idConta.value);
    try {
        const response = await Conta.getById(dados);
        if (response.status == 200) {
            let conta = response.data.data;

            detalhesConta.value = 'Banco:'+conta.nomeBanco+' agência:'+conta.agencia+' nro.conta:'+conta.conta+' saldo atual:'+Converte.numberToCurrency(conta.saldo);
            apelidoConta.value = conta.apelido;
            filtrar();
        }
    }
    catch (err) {
        console.log(err);
    }
}

function abrirModalTransferenciaContas() {
    dialogTransferenciaContas.value = true;
}

function abrirModalTransferenciaCaixa() {
    dialogTransferenciaCaixa.value = true;
}

async function imprimir() {
    let nomeRel = 'Lancamentos Contas';

    let auxDataInicial = null;
    let auxDataFinal = null;

    if(dataInicial.value != '') {
        auxDataInicial = Converte.datePtBrToIso(dataInicial.value); 
    }

    if(dataFinal.value != '') {
        auxDataFinal = Converte.datePtBrToIso(dataFinal.value); 
    }

    let response = await Relatorio.get(nomeRel, idConta.value, auxDataInicial, auxDataFinal)
    if (response.status == 200) {
        if(response.data.success == true) {
            let nomeArquivo = response.data.nomeArquivo;
            let url = config.baseUrlRel+nomeArquivo;

            window.open(url, '_blank');
            return;

        }
        if(response.data.success == false ) {
            snackBarApp.value.open(response.data.message);
            return;
        }

        snackBarApp.value.open('Não foi possível fazer a impressão do relatório, tente novamente.');
    }
}

</script>

<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>
    <v-row>
        <v-col>
            <h1>Lançamentos de Contas Bancárias</h1>
        </v-col>
    </v-row>

    <v-row>
        <v-col>
            <v-btn v-show="temPermissao('03.09.01')" color="primary" :to="{ name: 'LancamentosContas.Create', params: { idConta: idConta }  }">Novo Lançamento de Conta</v-btn>&nbsp;&nbsp;
            <v-btn v-show="temPermissao('03.09.03')" color="primary" @click.prevent="abrirModalTransferenciaContas">Transferência entre Contas</v-btn>&nbsp;&nbsp;
            <v-btn v-show="temPermissao('03.09.04')" color="primary" @click.prevent="abrirModalTransferenciaCaixa">Transferência para o Caixa</v-btn>&nbsp;&nbsp;
            <v-btn v-show="temPermissao('03.09.05')" color="primary" @click.prevent="imprimir">Imprimir</v-btn>
        </v-col>
    </v-row>
    <v-row >
        <v-col cols="12">
            <v-form>
               <v-card elevation="16" class="fill-height">
                    <v-card-title>Informações de Conta e Filtro por Datas</v-card-title>
                    <v-card-text>
                        <v-dialog v-model="dialogTransferenciaContas" transition="dialog-top-transition">
                            <TransferenciaContas 
                                @transferiu="filtrar(); dialogTransferenciaContas = false; changeConta()" 
                                @close-dialog="dialogTransferenciaContas = false"
                                :idContaOrigem="idConta"
                                :apelidoContaOrigem= "apelidoConta" 
                                :detalhesContaOrigem="detalhesConta"/>
                        </v-dialog>

                        <v-dialog v-model="dialogTransferenciaCaixa" transition="dialog-top-transition">
                            <TransferenciaCaixa 
                                @transferiu="filtrar(); dialogTransferenciaCaixa = false; changeConta()" 
                                @close-dialog="dialogTransferenciaCaixa = false"
                                :idContaOrigem="idConta"
                                :apelidoContaOrigem= "apelidoConta" 
                                :detalhesContaOrigem="detalhesConta"/>
                        </v-dialog>

                        <v-row>
                            <v-col cols="3">
                                <v-select v-model="idConta"  :items="contas" item-title="apelido" item-value="idConta" @update:modelValue="changeConta()" label="Conta" ></v-select>
                            </v-col>    
                            <v-col cols="9">
                                <v-text-field label="Detalhes da Conta" v-model="detalhesConta" readonly tabindex=-1></v-text-field>
                            </v-col>    

                        </v-row>
                        <v-row>
                            <v-col md="12">
                                <v-row>
                                    <v-col cols="5">
                                        <Data label="Data Inicial" type="text" v-model="dataInicial"></Data>
                                    </v-col>
                                    <v-col cols="5">
                                        <Data label="Data Final" type="text" v-model="dataFinal"></Data>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-btn color="primary" @click.prevent="filtrar()" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                           
            </v-form>
        </v-col>
    </v-row>
    
    <v-card elevation="16" class="mt-3">
        <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-center">Ações</th>
                    <th>Data</th>
                    <th>Histórico</th>
                    <th class="text-right">Valor</th>
                    <th>Documento</th>
                    <th class="text-right">Saldo</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="o in lancamentosConta" :key="o">
                    <td>
                        <v-tooltip text="Exclui o lançamento de conta.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-show="temPermissao('03.09.02') && o.idLancamentoConta>0" size="small" v-bind="props" variant="text" @click.prevent="openModalDelete(o)" icon="mdi-trash-can">
                                </v-btn>
                            </template>
                        </v-tooltip>
                    </td>
                    <td>{{ dateFormat(o.data) }}</td>
                    <td>{{ o.historico }}</td>
                    <td class="text-right">{{ valorFormat(o.valor) }}</td>
                    <td>{{ o.documento }}</td>
                    <td class="text-right">{{ valorFormat(o.saldo) }}</td>
                </tr>
            </tbody>
        </v-table>
        <v-row>
            <v-col cols="6">
            </v-col>
            <v-col cols="6" class="text-right">
                <h4>{{ totalCreditos }}</h4>
                <h4>{{ totalDebitos }}</h4>
                <h4>{{ saldo }}</h4>            
            </v-col>
        </v-row>
    </v-card>

    <div class="block text-right">
        <v-pagination @update:modelValue="handleCurrentChange" v-model="currentPage" :length="Math.ceil(total / pageSize)" :total-visible="7" :show-first-last-page="true">
        </v-pagination>
    </div>
    <p></p>
    <v-btn v-show="temPermissao('03.09.01')" color="primary" :to="{ name: 'LancamentosContas.Create', params: { idConta: idConta }  }">Novo Lançamento de Conta</v-btn>&nbsp;&nbsp;
    <v-btn v-show="temPermissao('03.09.03')" color="primary" @click.prevent="abrirModalTransferenciaContas">Transferência entre Contas</v-btn>&nbsp;&nbsp;
    <v-btn v-show="temPermissao('03.09.04')" color="primary" @click.prevent="abrirModalTransferenciaCaixa">Transferência para o Caixa</v-btn>&nbsp;&nbsp;
    <v-btn v-show="temPermissao('03.09.05')" color="primary" @click.prevent="imprimir">Imprimir</v-btn>

</template>