<script setup>
import { ref, computed, onMounted, inject } from "vue";
import { Comissao } from "@/resources/comissaoResource";
import { Vendedor } from "@/resources/vendedorResource";
import config from "../../services/config";
import { PermissaoService } from "../../services/permissaoService";
import MsgConfirm from "../Utils/MsgConfirm";
import { useComissao } from "../../store/comissao";
import { valorFormat, porcentagemFormat } from "../../services/filtros";
import VendedorBusca from "../Clientes/ClienteBusca.vue";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";

let msgConfirm = ref(null);
let snackBarApp = inject("snackBarApp");

let dialogVendedor = ref(false);
let dialogPagamentoComissao = ref(false);
let dialogPagamentoTodasComissoes = ref(false);

const comissaoStore = useComissao();
let lancamentos = ref({});
const total = ref(0);
const pageSize = ref(config.maxPerPage);
const currentPage = ref(comissaoStore.currentPage);
const abertas = ref(comissaoStore.abertas);
const pendentes = ref(comissaoStore.pendentes);
const canceladas = ref(comissaoStore.canceladas);
const pagas = ref(comissaoStore.pagas);
const dataInicial = ref(comissaoStore.dataInicial);
const dataFinal = ref(comissaoStore.dataFinal);
const idVendedor = ref(comissaoStore.idVendedor);
const codigoVendedor = ref(comissaoStore.codigoVendedor);
const nomeVendedor = ref(comissaoStore.nomeVendedor);
const filtrarPor = ref(comissaoStore.filtrarPor);
const dataPagamento = ref(new Date().toLocaleDateString("pt-BR"));

const auxLancamento = ref(null);

const itemsFiltrarPor = ref([
    { value: 'data', label: 'Data de Emissão' },
    { value: 'dataPagamento', label: 'Data do Pagamento' },
]);

const direction = computed(() => {
    return comissaoStore.sort[0].direction;
});

const orderBy = computed(() => {
    return comissaoStore.sort[0].property;
});

onMounted(() => {
    filtrar();
});

async function destroy(idComissao) {
    let dados = new FormData();
    dados.append("idComissao", JSON.stringify(idComissao));
    dados.append('operacao','Excluir');
    try {
        const response = await Comissao.excluirCancelar(dados);
        if (response.status == 200) {
            if (response.data.success == true) {
                snackBarApp.value.open("Comissão excluída com sucesso.");
                filtrar();
            }
        }
    } catch (err) {
        console.log(err);
    }
}

function openModalDelete(lancamento) {
    
    msgConfirm.value
        .open(
            "Confirma Exclusão ?",
            "Confirma a exclusão dessa comissão ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                destroy(lancamento.idComissao);
            }
        });
}

function confirmaPagar() {
    if(dataPagamento.value == "" || dataPagamento.value == null) {
        snackBarApp.value.open("Informe a data do pagamento.");
        return;
    }

    msgConfirm.value
        .open(
            "Confirma Pagamento ?",
            "Confirma o pagamento dessa comissão ?",
            { 
                color: "primary",
                zIndex: 9999
                
            }
        )
        .then((confirm) => {
            if (confirm) {
                pagar(auxLancamento)
            }
        });
}

function confirmaPagarTodas() {
    if(dataPagamento.value == "" || dataPagamento.value == null) {
        snackBarApp.value.open("Informe a data do pagamento.");
        return;
    }

    msgConfirm.value
        .open(
            "Confirma Pagamento ?",
            "Confirma o pagamento de todas as comissões mostradas nessa tela ?",
            { 
                color: "primary",
                zIndex: 9999
            }
        )
        .then((confirm) => {
            if (confirm) {
                pagarTodas();
            }
        });
    
}

async function pagar(lancamento) {

    let dados = new FormData();
    lancamento.value.formaPagamento = 'CAI';
    lancamento.value.dataPagamento = Converte.datePtBrToIso(dataPagamento.value);
    
    dados.append('data', JSON.stringify(lancamento.value));

    try {
        const response = await Comissao.paga(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open("Comissão paga com sucesso.");
                filtrar();
                dialogPagamentoComissao.value = false;
            } else {
                snackBarApp.value.open("Não foi possível pagar a comissão.");
            }
        }
    } catch (err) {
        console.log(err);
    }
}

async function pagarTodas() {
    let dados = new FormData();
    dados.append('dados', JSON.stringify(lancamentos.value));
    dados.append("dataPagamento", Converte.datePtBrToIso(dataPagamento.value));

    try {
        const response = await Comissao.pagarTodas(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open("Comissão paga com sucesso.");
                filtrar();
            } else {
                snackBarApp.value.open("Não foi possível pagar a comissão. " + response.data.message);
            }
        }
    } catch (err) {
        console.log(err);
    }
}



async function filtrar(tipoFiltro, mudouPagina = false) {
    let start = comissaoStore.start;
    let limit = comissaoStore.limit;
    let sort = JSON.stringify(comissaoStore.sort);

    if(tipoFiltro == null || tipoFiltro == "" || tipoFiltro == undefined) {
        tipoFiltro = comissaoStore.tipoFiltro;
    }
    comissaoStore.setIdVendedor(idVendedor.value);
    comissaoStore.setCodigoVendedor(codigoVendedor.value);
    comissaoStore.setNomeVendedor(nomeVendedor.value);
    comissaoStore.setDataInicial(dataInicial.value);
    comissaoStore.setDataFinal(dataFinal.value);
    comissaoStore.setAbertas(abertas.value);
    comissaoStore.setPendentes(pendentes.value);
    comissaoStore.setCanceladas(canceladas.value);
    comissaoStore.setPagas(pagas.value);
    comissaoStore.setFiltrarPor(filtrarPor.value);
                
    let auxDataInicial = null;
    let auxDataFinal = null;
    if (dataInicial.value != "") {
        auxDataInicial = Converte.datePtBrToIso(dataInicial.value); 
    }

    if (dataFinal.value != "") {
        auxDataFinal = Converte.datePtBrToIso(dataFinal.value); 
    }

    if(mudouPagina == false) {
        handleCurrentChange(1);
        return;
    }

    try {
        const response = await Comissao.listar(start, limit, sort, filtrarPor.value, 
            auxDataInicial, auxDataFinal, abertas.value, canceladas.value, pendentes.value, pagas.value, idVendedor.value)
        lancamentos.value = response.data.data;
        total.value = parseInt(response.data.total);
    } catch (err) {
        console.log(err);
    }
}
function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    comissaoStore.setCurrentPage(val);
    comissaoStore.setStart(start);
    currentPage.value = val;
    filtrar(null, true);
}
function setSort(property) {
    let sort = comissaoStore.sort;
    let directionAtual = sort[0].direction;
    let direction = "";

    if (property !== sort[0].property) {
        direction = "ASC";
    } else {
        if (directionAtual == "ASC") {
            direction = "DESC";
        } else {
            direction = "ASC";
        }
    }

    let aSort = [ 
        {
            property: property,
            direction: direction,
        },
    ];

    comissaoStore.setSort(aSort);
    filtrar();
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}

function selecionouVendedor(vendedor) {
    if (vendedor == null) {
        return;
    }

    codigoVendedor.value = vendedor.codigo;
    getVendedor();
    dialogVendedor.value = false;
}

async function getVendedor() {
    idVendedor.value = null;
    nomeVendedor.value = "";

    if (codigoVendedor.value.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", codigoVendedor.value);
    dados.append("getInativosBloqueados", true);
    dados.append("codigoModulo", "03.08");

    try {
        let response = await Vendedor.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                document.getElementById("codigoVendedor").focus();
                snackBarApp.value.open("O vendedor não foi encontrado, tente outro código ou faça uma busca.");
                codigoVendedor.value = "";
                return;
            }
            idVendedor.value = response.data.data.idVendedor;
            codigoVendedor.value = response.data.data.codigo;
            nomeVendedor.value = response.data.data.nome;
        }
    } catch (err) {
        console.log("Erro ao buscar o vendedor: " + err);
    }
}

function openModalCancelar(lancamento) {
    msgConfirm.value
        .open(
            "Confirma Cancelamento ?",
                "Confirma o cancelamento dessa comissão ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                cancelar(lancamento.idComissao);
            }
        });
}

async function cancelar(idComissao) {
    let dados = new FormData();
    dados.append("idComissao", JSON.stringify(idComissao));
    dados.append('operacao','Cancelar');

    try {
        const response = await Comissao.excluirCancelar(dados);
        if (response.status == 200) {
            if (response.data.success == true) {
                snackBarApp.value.open("Comissão cancelada com sucesso.");
                filtrar();
            } else {
                snackBarApp.value.open("Não foi possível cancelar a comissão.");
            }
        }
    } catch (err) {
        console.log(err);
    }
}
</script>

<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>


    <v-row>
        <v-col>
            <h1>Comissões de Vendedores/Funcionários</h1>
        </v-col>
    </v-row>

    <v-row>
        <v-col>
            <v-btn v-show="temPermissao('03.08.02')" color="primary" @click.prevent="dialogPagamentoTodasComissoes = true">Pagar Todas as Comissões Listadas na Tela</v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="4">
            <v-card elevation="16" density="compact">
                <v-card-title>Filtrar Por</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-select density="compact" label="Filtrar Por" v-model="filtrarPor" :items="itemsFiltrarPor"
                                item-title="label" item-value="value"></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="8">
            <v-card elevation="16" density="compact">
                <v-card-title color="primary">Mostrar</v-card-title>
                <v-card-text density="compact">
                    <v-row>
                        <v-col cols="3">
                            <v-checkbox density="compact" v-model="abertas" label="Abertas" @change="filtrar()"></v-checkbox>
                        </v-col>
                        <v-col cols="3">
                            <v-checkbox density="compact" v-model="pendentes" label="Pendentes" @change="filtrar()"></v-checkbox>
                        </v-col>
                        <v-col cols="3">
                            <v-checkbox density="compact" v-model="canceladas" label="Canceladas" @change="filtrar()"></v-checkbox>
                        </v-col>
                        <v-col cols="3">
                            <v-checkbox density="compact" v-model="pagas" label="Pagas" @change="filtrar()"></v-checkbox>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-card elevation="16">
                <v-card-title>Filtro</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="2">
                            <Data compact label="Data Inicial" type="text" v-model="dataInicial"></Data>
                        </v-col>
                        <v-col cols="2">
                            <Data label="Data Final" type="text" v-model="dataFinal"></Data>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field id="codigoVendedor" label="Cód.Vend./Func." type="text" v-model="codigoVendedor" @blur="getVendedor()"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Nome do Vendedor/Funcionário" type="text" v-model="nomeVendedor" readonly tabindex="-1">
                                <template v-slot:append>
                                    <v-tooltip text="Faz a busca de um vendedor/funcionário por nome/cpf.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn v-bind="props" color="primary" @click="dialogVendedor = true">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                    <v-dialog v-model="dialogVendedor" transition="dialog-top-transition">
                                        <VendedorBusca @selecionouVendedor="selecionouVendedor" @close-dialog="dialogVendedor = false" />
                                    </v-dialog>

                                    &nbsp;&nbsp;
                                    <v-btn color="primary" @click.prevent="filtrar()" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <p></p>
    <v-card elevation="16" class="mt-4">
        <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-center">Ações</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigoVendedor')">
                            Cód.Vend./Func.
                            <v-icon v-if="orderBy == 'codigoVendedor'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('nomeVendedor')">
                            Nome do Vendedor/Funcionário
                            <v-icon v-if="orderBy == 'nomeVendedor'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('data')">
                            Data Emissão
                            <v-icon v-if="orderBy == 'data'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('dataPagamento')">
                            Data do Pagamento
                            <v-icon v-if="orderBy == 'dataPagamento'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>Comissão(R$)</th>
                    <th>Comissão(%)</th>
                    <th>Situação</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('documento')">
                            Documento
                            <v-icon v-if="orderBy == 'documento'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>Valor Total</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="o in lancamentos" :key="o">
                    <td>
                        <v-tooltip text="Faz o pagamento da comissão">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-cash-check"
                                    v-show="temPermissao('03.08.01') && o.situacao == 'A'"
                                    @click.prevent="auxLancamento = o; dialogPagamentoComissao = true;"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Cancela a comissão.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-cancel"
                                    v-show="temPermissao('03.08.03') && o.situacao !== 'C'"
                                    @click.prevent="openModalCancelar(o)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Exclui a comissão.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-trash-can"
                                    v-show="temPermissao('03.08.04')"
                                    @click.prevent="openModalDelete(o)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                    </td>
                    <td>{{ o.codigoVendedor }}</td>
                    <td>{{ o.nomeVendedor }}</td>
                    <td>{{ o.data }}</td>
                    <td>{{ o.dataPagamento }}</td>
                    <td>{{ valorFormat(o.valor) }}</td>
                    <td>{{ porcentagemFormat(o.porcentagemComissao, 4) }}</td>
                    <td>{{ o.situacaoExtenso }}</td>
                    <td>{{ o.documento }}</td>
                    <td>{{ valorFormat(o.valorTotal) }}</td>
                </tr>
            </tbody>
        </v-table>
    </v-card>

    <div class="block text-right">
        <v-pagination @update:modelValue="handleCurrentChange" v-model="currentPage" :length="Math.ceil(total / pageSize)" :total-visible="7" :show-first-last-page="true">
        </v-pagination>
    </div>
    <p></p>
    <v-row>
        <v-col>
            <v-btn v-show="temPermissao('03.08.02')" color="primary" @click.prevent="dialogPagamentoTodasComissoes = true">Pagar Todas as Comissões Listadas na Tela</v-btn>        </v-col>
    </v-row>

    <v-dialog v-model="dialogPagamentoComissao" max-width="600" transition="dialog-top-transition" persistent>
        <v-card>
            <v-card-title>Pagamento de Comissão</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <Data label="Data do Pagamento" type="text" v-model="dataPagamento"></Data>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" @click.prevent="confirmaPagar()">Pagar Comissão</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" variant="elevated" @click.prevent="dialogPagamentoComissao = false">Fechar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPagamentoTodasComissoes" max-width="600" transition="dialog-top-transition" persistent>
        <v-card>
            <v-card-title>Pagamento de todas as Comissão</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <Data label="Data do Pagamento" type="text" v-model="dataPagamento"></Data>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" @click.prevent="confirmaPagarTodas()">Pagar Comissão</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" variant="elevated" @click.prevent="dialogPagamentoTodasComissoes = false">Fechar</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

  </template>