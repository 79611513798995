import Http from './http';
import { useConfiguracoesOrdensServicos } from '../store/configuracoesOrdensServicos';

export class ConfiguracoesOrdensServicos {

    static get = function() {
        return Http.get('/Serv/ConfiguracoesOrdensServicos/ConfiguracoesOrdensServicos.php', {
            params: {
                'operacao': 'retornar',
            }
        });
    };

    static save = function(dados) {
        return Http.post('/Serv/ConfiguracoesOrdensServicos/ConfiguracoesOrdensServicos.php?operacao=gravar', dados);
    };

    static async inicializa() {
        const configuracoesOrdensServicos = useConfiguracoesOrdensServicos();
        try {
            let response = await Http.get('/Serv/ConfiguracoesOrdensServicos/ConfiguracoesOrdensServicos.php', {
                params: {
                    'operacao': 'retornar',
                }});
            if (response.status == 200) {
                configuracoesOrdensServicos.setLabelCampo1(response.data.data.labelCampo1);
                configuracoesOrdensServicos.setLabelCampo2(response.data.data.labelCampo2);
                configuracoesOrdensServicos.setLabelCampo3(response.data.data.labelCampo3);
                configuracoesOrdensServicos.setLabelCampo4(response.data.data.labelCampo4);
                configuracoesOrdensServicos.setLabelCampo5(response.data.data.labelCampo5);
                configuracoesOrdensServicos.setJaCarregado(true);
            }
    
        }
        catch (error) {
            console.error(error);
        }
    }
}
