import Http from './http';

export class ChequeRecebido {
    static listar = function(start, limit, sort, filtrarPor, dataInicial, dataFinal, abertos, cancelados, baixados, voltaram, idCliente, filtro, tipoFiltro) {
        return Http.get('/Serv/ChequeRecebido/ChequeRecebido.php', {
            params: {
                'operacao': 'listar',
                'start':start,
                'limit':limit,
                'sort':sort,
                'filtrarPor': filtrarPor,
                'dataInicial': dataInicial,
                'dataFinal': dataFinal,
                'abertos': abertos,
                'cancelados': cancelados,
                'baixados': baixados,
                'voltaram': voltaram,
                'idCliente': idCliente,
                'filtro': filtro,
                'tipoFiltro': tipoFiltro,
            }
        });
    };

    static save = function(dados) {
        return Http.post('/Serv/ChequeRecebido/ChequeRecebido.php?operacao=gravar', dados);
    };

    static proximoCodigo = function() {
        return Http.get('/Serv/ChequeRecebido/ChequeRecebido.php?operacao=proximocodigo');
    };

    static codigoExiste = function(codigo, idChequeRecebido) {
        let dados = new FormData();
        dados.append('codigo', codigo);
        dados.append('idChequeRecebido', idChequeRecebido);
        return Http.post('/Serv/ChequeRecebido/ChequeRecebido.php?operacao=codigoexiste', dados);
    };

    static getById = function(dados) {
        return Http.post('/Serv/ChequeRecebido/ChequeRecebido.php?operacao=getbyid', dados);
    };

    static excluir = function(dados) {
        return Http.post('/Serv/ChequeRecebido/ChequeRecebido.php?operacao=excluir', dados);
    };

    static cancelar = function(dados) {
        return Http.post('/Serv/ChequeRecebido/ChequeRecebido.php?operacao=cancelar', dados);
    };

    static baixar = function(dados) {
        return Http.post('/Serv/ChequeRecebido/ChequeRecebido.php?operacao=baixar', dados);
    };
    static voltou = function(dados) {
        return Http.post('/Serv/ChequeRecebido/ChequeRecebido.php?operacao=voltou', dados);
    };
}