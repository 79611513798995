<script setup>
import { ref, onMounted, inject, defineEmits, defineProps } from "vue";
import { LancamentoConta } from "../../resources/lancamentoContaResource";
import { useField, useForm } from "vee-validate";
import { string, object, addMethod } from "yup";
import { usePermissoes } from "../../store/permissoes";
import Moeda from "../Utils/moeda.vue";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";

const props = defineProps({
    apelidoContaOrigem: { 
        type: String,
        default: '',
    },
    idContaOrigem: { 
        type: Number,
        default: 0,
    },
    detalhesContaOrigem: { 
        type: String,
        default: '',
    },
});

const showBtnGravar = ref(true);
const snackBarApp = inject("snackBarApp");
const permissoesStore = usePermissoes();
const detalhesContaOrigem = ref(props.apelidoContaOrigem + ', ' + props.detalhesContaOrigem);
const emit = defineEmits(["transferiu",'close-dialog']);

addMethod(string, "valor_maior_zero", function valorMaiorZero(message) {
    return string().test(
        "valor-maior-zero", // Name
        message, // Msg
        function (valor) {
            if(Converte.stringToFloat(valor) <= 0) {
                return false;
            }
            return true;
        }
    );
});

const validationSchema = object({
    transferenciaCaixa: object({
        idContaOrigem: undefined, 
        data: string().required("A data é obrigatória"),
        valor: string().required("O valor é obrigatório").valor_maior_zero("O valor deve ser maior que zero"),
    }).required(),
});
const { handleSubmit, errors } = useForm({ validationSchema });

const transferenciaCaixa = ref({
    idContaOrigem: useField("transferenciaCaixa.idContaOrigem").value,
    data: useField("transferenciaCaixa.data").value,
    valor: useField("transferenciaCaixa.valor").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    cleanForm();
    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    transferenciaCaixa.value.idContaOrigem = props.idContaOrigem;
    transferenciaCaixa.value.data = new Date().toLocaleDateString("pt-BR");
    transferenciaCaixa.value.valor = "R$ 0,00";
}

async function gravar(values, actions) {
    let dados = new FormData();

    values.transferenciaCaixa.data = Converte.datePtBrToIso(values.transferenciaCaixa.data); 

    dados.append("dados", JSON.stringify(values.transferenciaCaixa));

    try {
        
        const response = await LancamentoConta.transferenciaCaixa(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("A transferência não pode ser efetuada, mensagem de erro: " + response.data.message, 6000);
                return;
            }
            snackBarApp.value.open("Transferência efetuada com sucesso.");
            actions.resetForm();
            emit('transferiu');
            
        }
    } catch (err) {
        snackBarApp.value.open("Erro, mensagem de erro: " + err);
    }
}

</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2>Transferência para o Caixa</h2>
        </v-card-title>
        <form ref="formTransferenciaCaixa" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            label="Conta de Origem"
                            id="detalhesContaOrigem"
                            v-model="detalhesContaOrigem"
                            readonly>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <Data
                            label="Data"
                            id="data"
                            v-model="transferenciaCaixa.data"
                            :error-messages="errors['transferenciaCaixa.data']"
                            maxlength="10"
                        />
                    </v-col>
                    <v-col cols="3">
                        <moeda
                            label="Valor"
                            id="valor"
                            v-model="transferenciaCaixa.valor"
                            :error-messages="errors['transferenciaCaixa.valor']"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" v-show="showBtnGravar" type="submit" id="btnTransferenciaCaixaGravar">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" variant="elevated" @click.prevent= "emit('close-dialog');">Fechar</v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>
