import Http from './http';

export class Conta {

    static get = function(start, limit, filtro, sort, filtrarPor) {
        return Http.get('/Serv/Contas/Contas.php', {
            params: {
                'operacao': 'listar',
                'start':start,
                'limit':limit,
                'filtro':filtro,
                'sort':sort,
                'filtrarPor': filtrarPor,
            }
        });
    };

    static save = function(dados) {
        return Http.post('/Serv/Contas/Contas.php?operacao=gravar', dados);
    };

    static getById = function(dados) {
        return Http.post('/Serv/Contas/Contas.php?operacao=getbyid', dados);
    };

    static excluir = function(dados) {
        return Http.post('/Serv/Contas/Contas.php?operacao=excluir', dados);
    };

    static proximoCodigo = function() {
        return Http.get('/Serv/Contas/Contas.php?operacao=proximocodigo');
    };

    static codigoExiste = function(codigo, idConta) {
        let dados = new FormData();
        dados.append('codigo', codigo);
        dados.append('idConta', idConta);
        return Http.post('/Serv/Contas/Contas.php?operacao=codigoexiste', dados);
    };

    static getContas = function() {
        return Http.post('/Serv/Contas/Contas.php?operacao=getcontas');
    };

}
