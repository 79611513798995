

<template>
    <v-text-field
        @input="updatevalue"
        @blur="formatvalue"
        @focus="converte"
    />
</template>
    

<script setup>

    import {ref, defineEmits, defineProps, onMounted} from 'vue';

    const props = defineProps({
        tipo: {
            type: String,
            default: '',
        },
    });

    const casasDecimais = ref(2);
    const emit = defineEmits(['update:modelValue']);

    const updatevalue = (value) => {
        value.target.value = value.target.value.replace('.','');
        emit('update:modelValue', value.target.value);
    }

    onMounted(() => {
        if(props.tipo == 'multa' || props.tipo == 'juros' || props.tipo == 'comissao' || props.tipo == 'tarifaP') {
            casasDecimais.value = 4;
        }                
    });

    const formatvalue = (value) => {
        let number = '';
        let result = '';
        if(value.target.value  && typeof value.target.value !== 'undefined') {
            var numberRegex = value.target.value.match(/^-?[0-9]+,?[0-9]*$/);
            number = numberRegex ? numberRegex[0] : numberRegex;
            if(number !== null) {
                number = number.replace(',','.');
            }
        }

        result = new Intl.NumberFormat('pt-BR', {
            maximumFractionDigits:casasDecimais.value,
            minimumFractionDigits:casasDecimais.value,
            style: 'decimal',
        }).format(number).replace(/^(-\D+)/, '$1 ');

        result = result+' %';

        value.target.value = result;
        emit('update:modelValue', result);  
    }

    function converte(event) {
        setTimeout(function () {
            let valor = '';
            if(event.target.value.length > 0) {
                valor =  event.target.value.replace(/[^-\d,]/g,'');
            }
            event.target.value = valor;
            emit('update:modelValue', valor);  
            setTimeout(function () {
                event.target.select()
            }, 3)
        }, 3)
    }
</script>
