<script setup>
import { ref, onMounted, inject, defineEmits, defineProps } from "vue";
import { useRoute } from "vue-router";
import { PedidoCompra } from "../../resources/pedidoCompraResource";
import {Produto} from "../../resources/produtoResource";
import { useForm, useField } from "vee-validate";
import { string, addMethod, object } from "yup";
import { usePermissoes } from "../../store/permissoes";
import ProdutoCreate from "../Produtos/ProdutoCreate.vue";
import ProdutoBusca from "../Produtos/ProdutoBusca.vue";
import { useCasasDecimais } from "../../store/casasDecimais";
import Moeda from "../Utils/moeda.vue";
import CodigoProduto from "../Utils/codigoProduto.vue";
import Quantidade from "../Utils/quantidade.vue";
import { Converte } from "../../utils/converte";
import UltimasEntradas from "./UltimasEntradas.vue";

const props = defineProps({
    produtoPedidoCompra: {
        type: Object,
    },
});

const emit = defineEmits(['close-dialog', 'alterou-produto']);
const route = useRoute();
const dialogProduto = ref(false);
const dialogBuscaProduto = ref(false);
const dialogUltimasEntradas = ref(false);
let showBtnGravar = ref(true);
const casasDecimaisStore = useCasasDecimais();

const snackBarApp = inject("snackBarApp");

const permissoesStore = usePermissoes();


addMethod(string, "quantidade_maior_zero", function quantidadeMaiorZero(message) {
    return string().test(
        "quantidade-maior_zero", // Name
        message, // Msg
        function (quantidade) {
            if(Converte.stringToFloat(quantidade) > 0) {
                return true;
            }
            return false;
        }
    );
});

const validationSchema = object({
    updateProdutoPedidoCompra: object({
        idProdutoPedidoCompra: undefined,
        idPedidoCompra: undefined,
        idProduto: undefined, 
        codigoProduto: string().required("O código do produto é obrigatório").label("O código do produto"),
        codigoBarras: undefined,
        descricaoProduto: undefined,
        unidadeProduto: undefined,
        estoqueMinimo: undefined,
        estoqueMaximo: undefined,
        quantidadeAtual: undefined,
        maximoMenosAtual: undefined,
        quantidade: string().quantidade_maior_zero("A quantidade deve ser maior que 0."),
        valorUnitario: string().required("O valor unitário é obrigatório").label("O valor unitário"),
        valorTotal: string().required("O valor total é obrigatório").label("O valor total"),
    }).required(),
});
const { handleSubmit, errors } = useForm({ validationSchema });

const updateProdutoPedidoCompra = ref({
    idProdutoPedidoCompra: useField("updateProdutoPedidoCompra.idProdutoPedidoCompra").value,
    idPedidoCompra: useField("updateProdutoPedidoCompra.idPedidoCompra").value,
    idProduto: useField("updateProdutoPedidoCompra.idProduto").value,
    codigoProduto: useField("updateProdutoPedidoCompra.codigoProduto").value,
    codigoBarras: useField("updateProdutoPedidoCompra.codigoBarras").value,
    descricaoProduto: useField("updateProdutoPedidoCompra.descricaoProduto").value,
    unidadeProduto: useField("updateProdutoPedidoCompra.unidadeProduto").value,
    estoqueMinimo: useField("updateProdutoPedidoCompra.estoqueMinimo").value,
    estoqueMaximo: useField("updateProdutoPedidoCompra.estoqueMaximo").value,
    quantidadeAtual: useField("updateProdutoPedidoCompra.quantidadeAtual").value,
    maximoMenosAtual: useField("updateProdutoPedidoCompra.maximoMenosAtual").value,
    quantidade: useField("updateProdutoPedidoCompra.quantidade").value,
    valorUnitario: useField("updateProdutoPedidoCompra.valorUnitario").value,
    valorTotal: useField("updateProdutoPedidoCompra.valorTotal").value,
});

const submitUpdateProduto = handleSubmit((values, actions) => {
    updateProduto(values, actions);
});

onMounted(() => {
    cleanForm();

    updateProdutoPedidoCompra.value.idProdutoPedidoCompra = props.produtoPedidoCompra.idProdutoPedidoCompra;
    updateProdutoPedidoCompra.value.idProduto = props.produtoPedidoCompra.idProduto;
    updateProdutoPedidoCompra.value.codigoProduto = props.produtoPedidoCompra.codigoProduto;
    updateProdutoPedidoCompra.value.descricaoProduto = props.produtoPedidoCompra.descricaoProduto;
    updateProdutoPedidoCompra.value.unidadeProduto = props.produtoPedidoCompra.unidadeProduto;
    updateProdutoPedidoCompra.value.quantidade = Converte.numberToQuantidadeProduto(props.produtoPedidoCompra.quantidade);
    updateProdutoPedidoCompra.value.valorUnitario = Converte.numberToPCustoProduto(props.produtoPedidoCompra.valorUnitario);

    getProduto('codigo', true);

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    updateProdutoPedidoCompra.value.idProdutoPedidoCompra = null;
    updateProdutoPedidoCompra.value.idPedidoCompra = route.params.id;
    updateProdutoPedidoCompra.value.idProduto = null;
    updateProdutoPedidoCompra.value.codigoProduto = "";
    updateProdutoPedidoCompra.value.codigoBarras = "";
    updateProdutoPedidoCompra.value.descricaoProduto = "";
    updateProdutoPedidoCompra.value.unidadeProduto = "";
    updateProdutoPedidoCompra.value.estoqueMinimo = casasDecimaisStore.quantidadeProdutoZero;
    updateProdutoPedidoCompra.value.estoqueMaximo = casasDecimaisStore.quantidadeProdutoZero;
    updateProdutoPedidoCompra.value.quantidadeAtual = casasDecimaisStore.quantidadeProdutoZero;
    updateProdutoPedidoCompra.value.maximoMenosAtual = casasDecimaisStore.quantidadeProdutoZero;
    updateProdutoPedidoCompra.value.quantidade = casasDecimaisStore.quantidadeProdutoUm;
    updateProdutoPedidoCompra.value.valorUnitario = casasDecimaisStore.pCustoProdutoZero;
    updateProdutoPedidoCompra.value.valorTotal = 'R$ 0,00';
}
async function updateProduto(values) {
    let dados = new FormData();
    dados.append("dados", JSON.stringify(values.updateProdutoPedidoCompra));

    try {
        const response = await PedidoCompra.updateProduto(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("O produto não pode ser alterado no pedido de compra, mensagem: " + response.data.message, 6000);
                return;
            }

            snackBarApp.value.open("Produto alterado com sucesso no pedido de compra.", 1500);
            emit('alterou-produto');

            

        }
    } catch (err) {
        console.log(err);
    }
}

async function getProduto(campo, mounted = false) {

    let valor = "";

    if(campo == 'codigoBarras') {
        valor = updateProdutoPedidoCompra.value.codigoBarras;
        if(valor == "") {
            return;
        }
    } else
    {
        valor = updateProdutoPedidoCompra.value.codigoProduto;
    }

    if(valor == null || valor.trim() == '') {
        
        updateProdutoPedidoCompra.value.codigoBarras = '';
        updateProdutoPedidoCompra.value.descricaoProduto = '';
        updateProdutoPedidoCompra.value.unidadeProduto = '';
        updateProdutoPedidoCompra.value.quantidadeAtual = casasDecimaisStore.quantidadeProdutoZero;
        if(campo == 'codigo') {
            updateProdutoPedidoCompra.value.idProduto =  null;
        }
        calculaTotal();
        return;
    }

    if(campo == 'codigo') {
        valor = Converte.codigoProduto(valor);
    }

    updateProdutoPedidoCompra.value.idProduto = null;
    updateProdutoPedidoCompra.value.descricaoProduto = "";
    updateProdutoPedidoCompra.value.unidadeProduto = "";
    if(campo == "codigo") {
        updateProdutoPedidoCompra.value.codigoBarras = "";
    }
    else {
        updateProdutoPedidoCompra.value.codigoProduto = "";
    }


    updateProdutoPedidoCompra.value.quantidadeAtual = casasDecimaisStore.produtoQuantidadeZero;

    let dados = new FormData();
    dados.append('codigoCodigoBarras', valor);
    dados.append('campo', campo);
    dados.append("codigoModulo", "03.13");

    try {
        let response = await Produto.getByCodigoCodigoBarras(dados);
        if (response.status == 200) {
            if(!response.data.data) {
                if(campo == 'codigo') {
                    document.getElementById("codigoProduto").focus();
                }
                if(campo == 'codigoBarras') {
                    document.getElementById("codigoBarras").focus();
                }
                snackBarApp.value.open("Produto não encontrado, tente outro código ou faça uma busca.", 6000);
                calculaTotal();
                return;
            }

            if(response.data.data.ativoInativoBloqueado == 'I') {
                    snackBarApp.value.open("Produto inativo. O produto '"+ (response.data.data.codigo).trim()+"/" + response.data.data.descricao+ 
                        "' está nativo, um produto inativo não pode ser incluído em pedidos de compras.", 6000);
                    updateProdutoPedidoCompra.value.codigoProduto = '';
                    document.getElementById("codigoProduto").focus();
                    calculaTotal();
                    return;
            }
            if(response.data.data.ativoInativoBloqueado == 'B') {
                snackBarApp.value.open("Produto bloqueado. O produto '"+ (response.data.data.codigo).trim()+"/" + response.data.data.descricao+
                    "' está bloqueado, um produto bloqueado não pode ser incluído em pedidos de compras.", 6000);
                updateProdutoPedidoCompra.value.codigoProduto = '';
                document.getElementById("codigoProduto").focus();
                calculaTotal();
                return;
            }

            updateProdutoPedidoCompra.value.idProduto = response.data.data.idProduto;
            updateProdutoPedidoCompra.value.codigoProduto = response.data.data.codigo;
            updateProdutoPedidoCompra.value.descricaoProduto = response.data.data.descricao;
            updateProdutoPedidoCompra.value.codigoBarras = response.data.data.codigoBarras;

            updateProdutoPedidoCompra.value.unidadeProduto = response.data.data.unidade;
            updateProdutoPedidoCompra.value.quantidadeAtual = Converte.numberToQuantidadeProduto(response.data.data.quantidade);
            updateProdutoPedidoCompra.value.estoqueMinimo = Converte.numberToQuantidadeProduto(response.data.data.estoqueMinimo);
            updateProdutoPedidoCompra.value.estoqueMaximo = Converte.numberToQuantidadeProduto(response.data.data.estoqueMaximo);
            updateProdutoPedidoCompra.value.maximoMenosAtual = Converte.numberToQuantidadeProduto(response.data.data.estoqueMaximo - response.data.data.quantidade);
            if(!mounted) {
                updateProdutoPedidoCompra.value.valorUnitario = Converte.numberToPCustoProduto(response.data.data.pCusto);
            }
            
            calculaTotal();
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouProduto(produto) {
    if (produto == null) {
        return;
    }

    document.getElementById("quantidadeUpdate").focus();

    updateProdutoPedidoCompra.value.codigoProduto = produto.codigo ;
    getProduto('codigo');


    dialogBuscaProduto.value = false;    
}


function calculaTotal() {
    let quantidade = Converte.stringToFloat(updateProdutoPedidoCompra.value.quantidade);
    let valorUnitario = Converte.stringToFloat(updateProdutoPedidoCompra.value.valorUnitario);
    let total = quantidade * valorUnitario;
    updateProdutoPedidoCompra.value.valorTotal = Converte.numberToCurrency(total);
}

function cadastrouProduto(codigoProduto) {
    if (codigoProduto == null) {
        return;
    }
    updateProdutoPedidoCompra.value.codigoProduto = codigoProduto;
    getProduto('codigo');
    
}

function closeModalUpdateProdutoPedidoCompra() {
    emit('close-dialog');
}

</script>

<template>
    <v-card elevetion="16">
        <v-card-title>
            <h2>Alteração de Item do Pedido de Compra</h2>
        </v-card-title>
        <form ref="formProdutoPedidoCompra" @submit.prevent="submitUpdateProduto">
        <v-card-text>
            <v-row>
                <v-col cols="2">
                    <CodigoProduto
                        label="Código do Produto"
                        id="codigoProdutoUpdate"
                        type="text"
                        v-model="updateProdutoPedidoCompra.codigoProduto"
                        :error-messages="errors['updateProdutoPedidoCompra.codigoProduto']"
                        maxlength="15"
                        @blur="getProduto('codigo')"
                    />
                </v-col>
                <v-col cols="2">
                    <v-text-field
                        label="Código de Barras"
                        id="codigoBarrasUpdate"
                        type="text"
                        v-model="updateProdutoPedidoCompra.codigoBarras"
                        :error-messages="errors['updateProdutoPedidoCompra.codigoBarras']"
                        maxlength="14"
                        @blur="getProduto('codigoBarras')"
                    />
                </v-col>
                <v-col cols="8">
                    <v-text-field label="Descrição do Produto" id="descricaoProdutoUpdate" type="text" v-model="updateProdutoPedidoCompra.descricaoProduto" readonly tabindex="-1">
                        <template v-slot:append v-if="route.name == 'PedidosCompras.Create' || route.name == 'PedidosCompras.Continuar'">
                            <v-tooltip text="Faz a busca de um produto pela descrição, referência.">
                                <template v-slot:activator="{ props }">
                                    <v-btn v-bind="props" color="primary" @click="dialogBuscaProduto = true" id="btnBuscaProduto">
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                </template>
                            </v-tooltip>
                            <v-dialog v-model="dialogBuscaProduto" transition="dialog-top-transition">
                                <ProdutoBusca @selecionouProduto="selecionouProduto" @close-dialog="dialogBuscaProduto = false;" />
                            </v-dialog>
                            &nbsp;
                            <v-tooltip text="Cadastra um novo produto.">
                                <template v-slot:activator="{ props }">
                                    <v-btn v-bind="props" color="primary" @click="dialogProduto = true" id="btnNovoProdutoUpdate" >
                                        <v-icon>mdi-invoice-text-plus</v-icon>
                                    </v-btn>
                                </template>
                            </v-tooltip>
                            <v-dialog v-model="dialogProduto" transition="dialog-top-transition">
                                <ProdutoCreate @cadastrouProduto="cadastrouProduto" @close-dialog="dialogProduto = false" />
                            </v-dialog>
                            &nbsp;
                            <v-tooltip text="Consulta as últimas entradas do produto.">
                                <template v-slot:activator="{ props }">
                                    <v-btn v-bind="props" color="primary" @click="dialogUltimasEntradas = true" id="btnUltimasEntradasUpdate" >
                                        <v-icon>mdi-invoice-list-outline</v-icon>
                                    </v-btn>
                                </template>
                            </v-tooltip>
                            <v-dialog v-model="dialogUltimasEntradas" transition="dialog-top-transition">
                                <UltimasEntradas :idProduto="updateProdutoPedidoCompra.idProduto"
                                    :codigoProduto = "updateProdutoPedidoCompra.codigoProduto"
                                    :descricaoProduto = "updateProdutoPedidoCompra.descricaoProduto" 
                                    @close-dialog="dialogUltimasEntradas = false" />
                            </v-dialog>
                        </template>                    
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="3">
                    <v-text-field label="Unid." id="unidadeUpdate" type="text" v-model="updateProdutoPedidoCompra.unidadeProduto" readonly tabindex="-1">
                    </v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-text-field label="Estoque Mínimo" id="estoqueMinimoUpdate" 
                        type="text" v-model="updateProdutoPedidoCompra.estoqueMinimo" 
                        tipo="quantidadeProduto" readonly tabindex="-1">
                    </v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-text-field label="Estoque Máximo" id="estoqueMaximoUpdate" 
                        type="text" v-model="updateProdutoPedidoCompra.estoqueMaximo" 
                        tipo="quantidadeProduto" readonly tabindex="-1">
                    </v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-text-field label="Quantidade Atual" id="quantidadeAtualUpdate" 
                        type="text" v-model="updateProdutoPedidoCompra.quantidadeAtual" 
                        tipo="quantidadeProduto" readonly tabindex="-1">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="3">
                    <v-text-field label="Máximo - Atual" id="maximoMenosAtualUpdate" 
                        type="text" v-model="updateProdutoPedidoCompra.maximoMenosAtual" 
                        tipo="quantidadeProduto" readonly tabindex="-1">
                    </v-text-field>
                </v-col>
                <v-col cols="3">
                    <Quantidade label="Quantidade" id="quantidadeUpdate" type="text" v-model="updateProdutoPedidoCompra.quantidade"  tipo="quantidadeProduto"
                        :error-messages="errors['updateProdutoPedidoCompra.quantidade']" maxlength="15"
                        @blur="calculaTotal()" />
                </v-col>
                <v-col cols="3">
                    <Moeda label="Valor Unitário" id="valorUnitarioUpdate" type="text" v-model="updateProdutoPedidoCompra.valorUnitario" 
                        :error-messages="errors['updateProdutoPedidoCompra.valorUnitario']" maxlength="15" tipo="pCustoProduto"
                        @blur="calculaTotal()" />
                </v-col>
                <v-col cols="3">
                    <Moeda label="Valor Total" id="valorTotalUpdate" type="text" v-model="updateProdutoPedidoCompra.valorTotal" 
                        :error-messages="errors['updateProdutoPedidoCompra.valorTotal']" maxlength="15" readonly tabindex="-1" />
                </v-col>
            </v-row>    

        </v-card-text>
        <v-card-actions>
            <v-btn color="primary" v-show="showBtnGravar" 
                variant="elevated" 
                type="submit" 
                id="btnUpdateProdutoGravar">Alterar Produto</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="elevated" @click.prevent='closeModalUpdateProdutoPedidoCompra'>Fechar</v-btn>
        </v-card-actions>
        </form>
    </v-card>
</template>