
<script setup>
    import {defineEmits, defineProps} from 'vue';

    const emit = defineEmits(['close-dialog']);

    const props = defineProps({
        dataValidade: String
    });

    function adquirir() {
        window.open('http://www.osgold.com.br/adquirir.php', '_blank');
        return;
    }

</script>


<template>
    <v-card elevation="16">
        <v-card-title>
        <h4>OS GOLD</h4>
        </v-card-title>
        <v-card-text>
            <p>Seu período de acesso ao sistema venceu em {{ props.dataValidade }}. Para renovar o acesso, clique no botão abaixo, selecione o plano desejado ou entre em contato conosco.</p>
            <br />
            <div class="row">
                <div class="text-center">
                    <v-btn color="primary" variant="elevated" @click="adquirir">Selecionar Plano de Acesso</v-btn>
                </div>
            </div>
            <br />
            <p>Caso tenha alguma dúvida é só entrar em contato por email(osgold@osgold.com.br), WhatsApp 041-3078-9507 ou formulários de contato</p>
        </v-card-text>

        <v-card-actions>
            <v-btn color="primary" variant="elevated" @click="emit('close-dialog')">Fechar</v-btn>
        </v-card-actions>
    </v-card>
</template>
