import {differenceInDays, isSaturday, isSunday, addDays} from 'date-fns';
export class ContaReceberService {
    static calculaValorTotalAReceber = function (auxDataVencimento, auxDataRecebimento, valor, acrescimo, valorAReceberJuros,
            valorAReceberMulta, valorRecebido, jurosRecebido, multaRecebida, desconto, juros, multa, carencia, situacao) {

        let resultado = {
            'valorAreceber':0,
            'diasAtraso':0,
            'valorJuros':0,
            'valorMulta':0,
        };
        let valorAReceber = 0;
        let diasAtraso =0;
        let auxData = '';
        let valorJuros = 0;
        let valorMulta = 0;
        let dataRecebimento;
        let dataVencimento;

        resultado.valorAreceber = valorAReceber;
        resultado.diasAtraso = diasAtraso;
        resultado.valorJuros =  valorJuros;
        resultado.valorMulta = valorMulta;

        if(situacao !== 'A') {
            return resultado;
        }

        valorAReceber = (valor + valorAReceberJuros +
            valorAReceberMulta + jurosRecebido + multaRecebida) -
            (valorRecebido );


        // console.log('valor:'+valor);
        // console.log('valorAReceberJuros:'+valorAReceberJuros);
        // console.log('valorAReceberMulta:'+valorAReceberMulta);
        // console.log('jurosRecebido:'+jurosRecebido);
        // console.log('multaRecebida:'+multaRecebida);
        // console.log('valorRecebido:'+valorRecebido);


        dataRecebimento = auxDataRecebimento;
        // Except
        // DataRecebimento := NOW;
        // end;

        dataVencimento = auxDataVencimento;
        // DataVencimento := NOW;
        // End;

        if(dataVencimento > dataRecebimento) {
            diasAtraso = 0;
        }
        else {
            diasAtraso = differenceInDays(dataRecebimento, dataVencimento) // dataRecebimento.diff(dataVencimento, "days");
        }

        let cont = 0;

        auxData = dataVencimento;

        // console.log('Dias de Atraso:'+diasAtraso);

        if(diasAtraso >  0) {
            while (isSaturday(auxData) || isSunday(auxData)) {
                cont = cont + 1;
                auxData = addDays(auxData);
            }

            if ((diasAtraso > cont) && ((diasAtraso - carencia) > 0)) {
                // Calcula a Multa, a multa é paga apenas uma vez.
                if (multaRecebida == 0) {
                    valorMulta = (multa / 100) * valorAReceber;
                }
                valorJuros = ((juros * diasAtraso) / 100) * valorAReceber;

                // Aqui calcula o valor a receber com juros e multa.
                valorAReceber = valorAReceber + valorMulta + valorJuros;

            }
        }
        valorAReceber = valorAReceber + acrescimo - desconto;

        resultado.valorAreceber = valorAReceber;
        resultado.diasAtraso = diasAtraso;
        resultado.valorJuros = valorJuros;
        resultado.valorMulta = valorMulta;

        return resultado;
    }
}

