<script setup>
    import { ref, onMounted, inject } from 'vue';
    import {NovoCadastro} from '../../resources/novoCadastroResource';
    import { useField, useForm } from 'vee-validate'
    import {object, string} from 'yup';
    import { useRouter } from "vue-router";

    const snackBarApp = inject('snackBarApp');
    const router = useRouter();

    const validationSchema  = object({
        novoCadastro: object({
            cnpj: string().max(14).typeError('deve ser um número').label('CNPJ/CPF'),
            email: string().email().required().label('Email'),
            repitaEmail: string().email().required().label('Repita o email').test(
                "email-igual",
                "Os emails não são iguais",
                (value) => value === novoCadastro.value.email),
            login: string().required().label('Login'),
            senha: string().required().label('Senha'),
            repitaSenha: string().required().test(
              "senha-igual",
                "As senhas não são iguais",
                (value) => value === novoCadastro.value.senha
            ).label('Repita a senha'),
        }).required()
    });
    const { handleSubmit, errors } = useForm({validationSchema});

    const novoCadastro =  ref({ 
        cnpj: useField('novoCadastro.cnpj').value,
        email: useField('novoCadastro.email').value,
        repitaEmail: useField('novoCadastro.repitaEmail').value,
        login: useField('novoCadastro.login').value,
        senha: useField('novoCadastro.senha').value,
        repitaSenha: useField('novoCadastro.repitaSenha').value,
    });

    const submit = handleSubmit(() => {
        gravar();
    });

    onMounted(() => {
        novoCadastro.value.cnpj = '';
        novoCadastro.value.email = '';
        novoCadastro.value.repitaEmail = '';
        novoCadastro.value.login = '';
        novoCadastro.value.senha = '';
        novoCadastro.value.repitaSenha = '';

        document.getElementById("cnpj").focus();
    });

    async function gravar() {
        let dados = new FormData();
        dados.append('dados', JSON.stringify(novoCadastro.value));

        try {
            const response = await NovoCadastro.criarCadastro(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBarApp.value.open('Não foi possível criar o novo cadastro, mensagem de erro: '+ response.data.message, 6000);
                    return;
                }

                snackBarApp.value.open('Novo cadastro criado com sucesso. Você terá 30 dias para testar o sistema completo. Você será redirecionado para a tela de login.', 5000);    
                setTimeout(() => {
                    router.push({'name':'Login'});
                }, 6000);
            }
        }  catch(err) {
            snackBarApp.value.open('Erro, mensagem de erro: '+ err);
        }
    }

</script>


<template>
    <div class="d-flex align-center flex-column">
        <v-card  elevation="16" max-width="1024">
            <v-card-title>
            <h2>Novo Cadastro no Sistema OS Gold</h2>
            </v-card-title>
            
            <form ref="formNovoCadastro" @submit.prevent="submit">
            <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-text-field label="CPF/CNPJ(Opcional)"
                            id="cnpj"
                            type="text"
                            maxlength="14"
                            v-model='novoCadastro.cnpj'
                            :error-messages="errors['novoCadastro.cnpj']"/>
                </v-col>
            </v-row>    
            <v-row>
                <v-col cols="12">
                    <v-text-field label="Email"
                            id="email"
                            type="text"
                            maxlength="80"
                            v-model='novoCadastro.email'
                            :error-messages="errors['novoCadastro.email']"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-text-field label="Repita o Email"
                            id="repitaEmail"
                            type="text"
                            maxlength="80"
                            v-model='novoCadastro.repitaEmail'
                            :error-messages="errors['novoCadastro.repitaEmail']"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-text-field label="Login"
                            id="login"
                            type="text"
                            maxlength="10"
                            v-model='novoCadastro.login'
                            :error-messages="errors['novoCadastro.login']"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-text-field label="Senha"
                            id="senha"
                            type="password"
                            v-model='novoCadastro.senha'
                            :error-messages="errors['novoCadastro.senha']"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-text-field label="Repita a Senha"
                            id="repitaSenha"
                            type="password"
                            v-model='novoCadastro.repitaSenha'
                            :error-messages="errors['novoCadastro.repitaSenha']"/>
                </v-col>
            </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnNovoCadastroCriarCadastro" >Criar Novo Cadastro</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" variant="elevated" class="btn btn-primary" :to="{name: 'Login'}">Voltar</v-btn>
            </v-card-actions>
            </form>
        </v-card>
    </div>
</template>

<!-- <template>
    <div class="container">
        <h2>Novo Cadastro no Sistema OS Gold</h2>
        <div class="panel panel-default">
            <div class="panel-body">

                <form data-vv-scope="novoCadastro">
                    <div class="form-group" :class="[errors.has('novoCadastro.cnpj') ? 'has-error' : '']">
                        <label for="cnpj">CPF/CNPJ(Opcional)</label>
                        <input type="text"
                               v-validate="'numeric'"
                               class="form-control"
                               id="cnpj"
                               name="cnpj"
                               placeholder="CNPJ/CPF"
                               v-model='novoCadastro.cnpj'
                               autofocus>
                        <span class="help-block"  v-show="errors.has('novoCadastro.cnpj')">
                             {{ errors.first('novoCadastro.cnpj') }}</span>

                    </div>
                    <div class="form-group" :class="[errors.has('novoCadastro.email') ? 'has-error' : '']">
                        <label for="email">Email</label>
                        <input type="email"
                               ref="email"
                               v-validate="'required|email'"
                               class="form-control"
                               id="email"
                               name="email"
                               placeholder="Email"
                               v-model='novoCadastro.email'>
                        <span class="help-block"  v-show="errors.has('novoCadastro.email')">
                             {{ errors.first('novoCadastro.email') }}</span>

                    </div>
                    <div class="form-group" :class="[errors.has('novoCadastro.repitaEmail') ? 'has-error' : '']">
                        <label for="email">Repita o Email</label>
                        <input type="repitaEmail"
                               class="form-control"
                               v-validate="'required|email|confirmed:email'"
                               data-vv-as="repita o email"
                               id="repitaEmail"
                               name="repitaEmail"
                               placeholder="Repita o endereço de email"
                               v-model='novoCadastro.repitaEmail'>
                        <span class="help-block"  v-show="errors.has('novoCadastro.repitaEmail')">
                             {{ errors.first('novoCadastro.repitaEmail') }}</span>

                    </div>
                    <div class="form-group" :class="[errors.has('novoCadastro.login') ? 'has-error' : '']">
                        <label for="login">Login</label>
                        <input type="text"
                               v-validate="'required'"
                               class="form-control"
                               id="login"
                               name="login"
                               placeholder="Login"
                               maxlength="10"
                               v-model='novoCadastro.login'>
                        <span class="help-block"  v-show="errors.has('novoCadastro.login')">
                             {{ errors.first('novoCadastro.login') }}</span>

                    </div>
                    <div class="form-group" :class="[errors.has('novoCadastro.senha') ? 'has-error' : '']">
                        <label for="senha">Senha</label>
                        <input type="password"
                               ref="senha"
                               v-validate="'required'"
                               class="form-control"
                               id="senha"
                               name="senha"
                               v-model='novoCadastro.senha'>
                        <span class="help-block"  v-show="errors.has('novoCadastro.senha')">
                             {{ errors.first('novoCadastro.senha') }}</span>

                    </div>
                    <div class="form-group" :class="[errors.has('novoCadastro.repitaSenha') ? 'has-error' : '']">
                        <label for="repitaSenha">Repita a Senha</label>
                        <input type="password"
                               class="form-control"
                               v-validate="'required|confirmed:senha'"
                               data-vv-as="repita a senha"
                               id="repitaSenha"
                               name="repitaSenha"
                               v-model='novoCadastro.repitaSenha'>
                        <span class="help-block"  v-show="errors.has('novoCadastro.repitaSenha')">
                             {{ errors.first('novoCadastro.repitaSenha') }}</span>

                    </div>

                    <a class="btn btn-primary" href="#" @click.prevent="criarCadastro()">Criar Novo Cadastro</a>
                    <router-link class="btn btn-primary" :to="{name: 'Login'}">Voltar</router-link>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import {NovoCadastro} from '../../resources/novoCadastroResource';
    export default {
        data() {
            return {
                novoCadastro:{
                    'cnpj': '',
                    'email': '',
                    'repitaEmail': '',
                    'login':'',
                    'senha':'',
                    'repitaSenha':''
                },
                success:false,
            }
        },
        methods: {
            criarCadastro() {
                let self = this;
                const dados = new FormData();

                this.$validator.validateAll('novoCadastro').then((result) => {
                    if (result) {


                        dados.append('dados', JSON.stringify(this.novoCadastro));

                        NovoCadastro.criarCadastro(dados)
                            .then(function (response) {
                                if(response.data.success == false) {
                                    alert(response.data.message);
                                    return;
                                }
                                if(response.data.success == true) {
                                    alert('Cadastro criado com sucesso, agora é só efetuar o login para acessar o sistema.');
                                    self.$router.push({'name':'Login'});
                                    return;
                                }

                                alert('Não foi possível cadastrar o usuário no sistema');

                            })
                            .catch(function (error) {
                                    console.log(error);
                                }
                            );
                    }
                    else {
                        self.$notify({
                            title:'Erro',
                            message: 'Não foi possível criar o novo cadastro, corrija os erros do '+
                            'formulário e tente novamente.',
                            type: 'error'
                        });
                        return;

                    }
                });

            }
        }
    }

</script> -->
