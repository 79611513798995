import Http from './http';

export class Relatorio {

    static get = function(nomeRel, param1=null, param2=null, param3=null, param4=null) {
        let dados = new FormData();
        dados.append('nomeRel', nomeRel);
        dados.append('param1', param1);
        dados.append('param2', param2);
        dados.append('param3', param3);
        dados.append('param4', param4);

        return Http.post('/Serv/Relatorios/Relatorios.php', dados);
    };

}
