import Http from './http';

export class LancamentoCartao {
    static listar = function(start, limit, sort, filtrarPor, dataInicial, dataFinal, abertos, cancelados, recebidos, idCartao) {
        return Http.get('/Serv/LancamentoCartao/LancamentoCartao.php', {
            params: {
                'operacao': 'listar',
                'start':start,
                'limit':limit,
                'sort':sort,
                'filtrarPor': filtrarPor,
                'dataInicial': dataInicial,
                'dataFinal': dataFinal,
                'abertos': abertos,
                'cancelados': cancelados,
                'recebidos': recebidos,
                'idCartao': idCartao,
            }
        });
    };

    static receber = function(dados) {
        return Http.post('/Serv/LancamentoCartao/LancamentoCartao.php?operacao=receber', dados);
    };

    static excluirCancelar = function(dados) {
        return Http.post('/Serv/LancamentoCartao/LancamentoCartao.php?operacao=excluircancelar', dados);
    };

    static receberTodos = function(dados) {
        return Http.post('/Serv/LancamentoCartao/LancamentoCartao.php?operacao=recebertodos', dados);
    };

}
