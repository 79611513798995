import config from '../services/config';
import { defineStore } from 'pinia';

export const useExcluirReverter = defineStore('excluirReverter', {
    state: () => {
        return {
            start:0,
            limit: config.maxPerPage,
            currentPage: 1,
            idProduto: null,
            codigoProduto: '',
            codigoBarrasProduto: '',
            descricaoProduto: '',
            unidadeProduto: '',
            dataInicial: new Date().toLocaleDateString("pt-BR"), 
            dataFinal: new Date().toLocaleDateString("pt-BR"),   
            sort: [{
                'property':'data',
                'direction':'ASC'
            }],
        }
    },
    actions : {
        setStart(start) {
            this.start = start;
        },
        setDataInicial(dataInicial) {
            this.dataInicial = dataInicial;
        },
        setDataFinal(dataFinal) {
            this.dataFinal = dataFinal;
        },
        setCurrentPage(currentPage) {
            this.currentPage = currentPage;
        },
        setSort(sort) {
            this.sort = sort;
        },        
        setIdProduto(idProduto) {
            this.idProduto = idProduto;
        },
        setCodigoProduto(codigoProduto) {
            this.codigoProduto = codigoProduto;
        },
        setCodigoBarrasProduto(codigoBarrasProduto) {
            this.codigoBarrasProduto = codigoBarrasProduto;
        },
        setDescricaoProduto(descricaoProduto) {
            this.descricaoProduto = descricaoProduto;
        },
        setUnidadeProduto(unidadeProduto) {
            this.unidadeProduto = unidadeProduto;
        },
    }
});
