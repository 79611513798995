<script setup>
import { ref, onMounted } from "vue";
import { EntradaEstoque } from "../../resources/entradaEstoqueResource";
import { Produto } from "../../resources/produtoResource";
import { Fornecedor } from "../../resources/fornecedorResource";
import SnackBar from "../Utils/SnackBar";
import { useField, useForm } from "vee-validate";
import { string, addMethod, object } from "yup";
import { usePermissoes } from "../../store/permissoes";
import { useCasasDecimais } from "../../store/casasDecimais";
import ProdutoBusca from "../Produtos/ProdutoBusca.vue";
import FornecedorBusca from "../Fornecedores/FornecedorBusca.vue";
import FornecedorCreate from "../Fornecedores/FornecedorCreate.vue";
import ProdutoCreate from "../Produtos/ProdutoCreate.vue";
import { Converte } from "../../utils/converte";
import Data from "../Utils/data.vue";
import Moeda from "../Utils/moeda.vue";
import Quantidade from "../Utils/quantidade.vue";
import CodigoProduto from "../Utils/codigoProduto.vue";

const snackBar = ref(null);
let dialogFornecedor = ref(false);
let dialogProduto = ref(false);
let dialogBuscaFornecedor = ref(false);
let dialogBuscaProduto = ref(false);
let showBtnGravar = true;
const inputCodigo = ref(null);
const inputCodigoBarras = ref(null);

const permissoesStore = usePermissoes();
const casasDecimaisStore = useCasasDecimais();

addMethod(string, "quantidade_maior_um", function quantidadeMaiorUm(message) {
    return string().test(
        "quantidade-maior-um", // Name
        message, // Msg
        function (quantidade) {
            if(Converte.stringToFloat(quantidade) <= 0) {
                return false;
            }
            return true;
        }
    );
});


const validationSchema = object({
    entradaEstoque: object({
        idMovimentacaoEstoque: undefined,
        codigo: string().required("O código do produto é obrigatório").label("O código do produto"),
        idProduto: string(),
        codigoBarras: undefined,
        descricao: undefined,
        unidade: undefined,
        quantidadeAtual: undefined,
        quantidade: string().quantidade_maior_um("A quantidade que está entrando deve ser maior que 0."),
        valorUnitario: undefined,
        valorTotal: undefined,
        data: string().required("A data é obrigatória").label("A data"),
        documento: string().max(10),
        idFornecedor:undefined,
        codigoFornecedor: undefined, 
        razaoForncedor: undefined,
        gerarLancamentoCaixa: undefined,
    }).required(),
});

const { handleSubmit, errors } = useForm({ validationSchema });

const entradaEstoque = ref({
    idMovimentacaoEstoque: useField("entradaEstoque.idMovimentacaoEstoque").value,
    codigo: useField("entradaEstoque.codigo").value,
    idProduto: useField("entradaEstoque.idProduto").value,
    codigoBarras: useField("entradaEstoque.codigoBarras").value,
    descricao: useField("entradaEstoque.descricao").value,
    unidade: useField("entradaEstoque.unidade").value,
    quantidadeAtual: useField("entradaEstoque.quantidadeAtual").value,
    quantidade: useField("entradaEstoque.quantidade").value,
    valorUnitario: useField("entradaEstoque.valorUnitario").value,
    valorTotal: useField("entradaEstoque.valorTotal").value,
    data: useField("entradaEstoque.data").value,
    documento: useField("entradaEstoque.documento").value,
    idFornecedor: useField("entradaEstoque.idFornecedor").value,
    codigoFornecedor: useField("entradaEstoque.codigoFornecedor").value,
    razaoForncedor: useField("entradaEstoque.razaoForncedor").value,
    gerarLancamentoCaixa: useField("entradaEstoque.gerarLancamentoCaixa").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    cleanForm();
    

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {

    entradaEstoque.value.idMovimentacaoEstoque = null;
    entradaEstoque.value.codigo = "";
    entradaEstoque.value.idProduto = null;
    entradaEstoque.value.codigoBarras = "";
    entradaEstoque.value.descricao = "";
    entradaEstoque.value.unidade = "";
    entradaEstoque.value.valorTotal = 'R$ 0,00';
    entradaEstoque.value.data = new Date().toLocaleDateString("pt-BR");
    entradaEstoque.value.documento = "";
    entradaEstoque.value.idFornecedor = null;
    entradaEstoque.value.codigoFornecedor = "";
    entradaEstoque.value.razaoFornecedor = "";
    entradaEstoque.value.gerarLancamentoCaixa = true;    

    if(casasDecimaisStore.jaCarregado) {
        entradaEstoque.value.quantidadeAtual = casasDecimaisStore.quantidadeProdutoZero;
        entradaEstoque.value.quantidade = casasDecimaisStore.quantidadeProdutoUm;
        entradaEstoque.value.valorUnitario = casasDecimaisStore.pCustoProdutoZero;
    }
    else {
        setTimeout(() => {
            entradaEstoque.value.quantidadeAtual = casasDecimaisStore.quantidadeProdutoZero;
            entradaEstoque.value.quantidade = casasDecimaisStore.quantidadeProdutoUm;
            entradaEstoque.value.valorUnitario = casasDecimaisStore.pCustoProdutoZero;
        }, 500);
    }

}

async function gravar(values, actions) {
    let dados = new FormData();

    values.entradaEstoque.data = Converte.datePtBrToIso(values.entradaEstoque.data); 
    values.entradaEstoque.valor = entradaEstoque.value.valorTotal;

    dados.append("dados", JSON.stringify(values.entradaEstoque));
    dados.modulo = 'ENTRA';

    try {
        const response = await EntradaEstoque.save(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBar.value.open("A entrada no estoque não pode ser feita, mensagem de erro: " + response.data.message, 6000);
                return;
            }
            snackBar.value.open("Entrada no estoque realizada com sucesso.");
            actions.resetForm();
            cleanForm();
            document.getElementById("codigo").focus();
        }
    } catch (err) {
        snackBar.value.open("Erro, mensagem de erro: " + err);
    }
}

async function getFornecedor() {
    entradaEstoque.value.idFornecedor = null;
    entradaEstoque.value.razaoFornecedor = "";

    if (entradaEstoque.value.codigoFornecedor.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", entradaEstoque.value.codigoFornecedor);
    dados.append("getInativos", false);
    dados.append("codigoModulo", "02.01");

    try {
        let response = await Fornecedor.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                if (response.data.message == "inativo") {
                    snackBar.value.open("Um fornecedor inativo não pode gerar entradas no estoque.", 6000);
                    document.getElementById("codigoFornecedor").focus();
                    entradaEstoque.value.codigofornecedor = "";
                    return;
                }
                if (response.data.message == "bloqueado") {
                    snackBar.value.open("Um fornecedor bloqueado não pode gerar entradas no estoque.", 6000);
                    document.getElementById("codigoFornecedor").focus();
                    entradaEstoque.value.codigofornecedor = "";
                    return;
                }

                document.getElementById("codigoFornecedor").focus();

                snackBar.value.open("Fornecedor não encontrado, tente outro código ou faça uma busca.", 6000);
                
                return;
            }
            entradaEstoque.value.idFornecedor = response.data.data.idFornecedor;
            entradaEstoque.value.razaoFornecedor = response.data.data.razao;
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouFornecedor(fornecedor) {
    if (fornecedor == null) {
        return;
    }

    entradaEstoque.value.codigoFornecedor = fornecedor.codigo;
    entradaEstoque.value.razaoFornecedor = fornecedor.razao;
    getFornecedor();

    dialogBuscaFornecedor.value = false;

}

async function getProduto(campo) {

    let valor = "";

    if(campo == 'codigoBarras') {
        valor = entradaEstoque.value.codigoBarras;
        if(valor == "") {
            return;
        }
    } else
    {
        valor = entradaEstoque.value.codigo;
    }

    if(valor.trim() == '') {
        // entradaEstoque.value.idProduto = null;
        entradaEstoque.value.codigoBarras = '';
        entradaEstoque.value.descricao = '';
        entradaEstoque.value.unidade = '';
        entradaEstoque.value.quantidadeAtual = casasDecimaisStore.quantidadeProdutoZero;
        if(campo == 'codigo') {
            entradaEstoque.value.idProduto =  null;
        }
        calculaTotal();
        return;
    }

    if(campo == 'codigo') {
        valor = Converte.codigoProduto(valor);
    }

    entradaEstoque.value.idProduto = null;
    entradaEstoque.value.descricao = "";
    entradaEstoque.value.unidade = "";
    if(campo == "codigo") {
        entradaEstoque.value.codigoBarras = "";
    }
    else {
        entradaEstoque.value.codigo = "";
    }

    
    entradaEstoque.value.quantidadeAtual = casasDecimaisStore.produtoQuantidadeZero;
    

    let dados = new FormData();
    dados.append('codigoCodigoBarras', valor);
    dados.append('campo', campo);
    dados.append("codigoModulo", "02.01");

    try {
        let response = await Produto.getByCodigoCodigoBarras(dados);
        if (response.status == 200) {
            if(!response.data.data) {
                if(campo == 'codigo') {
                    document.getElementById("codigo").focus();
                }
                if(campo == 'codigoBarras') {
                    document.getElementById("codigoBarras").focus();
                }
                snackBar.value.open("Produto não encontrado, tente outro código ou faça uma busca.", 6000);
                calculaTotal();
                return;
            }

            if(response.data.data.ativoInativoBloqueado == 'I') {
                    snackBar.value.open("Produto inativo. Um produto inativo não pode gerar novas entradas no estoque.", 6000);
                    document.getElementById("codigo").focus();
                    calculaTotal();
                    return;
            }
            if(response.data.data.ativoInativoBloqueado == 'B') {

                snackBar.value.open("Produto bloqueado. Um produto bloqueado não pode gerar novas entradas no estoque.", 6000);
                document.getElementById("codigo").focus();
                calculaTotal();
                return;
            }

            entradaEstoque.value.idProduto = response.data.data.idProduto;
            entradaEstoque.value.codigo = response.data.data.codigo;
            entradaEstoque.value.descricao = response.data.data.descricao;
            entradaEstoque.value.codigoBarras = response.data.data.codigoBarras;

            entradaEstoque.value.unidade = response.data.data.unidade;
            entradaEstoque.value.quantidadeAtual = Converte.numberToQuantidadeProduto(response.data.data.quantidade);
            entradaEstoque.value.valorUnitario = Converte.numberToPCustoProduto(response.data.data.pCusto);
            calculaTotal();
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouProduto(produto) {
    if (produto == null) {
        return;
    }
    
    entradaEstoque.value.codigo = produto.codigo ;
    getProduto('codigo');

    dialogBuscaProduto.value = false;
    
}

function cadastrouFornecedor(codigoFornecedor) {
    if (codigoFornecedor == null) {
        return;
    }

    entradaEstoque.value.codigoFornecedor = codigoFornecedor;
    getFornecedor();
}

function cadastrouProduto(codigoProduto) {
    if (codigoProduto == null) {
        return;
    }
    entradaEstoque.value.codigo = codigoProduto;
    getProduto('codigo');
}

function calculaTotal() {
    let quantidade = Converte.stringToFloat(entradaEstoque.value.quantidade);
    let valorUnitario = Converte.stringToFloat(entradaEstoque.value.valorUnitario);
    let total = quantidade * valorUnitario;

    entradaEstoque.value.valorTotal = Converte.numberToCurrency(total);
}


</script>

<template>
    <SnackBar ref="snackBar"></SnackBar>
    <v-card elevation="16">
        <form ref="formEntradaEstoque" @submit.prevent="submit">
            <v-card-title>
                <h2>Entrada no Estoque</h2>
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col cols="2">
                        <CodigoProduto
                            label="Código do Produto"
                            id="codigo"
                            type="text"
                            v-model="entradaEstoque.codigo"
                            :error-messages="errors['entradaEstoque.codigo']"
                            maxlength="15"
                            :ref="inputCodigo"
                            @blur="getProduto('codigo')"
                        />
                    </v-col>
                    <v-col cols="2">
                        <v-text-field
                            label="Código de Barras"
                            id="codigoBarras"
                            type="text"
                            ref="inputCodigoBarras"
                            v-model="entradaEstoque.codigoBarras"
                            :error-messages="errors['entradaEstoque.codigoBarras']"
                            maxlength="14"
                            @blur="getProduto('codigoBarras')"
                        />
                    </v-col>
                    <v-col cols="8">
                        <v-text-field label="Descrição do Produto" id="descricao" type="text" v-model="entradaEstoque.descricao" readonly tabindex="-1">
                            <template v-slot:append>
                                <v-tooltip text="Faz a busca de um produto pela descrição, referência.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogBuscaProduto = true" id="btnBuscaProduto">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogBuscaProduto" transition="dialog-top-transition">
                                    <ProdutoBusca @selecionouProduto="selecionouProduto" @close-dialog="dialogBuscaProduto = false;" />
                                </v-dialog>
                                &nbsp;
                                <v-tooltip text="Cadastra um novo produto.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogProduto = true" id="btnNovoProduto">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogProduto" transition="dialog-top-transition">
                                    <ProdutoCreate @cadastrouProduto="cadastrouProduto" @close-dialog="dialogProduto = false" />
                                </v-dialog>

                            </template>                    
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="2">
                        <v-text-field label="Unid." id="unidade" type="text" v-model="entradaEstoque.unidade" readonly tabindex="-1">
                    
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Quantidade Atual" id="quantidadeAtual" 
                            type="text" v-model="entradaEstoque.quantidadeAtual" 
                            tipo="quantidadeProduto" readonly tabindex="-1">
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <Quantidade
                            label="Quantidade"
                            id="quantidade"
                            type="text"
                            v-model="entradaEstoque.quantidade"
                            :error-messages="errors['entradaEstoque.quantidade']"
                            tipo="quantidadeProduto"
                            maxlength="18"
                            @blur="calculaTotal();"
                        />
                    </v-col>
                    <v-col cols="4">
                        <Moeda label="Valor Unitário" id="valorUnitario" type="text" 
                                v-model="entradaEstoque.valorUnitario" 
                                tipo="pCustoProduto"
                                maxlength="18"
                                @blur="calculaTotal();" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <Moeda label="Valor Total" id="valorTotal" type="text" v-model="entradaEstoque.valorTotal" maxlength="18" readonly tabindex="-1" />
                    </v-col>
                    <v-col cols="4">
                        <Data
                            label="Data"
                            id="data"
                            type="text"
                            v-model="entradaEstoque.data"
                            :error-messages="errors['entradaEstoque.data']"
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field label="Documento" id="documento" type="text" v-model="entradaEstoque.documento" maxlength="10" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="2">
                        <v-text-field
                            label="Cód.Fornecedor"
                            id="codigoFornecedor"
                            type="text"
                            v-model="entradaEstoque.codigoFornecedor"
                            :error-messages="errors['entradaEstoque.codigoFornecedor']"
                            maxlength="20"
                            @blur="getFornecedor()"
                        />
                    </v-col>
                    <v-col cols="10">
                        <v-text-field label="Razão Social" id="razaoFornecedor" type="text" v-model="entradaEstoque.razaoFornecedor" readonly tabindex="-1">
                            <template v-slot:append>
                                <v-tooltip text="Faz a busca de um fornecedor pela razão social, nome fantasia ou CNPJ/CPF.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogBuscaFornecedor = true" id="btnBuscaFornecedor">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogBuscaFornecedor" transition="dialog-top-transition">
                                    <FornecedorBusca @selecionouFornecedor="selecionouFornecedor" @close-dialog="dialogBuscaFornecedor = false" />
                                </v-dialog>
                                &nbsp;
                                <v-tooltip text="Cadastra um novo fornecedor.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogFornecedor = true" id="btnNovoFornecedor">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogFornecedor" transition="dialog-top-transition">
                                    <FornecedorCreate @cadastrouFornecedor="cadastrouFornecedor" @close-dialog="dialogFornecedor = false" />
                                </v-dialog>

                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-checkbox
                            v-model="entradaEstoque.gerarLancamentoCaixa"
                            label="Gerar débito no caixa"
                            id="gerarLancamentoCaixa"
                        ></v-checkbox>
                    </v-col>
                </v-row>

            </v-card-text>
            <v-card-actions>
                <v-btn v-show="showBtnGravar" color="primary" variant="elevated" type="submit" id="btnEntradaEstoqueGravar">
                    Salvar
                </v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>