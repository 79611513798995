import Http from './http';

export class LancamentoConta {

    static get = function(start, limit, idConta, dataInicial, dataFinal) {
        return Http.get('/Serv/LancamentosContas/LancamentosContas.php', {
            params: {
                'operacao': 'listar',
                'start':start,
                'limit':limit,
                'idConta':idConta,
                'dataInicial':dataInicial,
                'dataFinal':dataFinal,
            }
        });
    };

    static save = function(dados) {
        return Http.post('/Serv/LancamentosContas/LancamentosContas.php?operacao=gravar', dados);
    };

    static excluir = function(dados) {
        return Http.post('/Serv/LancamentosContas/LancamentosContas.php?operacao=excluir', dados);
    };

    static transferenciaContas = function(dados) {
        return Http.post('/Serv/LancamentosContas/LancamentosContas.php?operacao=transferenciacontas', dados);
    };

    static transferenciaCaixa = function(dados) {
        return Http.post('/Serv/LancamentosContas/LancamentosContas.php?operacao=transferenciacaixa', dados);
    };


}

