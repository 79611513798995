import config from '../services/config';
import { defineStore } from 'pinia';

export const useChequeEmitido = defineStore('chequeEmitido', {
    state: () => {
        return {
            start:0,
            limit: config.maxPerPage,
            currentPage: 1,
            abertos: true,
            cancelados: true,
            baixados:true,
            voltaram:true,
            dataInicial: new Date().toLocaleDateString("pt-BR"), 
            dataFinal: new Date().toLocaleDateString("pt-BR"), 
            idFornecedor:null,
            sort: [{
                'property':'dataEmissao',
                'direction':'ASC'
            }],
            filtrarPor:'bomPara',
            codigo:'',
            numero:'',
            codigoFornecedor:'',
            razaoFornecedor:'',
            tipoFiltro: 'geral',
        }
    },
    actions : {
        setStart(start) {
            this.start = start;
        },
        setCurrentPage(currentPage) {
            this.currentPage = currentPage;
        },
        setAbertos(abertos) {
            this.abertos = abertos;
        },
        setCancelados(cancelados) {
            this.cancelados = cancelados;
        },
        setBaixados(baixados) {
            this.baixados = baixados;
        },
        setVoltaram(voltaram) {
            this.voltaram = voltaram;
        },
        setDataInicial(dataInicial) {
            this.dataInicial = dataInicial;
        },
        setDataFinal(dataFinal) {
            this.dataFinal = dataFinal;
        },
        setIdFornecedor(idFornecedor) {
            this.idFornecedor = idFornecedor;
        },
        setSort(sort) {
            this.sort = sort;
        },        
        setFiltrarPor(filtrarPor) {
            this.filtrarPor = filtrarPor;
        },
        setCodigo (codigo) {
            this.codigo = codigo;
        },
        setCodigoFornecedor(codigoFornecedor) {
            this.codigoFornecedor = codigoFornecedor;
        },
        setRazaoFornecedor(razaoFornecedor) {
            this.razaoFornecedor = razaoFornecedor
        },
        setTipoFiltro(tipoFiltro) {
            this.tipoFiltro = tipoFiltro;
        },
        setNumero(numero) {
            this.numero = numero;
        }
    }
});
