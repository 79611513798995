import config from '../services/config';
import { defineStore } from 'pinia';

export const usePedidosCompras = defineStore('pedidosCompras', {
    state: () => {
        return {
            start:0,
            limit: config.maxPerPage,
            currentPage: 1,
            abertos: true,
            cancelados: true,
            recebidos:true,
            dataInicial: new Date().toLocaleDateString("pt-BR"), 
            dataFinal: new Date().toLocaleDateString("pt-BR"), 
            idFornecedor:null,
            sort: [{
                'property':'dataEmissao',
                'direction':'ASC'
            }],
            filtrarPor:'data',
            codigo:'',
            documento:'',
            codigoFornecedor:'',
            razaoFornecedor:'',
            tipoFiltro: 'geral',
        }
    },
    actions : {
        setStart(start) {
            this.start = start;
        },
        setCurrentPage(currentPage) {
            this.currentPage = currentPage;
        },
        setAbertos(abertos) {
            this.abertos = abertos;
        },
        setCancelados(cancelados) {
            this.cancelados = cancelados;
        },
        setRecebidos(recebidos) {
            this.recebidos = recebidos;
        },
        setDataInicial(dataInicial) {
            this.dataInicial = dataInicial;
        },
        setDataFinal(dataFinal) {
            this.dataFinal = dataFinal;
        },
        setIdFornecedor(idFornecedor) {
            this.idFornecedor = idFornecedor;
        },
        setSort(sort) {
            this.sort = sort;
        },        
        setFiltrarPor(filtrarPor) {
            this.filtrarPor = filtrarPor;
        },
        setCodigo(codigo) {
            this.codigo = codigo;
        },
        setDocumento(documento) {
            this.documento = documento;
        },
        setCodigoFornecedor(codigoFornecedor) {
            this.codigoFornecedor = codigoFornecedor;
        },
        setRazaoFornecedor(razaoFornecedor) {
            this.razaoFornecedor = razaoFornecedor
        },
        setTipoFiltro(tipoFiltro) {
            this.tipoFiltro = tipoFiltro;
        }
    }
});
