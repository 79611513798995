<script setup>
import { ref, onMounted, inject, defineEmits, defineProps } from "vue";
import { useRoute } from "vue-router";
import { OrcamentoOrdemServicos } from "../../resources/orcamentoOrdemServicosResource";
import { Produto } from "../../resources/produtoResource";
import { useForm, useField } from "vee-validate";
import { string, addMethod, object } from "yup";
import { usePermissoes } from "../../store/permissoes";
import ProdutoCreate from "../Produtos/ProdutoCreate.vue";
import ProdutoBusca from "../Produtos/ProdutoBusca.vue";
import { useCasasDecimais } from "../../store/casasDecimais";
import Moeda from "../Utils/moeda.vue";
import CodigoProduto from "../Utils/codigoProduto.vue";
import Quantidade from "../Utils/quantidade.vue";
import { Converte } from "../../utils/converte";

const props = defineProps({
    produtoOrcamentoOrdemServicos: {
        type: Object,
    },
});

const emit = defineEmits(['close-dialog', 'alterou-produto']);
const route = useRoute();
const dialogProduto = ref(false);
const dialogBuscaProduto = ref(false);
let showBtnGravar = ref(true);
const casasDecimaisStore = useCasasDecimais();

const snackBarApp = inject("snackBarApp");

const permissoesStore = usePermissoes();


addMethod(string, "quantidade_maior_zero", function quantidadeMaiorZero(message) {
    return string().test(
        "quantidade-maior_zero", // Name
        message, // Msg
        function (quantidade) {
            if (Converte.stringToFloat(quantidade) > 0) {
                return true;
            }
            return false;
        }
    );
});

const validationSchema = object({
    updateProdutoOrcamentoOrdemServicos: object({
        idProdutoOrcamentoOS: undefined,
        idOrcamentoOrdemServicos: undefined,
        idProduto: undefined,
        codigoProduto: string().required("O código do produto é obrigatório").label("O código do produto"),
        codigoBarras: undefined,
        descricaoProduto: undefined,
        unidadeProduto: undefined,
        quantidadeAtual: undefined,
        quantidade: string().quantidade_maior_zero("A quantidade deve ser maior que 0."),
        valorUnitario: string().required("O valor unitário é obrigatório").label("O valor unitário"),
        valorTotal: string().required("O valor total é obrigatório").label("O valor total"),
    }).required(),
});
const { handleSubmit, errors } = useForm({ validationSchema });

const updateProdutoOrcamentoOrdemServicos = ref({
    idProdutoOrcamentoOS: useField("updateProdutoOrcamentoOrdemServicos.idProdutoOrcamentoOS").value,
    idOrcamentoOrdemServicos: useField("updateProdutoOrcamentoOrdemServicos.idOrcamentoOrdemServicos").value,
    idProduto: useField("updateProdutoOrcamentoOrdemServicos.idProduto").value,
    codigoProduto: useField("updateProdutoOrcamentoOrdemServicos.codigoProduto").value,
    codigoBarras: useField("updateProdutoOrcamentoOrdemServicos.codigoBarras").value,
    descricaoProduto: useField("updateProdutoOrcamentoOrdemServicos.descricaoProduto").value,
    unidadeProduto: useField("updateProdutoOrcamentoOrdemServicos.unidadeProduto").value,
    quantidadeAtual: useField("updateProdutoOrcamentoOrdemServicos.quantidadeAtual").value,
    quantidade: useField("updateProdutoOrcamentoOrdemServicos.quantidade").value,
    valorUnitario: useField("updateProdutoOrcamentoOrdemServicos.valorUnitario").value,
    valorTotal: useField("updateProdutoOrcamentoOrdemServicos.valorTotal").value,
});

const submitUpdateProduto = handleSubmit((values, actions) => {
    updateProduto(values, actions);
});

onMounted(() => {
    cleanForm();

    updateProdutoOrcamentoOrdemServicos.value.idProdutoOrcamentoOS = props.produtoOrcamentoOrdemServicos.idProdutoOrcamentoOS;
    updateProdutoOrcamentoOrdemServicos.value.idProduto = props.produtoOrcamentoOrdemServicos.idProduto;
    updateProdutoOrcamentoOrdemServicos.value.codigoProduto = props.produtoOrcamentoOrdemServicos.codigoProduto;
    updateProdutoOrcamentoOrdemServicos.value.descricaoProduto = props.produtoOrcamentoOrdemServicos.descricaoProduto;
    updateProdutoOrcamentoOrdemServicos.value.unidadeProduto = props.produtoOrcamentoOrdemServicos.unidadeProduto;
    updateProdutoOrcamentoOrdemServicos.value.quantidade = Converte.numberToQuantidadeProduto(props.produtoOrcamentoOrdemServicos.quantidade);
    updateProdutoOrcamentoOrdemServicos.value.valorUnitario = Converte.numberToPCustoProduto(props.produtoOrcamentoOrdemServicos.valorUnitario);

    getProduto('codigo', true);

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    updateProdutoOrcamentoOrdemServicos.value.idProdutoOrcamentoOS = null;
    updateProdutoOrcamentoOrdemServicos.value.idOrcamentoOrdemServicos = route.params.id;
    updateProdutoOrcamentoOrdemServicos.value.idProduto = null;
    updateProdutoOrcamentoOrdemServicos.value.codigoProduto = "";
    updateProdutoOrcamentoOrdemServicos.value.codigoBarras = "";
    updateProdutoOrcamentoOrdemServicos.value.descricaoProduto = "";
    updateProdutoOrcamentoOrdemServicos.value.unidadeProduto = "";
    updateProdutoOrcamentoOrdemServicos.value.quantidadeAtual = casasDecimaisStore.quantidadeProdutoZero;
    updateProdutoOrcamentoOrdemServicos.value.quantidade = casasDecimaisStore.quantidadeProdutoUm;
    updateProdutoOrcamentoOrdemServicos.value.valorUnitario = "R$ 0,00";
    updateProdutoOrcamentoOrdemServicos.value.valorTotal = "R$ 0,00";
}
async function updateProduto(values) {
    let dados = new FormData();
    dados.append("dados", JSON.stringify(values.updateProdutoOrcamentoOrdemServicos));

    try {
        const response = await OrcamentoOrdemServicos.updateProduto(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("O produto não pode ser alterado no orçamento de ordem de serviços, mensagem: " + response.data.message, 6000);
                return;
            }

            snackBarApp.value.open("Produto alterado com sucesso no orçamento de ordem de serviços.", 1500);
            emit('alterou-produto');

        }
    } catch (err) {
        console.log(err);
    }
}

async function getProduto(campo, mounted = false) {

    let valor = "";

    if (campo == 'codigoBarras') {
        valor = updateProdutoOrcamentoOrdemServicos.value.codigoBarras;
        if (valor == "") {
            return;
        }
    } else {
        valor = updateProdutoOrcamentoOrdemServicos.value.codigoProduto;
    }

    if (valor == null || valor.trim() == '') {

        updateProdutoOrcamentoOrdemServicos.value.codigoBarras = '';
        updateProdutoOrcamentoOrdemServicos.value.descricaoProduto = '';
        updateProdutoOrcamentoOrdemServicos.value.unidadeProduto = '';
        updateProdutoOrcamentoOrdemServicos.value.quantidadeAtual = casasDecimaisStore.quantidadeProdutoZero;
        if (campo == 'codigo') {
            updateProdutoOrcamentoOrdemServicos.value.idProduto = null;
        }
        calculaTotal();
        return;
    }

    if (campo == 'codigo') {
        valor = Converte.codigoProduto(valor);
    }

    updateProdutoOrcamentoOrdemServicos.value.idProduto = null;
    updateProdutoOrcamentoOrdemServicos.value.descricaoProduto = "";
    updateProdutoOrcamentoOrdemServicos.value.unidadeProduto = "";
    if (campo == "codigo") {
        updateProdutoOrcamentoOrdemServicos.value.codigoBarras = "";
    }
    else {
        updateProdutoOrcamentoOrdemServicos.value.codigoProduto = "";
    }


    updateProdutoOrcamentoOrdemServicos.value.quantidadeAtual = casasDecimaisStore.produtoQuantidadeZero;

    let dados = new FormData();
    dados.append('codigoCodigoBarras', valor);
    dados.append('campo', campo);
    dados.append("codigoModulo", "03.05");

    try {
        let response = await Produto.getByCodigoCodigoBarras(dados);
        if (response.status == 200) {
            if (!response.data.data) {
                if (campo == 'codigo') {
                    document.getElementById("codigoProduto").focus();
                }
                if (campo == 'codigoBarras') {
                    document.getElementById("codigoBarras").focus();
                }
                snackBarApp.value.open("Produto não encontrado, tente outro código ou faça uma busca.", 6000);
                calculaTotal();
                return;
            }

            if (response.data.data.ativoInativoBloqueado == 'I') {
                snackBarApp.value.open("Produto inativo. O produto '" + (response.data.data.codigo).trim() + "/" + response.data.data.descricao +
                    "' está nativo, um produto inativo não pode ser incluído em orçamentos de ordens de serviços.", 6000);
                updateProdutoOrcamentoOrdemServicos.value.codigoProduto = '';
                document.getElementById("codigoProduto").focus();
                calculaTotal();
                return;
            }
            if (response.data.data.ativoInativoBloqueado == 'B') {
                snackBarApp.value.open("Produto bloqueado. O produto '" + (response.data.data.codigo).trim() + "/" + response.data.data.descricao +
                    "' está bloqueado, um produto bloqueado não pode ser incluído em orçamentos de ordens de serviços.", 6000);
                updateProdutoOrcamentoOrdemServicos.value.codigoProduto = '';
                document.getElementById("codigoProduto").focus();
                calculaTotal();
                return;
            }

            updateProdutoOrcamentoOrdemServicos.value.idProduto = response.data.data.idProduto;
            updateProdutoOrcamentoOrdemServicos.value.codigoProduto = response.data.data.codigo;
            updateProdutoOrcamentoOrdemServicos.value.descricaoProduto = response.data.data.descricao;
            updateProdutoOrcamentoOrdemServicos.value.codigoBarras = response.data.data.codigoBarras;

            updateProdutoOrcamentoOrdemServicos.value.unidadeProduto = response.data.data.unidade;
            updateProdutoOrcamentoOrdemServicos.value.quantidadeAtual = Converte.numberToQuantidadeProduto(response.data.data.quantidade);
            if (!mounted) {
                updateProdutoOrcamentoOrdemServicos.value.valorUnitario = Converte.numberToCurrency(response.data.data.pCusto);
            }

            calculaTotal();
        }
    } catch (err) {
        console.log(err);
    }
}

function selecionouProduto(produto) {
    if (produto == null) {
        return;
    }

    document.getElementById("quantidadeUpdate").focus();

    updateProdutoOrcamentoOrdemServicos.value.codigoProduto = produto.codigo;
    getProduto('codigo');

    dialogBuscaProduto.value = false;
}


function calculaTotal() {
    let quantidade = Converte.stringToFloat(updateProdutoOrcamentoOrdemServicos.value.quantidade);
    let valorUnitario = Converte.stringToFloat(updateProdutoOrcamentoOrdemServicos.value.valorUnitario);
    let total = quantidade * valorUnitario;
    updateProdutoOrcamentoOrdemServicos.value.valorTotal = Converte.numberToCurrency(total);
}

function cadastrouProduto(codigoProduto) {
    if (codigoProduto == null) {
        return;
    }
    updateProdutoOrcamentoOrdemServicos.value.codigoProduto = codigoProduto;
    getProduto('codigo');

}

function closeModalUpdateProdutoOrcamentoOrdemServicos() {
    emit('close-dialog');
}

</script>

<template>
    <v-card elevetion="16">
        <v-card-title>
            <h2>Alteração de Item do Orçamento de Ordem de Serviços</h2>
        </v-card-title>
        <form ref="formProdutoOrcamentoOrdemServicos" @submit.prevent="submitUpdateProduto">
            <v-card-text>
                <v-row>
                    <v-col cols="2">
                        <CodigoProduto label="Código do Produto" id="codigoProdutoUpdate" type="text"
                            v-model="updateProdutoOrcamentoOrdemServicos.codigoProduto"
                            :error-messages="errors['updateProdutoOrcamentoOrdemServicos.codigoProduto']" maxlength="15"
                            @blur="getProduto('codigo')" />
                    </v-col>
                    <v-col cols="2">
                        <v-text-field label="Código de Barras" id="codigoBarrasUpdate" type="text"
                            v-model="updateProdutoOrcamentoOrdemServicos.codigoBarras"
                            :error-messages="errors['updateProdutoOrcamentoOrdemServicos.codigoBarras']" maxlength="14"
                            @blur="getProduto('codigoBarras')" />
                    </v-col>
                    <v-col cols="8">
                        <v-text-field label="Descrição do Produto" id="descricaoProdutoUpdate" type="text"
                            v-model="updateProdutoOrcamentoOrdemServicos.descricaoProduto" readonly tabindex="-1">
                            <template v-slot:append>
                                <v-tooltip text="Faz a busca de um produto pela descrição, referência.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogBuscaProduto = true"
                                            id="btnBuscaProduto">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogBuscaProduto" transition="dialog-top-transition">
                                    <ProdutoBusca @selecionouProduto="selecionouProduto"
                                        @close-dialog="dialogBuscaProduto = false;" />
                                </v-dialog>
                                &nbsp;
                                <v-tooltip text="Cadastra um novo produto.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" color="primary" @click="dialogProduto = true"
                                            id="btnNovoProdutoUpdate">
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogProduto" transition="dialog-top-transition">
                                    <ProdutoCreate @cadastrouProduto="cadastrouProduto"
                                        @close-dialog="dialogProduto = false" />
                                </v-dialog>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-text-field label="Unid." id="unidadeUpdate" type="text"
                            v-model="updateProdutoOrcamentoOrdemServicos.unidadeProduto" readonly tabindex="-1">
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Quantidade Atual" id="quantidadeAtualUpdate" type="text"
                            v-model="updateProdutoOrcamentoOrdemServicos.quantidadeAtual" tipo="quantidadeProduto" readonly
                            tabindex="-1">
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <Quantidade label="Quantidade" id="quantidadeUpdate" type="text"
                            v-model="updateProdutoOrcamentoOrdemServicos.quantidade" tipo="quantidadeProduto"
                            :error-messages="errors['updateProdutoOrcamentoOrdemServicos.quantidade']" maxlength="15"
                            @blur="calculaTotal()" />
                    </v-col>
                    <v-col cols="3">
                        <Moeda label="Valor Unitário" id="valorUnitarioUpdate" type="text"
                            v-model="updateProdutoOrcamentoOrdemServicos.valorUnitario"
                            :error-messages="errors['updateProdutoOrcamentoOrdemServicos.valorUnitario']" maxlength="15"
                            @blur="calculaTotal()" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <Moeda label="Valor Total" id="valorTotalUpdate" type="text"
                            v-model="updateProdutoOrcamentoOrdemServicos.valorTotal"
                            :error-messages="errors['updateProdutoOrcamentoOrdemServicos.valorTotal']" maxlength="15" readonly
                            tabindex="-1" />
                    </v-col>
                </v-row>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" v-show="showBtnGravar" variant="elevated" type="submit"
                    id="btnUpdateProdutoGravar">Alterar Produto</v-btn>
                
                <v-btn color="primary" variant="elevated"
                    @click.prevent='closeModalUpdateProdutoOrcamentoOrdemServicos'>Fechar</v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>
