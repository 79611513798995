<script setup>
    import { ref, onMounted, defineEmits } from 'vue';
    import { useRoute } from 'vue-router';
    import {GrupoContasPagar} from '../../resources/grupoContasPagarResource';
    import { useField, useForm } from 'vee-validate'
    import {object, string} from 'yup';
    import SnackBar from '../Utils/SnackBar';
    import { usePermissoes } from '../../store/permissoes';
    
    const emit = defineEmits(['close-dialog', 'cadastrou-grupo-contas-pagar']);
    const snackBar = ref(null);
    const route = useRoute();
    let  showBtnGravar = ref(true);
    const permissoesStore = usePermissoes();

    const validationSchema  = object({
        grupoContasPagar: object({
            idGrupoContasPagar: undefined,
            descricao: string().required('A descrição é obrigatória').max(50)
        }).required()
    });
    const { handleSubmit, errors, setValues } = useForm({validationSchema});

    const grupoContasPagar =  ref({ 
        idGrupoContasPagar: useField('grupoContasPagar.idGrupoContasPagar').value,
        descricao: useField('grupoContasPagar.descricao').value 
    });

    const submit = handleSubmit((values, actions) => {
        gravar(values, actions);
    });

    onMounted(() => {
        if(route.name == 'GrupoContasPagar.Update') {
            getGrupoContasPagar(route.params.id);
        }
        else {
            grupoContasPagar.value.idGrupoContasPagar = null;

        }


        let tipo = permissoesStore.tipo;
        let validade = permissoesStore.validade;

        if((tipo == 'D' || tipo == 'P') && validade == 'N') {
            showBtnGravar.value = false;
        }
    });

    async function gravar(values, actions) {
        let dados = new FormData();
        dados.append('data', JSON.stringify(grupoContasPagar.value));

        try {
            const response = await GrupoContasPagar.save(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBar.value.open('Grupo de contas a pagar não pode ser cadastrado, mensagem de erro: '+ response.data.message, 6000);
                    return;
                }
                if(route.name == 'GrupoContasPagar.Update') {
                    snackBar.value.open('Grupo de contas a pagar alterado com sucesso.');
                }
                else{
                    snackBar.value.open('Novo grupo de contas a pagar cadastrado com sucesso.');
                    actions.resetForm();
                    grupoContasPagar.value.idGrupoContasPagar = null;
                    document.getElementById("descricao").focus();
                    emit('cadastrou-grupo-contas-pagar');
                }
            }
        }  catch(err) {
            snackBar.value.open('Erro, mensagem de erro: '+ err);
        }
    }
    function closeModalCadastroGrupoContasPagar() {
        emit('close-dialog');
    }
    async function getGrupoContasPagar(id) {
        let dados = new FormData();
        dados.append('codigoModulo', '01.15.02');
        dados.append('idGrupoContasPagar', id);
        try {
            const response = await GrupoContasPagar.getById(dados);
            if (response.status == 200) {
                setValues({'grupoContasPagar': response.data.data});
                document.getElementById("descricao").focus();
            }
        } catch(err) {
            console.log(err);
        }
    }

</script>


<template>
    <SnackBar ref="snackBar"></SnackBar>
        <v-card elevation="16">
            <v-card-title>
            <h2 v-show="$route.name !=='GrupoContasPagar.Update'">Novo Grupo de Contas a Pagar</h2>
                <h2 v-show="$route.name =='GrupoContasPagar.Update'">Alteração de Grupo de Contas a Pagar</h2>          
            </v-card-title>
            
            <form ref="formGrupoContasPagar" @submit.prevent="submit">
            <v-card-text>
            <v-row>
                <v-col>
                    <v-text-field label="Descrição"
                            id="descricao"
                            type="text"
                            maxlength="50"
                            v-model='grupoContasPagar.descricao'
                            :error-messages="errors['grupoContasPagar.descricao']"/>
                </v-col>
            </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnGrupoContasPagarGravar" >Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" 
                    variant="elevated" 
                    @click.prevent='$router.go(-1)' 
                    v-if="showBtnGravar" v-show="$route.name =='GrupoContasPagar.Create' || $route.name == 'GrupoContasPagar.Update' ">Voltar</v-btn>
                <v-btn color="primary" variant="elevated" v-if="showBtnGravar" 
                    v-show="$route.name !=='GrupoContasPagar.Create' && $route.name !== 'GrupoContasPagar.Update' " 
                    @click.prevent='closeModalCadastroGrupoContasPagar()'>Fechar</v-btn>
            </v-card-actions>
            </form>
        </v-card>
</template>
