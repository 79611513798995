<script setup>
    import { ref, onMounted } from 'vue';
    import {ConfiguracoesOrdensServicos} from '../../resources/configuracoesOrdensServicosResource';
    import { useField, useForm } from 'vee-validate'
    import {object, string} from 'yup';
    import SnackBar from '../Utils/SnackBar';
    import { useConfiguracoesOrdensServicos } from '../../store/configuracoesOrdensServicos';
    
    const snackBar = ref(null);
        const configuracoesOrdensServicosStore = useConfiguracoesOrdensServicos();

    const validationSchema  = object({
        configuracoesOrdensServicos: object({
            labelCampo1: string().max(15),
            labelCampo2: string().max(15),
            labelCampo3: string().max(15),
            labelCampo4: string().max(15),
            labelCampo5: string().max(15),
        }).required()
    });
    const { handleSubmit, errors, setValues } = useForm({validationSchema});

    const configuracoesOrdensServicos =  ref({ 
        labelCampo1: useField('configuracoesOrdensServicos.labelCampo1').value,
        labelCampo2: useField('configuracoesOrdensServicos.labelCampo2').value,
        labelCampo3: useField('configuracoesOrdensServicos.labelCampo3').value,
        labelCampo4: useField('configuracoesOrdensServicos.labelCampo4').value,
        labelCampo5: useField('configuracoesOrdensServicos.labelCampo5').value,
    });

    const submit = handleSubmit(() => {
        gravar();
    });

    onMounted(() => {
        getConfiguracoesOrdensServicos();
    });
    async function gravar() {
        let dados = new FormData();
        dados.append('data', JSON.stringify(configuracoesOrdensServicos.value));

        try {
            const response = await ConfiguracoesOrdensServicos.save(dados);

            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBar.value.open('Não foi possível alterar as configurações de ordens de serviços, mensagem de erro: '+ 
                        response.data.message, 6000);
                    return;
                }
                
                configuracoesOrdensServicosStore.setLabelCampo1(configuracoesOrdensServicos.value.labelCampo1);
                configuracoesOrdensServicosStore.setLabelCampo2(configuracoesOrdensServicos.value.labelCampo2);
                configuracoesOrdensServicosStore.setLabelCampo3(configuracoesOrdensServicos.value.labelCampo3);
                configuracoesOrdensServicosStore.setLabelCampo4(configuracoesOrdensServicos.value.labelCampo4);
                configuracoesOrdensServicosStore.setLabelCampo5(configuracoesOrdensServicos.value.labelCampo5);
                ConfiguracoesOrdensServicos.inicializa();

                snackBar.value.open('Configurações de ordens de serviços alteradas com sucesso.');
            }
        }  catch(err) {
            snackBar.value.open('Erro, mensagem de erro: '+ err);
        }
    }
    async function getConfiguracoesOrdensServicos() {
        try {
            const response = await ConfiguracoesOrdensServicos.get();
            if (response.status == 200) {
                setValues({'configuracoesOrdensServicos': response.data.data});

                document.getElementById("labelCampo1").focus();
            }
        } catch(err) {
            console.log(err);
        }
    }

</script>


<template>
    <SnackBar ref="snackBar"></SnackBar>
    <v-container>
        <v-card class="align-center" elevation="16">
            <v-card-title>
            <h2>Configurações de Ordens de Serviços</h2>
            </v-card-title>
            
            <form ref="formConfiguracoesOrdensServicos" @submit.prevent="submit">
            <v-card-text>
            <v-row>
                <v-col cols="6">
                    <v-text-field label="Título do 1º campo configurável da ordem de serviços"
                            id="labelCampo1"
                            type="text"
                            maxlength="15"
                            v-model='configuracoesOrdensServicos.labelCampo1'
                            :error-messages="errors['configuracoesOrdensServicos.labelCampo1']"/>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Título do 2º campo configurável da ordem de serviços"
                            id="labelCampo2"
                            type="text"
                            maxlength="15"
                            v-model='configuracoesOrdensServicos.labelCampo2'
                            :error-messages="errors['configuracoesOrdensServicos.labelCampo2']"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <v-text-field label="Título do 3º campo configurável da ordem de serviços"
                            id="labelCampo3"
                            type="text"
                            maxlength="15"
                            v-model='configuracoesOrdensServicos.labelCampo3'
                            :error-messages="errors['configuracoesOrdensServicos.labelCampo3']"/>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Título do 4º campo configurável da ordem de serviços"
                            id="labelCampo4"
                            type="text"
                            maxlength="15"
                            v-model='configuracoesOrdensServicos.labelCampo4'
                            :error-messages="errors['configuracoesOrdensServicos.labelCampo4']"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <v-text-field label="Título do 5º campo configurável da ordem de serviços"
                            id="labelCampo5"
                            type="text"
                            maxlength="15"
                            v-model='configuracoesOrdensServicos.labelCampo5'
                            :error-messages="errors['configuracoesOrdensServicos.labelCampo5']"/>
                </v-col>
            </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnConfiguracoesOrdensServicos">Salvar</v-btn>
            </v-card-actions>
            </form>
        </v-card>
    </v-container>
</template>


<!-- <template>
    <div class="container-fluid">
        <h2>Configurações de Ordens de Serviços</h2>

        <div class="panel panel-default">
            <div class="panel-body">

                <form data-vv-scope="cadastroConfiguracoesOrdensServicos">

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" :class="[errors.has('cadastroConfiguracoesOrdensServicos.labelCampo1') ? 'has-error' : '']">
                                <label class="control-label" for="labelCampo1">Título do 1º campo configurável da ordem de serviços</label>
                                <input type="text"
                                       v-validate="'required|max:15'"
                                       data-vv-as="campo 1"
                                       class="form-control"
                                       id="labelCampo1"
                                       name="labelCampo1"
                                       v-model='configuracoesOrdensServicos.labelCampo1'>
                                <span class="help-block"  v-show="errors.has('cadastroConfiguracoesOrdensServicos.labelCampo1')">
                             {{ errors.first('cadastroConfiguracoesOrdensServicos.labelCampo1') }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" :class="[errors.has('cadastroConfiguracoesOrdensServicos.labelCampo2') ? 'has-error' : '']">
                                <label class="control-label" for="labelCampo2">Título do 2º campo configurável da ordem de serviços</label>
                                <input type="text"
                                       v-validate="'required|max:15'"
                                       data-vv-as="campo 2"
                                       class="form-control"
                                       id="labelCampo2"
                                       name="labelCampo2"
                                       v-model='configuracoesOrdensServicos.labelCampo2'>
                                <span class="help-block"  v-show="errors.has('cadastroConfiguracoesOrdensServicos.labelCampo2')">
                             {{ errors.first('cadastroConfiguracoesOrdensServicos.labelCampo2') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" :class="[errors.has('cadastroConfiguracoesOrdensServicos.labelCampo3') ? 'has-error' : '']">
                                <label class="control-label" for="labelCampo3">Título do 3º campo configurável da ordem de serviços</label>
                                <input type="text"
                                       v-validate="'required|max:15'"
                                       data-vv-as="campo 3"
                                       class="form-control"
                                       id="labelCampo3"
                                       name="labelCampo3"
                                       v-model='configuracoesOrdensServicos.labelCampo3'>
                                <span class="help-block"  v-show="errors.has('cadastroConfiguracoesOrdensServicos.labelCampo3')">
                             {{ errors.first('cadastroConfiguracoesOrdensServicos.labelCampo3') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" :class="[errors.has('cadastroConfiguracoesOrdensServicos.labelCampo4') ? 'has-error' : '']">
                                <label class="control-label" for="labelCampo4">Título do 4º campo configurável da ordem de serviços</label>
                                <input type="text"
                                       v-validate="'required|max:15'"
                                       data-vv-as="campo 4"
                                       class="form-control"
                                       id="labelCampo4"
                                       name="labelCampo4"
                                       v-model='configuracoesOrdensServicos.labelCampo4'>
                                <span class="help-block"  v-show="errors.has('cadastroConfiguracoesOrdensServicos.labelCampo4')">
                             {{ errors.first('cadastroConfiguracoesOrdensServicos.labelCampo4') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" :class="[errors.has('cadastroConfiguracoesOrdensServicos.labelCampo5') ? 'has-error' : '']">
                                <label class="control-label" for="labelCampo5">Título do 5º campo configurável da ordem de serviços</label>
                                <input type="text"
                                       v-validate="'required|max:15'"
                                       data-vv-as="campo 5"
                                       class="form-control"
                                       id="labelCampo5"
                                       name="labelCampo5"
                                       v-model='configuracoesOrdensServicos.labelCampo5'>
                                <span class="help-block"  v-show="errors.has('cadastroConfiguracoesOrdensServicos.labelCampo5')">
                             {{ errors.first('cadastroConfiguracoesOrdensServicos.labelCampo5') }}</span>
                            </div>
                        </div>
                    </div>
                    <p></p>

                    <div class="row">
                        <div class="col-md-2">
                            <a class="btn btn-primary" href="#" @click.prevent="submit()">Salvar</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import {ConfiguracoesOrdensServicos} from '../../resources/configuracoesOrdensServicosResource';

    export default {
        data() {
            return {
                configuracoesOrdensServicos:   {
                    'labelCampo1': '',
                    'labelCampo2': '',
                    'labelCampo3': '',
                    'labelCampo4': '',
                    'labelCampo5': '',
                },
            }
        },
        mounted() {
            this.getConfiguracoesOrdensServicos();
        },
        methods: {
            submit() {
                let self = this;

                this.$validator.validateAll('cadastroConfiguracoesOrdensServicos').then((result) => {
                    if (result) {

                        let dados = new FormData();
                        dados.append('data', JSON.stringify(self.configuracoesOrdensServicos));

                        ConfiguracoesOrdensServicos.save(dados)
                            .then(function (response) {
                                if (response.status == 200) {
                                    if (response.data.success == false) {
                                        self.$notify({
                                            title: 'Erro',
                                            message: 'As configurações de ordens de serviços não puderam ser alteradas, mensagem: '+
                                            response.data.message,
                                            type: 'error',
                                            duration: 6000
                                        });

                                        return;
                                    }

                                    self.$notify({
                                        title: 'Config.Ordens de Serviços',
                                        message: 'Configurações de ordens de serviços alteradas com sucesso.',
                                        type: 'success'
                                    });

                                    ConfiguracoesOrdensServicos.inicializa();
                                }
                            })
                            .catch(function () {
                            });
                    }
                    else {
                        self.$notify({
                            title:'Erro',
                            message: 'Não foi possível alterar as configurações de ordens de serviços, corrija os erros do '+
                            'formulário e tente novamente.',
                            type: 'error'
                        });
                        return;

                    }
                });
            },
            getConfiguracoesOrdensServicos() {
                let self = this;
                ConfiguracoesOrdensServicos.get()
                    .then(function (response) {
                        if (response.status == 200) {

                            self.configuracoesOrdensServicos = response.data.data;

                            document.getElementById("labelCampo1").focus();
                        }
                    })
                    .catch(function () {
                    });
            }
        }
    }

</script> -->
