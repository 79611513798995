<script setup>
import { ref, onMounted, inject } from "vue";
import { Permissoes } from "@/resources/permissoesResource";
import { PermissaoService } from "../../services/permissaoService";
import { simNaoFormat } from "../../services/filtros";

const snackBarApp = inject("snackBarApp");
let permissoes = ref({});
const usuarios = ref([]);
const idUsuario = ref(null);

onMounted(() => {
    getUsuarios();
    filtrar();
});

async function filtrar() {
    try {
        const response = await Permissoes.get(idUsuario.value);
        permissoes.value = response.data.data;
    }
    catch(err) {
        console.log(err);
    }
}

async function getUsuarios() {
    try {
        let response = await Permissoes.getUsuarios();
        usuarios.value  = response.data.data;
        if(idUsuario.value == '' || idUsuario.value == null) {
             idUsuario.value = response.data.data[0].idUsuario;
             filtrar();
             return;
        }
    }
    catch(err) {
        snackBarApp.value.open('Erro ao buscar os usuários');
        console.log(err);
    }   
}

async function alteraPermissao(permissao) {
    let temPermissao = 'N';

    if(permissao.temPermissao == 'N') {
        temPermissao = 'S';
    }

    let idPermissao = permissao.idPermissaoUsuario;

    let dados = new FormData();
    dados.append('idPermissaoUsuario', idPermissao);
    dados.append('temPermissao', temPermissao);

    try {
        let response = await Permissoes.save(dados);
        if (response.status == 200) {
            snackBarApp.value.open('Permissão alterada com sucesso');
            filtrar();
        }

    }
    catch(err) {
        snackBarApp.value.open('Erro ao alterar a permissão');
        console.log(err);
    }
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}


</script>

<template>
    <v-row>
        <v-col>
            <h1>Define as Permissões dos Usuários</h1>
        </v-col>
    </v-row>

    <v-row >
        <v-col cols="12">
            <v-form>
                <v-row>
                    <v-col cols="12">
                        <v-select
                            :items="usuarios"
                            item-title="login"
                            item-value="idUsuario"
                            label="Usuário"
                            v-model="idUsuario"
                            @update:modelValue="filtrar()"
                        >
                        </v-select>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </v-row>
    
    <v-card elevation="16" class="mt-3">
        <v-table density="compact">
            <thead>
                <tr>
                    <th>Ações</th>
                    <th>Cód.Módulo</th>
                    <th>Descrição do Módulo</th>
                    <th>Tem Permissão</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="o in permissoes" :key="o">
                    <td> 
                        <v-tooltip text="Altera a Permissão.">
                            <template v-slot:activator="{ props }">
                                <v-btn v-show="temPermissao('05.07')" size="small" v-bind="props" variant="text" @click.prevent="alteraPermissao(o)" icon="mdi-pencil">
                                </v-btn>
                            </template>
                        </v-tooltip>
                    </td>
                    <td>{{ o.codigoModulo }}</td>
                    <td>{{ o.descricaoModulo }}</td>
                    <th>{{ simNaoFormat(o.temPermissao) }}</th>
                </tr>
            </tbody>
        </v-table>
    </v-card>

</template>