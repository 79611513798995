<script setup>
import { ref, onMounted, inject } from 'vue';
import { ConfigCabecalho } from '../../resources/configCabecalhoResource';
import { useField, useForm } from 'vee-validate'
import { string, object, number } from 'yup';
import config from '../../services/config';
// import { useRouter } from "vue-router";

const itemsTextoImagem = ref([
    { text: "Texto", value: "T" },
    { text: "Imagem", value: "I" },
]);

const snackBarApp = inject("snackBarApp");
// const router = useRouter();

const validationSchema = object({
    configCabecalho: object({
        textoImagem: undefined,
        urlImagem: undefined,
        larguraImagem: number().integer().min(0).typeError('deve ser um número').label('Largura da Imagem'),
        alturaImagem: number().integer().min(0).typeError('deve ser um número').label('Altura da Imagem'),
        linha1: string().max(80),
        linha2: string().max(80),
        linha3: string().max(80),
        linha4: string().max(80),
        imagem: undefined
    }).required()
});
const { handleSubmit, errors } = useForm({ validationSchema });

const configCabecalho = ref({
    textoImagem: useField('configCabecalho.textImagem').value,
    urlImagem: useField('configCabecalho.urlImagem').value,
    larguraImagem: useField('configCabecalho.larguraImagem').value,
    alturaImagem: useField('configCabecalho.alturaImagem').value,
    linha1: useField('configCabecalho.linha1').value,
    linha2: useField('configCabecalho.linha2').value,
    linha3: useField('configCabecalho.linha3').value,
    linha4: useField('configCabecalho.linha4').value,
    imagem: useField('configCabecalho.imagem').value
});

const submit = handleSubmit(() => {
    gravar();
});

onMounted(() => {
    getConfigCabecalho();
});

async function gravar() {

    let imagem = '';
    if (configCabecalho.value.textoImagem == 'I') {
        imagem = configCabecalho.value.imagem;
    }

    let dados = new FormData();
    dados.append('textoImagem', configCabecalho.value.textoImagem);
    dados.append('imagem', imagem);
    dados.append('larguraImagem', configCabecalho.value.larguraImagem);
    dados.append('alturaImagem', configCabecalho.value.alturaImagem);
    dados.append('linha1', configCabecalho.value.linha1);
    dados.append('linha2', configCabecalho.value.linha2);
    dados.append('linha3', configCabecalho.value.linha3);
    dados.append('linha4', configCabecalho.value.linha4);
    try {
        let response = await ConfigCabecalho.save(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open('As configurações do cabeçalho dos relatórios não puderam ser alteradas, mensagem: ' + response.data.mensagem, 6000);
                return;
            }

            snackBarApp.value.open('As configurações do cabeçalho dos relatórios foram alteradas com sucesso.');
        }

    }
    catch (err) {
        snackBarApp.value.open('Erro ao alterar as configurações do cabeçalho dos relatórios');
        console.log(err);
    }

}

async function getConfigCabecalho() {
    try {

        let response = await ConfigCabecalho.get();
        if (response.status == 200) {
            configCabecalho.value = response.data.data;

            configCabecalho.value.urlImagem = config.baseUrlImagem + configCabecalho.value.nomeImagem;
            document.getElementById("textoImagem").focus();
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar as configurações do cabeçalho dos relatórios');
        console.log(err);
    }
}

function processFile(file) {
    if (!file) {
      return;
    }
    createImage(file);
}

function createImage(file) {
    console.log(file);
    const reader = new FileReader();

    reader.onload = e => {
      configCabecalho.value.urlImagem = e.target.result;
    };
    reader.readAsDataURL(file.target.files[0]);
}
  
  
</script>


<template>
    <v-container>
        <v-card elevation="16">
            <v-card-title>
                <h2>Configurações do Cabeçalho dos Relatórios</h2>
            </v-card-title>

            <form ref="formConfigCabecalho" @submit.prevent="submit">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-select :items="itemsTextoImagem" item-title="text" item-value="value"
                                v-model="configCabecalho.textoImagem"
                                label="Deseja ter texto ou imagem no cabeçalho dos relatórios" id="textoImagem"
                                :error-messages="errors['configCabecalho.textoImagem']" />
                        </v-col>
                    </v-row>
                    <v-row v-show="configCabecalho.textoImagem == 'I'">
                        <v-col cols="2">
                            <v-file-input accept=".jpg, .jpeg" v-model="configCabecalho.imagem" label="Escolha a imagem"
                                id="imagem" @change="processFile">
                            </v-file-input>
                        </v-col>
                        <v-col cols="10">
                            <v-img :src="configCabecalho.urlImagem" height="80" width="300" />
                        </v-col>
                    </v-row>
                    <v-row v-show="configCabecalho.textoImagem == 'I'">
                        <v-col cols="6">
                            <v-text-field label="Largura da Imagem(mm)" id="larguraImagem" type="number"
                                v-model='configCabecalho.larguraImagem'
                                :error-messages="errors['configCabecalho.larguraImagem']" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Altura da Imagem(mm)" id="alturaImagem" type="number"
                                v-model='configCabecalho.alturaImagem'
                                :error-messages="errors['configCabecalho.alturaImagem']" />
                        </v-col>
                    </v-row>
                    <v-row v-show="configCabecalho.textoImagem == 'T'">
                        <v-col cols="12">
                            <v-text-field label="Digite nas linhas abaixo o cabeçalho dos relatórios" id="linha1"
                                type="text" v-model='configCabecalho.linha1'
                                :error-messages="errors['configCabecalho.linha1']" />
                        </v-col>
                    </v-row>
                    <v-row v-show="configCabecalho.textoImagem == 'T'">
                        <v-col cols="12">
                            <v-text-field id="linha2" type="text" v-model='configCabecalho.linha2'
                                :error-messages="errors['configCabecalho.linha2']" />
                        </v-col>
                    </v-row>
                    <v-row v-show="configCabecalho.textoImagem == 'T'">
                        <v-col cols="12">
                        <v-text-field id="linha3" type="text" v-model='configCabecalho.linha3'
                            :error-messages="errors['configCabecalho.linha3']" />
                        </v-col>    
                    </v-row>
                    <v-row v-show="configCabecalho.textoImagem == 'T'">
                        <v-col cols="12">
                            <v-text-field id="linha4" type="text" v-model='configCabecalho.linha4'
                                :error-messages="errors['configCabecalho.linha4']" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" variant="elevated" type="submit" id="btnConfigCabecalhoGravar">Salvar</v-btn>
                </v-card-actions>
            </form>
        </v-card>
    </v-container>
</template>