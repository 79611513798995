import { defineStore } from 'pinia';

export const usePermissoes = defineStore('permissoes', {
    state: () => {
        return {
            idUsuario: '',
            tipo: '',
            validade: 'N',
            dataValidade: '',
        }
    },        
    actions : {
        setIdUsuario(idUsuario) {
            this.idUsuario = idUsuario;
        },
        setTipo(tipo) {
            this.tipo = tipo;
        },
        setValidade(validade) {
            this.validade = validade;
        },
        setDataValidade(dataValidade) {
            this.dataValidade = dataValidade;
        },

    }
});