<script setup>
import { ref, onMounted, defineEmits, inject } from "vue";
import { useRoute } from "vue-router";
import { Usuario } from "../../resources/usuarioResource";
import { useField, useForm } from "vee-validate";
import { string, number, addMethod, object } from "yup";
import { usePermissoes } from "../../store/permissoes";

const isRealValidation = ref(null);
const isRealValidationLogin = ref(null);
const validationCodigo = ref(null);
const validationLogin = ref(null);
const snackBarApp = inject("snackBarApp");
const route = useRoute();
let showBtnGravar = ref(true);
const emit = defineEmits(["close-dialog", "cadastrou-usuario"]);
const isReadOnly = ref(false);
const inputCodigo = ref(null);

const permissoesStore = usePermissoes();
const itemsAtivoInativo = ref([
    { text: "Ativo", value: "A" },
    { text: "Inativo", value: "I" },
]);

addMethod(number, "codigo_usuario_existe", function codigoUsuarioExiste(message) {
    return number().test(
        "codigo-usuario-existe", // Name
        message, // Msg
        function (codigo_usuario) {
            if (isRealValidation.value == null) {
                return true;
            }
            if (!isRealValidation.value) {
                return validationCodigo.value;
            }
            isRealValidation.value = false;
            validationCodigo.value = false;

            return new Promise((resolve) => {
                Usuario.codigoExiste(codigo_usuario, usuario.value.idUsuario).then((res) => {
                    if (res.data == false) {
                        validationCodigo.value = true;
                        resolve(true);
                    }

                    resolve(false);
                });
            });
        }
    );
});


addMethod(string, "login_usuario_existe", function loginUsuarioExiste(message) {
    return string().test(
        "login-usuario-existe", // Name
        message, // Msg
        function (login_usuario) {
            if (isRealValidationLogin.value == null) {
                return true;
            }
            if (!isRealValidationLogin.value) {
                return validationLogin.value;
            }
            isRealValidationLogin.value = false;
            validationLogin.value = false;

            return new Promise((resolve) => {
                Usuario.loginExiste(login_usuario, usuario.value.idUsuario).then((res) => {
                    if (res.data == false) {
                        validationLogin.value = true;
                        resolve(true);
                    }

                    resolve(false);
                });
            });
        }
    );
});


const validationSchema = object({
    usuario: object({
        idUsuario: undefined,
        codigo: number()
            .min(1)
            .max(999999999)
            .codigo_usuario_existe("Esse código já está sendo usado por outro usuario, tente outro código.")
            .typeError("O código deve ser um número")
            .label("O código"),
        nome: string().required("A nome do usuário é obrigatório").max(50).label("O nome"),
        login: string()
            .login_usuario_existe("Esse login já está sendo usado por outro usuario, tente outro login.")
            .required("O login é obrigatório")
            .max(10)
            .label("Login"),
        senha: string().max(50).label("A senha"),
        repitaSenha: string().max(50).label("O campo repita a senha").test(
            "senha-igual",
            "As senhas não são iguais",
            (value) => value === usuario.value.senha
        ),
        ativoInativo: undefined,
        motivoInatividade: string().max(50).label("O motivo da inatividade"),
    }).required(),
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const usuario = ref({
    idUsuario: useField("usuario.idUsuario").value,
    codigo: useField("usuario.codigo").value,
    nome: useField("usuario.nome").value,
    login: useField("usuario.login").value,
    senha: useField("usuario.senha").value,
    repitaSenha: useField("usuario.repitaSenha").value,
    ativoInativo: useField("usuario.ativoInativo").value,
    motivoInatividade: useField("usuario.motivoInatividade").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    cleanForm();
    if (route.name == "Usuario.Update" || route.name=="Usuario.Show") {
        getUsuario(route.params.id);
    }

    if(route.name == "Usuario.Show"){
        isReadOnly.value = true;
    }

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    if (route.name !== "Usuario.Update" && route.name !== "Usuario.Show") {
        usuario.value.idUsuario = null;
        proximoCodigo();
    }
    usuario.value.nome = "";
    usuario.value.login = "";
    usuario.value.senha = "";
    usuario.value.repitaSenha = "";
    usuario.value.ativoInativo = "A";
    usuario.value.motivoInatividade = "";
}

async function gravar(values, actions) {
    let dados = new FormData();
    dados.append("data", JSON.stringify(values.usuario));

    try {
        const response = await Usuario.save(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("O usuário não pode ser cadastrado, mensagem de erro: " + response.data.message, 6000);
                return;
            }
            if (route.name == "Usuario.Update") {
                snackBarApp.value.open("Usuário alterado com sucesso.");
            } else {
                snackBarApp.value.open("Novo usuário cadastrado com sucesso.");
                emit("cadastrou-usuario", usuario.value.codigo);
                actions.resetForm();
                cleanForm();
                inputCodigo.value.focus();
                
            }
        }
    } catch (err) {
        snackBarApp.value.open("Erro, mensagem de erro: " + err);
    }
}

function closeModalCadastroUsuario() {
    emit("close-dialog");
}

async function getUsuario(id) {
    let dados = new FormData();
    dados.append("codigoModulo", "05.01");
    dados.append("idUsuario", id);
    try {
        const response = await Usuario.getById(dados);
        if (response.status == 200) {
            setValues({ usuario: response.data.data });
            document.getElementById("codigo").focus();
        }
    } catch (err) {
        console.log(err);
    }
}

async function proximoCodigo() {
    try {
        const response = await Usuario.proximoCodigo();
        if (response.status == 200) {
            usuario.value.codigo = response.data;
        }
    } catch (err) {
        console.log(err);
    }
}


</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2 v-show="$route.name !== 'Usuario.Update' && $route.name !== 'Usuario.Show'">Novo Usuário</h2>
            <h2 v-show="$route.name == 'Usuario.Update'">Alteração de Usuário</h2>
            <h2 v-show="$route.name == 'Usuario.Show'">Consulta de Usuário</h2>
        </v-card-title>
        <form ref="formUsuario" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="2">
                        <v-text-field
                            label="Código"
                            id="codigo"
                            type="text"
                            ref="inputCodigo"
                            v-model="usuario.codigo"
                            :error-messages="errors['usuario.codigo']"
                            maxlength="9"
                            @blur="(e) => { isRealValidation = true; usuario.codigo = e.target.value;  }"
                            :readonly="isReadOnly"
                        />
                    </v-col>

                    <v-col cols="10">
                        <v-text-field
                            label="Nome do Usuário"
                            id="nome"
                            type="text"
                            v-model="usuario.nome"
                            maxlength="50"
                            :error-messages="errors['usuario.nome']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-text-field
                            label="Login"
                            id="login"
                            type="text"
                            v-model="usuario.login"
                            :error-messages="errors['usuario.login']"
                            maxlength="10"
                            @blur="(e) => { isRealValidationLogin = true; usuario.login = e.target.value;  }"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            label="Senha"
                            id="senha"
                            type="password"
                            v-model="usuario.senha"
                            maxlength="60"
                            :error-messages="errors['usuario.senha']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            label="Repita a Senha"
                            id="repitaSenha"
                            type="password"
                            v-model="usuario.repitaSenha"
                            maxlength="60"
                            :error-messages="errors['usuario.repitaSenha']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                </v-row>
                <v-row cols="3">
                    <v-col>
                        <v-select
                            :items="itemsAtivoInativo"
                            label="Ativo/Inativo"
                            v-model="usuario.ativoInativo"
                            item-title="text"
                            item-value="value"
                            :readonly="isReadOnly"
                        ></v-select>
                    </v-col>
                    <v-col cols="9" v-show="usuario.ativoInativo == 'I'">
                        <v-text-field
                            label="Motivo da inatividade"
                            id="motivoInatividade"
                            type="text"
                            maxlength="50"
                            v-model="usuario.motivoInatividade"
                            :error-messages="errors['usuario.motivoInatividade']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnUsuarioGravar" v-show="$route.name !== 'Usuario.Show'">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="elevated"
                    @click.prevent="$router.go(-1)"
                    v-if="showBtnGravar"
                    v-show="$route.name == 'Usuario.Create' || $route.name == 'Usuario.Update' || $route.name == 'Usuario.Show'"
                    >Voltar</v-btn
                >
                <v-btn
                    color="primary"
                    variant="elevated"
                    v-if="showBtnGravar"
                    v-show="$route.name !== 'Usuario.Create' && $route.name !== 'Usuario.Update' && $route.name !== 'Usuario.Show'"
                    @click.prevent="closeModalCadastroUsuario()"
                    >Fechar</v-btn
                >
            </v-card-actions>
        </form>
    </v-card>
</template>