<script setup>
import { onMounted, inject, defineProps, defineEmits, ref} from "vue";

import Moeda from "../Utils/moeda.vue";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";

const snackBarApp = inject("snackBarApp");

const props = defineProps({
    propLancamento: {
        type: Object,
    },
});

const lancamento = ref({});


const emit = defineEmits(['close-dialog', 'alterou-lancamento']);

onMounted(() => {

    document.getElementById("dataVencimento").focus();
    lancamento.value = props.propLancamento;

    lancamento.value.valor = Converte.numberToCurrency(lancamento.value.valor);


});

async function gravar() {
    if(Converte.stringToFloat(lancamento.value.valor) <= 0) {
        snackBarApp.value.open("O valor da parcela tem que ser maior que zero.", 6000);
        return;
    }

    if(lancamento.value.dataVencimento == '') {
        snackBarApp.value.open("A data de vencimento é obrigatória.", 6000);
        return;
    }
    
    snackBarApp.value.open("Lançamento alterado com sucesso.", 3000);

    emit('alterou-lancamento', lancamento.value);
}



</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2>Alteração de Parcela</h2>
        </v-card-title>
        <form ref="formAlteraLancamento">
            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <Data label="Data de Vencimento" id="dataVencimento" v-model="lancamento.dataVencimento" />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            label="Documento"
                            id="documento"
                            type="text"
                            v-model="lancamento.documento"
                            maxlength="10"
                        />
                    </v-col>
                    <v-col cols="4">
                        <Moeda label="Valor" id="valor" v-model="lancamento.valor"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            label="Observações"
                            id="observacoes"
                            v-model="lancamento.observacoes"
                            rows="6"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" @click.prevent="gravar()" id="btnAlteraLancamento">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="elevated"
                    @click.prevent="emit('close-dialog')">
                    Fechar
                    </v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>