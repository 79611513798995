import { defineStore } from 'pinia';

export const useConfiguracoesOrdensServicos = defineStore('configuracoesOrdensServicos', {
    state: () => {
        return {
            jaCarregado: false,
            labelCampo1: '',
            labelCampo2: '',
            labelCampo3: '',
            labelCampo4: '',
            labelCampo5: '',
        }
    },
    actions : {
        setJaCarregado(jaCarregado) {
            this.jaCarregado = jaCarregado;
        },
        setLabelCampo1(labelCampo1) {
            this.labelCampo1  = labelCampo1;
        },
        setLabelCampo2(labelCampo2) {
            this.labelCampo2  = labelCampo2;
        },
        setLabelCampo3(labelCampo3) {
            this.labelCampo3  = labelCampo3;
        },
        setLabelCampo4(labelCampo4) {
            this.labelCampo4  = labelCampo4;
        },
        setLabelCampo5(labelCampo5) {
            this.labelCampo5  = labelCampo5;
        },
    }
});