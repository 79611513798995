import config from '../services/config';
import { defineStore } from 'pinia';

export const useAgendamentoVisita = defineStore('agendamentoVisita', {
    state: () => {
        return {
            start:0,
            limit: config.maxPerPage,
            currentPage: 1,
            pendentes: true,
            cancelados: true,
            visitados:true,
            dataInicialCliente: new Date().toLocaleDateString("pt-BR"), 
            dataFinalCliente: new Date().toLocaleDateString("pt-BR"),   
            dataInicialVendedor: new Date().toLocaleDateString("pt-BR"), 
            dataFinalVendedor: new Date().toLocaleDateString("pt-BR"),  
            idCliente:null,
            idVendedor:null,
            sort: [{
                'property':'data',
                'direction':'ASC'
            }],
            filtrarPor:'cliente',
            codigo:'',
            codigoVendedor:'',
            nomeVendedor:'',
            codigoCliente:'',
            nomeClienteCliente:'',
        }
    },
    actions : {
        setStart(start) {
            this.start = start;
        },
        setPendentes(pendentes) {
            this.pendentes = pendentes;
        },
        setCancelados(cancelados) {
            this.cancelados = cancelados;
        },
        setVisitados(visitados) {
            this.visitados = visitados;
        },
        setDataInicialCliente(dataInicialCliente) {
            this.dataInicialCliente = dataInicialCliente;
        },
        setDataFinalCliente(dataFinalCliente) {
            this.dataFinalCliente = dataFinalCliente;
        },
        setDataInicialVendedor(dataInicialVendedor) {
            this.dataInicialVendedor = dataInicialVendedor;
        },
        setDataFinalVendedor(dataFinalVendedor) {
            this.dataFinalVendedor = dataFinalVendedor;
        },
        setCurrentPage(currentPage) {
            this.currentPage = currentPage;
        },
        setSort(sort) {
            this.sort = sort;
        },        
        setIdVendedor(idVendedor) {
            this.idVendedor = idVendedor;
        },
        setIdCliente(idCliente) {
            this.idCliente = idCliente;
        },
        setCodigo(codigo) {
            this.codigo = codigo;
        },
        setFiltrarPor(filtrarPor) {
            this.filtrarPor = filtrarPor;
        },        
        setCodigoCliente(codigoCliente) {
            this.codigoCliente = codigoCliente;
        },
        setNomeCliente(nomeCliente) {
            this.nomeCliente = nomeCliente;
        },
        setCodigoVendedor(codigoVendedor) {
            this.codigoVendedor = codigoVendedor;
        },
        setNomeVendedor(nomeVendedor) {
            this.nomeVendedor = nomeVendedor;
        },
    }
});
