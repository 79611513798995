import config from '../services/config';
import { defineStore } from 'pinia';

export const useOrdensServicos = defineStore('ordensServicos', {
    state: () => {
        return {
            start:0,
            limit: config.maxPerPage,
            currentPage: 1,
            abertas: true,
            canceladas: true,
            finalizadas:true,
            dataInicial: new Date().toLocaleDateString("pt-BR"), 
            dataFinal: new Date().toLocaleDateString("pt-BR"), 
            idCliente:null,
            sort: [{
                'property':'data',
                'direction':'ASC'
            }],
            filtrarPor:'data',
            codigo:'',
            codigoCliente:'',
            nomeCliente:'',
            tipoFiltro: 'geral',
            campo: 'campo1',
            buscaCampo:'',
        }
    },
    actions : {
        setStart(start) {
            this.start = start;
        },
        setCurrentPage(currentPage) {
            this.currentPage = currentPage;
        },
        setAbertas(abertas) {
            this.abertas = abertas;
        },
        setCanceladas(canceladas) {
            this.canceladas = canceladas;
        },
        setFinalizadas(finalizadas) {
            this.finalizadas = finalizadas;
        },
        setDataInicial(dataInicial) {
            this.dataInicial = dataInicial;
        },
        setDataFinal(dataFinal) {
            this.dataFinal = dataFinal;
        },
        setIdCliente(idCliente) {
            this.idCliente = idCliente;
        },
        setSort(sort) {
            this.sort = sort;
        },        
        setFiltrarPor(filtrarPor) {
            this.filtrarPor = filtrarPor;
        },
        setCodigo(codigo) {
            this.codigo = codigo;
        },
        setCodigoCliente(codigoCliente) {
            this.codigoCliente = codigoCliente;
        },
        setNomeCliente(nomeCliente) {
            this.nomeCliente = nomeCliente
        },
        setTipoFiltro(tipoFiltro) {
            this.tipoFiltro = tipoFiltro;
        },
        setCampo(campo) {
            this.campo = campo;
        },
        setBuscaCampo(buscaCampo) {
            this.buscaCampo = buscaCampo;
        }
    }
});