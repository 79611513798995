<script setup>
import { ref, onMounted, computed } from "vue";
import { SubgrupoProdutos } from "../../resources/subgrupoProdutosResource";
import config from "../../services/config";
import { PermissaoService } from "../../services/permissaoService";
import MsgConfirm from "../Utils/MsgConfirm";
import SnackBar from "../Utils/SnackBar";
import { useSubgrupoProdutos } from "../../store/subgrupoProdutos";

let msgConfirm = ref(null);
let snackBar = ref(null);

const subgrupoProdutosStore = useSubgrupoProdutos();
let subgrupoProdutos = ref({});
let subgruposProdutos = ref({});
const total = ref(0);
const pageSize = ref(config.maxPerPage);
const currentPage = ref(subgrupoProdutosStore.currentPage);
const filtro = ref(subgrupoProdutosStore.filtro);

onMounted(() => {
    search();
});
const direction = computed(() => {
    return subgrupoProdutosStore.sort[0].direction;
});
async function destroy() {
    let dados = new FormData();
    dados.append("data", JSON.stringify(subgrupoProdutos.value));

    try {
        const response = await SubgrupoProdutos.excluir(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBar.value.open("Subgrupo de produtos excluído com sucesso.");
                search();
            }
            if (response.data.success == false) {
                snackBar.value.open(response.data.vinculos, 1000000);
            }
        }
    } catch (err) {
        console.log(err);
    }
}
function openModalDelete(subgrupoProd) {
    subgrupoProdutos.value = subgrupoProd;
    msgConfirm.value
        .open(
            "Confirma Exclusão ?",
            "Confirma a exclusão do subgrupo de produtos: " +
                subgrupoProdutos.value.descricao +
                " ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                destroy();
            }
        });
}
async function search() {
    let start = subgrupoProdutosStore.start;
    let limit = subgrupoProdutosStore.limit;
    let sort = JSON.stringify(subgrupoProdutosStore.sort);

    subgrupoProdutosStore.setFiltro(filtro.value);

    try {
        const response = await SubgrupoProdutos.get(start, limit, filtro.value, sort);
        subgruposProdutos.value = response.data.data;
        total.value = parseInt(response.data.total);
    } catch (err) {
        console.log(err);
    }
}
function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    subgrupoProdutosStore.setCurrentPage(val);
    subgrupoProdutosStore.setStart(start);
    currentPage.value = val;
    search();
}
function setSort(property) {
    let sort = subgrupoProdutosStore.sort;
    let directionAtual = sort[0].direction;
    let direction = "";

    if (property !== sort[0].property) {
        direction = "ASC";
    } else {
        if (directionAtual == "ASC") {
            direction = "DESC";
        } else {
            direction = "ASC";
        }
    }

    let aSort = [
        {
            property: property,
            direction: direction,
        },
    ];
    subgrupoProdutosStore.setSort(aSort);
    search();
}
function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}
</script>

<template>
    <SnackBar ref="snackBar"></SnackBar>

    <MsgConfirm ref="msgConfirm"></MsgConfirm>

    <v-row>
        <v-col>
            <h1>Subgrupos de Produtos</h1>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="4">
            <v-btn
                v-show="temPermissao('01.11.01')"
                color="primary"
                :to="{ name: 'SubgrupoProdutos.Create' }"
                >Novo Subgrupo de Produtos</v-btn
            >
        </v-col>
        <v-col cols="8">
            <v-form>
                <v-row>
                    <v-col cols="9">
                        <v-text-field
                            label="Filtro"
                            type="text"
                            v-model="filtro"
                            prepend-inner-icon="mdi-magnify"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-btn
                            color="primary"
                            @click.prevent="search()"
                            prepend-icon="mdi-magnify"
                            >Filtrar</v-btn
                        >
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </v-row>

    <p></p>
    <v-card elevation="16">
        <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-center">Ações</th>
                    <th>
                        <a
                            href="#"
                            class="text-decoration-none text-grey-darken-3"
                            @click.prevent="setSort('descricao')"
                        >
                            Descrição do Subgrupo
                            <v-icon
                                :icon="
                                    direction == 'ASC'
                                        ? 'mdi-order-alphabetical-ascending'
                                        : 'mdi-order-alphabetical-descending'
                                "
                            ></v-icon>
                        </a>
                    </th>
                    <th>Descrição do Grupo</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="o in subgruposProdutos" :key="o">
                    <td class="text-center">
                        <v-tooltip text="Altera o subgrupo de produtos.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-pencil"
                                    v-show="temPermissao('01.11.02')"
                                    :to="{
                                        name: 'SubgrupoProdutos.Update',
                                        params: { id: o.idSubgrupoProdutos },
                                    }"
                                    size="small"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Exclui o subgrupo de produtos.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    v-show="temPermissao('01.11.03')"
                                    v-bind="props"
                                    variant="text"
                                    @click.prevent="openModalDelete(o)"
                                    icon="mdi-trash-can"
                                    size="small"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                    </td>
                    <td>{{ o.descricao }}</td>
                    <td>{{ o.descricaoGrupo }}</td>
                </tr>
            </tbody>
        </v-table>
    </v-card>

    <div class="block text-right">
        <v-pagination
            @update:modelValue="handleCurrentChange"
            v-model="currentPage"
            :length="Math.ceil(total / pageSize)"
            :total-visible="7"
            :show-first-last-page="true"
        >
        </v-pagination>
    </div>
    <p></p>
    <v-btn
        Primary
        v-show="temPermissao('01.11.01')"
        color="primary"
        :to="{ name: 'SubgrupoProdutos.Create' }"
        >Novo Subgrupo de Produtos</v-btn
    >
</template>
