import config from '../services/config';
import { defineStore } from 'pinia';

export const useServico = defineStore('servico', {
    state: () => {
        return {
            start:0,
            limit: config.maxPerPage,
            currentPage: 1,
            filtrarPor: 'descricao',
            filtro: '',
            sort: [{
                'property':'descricao',
                'direction':'ASC'
            }]
        }        
    },
    actions : {
        setStart(start) {
            this.start = start;
        },
        setFiltro(filtro) {
            this.filtro = filtro;
        },
        setCurrentPage(currentPage) {
            this.currentPage = currentPage;
        },
        setSort(sort) {
            this.sort = sort;
        },        
        setFiltrarPor(filtrarPor) {
            this.filtrarPor = filtrarPor;
        },        

    }
});