import config from '../services/config';
import { defineStore } from 'pinia';

export const usePagamentoVendedor = defineStore('pagamentoVendedor', {
    state: () => {
        return {
            start:0,
            limit: config.maxPerPage,
            currentPage: 1,
            idVendedor: null,
            codigoVendedor: '',
            nomeVendedor: '',
            dataInicial: new Date().toLocaleDateString("pt-BR"), 
            dataFinal: new Date().toLocaleDateString("pt-BR"),   
            sort: [{
                'property':'data',
                'direction':'ASC'
            }],
        }
    },
    actions : {
        setStart(start) {
            this.start = start;
        },
        setDataInicial(dataInicial) {
            this.dataInicial = dataInicial;
        },
        setDataFinal(dataFinal) {
            this.dataFinal = dataFinal;
        },
        setCurrentPage(currentPage) {
            this.currentPage = currentPage;
        },
        setSort(sort) {
            this.sort = sort;
        },        
        setIdVendedor(idVendedor) {
            this.idVendedor = idVendedor;
        },
        setCodigoVendedor(codigoVendedor) {
            this.codigoVendedor = codigoVendedor;
        },
        setNomeVendedor(nomeVendedor) {
            this.nomeVendedor = nomeVendedor;
        },

    }
});