import config from '../services/config';
import { defineStore } from 'pinia';

export const useContasReceber = defineStore('contasReceber', {
    state: () => {
        return {
            start:0,
            limit: config.maxPerPage,
            currentPage: 1,
            abertos: true,
            cancelados: true,
            quitados:true,
            dataInicial: new Date().toLocaleDateString("pt-BR"), 
            dataFinal: new Date().toLocaleDateString("pt-BR"), 
            idCliente:null,
            sort: [{
                'property':'dataEmissao',
                'direction':'ASC'
            }],
            filtrarPor:'dataEmissao',
            lancamento:'',
            documento:'',
            codigoCliente:'',
            nomeCliente:'',
            tipoFiltro: 'geral',
        }
    },
    actions : {
        setStart(start) {
            this.start = start;
        },
        setCurrentPage(currentPage) {
            this.currentPage = currentPage;
        },
        setAbertos(abertos) {
            this.abertos = abertos;
        },
        setCancelados(cancelados) {
            this.cancelados = cancelados;
        },
        setQuitados(quitados) {
            this.quitados = quitados;
        },
        setDataInicial(dataInicial) {
            this.dataInicial = dataInicial;
        },
        setDataFinal(dataFinal) {
            this.dataFinal = dataFinal;
        },
        setIdCliente(idCliente) {
            this.idCliente = idCliente;
        },
        setSort(sort) {
            this.sort = sort;
        },        
        setFiltrarPor(filtrarPor) {
            this.filtrarPor = filtrarPor;
        },
        setLancamento(lancamento) {
            this.lancamento = lancamento;
        },
        setDocumento(documento) {
            this.documento = documento;
        },
        setCodigoCliente(codigoCliente) {
            this.codigoCliente = codigoCliente;
        },
        setNomeCliente(nomeCliente) {
            this.nomeCliente = nomeCliente;
        },
        setTipoFiltro(tipoFiltro) {
            this.tipoFiltro = tipoFiltro;
        }
    }
});