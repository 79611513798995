<script setup>
import { onMounted, inject, defineEmits, ref } from "vue";
import { string, object } from "yup";
import { OrdemServicos } from "../../resources/ordemServicosResource";
import { useField, useForm } from "vee-validate";
import { Converte } from "@/utils/converte";
import { useRoute } from "vue-router";
import Data from "../Utils/data.vue";
import Moeda from "../Utils/moeda.vue";

const snackBarApp = inject("snackBarApp");
const route = useRoute();
const emit = defineEmits(['close-dialog', 'alterou-adiantamento']);

const validationSchema = object({
    adiantamento: object({
        idOrdemServicos: undefined,
        dataAdiantamento: string().required().label("O campo da data do adiantamento"),
        adiantamento: undefined,
    }).required(),
});

const { handleSubmit, errors } = useForm({ validationSchema });

const adiantamento = ref({
    idOrdemServicos: useField("adiantamento.idOrdemServicos").value,
    dataAdiantamento: useField("adiantamento.dataAdiantamento").value,
    adiantamento: useField("adiantamento.adiantamento").value,
});

const readOnlyAdiamento = ref(false);

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    adiantamento.value.idOrdemServicos = route.params.id;
    getAdiantamento();
    document.getElementById("dataAdiantamento").focus();

    if(route.name == 'OrdensServicos.Show') {
        readOnlyAdiamento.value = true;
    }
});

async function getAdiantamento() {
    if(route.params.id == null) {
        return;
    }

    let dados = new FormData();
    dados.append('idOrdemServicos', route.params.id);
    dados.append('codigoModulo', '03.03.02');

    adiantamento.value.dataAdiantamento = new Date().toLocaleDateString("pt-BR");
    adiantamento.value.adiantamento = 'R$ 0,00';

    try {
        let response = await OrdemServicos.getAdiantamento(dados)
        if (response.status == 200) {
            if(response.data.data.dataAdiantamento !== null) {
                adiantamento.value.dataAdiantamento = Converte.dateIsoToPtBr(response.data.data.dataAdiantamento);
                adiantamento.value.adiantamento = Converte.numberToCurrency(response.data.data.adiantamento);

            }
        }
    }
    catch (err) {
        console.log(err);
    }
}

async function gravar(values) {
    try {
        let dados = new FormData();

        values.adiantamento.dataAdiantamento = Converte.datePtBrToIso(values.adiantamento.dataAdiantamento);

        dados.append('dados', JSON.stringify(values.adiantamento));

        let response = await OrdemServicos.gravarAdiantamento(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open('Não foi possível gravar o adiantamento, '+ response.data.message);
                return;
            }

            snackBarApp.value.open('Adiantamento gravado com sucesso.');
            emit('alterou-adiantamento');
            emit('close-dialog');
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao gravar o adiantamento.');
        console.log(err);
    }
}


</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2>Adiantamento de Ordem de Serviços</h2>
        </v-card-title>
        <form ref="formAdiantamentoOrdemServicos" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <Data 
                            id="dataAdiantamento"
                            v-model="adiantamento.dataAdiantamento"
                            :error-messages="errors['adiantamento.dataAdiantamento']"
                            :readOnly="readOnlyAdiamento"></Data>
                    </v-col>
                    <v-col cols="6">
                        <Moeda label="Valor do Adiantamento" id="valorAdiantamento" type="text"
                            v-model="adiantamento.adiantamento"
                            :error-messages="errors['adiantamento.adiantamento']" maxlength="15"
                            :readOnly="readOnlyAdiamento"/>
                    </v-col>
                </v-row>
                <h4 v-show="route.name=='OrdensServicos.Continuar'">* Caso queira excluir o adiantamento deixe o valor como R$ 0,00 e clique em salvar</h4>
            </v-card-text>
            <v-card-actions>
                <v-btn v-show="route.name=='OrdensServicos.Continuar'" color="primary" variant="elevated"  type="submit" id="btnGravarAdiantamento">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="elevated"
                    @click.prevent="emit('close-dialog')">
                    Fechar
                    </v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>