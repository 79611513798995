<script setup>
    import {Login} from '../resources/loginResource';
    import {ValidadeServico} from '../resources/validadeServicoResource';
    import {PermissaoService} from '../services/permissaoService';
    import TelaDemo from '../components/Telas/TelaDemo.vue'
    import TelaPaga from '../components/Telas/TelaPaga.vue'
    import { useLogado} from "../store/logado";
    import MsgConfirm from "./Utils/MsgConfirm";
    import {useRouter} from 'vue-router';
    import {computed, onMounted, inject, ref} from 'vue';

    const snackBarApp = inject('snackBarApp');
    let msgConfirm = ref(null);
    const logadoStore = useLogado();
    const router = useRouter();
    const dialogTelaDemo = ref(false);
    const dialogTelaPaga = ref(false);

    const logado = computed(() => {
        return logadoStore.logado;
    });

    onMounted(() => {
        PermissaoService.setaPermissoes();
    });

    function logout() {
        Login.efetuarLogout();
        router.go({'name': 'login'});
    }

    function handleSelect(key) {
        if(key == '2-2') {
           // console.log('Selecionado Saida Estoque');
        }
       // console.log(key)
    }
    
    function  adquirirRenovar() {
        window.open('https://www.osgold.com.br/planos.php', '_blank');
        return;
    }
     
    
    function historicoAtualizacoes() {
        window.open('https://www.osgold.com.br/historico.php', '_blank');
        return;
    }

    async function validadeServico() {
        try {
            let response = await ValidadeServico.get();
            if (response.status == 200) {
                if (response.data.success == false) {
                    snackBarApp.value.open('Não foi possível consultar a data da validade do serviço, tente novamente.');
                    return;
                }
                if (response.data.success == true) {
                    snackBarApp.value.open('O seu período de acesso vence/venceu em: ' +
                        response.data.data.validade + '. O número de acesso(s) simultâneo(s) ' +
                        'contratado é de: ' + response.data.data.numeroUsuarios, 10000);

                    return;
                }
            }
        }
        catch(err) {
            snackBarApp.value.open('Não foi possível consultar a data da validade do serviço, tente novamente.');
            console.log(err);
        }
    }
        
</script>



<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>

    <div v-show="logado">

    <el-menu
        class="el-menu-demo"
        mode="horizontal"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
        @select="handleSelect"
        :router=true
    >
    <el-sub-menu index="1">
      <template #title>Cadastros</template>
      <el-menu-item index="1-1" route="/GrupoClientes">Grupos de Clientes</el-menu-item>
      <el-menu-item index="1-2" route="/GrupoServicos">Grupos de Serviços</el-menu-item>    
      <el-menu-item index="1-3" route="/SubgrupoServicos">Subgrupos de Serviços</el-menu-item>    
      <el-menu-item index="1-4" route="/Servico">Serviços</el-menu-item>    
      <el-menu-item index="1-5" route="/GrupoProdutos">Grupos de Produtos</el-menu-item>    
      <el-menu-item index="1-6" route="/SubgrupoProdutos">Subgrupos de Produtos</el-menu-item>    
      <el-menu-item index="1-7" route="/Produto">Produtos</el-menu-item>    
      <el-menu-item index="1-8" route="/Cliente">Clientes</el-menu-item> 
      <el-menu-item index="1-9" route="/TipoDocumento">Tipos de Documentos</el-menu-item> 
      <el-menu-item index="1-10" route="/GrupoFornecedores">Grupos de Fornecedores</el-menu-item> 
      <el-menu-item index="1-11" route="/Fornecedor">Fornecedores</el-menu-item> 
      <el-menu-item index="1-12" route="/Vendedor">Vendedores/Funcionários</el-menu-item> 
      <el-menu-item index="1-13" route="/Banco">Bancos</el-menu-item> 
      <el-menu-item index="1-14" route="/Conta">Contas Bancárias</el-menu-item> 
      <el-menu-item index="1-15" route="/Cartao">Cartões/Convênios</el-menu-item> 
      <el-menu-item index="1-16" route="/GrupoContasPagar">Grupos de Conta a Pagar</el-menu-item>    
      <el-menu-item index="1-17" route="/SubgrupoContasPagar">Subgrupos de Conta a Pagar</el-menu-item>    
      <el-menu-item index="1-18" route="/GrupoContasReceber">Grupos de Conta a Receber</el-menu-item> 
      <el-menu-item index="1-19" route="/SubgrupoContasReceber">Subgrupos de Conta a Receber</el-menu-item> 
      <el-menu-item index="1-20" route="/AgendamentoVisita">Agendamento de Visitas</el-menu-item> 
    </el-sub-menu>
    <el-sub-menu index="2">
      <template #title>Movimentação de Estoque</template>
        <el-menu-item index="2-1" route="/EntradaEstoque">Entrada no Estoque</el-menu-item> 
        <el-menu-item index="2-2" route="/SaidaEstoque">Saída do Estoque</el-menu-item> 
        <el-menu-item index="2-3" route="/ExcluirReverter">Excluir/Reverter uma Movimentação de Estoque</el-menu-item> 
    </el-sub-menu>
    <el-sub-menu index="3">
        <template #title>Faturamento</template>
            <el-menu-item index="3-1" route="/OrdensServicos">Ordens de Serviços</el-menu-item>
            <el-menu-item index="3-2" route="/OrcamentosOrdensServicos">Orçamentos de Ordens de Serviços</el-menu-item>
            <el-menu-item index="3-3" route="/Caixa">Caixa</el-menu-item>    
            <el-menu-item index="3-4" route="/ContasReceber">Contas a Receber</el-menu-item> 
            <el-menu-item index="3-5" route="/ContasPagar">Contas a Pagar</el-menu-item> 
            <el-menu-item index="3-6" route="/Comissoes">Comissões de Vendedores/Funcionários</el-menu-item> 
            <el-menu-item index="3-7" route="/PagamentosVendedores">Pagamentos a Vendedores/Funcionários</el-menu-item>             
            <el-menu-item index="3-8" route="/LancamentosContas">Lançamentos de Contas Bancárias</el-menu-item>             
            <el-menu-item index="3-9" route="/ChequesEmitidos">Cheques Emitidos</el-menu-item>             
            <el-menu-item index="3-10" route="/ChequesRecebidos">Cheques Recebidos</el-menu-item>             
            <el-menu-item index="3-11" route="/PedidosCompras">Pedidos de Compras de Fornecedores</el-menu-item>             
            <el-menu-item index="3-12" route="/LancamentosCartoes">Lançamentos de Cartões/Convênios</el-menu-item>             

    </el-sub-menu>
    <el-sub-menu index="4">
        <template #title>Relatórios</template>
        <el-menu-item index="4-0" route="/Relatorios">Relatórios</el-menu-item>
        <!-- <el-sub-menu index="4-1">
            <template #title>Ordens de Serviços</template>
                <el-menu-item index="4-1-1" @click="abreTelaDatas('OS Finalizadas Data')">Ordens de Serviços Finalizadas(Filtrado/Ordenado por Data)</el-menu-item>
                <el-menu-item index="4-1-2" @click="abreTelaDatas('OS Finalizadas Data Finalizacao')">Ordens de Serviços Finalizadas(Filtrado/Ordenado por Data de Finalização)</el-menu-item>
                <el-menu-item index="4-1-3" @click="abreTelaDatas('OS Abertas Data')">Ordens de Serviços Em Aberto(Filtrado/Ordenado por Data)</el-menu-item>
                <el-menu-item index="4-1-4" @click="abreTelaDatas('OS Abertas Prazo')">Ordens de Serviços Em Aberto(Filtrado/Ordenado por Prazo)</el-menu-item>
                <el-menu-item index="4-1-5" @click="abreTelaDatas('OS Canceladas Data')">Ordens de Serviços Canceladas(Filtrado/Ordenado por Data)</el-menu-item>
                <el-menu-item index="4-1-6" @click="abreTelaDatasCliente('OS Finalizadas Data Um Cliente')">Ordens de Serviços Finalizadas de um Cliente(Filtrado/Ordenado por Data)</el-menu-item>
                <el-menu-item index="4-1-7" @click="abreTelaDatasCliente('OS Finalizadas Data Finalizacao Um Cliente')">Ordens de Serviços Finalizadas de um Cliente(Filtrado/Ordenado por Data de Finalização)</el-menu-item>
                <el-menu-item index="4-1-8" @click="abreTelaDatasCliente('OS Abertas Data Um Cliente')">Ordens de Serviços Em Aberto de um Cliente(Filtrado/Ordenado por Data)</el-menu-item>
                <el-menu-item index="4-1-9" @click="abreTelaDatasCliente('OS Abertas Prazo Um Cliente')">Ordens de Serviços Em Aberto de um Cliente(Filtrado/Ordenado por Prazo)</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="4-2">
            <template #title>Itens no Estoque</template>
                <el-menu-item index="4-2-1" @click="imprimirItemEstoqueGeral('RItem Descricao I')">Itens nos Estoques Geral I (Ordenado por Descrição)</el-menu-item>
                <el-menu-item index="4-2-2" @click="imprimirRelatorio('RAbaixoEstoqueMinimo I')">Itens Abaixo do Estoque Mínimo I(Ordenado por Descrição)</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="4-3">
            <template #title>Movimentação de Estoque</template>
                <el-menu-item index="4-3-1" @click="abreTelaDatas('RMovEstoqueGeral')">Movimentação de Estoque Geral por Datas I</el-menu-item>
                <el-menu-item index="4-3-2" @click="abreTelaDatas('RMovEstoqueSaidas')">Movimentação de Estoque Somente Saídas por Datas I</el-menu-item>
                <el-menu-item index="4-3-3" @click="abreTelaDatas('RMovEstoqueEntradas')">Movimentação de Estoque Somente Entradas por Datas I</el-menu-item>
                <el-menu-item index="4-3-4" @click ="abreTelaDatasFornecedor('RMovEstoqueUmFornecedor')">Movimentação de Estoque de um Fornecedor I</el-menu-item>
        </el-sub-menu>

        <el-sub-menu index="4-4">
            <template #title>Caixa</template>
                <el-menu-item index="4-4-1" @click ="abreTelaDatas('Caixa')">Caixa Geral</el-menu-item>
                <el-menu-item index="4-4-2" @click ="abreTelaDatas('CaixaSaldoAnterior')">Caixa Geral(Com Saldo Anterior)</el-menu-item>

            </el-sub-menu>

        <el-sub-menu index="4-5">
            <template #title>Contas a Receber</template>
                <el-menu-item index="4-5-1" @click ="abreTelaDatas('ContasReceber')">Contas a Receber(Ordenado/Filtrado por Data de Vencimento)</el-menu-item>
                <el-menu-item index="4-5-2" @click ="abreTelaDatasCliente('ContasReceber Um Cliente')">Contas a Receber de um Cliente(Ordenado/Filtrado por Data de Vencimento)</el-menu-item>
                <el-menu-item index="4-5-3" @click ="abreTelaDatas('ContasRecebidas')">Contas Recebidas(Ordenado/Filtrado por Data do Recebimento)</el-menu-item>
                <el-menu-item index="4-5-4" @click ="abreTelaDatasCliente('ContasRecebidas Um Cliente')">Contas Recebidas de um Cliente(Ordenado/Filtrado por Data do Recebimento)</el-menu-item>
        </el-sub-menu>

        <el-sub-menu index="4-6">
            <template #title>Contas a Pagar</template>
                <el-menu-item index="4-6-1" @click ="abreTelaDatas('ContasPagar')">Contas a Pagar(Ordenado/Filtrado por Data de Vencimento)</el-menu-item>
                <el-menu-item index="4-6-2" @click ="abreTelaDatasFornecedor('ContasPagar Um Fornecedor')">Contas a Pagar de um Fornecedor(Ordenado/Filtrado por Data de Vencimento)</el-menu-item>
                <el-menu-item index="4-6-3" @click ="abreTelaDatas('ContasPagas')">Contas Pagas(Ordenado/Filtrado por Data do Pagamento)</el-menu-item>
                <el-menu-item index="4-6-4" @click ="abreTelaDatasFornecedor('ContasPagas Um Fornecedor')">Contas Pagas para um Fornecedor(Ordenado/Filtrado por Data do Pagamento)</el-menu-item>
        </el-sub-menu>

        <el-sub-menu index="4-7">
            <template #title>Orçamentos de Ordens de Serviços</template>
                <el-menu-item index="4-7-1" @click ="abreTelaDatas('ORS Data')">Orçamentos(Ordenado/Filtrado por Data)</el-menu-item>
                <el-menu-item index="4-7-2" @click ="abreTelaDatas('ORS Validade')">Orçamentos(Ordenado/Filtrado por Validade)</el-menu-item>
                <el-menu-item index="4-7-3" @click ="abreTelaDatas('ORS Abertos Data')">Orçamentos em Aberto(Ordenado/Filtrado por Data)</el-menu-item>
                <el-menu-item index="4-7-4" @click ="abreTelaDatas('ORS Abertos Validade')">Orçamentos em Aberto(Ordenado/Filtrado por Validade)</el-menu-item>
                <el-menu-item index="4-7-5" @click ="abreTelaDatas('ORS Cancelados Data')">Orçamentos Cancelados(Ordenado/Filtrado por Data)</el-menu-item>
                <el-menu-item index="4-7-6" @click ="abreTelaDatas('ORS Cancelados Validade')">Orçamentos Cancelados(Ordenado/Filtrado por Validade)</el-menu-item>
                <el-menu-item index="4-7-7" @click ="abreTelaDatas('ORS Transformados Data')">Orçamentos Transformados em Ordem de Serviços(Ordenado/Filtrado por Data)</el-menu-item>
                <el-menu-item index="4-7-8" @click ="abreTelaDatas('ORS Transformados Validade')">Orçamentos Transformados em Ordem de Serviços(Ordenado/Filtrado por Validade)</el-menu-item>
        </el-sub-menu>

        <el-sub-menu index="4-8">
            <template #title>Pagamentos a Vendedores/Funcionários</template>
                <el-menu-item index="4-8-1" @click ="abreTelaDatas('PagamentosVendedores')">Pagamentos por Data</el-menu-item>
                <el-menu-item index="4-8-2" @click ="abreTelaDatasVendedor('PagamentosVendedores Um Vendedor')">Pagamentos a um Vendedor/Funcionário</el-menu-item>
                <el-menu-item index="4-8-3" @click ="abreTelaDatas('PagamentosVendedores Vendedores')">Pagamentos por Vendedores</el-menu-item>
        </el-sub-menu>

        <el-sub-menu index="4-9">
            <template #title>Comissões de Vendedores/Funcionários</template>
                <el-sub-menu index="4-9-1">
                    <template #title>Por Datas</template>
                        <el-menu-item index="4-9-1" @click ="abreTelaDatas('Comissoes Data')">Comissões(Ordenadas/Filtradas por Data)</el-menu-item>
                        <el-menu-item index="4-9-1-2" @click ="abreTelaDatas('Comissoes Data A')">Comissões Em Aberto(Ordenadas/Filtradas por Data)</el-menu-item>
                        <el-menu-item index="4-9-1-3" @click ="abreTelaDatas('Comissoes Data P')">Comissões Pendentes(Ordenadas/Filtradas por Data)</el-menu-item>
                        <el-menu-item index="4-9-1-4" @click ="abreTelaDatas('Comissoes Data C')">Comissões Canceladas(Ordenadas/Filtradas por Data)</el-menu-item>
                        <el-menu-item index="4-9-1-5" @click ="abreTelaDatas('Comissoes DataPagamento Q')">Comissões Pagas(Ordenadas/Filtradas por Data de Pagamento)</el-menu-item>
                </el-sub-menu>
                <el-sub-menu index="4-9-2">
                    <template #title>Por Vendedores/Funcionários</template>
                        <el-menu-item index="4-9-2-1" @click ="abreTelaDatas('Comissoes Vendedores')">Comissões(Ordenadas/Filtradas por Data)</el-menu-item>
                        <el-menu-item index="4-9-2-2" @click ="abreTelaDatas('Comissoes Vendedores A')">Comissões Em Aberto(Ordenadas/Filtradas por Data)</el-menu-item>
                        <el-menu-item index="4-9-2-3" @click ="abreTelaDatas('Comissoes Vendedores P')">Comissões Pendentes(Ordenadas/Filtradas por Data)</el-menu-item>
                        <el-menu-item index="4-9-2-4" @click ="abreTelaDatas('Comissoes Vendedores C')">Comissões Canceladas(Ordenadas/Filtradas por Data)</el-menu-item>
                        <el-menu-item index="4-9-2-5" @click ="abreTelaDatas('Comissoes Vendedores Q')">Comissões Pagas(Ordenadas/Filtradas por Data de Pagamento)</el-menu-item>
                </el-sub-menu>
                <el-sub-menu index="4-9-3">
                    <template #title>De Um Vendedor/Funcionário</template>
                        <el-menu-item index="4-9-3-1" @click ="abreTelaDatasVendedor('Comissoes Um Vendedor')">Comissões de um Vendedor/Funcionário(Ordenadas/Filtradas por Data)</el-menu-item>
                        <el-menu-item index="4-9-3-2" @click ="abreTelaDatasVendedor('Comissoes Um Vendedor A')">Comissões Em Aberto de um Vendedor/Funcionário(Ordenadas/Filtradas por Data)</el-menu-item>
                        <el-menu-item index="4-9-3-3" @click ="abreTelaDatasVendedor('Comissoes Um Vendedor P')">Comissões Pendentes de um Vendedor/Funcionário(Ordenadas/Filtradas por Data)</el-menu-item>
                        <el-menu-item index="4-9-3-4" @click ="abreTelaDatasVendedor('Comissoes Um Vendedor C')">Comissões Canceladas de um Vendedor/Funcionário(Ordenadas/Filtradas por Data)</el-menu-item>
                        <el-menu-item index="4-9-3-5" @click ="abreTelaDatasVendedor('Comissoes Um Vendedor Q')">Comissões Pagas de um Vendedor/Funcionário(Ordenadas/Filtradas por Data de Pagamento)</el-menu-item>
                </el-sub-menu>
        </el-sub-menu>

        <el-sub-menu index="4-10">
            <template #title>Cheques Emitidos</template>
            <el-menu-item index="4-10-1" @click ="abreTelaDatas('ChequesEmitidos Data')">Cheques Emitidos(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
            <el-menu-item index="4-10-2" @click ="abreTelaDatas('ChequesEmitidos BomPara')">Cheques Emitidos(Ordenados/Filtrados por Bom Para)</el-menu-item>
            <el-menu-item index="4-10-3" @click ="abreTelaDatas('ChequesEmitidos Data A')">Cheques Emitidos em Aberto(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
            <el-menu-item index="4-10-4" @click ="abreTelaDatas('ChequesEmitidos BomPara A')">Cheques Emitidos em Aberto(Ordenados/Filtrados por Bom Para)</el-menu-item>
            <el-menu-item index="4-10-5" @click ="abreTelaDatas('ChequesEmitidos Data B')">Cheques Emitidos Baixados(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
            <el-menu-item index="4-10-6" @click ="abreTelaDatas('ChequesEmitidos BomPara B')">Cheques Emitidos Baixados(Ordenados/Filtrados por Bom Para)</el-menu-item>
            <el-menu-item index="4-10-7" @click ="abreTelaDatas('ChequesEmitidos DataBaixa B')">Cheques Emitidos Baixados(Ordenados/Filtrados por Data da Baixa)</el-menu-item>
            <el-menu-item index="4-10-8" @click ="abreTelaDatas('ChequesEmitidos Data C')">Cheques Emitidos Cancelados(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
            <el-menu-item index="4-10-9" @click ="abreTelaDatas('ChequesEmitidos BomPara C')">Cheques Emitidos Cancelados(Ordenados/Filtrados por Bom Para)</el-menu-item>
            <el-menu-item index="4-10-10" @click ="abreTelaDatas('ChequesEmitidos Data V')">Cheques Emitidos que Voltaram(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
            <el-menu-item index="4-10-11" @click ="abreTelaDatas('ChequesEmitidos BomPara V')">Cheques Emitidos que Voltaram(Ordenados/Filtrados por Bom Para)</el-menu-item>
            <el-sub-menu index="4-10-12">
                <template #title>Para um Fornecedor</template>
                <el-menu-item index="4-10-12-1" @click ="abreTelaDatasFornecedor('ChequesEmitidos Um Fornecedor Data')">Cheques Emitidos para um Fornecedor(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-10-12-2" @click ="abreTelaDatasFornecedor('ChequesEmitidos Um Fornecedor BomPara')">Cheques Emitidos para um Fornecedor(Ordenados/Filtrados por Bom Para)</el-menu-item>
                <el-menu-item index="4-10-12-3" @click ="abreTelaDatasFornecedor('ChequesEmitidos Um Fornecedor Data A')">Cheques Emitidos em Aberto para um Fornecedor(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-10-12-4" @click ="abreTelaDatasFornecedor('ChequesEmitidos Um Fornecedor BomPara A')">Cheques Emitidos em Aberto para um Fornecedor(Ordenados/Filtrados por Bom Para)</el-menu-item>
                <el-menu-item index="4-10-12-5" @click ="abreTelaDatasFornecedor('ChequesEmitidos Um Fornecedor Data B')">Cheques Emitidos Baixados para um Fornecedor(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-10-12-6" @click ="abreTelaDatasFornecedor('ChequesEmitidos Um Fornecedor BomPara B')">Cheques Emitidos Baixados para um Fornecedor(Ordenados/Filtrados por Bom Para)</el-menu-item>
                <el-menu-item index="4-10-12-7" @click ="abreTelaDatasFornecedor('ChequesEmitidos Um Fornecedor DataBaixa B')">Cheques Emitidos Baixados para um Fornecedor(Ordenados/Filtrados por Data da Baixa)</el-menu-item>
                <el-menu-item index="4-10-12-8" @click ="abreTelaDatasFornecedor('ChequesEmitidos Um Fornecedor Data C')">Cheques Emitidos Cancelados para um Fornecedor(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-10-12-9" @click ="abreTelaDatasFornecedor('ChequesEmitidos Um Fornecedor BomPara C')">Cheques Emitidos Cancelados para um Fornecedor(Ordenados/Filtrados por Bom Para)</el-menu-item>
                <el-menu-item index="4-10-12-10" @click ="abreTelaDatasFornecedor('ChequesEmitidos Um Fornecedor Data V')">Cheques Emitidos para um Fornecedor que Voltaram(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-10-12-11" @click ="abreTelaDatasFornecedor('ChequesEmitidos Um Fornecedor BomPara V')">Cheques Emitidos para um Fornecedor que Voltaram(Ordenados/Filtrados por Bom Para)</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="4-10-13">
                <template #title>De uma Conta</template>
                <el-menu-item index="4-10-13-1" @click ="abreTelaDatasConta('ChequesEmitidos Uma Conta Data')">Cheques Emitidos de uma Conta(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-10-13-2" @click ="abreTelaDatasConta('ChequesEmitidos Uma Conta BomPara')">Cheques Emitidos de uma Conta(Ordenados/Filtrados por Bom Para)</el-menu-item>
                <el-menu-item index="4-10-13-3" @click ="abreTelaDatasConta('ChequesEmitidos Uma Conta Data A')">Cheques Emitidos em Aberto de uma Conta(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-10-13-4" @click ="abreTelaDatasConta('ChequesEmitidos Uma Conta BomPara A')">Cheques Emitidos em Aberto de uma Conta(Ordenados/Filtrados por Bom Para)</el-menu-item>
                <el-menu-item index="4-10-13-5" @click ="abreTelaDatasConta('ChequesEmitidos Uma Conta Data B')">Cheques Emitidos Baixados de uma Conta(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-10-13-6" @click ="abreTelaDatasConta('ChequesEmitidos Uma Conta BomPara B')">Cheques Emitidos Baixados de uma Conta(Ordenados/Filtrados por Bom Para)</el-menu-item>
                <el-menu-item index="4-10-13-7" @click ="abreTelaDatasConta('ChequesEmitidos Uma Conta DataBaixa B')">Cheques Emitidos Baixados de uma Conta(Ordenados/Filtrados por Data da Baixa)</el-menu-item>
                <el-menu-item index="4-10-13-8" @click ="abreTelaDatasConta('ChequesEmitidos Uma Conta Data C')">Cheques Emitidos Cancelados de uma Conta(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-10-13-9" @click ="abreTelaDatasConta('ChequesEmitidos Uma Conta BomPara C')">Cheques Emitidos Cancelados de uma Conta(Ordenados/Filtrados por Bom Para)</el-menu-item>
                <el-menu-item index="4-10-13-10" @click ="abreTelaDatasConta('ChequesEmitidos Uma Conta Data V')">Cheques Emitidos de uma Conta que Voltaram(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-10-13-11" @click ="abreTelaDatasConta('ChequesEmitidos Uma Conta BomPara V')">Cheques Emitidos de uma Conta que Voltaram(Ordenados/Filtrados por Bom Para)</el-menu-item>
            </el-sub-menu>
        </el-sub-menu>

        <el-sub-menu index="4-11">
            <template #title>Cheques Recebidos</template>
            <el-menu-item index="4-11-1" @click ="abreTelaDatas('ChequesRecebidos Data')">Cheques Recebidos(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
            <el-menu-item index="4-11-2" @click ="abreTelaDatas('ChequesRecebidos BomPara')">Cheques Recebidos(Ordenados/Filtrados por Bom Para)</el-menu-item>
            <el-menu-item index="4-11-3" @click ="abreTelaDatas('ChequesRecebidos Data A')">Cheques Recebidos em Aberto(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
            <el-menu-item index="4-11-4" @click ="abreTelaDatas('ChequesRecebidos BomPara A')">Cheques Recebidos em Aberto(Ordenados/Filtrados por Bom Para)</el-menu-item>
            <el-menu-item index="4-11-5" @click ="abreTelaDatas('ChequesRecebidos Data B')">Cheques Recebidos Baixados(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
            <el-menu-item index="4-11-6" @click ="abreTelaDatas('ChequesRecebidos BomPara B')">Cheques Recebidos Baixados(Ordenados/Filtrados por Bom Para)</el-menu-item>
            <el-menu-item index="4-11-7" @click ="abreTelaDatas('ChequesRecebidos DataBaixa B')">Cheques Recebidos Baixados(Ordenados/Filtrados por Data da Baixa)</el-menu-item>
            <el-menu-item index="4-11-8" @click ="abreTelaDatas('ChequesRecebidos Data C')">Cheques Recebidos Cancelados(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
            <el-menu-item index="4-11-9" @click ="abreTelaDatas('ChequesRecebidos BomPara C')">Cheques Recebidos Cancelados(Ordenados/Filtrados por Bom Para)</el-menu-item>
            <el-menu-item index="4-11-10" @click ="abreTelaDatas('ChequesRecebidos Data V')">Cheques Recebidos que Voltaram(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
            <el-menu-item index="4-11-11" @click ="abreTelaDatas('ChequesRecebidos BomPara V')">Cheques Recebidos que Voltaram(Ordenados/Filtrados por Bom Para)</el-menu-item>
            <el-sub-menu index="4-11-12">
                <template #title>De um Cliente</template>
                <el-menu-item index="4-11-12-1" @click ="abreTelaDatasCliente('ChequesRecebidos Um Cliente Data')">Cheques Recebidos de um Cliente(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-11-12-2" @click ="abreTelaDatasCliente('ChequesRecebidos Um Cliente BomPara')">Cheques Recebidos de um Cliente(Ordenados/Filtrados por Bom Para)</el-menu-item>
                <el-menu-item index="4-11-12-3" @click ="abreTelaDatasCliente('ChequesRecebidos Um Cliente Data A')">Cheques Recebidos em Aberto de um Cliente(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-11-12-4" @click ="abreTelaDatasCliente('ChequesRecebidos Um Cliente BomPara A')">Cheques Recebidos em Aberto de um Cliente(Ordenados/Filtrados por Bom Para)</el-menu-item>
                <el-menu-item index="4-11-12-5" @click ="abreTelaDatasCliente('ChequesRecebidos Um Cliente Data B')">Cheques Recebidos Baixados de um Cliente(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-11-12-6" @click ="abreTelaDatasCliente('ChequesRecebidos Um Cliente BomPara B')">Cheques Recebidos Baixados de um Cliente(Ordenados/Filtrados por Bom Para)</el-menu-item>
                <el-menu-item index="4-11-12-7" @click ="abreTelaDatasCliente('ChequesRecebidos Um Cliente DataBaixa B')">Cheques Recebidos Baixados de um Cliente(Ordenados/Filtrados por Data da Baixa)</el-menu-item>
                <el-menu-item index="4-11-12-8" @click ="abreTelaDatasCliente('ChequesRecebidos Um Cliente Data C')">Cheques Recebidos Cancelados de um Cliente(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-11-12-9" @click ="abreTelaDatasCliente('ChequesRecebidos Um Cliente BomPara C')">Cheques Recebidos Cancelados de um Cliente(Ordenados/Filtrados por Bom Para)</el-menu-item>
                <el-menu-item index="4-11-12-10" @click ="abreTelaDatasCliente('ChequesRecebidos Um Cliente Data V')">Cheques Recebidos de um Cliente que Voltaram(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-11-12-11" @click ="abreTelaDatasCliente('ChequesRecebidos Um Cliente BomPara V')">Cheques Recebidos de um Cliente que Voltaram(Ordenados/Filtrados por Bom Para)</el-menu-item>
            </el-sub-menu>
        </el-sub-menu>

        <el-sub-menu index="4-12">
            <template #title>Lançamentos de Cartões/Convênios</template>
            <el-sub-menu index="4-12-1">
                <template #title>Por Datas</template>
                <el-menu-item index="4-12-1-1" @click ="abreTelaDatas('LancamentosCartoes Data')">Lançamentos(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-12-1-2" @click ="abreTelaDatas('LancamentosCartoes Data A')">Lançamentos Em Aberto(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-12-1-3" @click ="abreTelaDatas('LancamentosCartoes Data P')">Lançamentos Recebidos(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-12-1-4" @click ="abreTelaDatas('LancamentosCartoes Data C')">Lançamentos Cancelados(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="4-12-2">
                <template #title>Geral por Data de Recebimento</template>
                <el-menu-item index="4-12-2-1" @click ="abreTelaDatas('LancamentosCartoes DataRecebimento P')">Lançamentos(Ordenados/Filtrados por Data de Recebimento)</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="4-12-3">
                <template #title>Por Cartões/Convênios por Datas</template>
                <el-menu-item index="4-12-3-1" @click ="abreTelaDatas('LancamentosCartoes Cartoes Data')">Lançamentos(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-12-3-2" @click ="abreTelaDatas('LancamentosCartoes Cartoes Data A')">Lançamentos Em Aberto(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-12-3-3" @click ="abreTelaDatas('LancamentosCartoes Cartoes Data P')">Lançamentos Recebidos(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-12-3-4" @click ="abreTelaDatas('LancamentosCartoes Cartoes Data C')">Lançamentos Cancelados(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
            </el-sub-menu>  
            <el-sub-menu index="4-12-4">
                <template #title>Por Cartões/Convênios por Data de Recebimento</template>
                <el-menu-item index="4-12-4-1" @click ="abreTelaDatas('LancamentosCartoes Cartoes DataRecebimento P')">Lançamentos Recebidos(Ordenados/Filtrados por Data de Recebimento)</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="4-12-5">
                <template #title>De Um Cartão/Convênio por Data</template>
                <el-menu-item index="4-12-5-1" @click ="abreTelaDatasCartao('LancamentosCartoes Um Cartao Data')">Lançamentos(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-12-5-2" @click ="abreTelaDatasCartao('LancamentosCartoes Um Cartao Data A')">Lançamentos Em Aberto(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-12-5-3" @click ="abreTelaDatasCartao('LancamentosCartoes Um Cartao Data P')">Lançamentos Recebidos(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-12-5-4" @click ="abreTelaDatasCartao('LancamentosCartoes Um Cartao Data C')">Lançamentos Cancelados(Ordenados/Filtrados por Data de Emissão)</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="4-12-6">
                <template #title>De Um Cartão/Convênio por Data de Recebimento</template>
                <el-menu-item index="4-12-6-1" @click ="abreTelaDatasCartao('LancamentosCartoes Um Cartao DataRecebimento P')">Lançamentos Recebidos(Ordenados/Filtrados por Data de Recebimento)</el-menu-item>
            </el-sub-menu>
        </el-sub-menu>

        <el-sub-menu index="4-13">
            <template #title>Agendamentos de Visitas</template>
            <el-sub-menu index="4-13-1">
                <template #title>Por Datas</template>
                <el-menu-item index="4-13-1-1" @click ="abreTelaDatas('Agendamento Visita Datas')">Agendamentos de Visitas</el-menu-item>
                <el-menu-item index="4-13-1-2" @click ="abreTelaDatas('Agendamento Visita Datas P')">Agendamentos de Visitas(Pendentes)</el-menu-item>
                <el-menu-item index="4-13-1-3" @click ="abreTelaDatas('Agendamento Visita Datas C')">Agendamentos de Visitas(Cancelados)</el-menu-item>
                <el-menu-item index="4-13-1-4" @click ="abreTelaDatas('Agendamento Visita Datas V')">Agendamentos de Visitas(Visitados)</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="4-13-2">
                <template #title>Por Cliente</template>
                <el-menu-item index="4-13-2-1" @click ="abreTelaDatasCliente('Agendamento Visita Um Cliente')">Agendamentos de Visitas de um Cliente</el-menu-item>
                <el-menu-item index="4-13-2-2" @click ="abreTelaDatasCliente('Agendamento Visita Um Cliente P')">Agendamentos de Visitas de um Cliente(Pendentes)</el-menu-item>
                <el-menu-item index="4-13-2-3" @click ="abreTelaDatasCliente('Agendamento Visita Um Cliente C')">Agendamentos de Visitas de um Cliente(Cancelados)</el-menu-item>
                <el-menu-item index="4-13-2-4" @click ="abreTelaDatasCliente('Agendamento Visita Um Cliente V')">Agendamentos de Visitas de um Cliente(Visitados)</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="4-13-3">
                <template #title>Por Vendedor/Funcionário</template>
                <el-menu-item index="4-13-3-1" @click ="abreTelaDatasVendedor('Agendamento Visita Um Vendedor')">Agendamentos de Visitas de um Vendedor/Funcionário</el-menu-item>
                <el-menu-item index="4-13-3-2" @click ="abreTelaDatasVendedor('Agendamento Visita Um Vendedor P')">Agendamentos de Visitas de um Vendedor/Funcionário(Pendentes)</el-menu-item>
                <el-menu-item index="4-13-3-3" @click ="abreTelaDatasVendedor('Agendamento Visita Um Vendedor C')">Agendamentos de Visitas de um Vendedor/Funcionário(Cancelados)</el-menu-item>
                <el-menu-item index="4-13-3-4" @click ="abreTelaDatasVendedor('Agendamento Visita Um Vendedor V')">Agendamentos de Visitas de um Vendedor/Funcionário(Visitados)</el-menu-item>  
            </el-sub-menu>
        </el-sub-menu>
        <el-sub-menu index="4-14">
            <template #title>Pedidos de Compras</template>
            <el-menu-item index="4-14-1" @click ="abreTelaDatas('Pedidos Compras Recebidos')">Pedidos Recebidos(Filtrado/Ordenado por Data de Recebimento)</el-menu-item>
            <el-menu-item index="4-14-2" @click ="abreTelaDatas('Pedidos Compras Abertos Data')">Pedidos Abertos(Filtrado/Ordenado por Data de Emissão)</el-menu-item>
            <el-menu-item index="4-14-3" @click ="abreTelaDatas('Pedidos Compras Abertos Prazo')">Pedidos Abertos(Filtrado/Ordenado por Prazo de Entrega)</el-menu-item>
            <el-menu-item index="4-14-4" @click ="abreTelaDatas('Pedidos Compras Abertos Data Faturamento')">Pedidos Abertos(Filtrado/Ordenado por Data do Faturamento)</el-menu-item>
            <el-menu-item index="4-14-5" @click ="abreTelaDatas('Pedidos Compras Cancelados')">Pedidos Cancelados(Filtrado/Ordenado por Data de Emissão)</el-menu-item>
            <el-sub-menu index="4-14-6">
                <template #title>De um Fornecedor</template>
                <el-menu-item index="4-14-6-1" @click ="abreTelaDatasFornecedor('Pedidos Compras Recebidos Um Fornecedor')">Pedidos Recebidos de um Fornecedor(Filtrado/Ordenado por Data de Recebimento)</el-menu-item>
                <el-menu-item index="4-14-6-2" @click ="abreTelaDatasFornecedor('Pedidos Compras Abertos Um Fornecedor Data')">Pedidos Abertos de um Fornecedor(Filtrado/Ordenado por Data de Emissão)</el-menu-item>
                <el-menu-item index="4-14-6-3" @click ="abreTelaDatasFornecedor('Pedidos Compras Abertos Um Fornecedor Prazo')">Pedidos Abertos de um Fornecedor(Filtrado/Ordenado por Prazo de Entrega)</el-menu-item>
                <el-menu-item index="4-14-6-4" @click ="abreTelaDatasFornecedor('Pedidos Compras Abertos Um Fornecedor Data Faturamento')">Pedidos Abertos de um Fornecedor(Filtrado/Ordenado por Data do Faturamento)</el-menu-item>
                <el-menu-item index="4-14-6-5" @click ="abreTelaDatasFornecedor('Pedidos Compras Cancelados Um Fornecedor')">Pedidos Cancelados de um Fornecedor(Filtrado/Ordenado por Data de Emissão)</el-menu-item>
            </el-sub-menu>
        </el-sub-menu> -->

    </el-sub-menu>

    <el-sub-menu index="5">
      <template #title>Utilitários</template>
        <el-menu-item index="5-1" route="/Usuario">Cadastro de Usuários</el-menu-item>  
        <el-menu-item index="5-2" route="/ConfigCabecalho">Configurações dos Cabeçalhos dos Relatórios</el-menu-item>  
        <el-menu-item index="5-2" route="/CasasDecimais">Configurações de Casas Decimais</el-menu-item>
        <el-menu-item index="5-3" route="/ConfiguracoesContasReceber">Configurações de Contas a Receber</el-menu-item>
        <el-menu-item index="5-4" route="/ConfiguracoesOrdensServicos">Configurações de Ordens de Serviços</el-menu-item>
        <el-menu-item index="5-5" route="/ConsultaLogs">Consulta de Log de Usuários</el-menu-item>
        <el-menu-item index="5-6" route="/Permissoes">Define as Permissões dos Usuários</el-menu-item>
        <el-menu-item index="5-7" @click="adquirirRenovar()">Adquirir/Renovar Serviço</el-menu-item>
        <el-menu-item index="5-8" @click="historicoAtualizacoes()">Histórico das Atualizações do Sistema</el-menu-item>
        <el-menu-item index="5-8" @click="validadeServico()">Validade do Serviço</el-menu-item>
        <el-menu-item index="5-9" route="/DadosEmpresa">Dados da Empresa</el-menu-item>
    </el-sub-menu>
    <el-menu-item index="9" @click="logout()" >Logout</el-menu-item>
  </el-menu>

        <!-- <div v-if="false" class="modal fade bs-example-modal-xl" id="modalTelaFree" tabindex="-1" role="dialog"
             aria-labelledby="OS Gold Free">
            <div class="modal-dialog modal-xl " role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <tela-free>
                        </tela-free>
                    </div>
                </div>
            </div>
        </div> -->

        <v-dialog v-model="dialogTelaDemo" transition="dialog-top-transition" max-width="800">
            <TelaDemo @close-dialog="dialogTelaDemo = false" />
        </v-dialog>

        <v-dialog v-model="dialogTelaPaga" transition="dialog-top-transition" max-width="800">
            <TelaPaga @close-dialog="dialogTelaPaga = false" />
        </v-dialog> 





</div>

</template>


