<script setup>
import { onMounted, inject, defineProps, defineEmits, ref } from "vue";

import Moeda from "../Utils/moeda.vue";
import Porcentagem from "../Utils/porcentagem.vue";
import { useRoute } from "vue-router";
import { OrcamentoOrdemServicos } from "../../resources/orcamentoOrdemServicosResource";
import { Converte } from "../../utils/converte";


const snackBarApp = inject("snackBarApp");

const props = defineProps({
    propOrcamentoOrdemServicos: {
        type: Object,
    },
    operacao: {
        type: String,
    },
});

const realPorcentagem = ref([
    { text: "Reais", value: "R" },
    { text: "Porcentagem", value: "P" },
]);

const orcamentoOrdemServicos = ref({});
const operacao = ref(props.operacao);
const route = useRoute();
const isReadOnly = ref(false);

const emit = defineEmits(['close-dialog', 'desconto-alterado']);

onMounted(() => {
    cleanData();

    getOrcamentoOrdemServicos(route.params.id);

    if(operacao.value == "C") {
       isReadOnly.value = true;
    }

    setTimeout(() => {
        document.getElementById("descRPP").focus();
    }, 200);
});


function cleanData() {
    orcamentoOrdemServicos.value.idOrcamentoOrdemServicos = route.params.id;
    orcamentoOrdemServicos.value.valorTotalPS = 'R$ 0,00';
    orcamentoOrdemServicos.value.valorTotalP = 'R$ 0,00';
    orcamentoOrdemServicos.value.descRPP = 'R';
    orcamentoOrdemServicos.value.descontoP = 'R$ 0,00';
    orcamentoOrdemServicos.value.valorTotalPComDesconto = 'R$ 0,00';
    orcamentoOrdemServicos.value.valorTotalS = 'R$ 0,00';
    orcamentoOrdemServicos.value.descRPS = 'R';
    orcamentoOrdemServicos.value.descontoS = 'R$ 0,00';
    orcamentoOrdemServicos.value.valorTotalSComDesconto = 'R$ 0,00';
    orcamentoOrdemServicos.value.valorTotalComDesconto = 'R$ 0,00';
    
    getTotais();

}

async function getTotais() {
    try {
        let response = await OrcamentoOrdemServicos.totais(orcamentoOrdemServicos.value.idOrcamentoOrdemServicos);
        if (response.status == 200) {
            if (response.status == 200) {
                orcamentoOrdemServicos.value.valorTotalPS =  Converte.numberToCurrency(response.data.data.total);
                orcamentoOrdemServicos.value.valorTotalP = Converte.numberToCurrency(response.data.data.totalProdutos);
                orcamentoOrdemServicos.value.valorTotalS = Converte.numberToCurrency(response.data.data.totalServicos);
                calculaValorTotalComDesconto();
            }

        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar os totais do orçamento de ordem de serviços.');
        console.log(err);
    }
}


async function submit() {
    let valorTotalPComDesconto = Converte.stringToFloat(orcamentoOrdemServicos.value.valorTotalPComDesconto);
    if(valorTotalPComDesconto < 0 ) {
        snackBarApp.value.open('O valor total dos produtos com desconto deve ser maior ou igual a 0. Corrija os valores dos descontos dos produtos.', 6000);
        return;
    }

    let valorTotalSComDesconto = Converte.stringToFloat(orcamentoOrdemServicos.value.valorTotalSComDesconto);
    if(valorTotalSComDesconto < 0 ) {
        snackBarApp.value.open('O valor total dos serviços com desconto deve ser maior ou igual a 0. Corrija os valores dos descontos dos serviços.', 6000);
        return;
    }

    let valorTotalComDesconto = Converte.stringToFloat(orcamentoOrdemServicos.value.valorTotalComDesconto);
    if(valorTotalComDesconto < 0 ) {
        snackBarApp.value.open('O valor total com desconto deve ser maior ou igual a 0. Corrija os valores dos descontos.', 6000);
        return;
    }


    let obj =  {};
    obj.idOrcamentoOrdemServicos = orcamentoOrdemServicos.value.idOrcamentoOrdemServicos;
    obj.descRPP = orcamentoOrdemServicos.value.descRPP;
    obj.descRPS = orcamentoOrdemServicos.value.descRPS;
    obj.descontoP = Converte.stringToFloat(orcamentoOrdemServicos.value.descontoP);
    obj.descontoS = Converte.stringToFloat(orcamentoOrdemServicos.value.descontoS);

    let dados = new FormData();
    dados.append('dados', JSON.stringify(obj));

    let response = await  OrcamentoOrdemServicos.descontos(dados);
    try {
        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open('Não foi possível salvar os descontos, '+response.data.message, 6000);
                return;
            }

            emit('desconto-alterado');

            snackBarApp.value.open('Descontos gravados com sucesso.');
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao salvar os descontos.');
    }
}

function changeDescRPP() {
    if(orcamentoOrdemServicos.value.descRPP == 'P') {
        orcamentoOrdemServicos.value.descontoP = Converte.numberToPorcentagem(Converte.stringToFloat(orcamentoOrdemServicos.value.descontoP),2);
    }
    if(this.descRPP == 'R') {
        orcamentoOrdemServicos.value.descontoP = Converte.numberToCurrency(Converte.stringToFloat(orcamentoOrdemServicos.value.descontoP));
    }

    calculaValorTotalComDesconto();
}

function changeDescRPS() {
    if(orcamentoOrdemServicos.value.descRPS == 'P') {
        orcamentoOrdemServicos.value.descontoS = Converte.numberToPorcentagem(Converte.stringToFloat(orcamentoOrdemServicos.value.descontoS),2);
    }
    if(this.descRPS == 'R') {
        orcamentoOrdemServicos.value.descontoS = Converte.numberToCurrency(Converte.stringToFloat(orcamentoOrdemServicos.value.descontoS));
    }

    calculaValorTotalComDesconto();
}


function calculaValorTotalComDesconto() {
    let valorTotalComDesconto = 0;
    let descontoP = Converte.stringToFloat(orcamentoOrdemServicos.value.descontoP);
    let descontoS = Converte.stringToFloat(orcamentoOrdemServicos.value.descontoS);
    let descRPP = orcamentoOrdemServicos.value.descRPP;
    let descRPS = orcamentoOrdemServicos.value.descRPS;
    let valorTotalP = Converte.stringToFloat(orcamentoOrdemServicos.value.valorTotalP);
    let valorTotalS = Converte.stringToFloat(orcamentoOrdemServicos.value.valorTotalS);
    let adiantamento = Converte.stringToFloat(orcamentoOrdemServicos.value.adiantamento);
    let valorTotalPComDesconto = 0;
    let valorTotalSComDesconto = 0;

    if(descRPP == 'R') {
        valorTotalPComDesconto = valorTotalP - descontoP;
    } else {
        valorTotalPComDesconto = valorTotalP - (valorTotalP * descontoP / 100);
    }

    if(descRPS == 'R') {
        valorTotalSComDesconto = valorTotalS - descontoS;
    } else {
        valorTotalSComDesconto = valorTotalS - (valorTotalS * descontoS / 100);
    }

    valorTotalComDesconto = valorTotalPComDesconto + valorTotalSComDesconto - adiantamento;

    orcamentoOrdemServicos.value.valorTotalComDesconto = Converte.numberToCurrency(valorTotalComDesconto);
    orcamentoOrdemServicos.value.valorTotalPComDesconto = Converte.numberToCurrency(valorTotalPComDesconto);
    orcamentoOrdemServicos.value.valorTotalSComDesconto = Converte.numberToCurrency(valorTotalSComDesconto);
}

async function getOrcamentoOrdemServicos(idOrcamentoOrdemServicos) {
    let dados = new FormData();
    dados.append('codigoModulo', '03.05.03');
    dados.append('idOrcamentoOrdemServicos', idOrcamentoOrdemServicos);

    try {

        let response = await OrcamentoOrdemServicos.getById(dados);
        if (response.status == 200) {
            orcamentoOrdemServicos.value.descRPP = response.data.data.descRPP;
            orcamentoOrdemServicos.value.descRPS = response.data.data.descRPS;
            if(response.data.data.descRPP == 'R') {
                orcamentoOrdemServicos.value.descontoP = Converte.numberToCurrency(response.data.data.descontoP);
            }
            else {
                orcamentoOrdemServicos.value.descontoP = Converte.numberToPorcentagem(response.data.data.descontoP,2);
            }

            if(response.data.data.descRPS == 'R') {
                orcamentoOrdemServicos.value.descontoS = Converte.numberToCurrency(response.data.data.descontoS);
            }
            else {
                orcamentoOrdemServicos.value.descontoS = Converte.numberToPorcentagem(response.data.data.descontoS,2);
            }
            calculaValorTotalComDesconto();
        }
    }
    catch (err) {
        snackBarApp.value.open('Erro ao buscar o orçamento de ordem de serviços.');
        console.log(err);
    }
}
</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2>Descontos do Orçamento de Ordem de Serviços</h2>
        </v-card-title>
        <form ref="formDescontosOrcamentoOrdemServicos" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <v-text-field label="Valor Total Produtos+Serviços" id="valorTotalPS" v-model="orcamentoOrdemServicos.valorTotalPS" readOnly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Valor Total Produtos" id="valorTotalP" v-model="orcamentoOrdemServicos.valorTotalP" readOnly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-select
                                id="descRPP"
                                :items="realPorcentagem"
                                item-title="text"
                                item-value="value"
                                label="Desc. em R$ ou em % Produtos"
                                v-model="orcamentoOrdemServicos.descRPP"
                                :readonly="isReadOnly"
                                @update:modelValue="changeDescRPP()"
                         ></v-select>
                    </v-col>
                    <v-col cols="3" v-show="orcamentoOrdemServicos.descRPP == 'R'">
                        <Moeda label="Desconto nos Produtos em (R$)" id="descontoP" v-model="orcamentoOrdemServicos.descontoP" 
                            @blur="calculaValorTotalComDesconto()" 
                            :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3" v-show="orcamentoOrdemServicos.descRPP == 'P'">
                        <Porcentagem label="Desconto nos Produtos em (%)" id="descontoP" v-model="orcamentoOrdemServicos.descontoP" 
                            @blur="calculaValorTotalComDesconto()" 
                            :readonly="isReadOnly" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <Moeda label="V.Total dos Produtos com Descontos" id="valorTotalPComDesconto" 
                            v-model="orcamentoOrdemServicos.valorTotalPComDesconto" 
                            readonly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Valor Total Serviços" id="valorTotalS" v-model="orcamentoOrdemServicos.valorTotalS" readOnly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-select
                                :items="realPorcentagem"
                                item-title="text"
                                item-value="value"
                                label="Desc.em R$ ou em % Serviços"
                                v-model="orcamentoOrdemServicos.descRPS"
                                :readonly="isReadOnly"
                                @update:modelValue="changeDescRPS()"
                            ></v-select>
                    </v-col>
                    <v-col cols="3" v-show="orcamentoOrdemServicos.descRPS == 'R'">
                        <Moeda label="Desconto nos Serviços em (R$)" id="descontoS" v-model="orcamentoOrdemServicos.descontoS" 
                            @blur="calculaValorTotalComDesconto()" 
                            :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3" v-show="orcamentoOrdemServicos.descRPS == 'P'">
                        <Porcentagem label="Desconto nos Serviços em (%)" id="descontoS" v-model="orcamentoOrdemServicos.descontoS" 
                            @blur="calculaValorAPagar()" 
                            :readonly="isReadOnly" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <Moeda label="Valor Total dos Serviços com Descontos" id="valorTotalSComDesconto" 
                            v-model="orcamentoOrdemServicos.valorTotalSComDesconto" 
                            readonly tabindex="-1" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Valor Total com Descontos" 
                            id="valorTotalComDesconto" 
                            v-model="orcamentoOrdemServicos.valorTotalComDesconto" 
                            readOnly 
                            tabindex="-1" />
                    </v-col>
                </v-row>

            </v-card-text>
            <v-card-actions>
                <v-btn v-show="operacao !== 'C'" color="primary" variant="elevated" @click.prevent="submit()" id="btnConfirmarDesconto">Confirmar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="elevated"
                    @click.prevent="emit('close-dialog')">
                    Fechar
                    </v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>