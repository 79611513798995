<script setup>
import { ref, onMounted, inject, defineEmits, defineProps } from "vue";
import { LancamentoConta } from "../../resources/lancamentoContaResource";
import {Conta } from "../../resources/contaResource";
import { useField, useForm } from "vee-validate";
import { string, object, addMethod } from "yup";
import { usePermissoes } from "../../store/permissoes";
import Moeda from "../Utils/moeda.vue";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";

const props = defineProps({
    apelidoContaOrigem: { 
        type: String,
        default: '',
    },
    idContaOrigem: { 
        type: Number,
        default: 0,
    },
    detalhesContaOrigem: { 
        type: String,
        default: '',
    },
});

const showBtnGravar = ref(true);
const snackBarApp = inject("snackBarApp");
const permissoesStore = usePermissoes();
const detalhesContaOrigem = ref(props.apelidoContaOrigem + ', ' + props.detalhesContaOrigem);
const detalhesContaDestino = ref('');
const emit = defineEmits(["transferiu",'close-dialog']);
const contasDestino = ref([]);

addMethod(string, "valor_maior_zero", function valorMaiorZero(message) {
    return string().test(
        "valor-maior-zero", // Name
        message, // Msg
        function (valor) {
            if(Converte.stringToFloat(valor) <= 0) {
                return false;
            }
            return true;
        }
    );
});

const validationSchema = object({
    transferenciaConta: object({
        idContaOrigem: undefined, 
        idContaDestino: undefined, 
        data: string().required("A data é obrigatória"),
        valor: string().required("O valor é obrigatório").valor_maior_zero("O valor deve ser maior que zero"),
    }).required(),
});
const { handleSubmit, errors } = useForm({ validationSchema });

const transferenciaConta = ref({
    idContaOrigem: useField("transferenciaConta.idContaOrigem").value,
    idContaDestino: useField("transferenciaConta.idContaDestino").value,
    data: useField("transferenciaConta.data").value,
    valor: useField("transferenciaConta.valor").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    cleanForm();

    getContas();


    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    transferenciaConta.value.idContaOrigem = props.idContaOrigem;
    transferenciaConta.value.data = new Date().toLocaleDateString("pt-BR");
    transferenciaConta.value.valor = "R$ 0,00";
}

async function gravar(values, actions) {
    let dados = new FormData();

    values.transferenciaConta.data = Converte.datePtBrToIso(values.transferenciaConta.data); 

    dados.append("dados", JSON.stringify(values.transferenciaConta));

    try {
        
        const response = await LancamentoConta.transferenciaContas(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBarApp.value.open("A transferência não pode ser efetuada, mensagem de erro: " + response.data.message, 6000);
                return;
            }
            snackBarApp.value.open("Transferência efetuada com sucesso.");
            actions.resetForm();
            emit('transferiu');
            
        }
    } catch (err) {
        snackBarApp.value.open("Erro, mensagem de erro: " + err);
    }
}

async function getContas() {
    const response = await Conta.getContas();
    contasDestino.value = response.data.data;

    if (contasDestino.value.length > 0 && (transferenciaConta.value.idContaDestino == null || transferenciaConta.value.idContaDestino == 0)) {
        transferenciaConta.value.idContaDestino = response.data.data[0].idConta;
    }

    changeConta();
}

async function changeConta() {
    let dados = new FormData();
    dados.append('codigoModulo', '03.09');
    dados.append('idConta', transferenciaConta.value.idContaDestino);
    try {
        const response = await Conta.getById(dados);
        if (response.status == 200) {
            let conta = response.data.data;

            detalhesContaDestino.value = 'Banco:'+conta.nomeBanco+' agência:'+conta.agencia+' nro.conta:'+conta.conta+' saldo atual:'+Converte.numberToCurrency(conta.saldo);
        }
    }
    catch (err) {
        console.log(err);
    }
}


</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2>Transferência entre Contas</h2>
        </v-card-title>
        <form ref="formTransferenciaContas" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            label="Conta de Origem"
                            id="detalhesContaOrigem"
                            v-model="detalhesContaOrigem"
                            readonly>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-select
                            :items="contasDestino"
                            item-title="apelido"
                            item-value="idConta"
                            label="Conta de Destino"
                            v-model="transferenciaConta.idContaDestino"
                            @update:modelValue="changeConta"
                        ></v-select>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field
                            label="Detalhes da Conta de Destino"
                            id="detalhesContaDestino"
                            v-model="detalhesContaDestino"
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <Data
                            label="Data"
                            id="data"
                            v-model="transferenciaConta.data"
                            :error-messages="errors['transferenciaConta.data']"
                            maxlength="10"
                        />
                    </v-col>
                    <v-col cols="3">
                        <moeda
                            label="Valor"
                            id="valor"
                            v-model="transferenciaConta.valor"
                            :error-messages="errors['transferenciaConta.valor']"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" v-show="showBtnGravar" type="submit" id="btnTransferencaContaGravar">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" variant="elevated" @click.prevent= "emit('close-dialog');">Fechar</v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>



<!-- <template>
    <div class="container-fluid">

        <h2>Transferência entre Contas</h2>

        <div class="panel panel-default">
            <div class="panel-body">

                <form data-vv-scope="formTransferenciaContas">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="control-label">Conta de Origem</label>
                                <span class="form-control">{{ detalhesContaOrigem }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label" for="idContaDestino">Conta de Destino</label>
                                <select class="form-control"
                                        id="idContaDestino"
                                        @change="changeConta()"
                                        name="idContaDestino"
                                        v-model='idContaDestino'>
                                    <option v-for="(o) in contas" :value="o.idConta" :key="o">{{ o.apelido }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="form-group">
                                <label class="control-label">Detalhes da Conta de Destino</label>
                                <span class="form-control">{{ detalhesContaDestino }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group" :class="[errors.has('formTransferenciaContas.data') ? 'has-error' : '']">
                                <label class="control-label" for="data">Data</label>
                                <date-picker
                                        class="form-control"
                                        v-validate="'required'"
                                        v-model="data"
                                        id="data"
                                        name="data"
                                        :config="configDatePicker">
                                </date-picker>
                                <span class="help-block"  v-show="errors.has('formTransferenciaContas.data')">
                             {{ errors.first('formTransferenciaContas.data') }}</span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group" >
                                <label class="control-label" for="valor">Valor</label>
                                <moeda
                                        class="form-control"
                                        id="valor"
                                        name="valor"
                                        v-model='valor'
                                        maxlength="18"></moeda>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-2">
                            <a class="btn btn-primary" href="#" @click.prevent="submit()">Confirmar</a>
                        </div>
                        <div class="col-md-4 col-md-offset-6 text-right">
                            <a class="btn btn-primary" href="#" @click.prevent='closeModalTransferenciaContas()'>Fechar</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '../../store/store';
    import {LancamentoConta} from '../../resources/lancamentoContaResource';
    import {Conta} from '../../resources/contaResource';
    import Moeda from '../Utils/moeda.vue';
    import {Converte} from '../../utils/converte';
    import Datepicker from 'vue-bootstrap-datetimepicker';

    export default {
        components: {
            'moeda':Moeda,
            'date-picker':Datepicker,

        },
        data() {
            return {
                contas:{},
                apelidoOrigem:'',
                idContaOrigem: null,
                idContaDestino: null,
                detalhesContaOrigem:'',
                detalhesContaDestino:'',
                data:  this.$moment(),
                valor:'R$ 0,00',
                configDatePicker: store.state.datePicker,
            }
        },

        mounted() {
            this.getContas();

           this.$('[data-toggle="tooltip"]').tooltip();


        },
        created() {
            this.$parent.$emit('setaTransferenciaContas', this.setaTransferenciaContas);
            this.$emit('transferiu');
        },

        methods: {
            setaTransferenciaContas(apelidoOrigem, idContaOrigem, detalhesOrigem) {
                this.idContaOrigem = idContaOrigem;
                this.detalhesContaOrigem = "Apelido:"+apelidoOrigem+','+detalhesOrigem;
            },
            closeModalTransferenciaContas() {
               this.$('#modalTransferenciaContas').modal('hide');
            },

            submit() {
                let self = this;

                this.$validator.validateAll('formTransferenciaContas').then((result) => {
                    if (result) {

                        if(self.data == '') {
                            self.data = null;
                        }
                        else {
                            self.data = this.$moment(self.data, 'YYYY-MM-DD').format('YYYY-MM-DD');
                        }

                        let obj =  {};
                        obj.idContaOrigem = self.idContaOrigem;
                        obj.idContaDestino = self.idContaDestino;
                        obj.data = self.data;
                        obj.valor = self.valor;

                        let dados = new FormData();
                        dados.append('dados', JSON.stringify(obj));

                        LancamentoConta.transferenciaContas(dados)
                            .then(function (response) {
                                if (response.status == 200) {

                                    if (response.data.success == false) {

                                        self.$notify({
                                            title: 'Erro',
                                            message: 'Não foi possível realizar a transferência entre as contas, '+
                                            response.data.message,
                                            type: 'error',
                                            duration: 6000
                                        });

                                        return;
                                    }

                                    self.$notify({
                                        title: 'Transferência entre Contas',
                                        message: 'Transferência realizada com sucesso.',
                                        type: 'success'
                                    });


                                    self.data = this.$moment();
                                    self.valor = 'R$ 0,00';

                                    self.$parent.$emit('transferiu');
                                    self.closeModalTransferenciaContas();
                                }
                            })
                            .catch(function () {
                            });
                    }
                    else {
                        self.$notify({
                            title:'Erro',
                            message: 'Não foi possível realizar a transferência entre as contas, corrija os erros do formulário e tente novamente.',
                            type: 'error'
                        });
                        return;

                    }
                });
            },
            getContas() {
                let self = this;
                Conta.getContas()
                    .then(function (response) {
                        self.contas = response.data.data;

                        if(self.idContaDestino == null || self.idContaDestino == 0) {
                            self.idContaDestino = response.data.data[0].idConta;
                        }
                        // else {
                        //     self.idConta = store.state.lancamentoConta.idConta;
                        // }

                        self.changeConta();
                    })
                    .catch(function () {

                    });
            },
            changeConta() {
                let self = this;

                let dados = new FormData();
                dados.append('codigoModulo', '03.09');
                dados.append('idConta', this.idContaDestino);
                Conta.getById(dados)
                    .then(function (response) {
                        if (response.status == 200) {
                            let conta = response.data.data;

                            self.detalhesContaDestino = 'Banco:'+conta.nomeBanco+' agência:'+conta.agencia+' nro.conta:'+conta.conta+' saldo atual:'+Converte.numberToCurrency(conta.saldo);
                        }
                    })
                    .catch(function () {
                    });
            },


        }
    }
</script> -->