//import Vue from 'vue'
// import Router from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/components/Home'
import Login from '@/components/Login'
import NovoCadastro from '@/components/NovoCadastro/NovoCadastro'
import UsuarioList from '@/components/Usuario/UsuarioList';
import UsuarioCreate from '@/components/Usuario/UsuarioCreate';
import Permissoes from '@/components/Permissoes/Permissoes';
import ClienteList from '@/components/Clientes/ClienteList';
import ClienteCreate from '@/components/Clientes/ClienteCreate';
import ServicoList from '@/components/Servicos/ServicoList';
import ServicoCreate from '@/components/Servicos/ServicoCreate';
import CasasDecimais from '@/components/CasasDecimais/CasasDecimais';
import ProdutoList from '@/components/Produtos/ProdutoList';
import ProdutoCreate from '@/components/Produtos/ProdutoCreate';
import CaixaList from '@/components/Caixa/CaixaList';
import CaixaCreate from '@/components/Caixa/CaixaCreate';
import EntradaEstoque from '@/components/MovimentacaoEstoque/EntradaEstoque';
import SaidaEstoque from '@/components/MovimentacaoEstoque/SaidaEstoque';
import ExcluirReverter from '@/components/MovimentacaoEstoque/ExcluirReverter';
import TipoDocumentoList from '@/components/TiposDocumentos/TipoDocumentoList';
import TipoDocumentoCreate from '@/components/TiposDocumentos/TipoDocumentoCreate';
import ConfiguracoesContasReceber from '@/components/ConfiguracoesContasReceber/ConfiguracoesContasReceber';
import ContasReceberCreate from '@/components/ContasReceber/ContasReceberCreate';
import ContasReceberList from '@/components/ContasReceber/ContasReceberList';
import ContasReceberCreateVarios from '@/components/ContasReceber/ContasReceberCreateVarios';
import ContasReceberRecebimentos from '@/components/ContasReceber/ContasReceberRecebimentos';
import ConfiguracoesOrdensServicos from '@/components/ConfiguracoesOrdensServicos/ConfiguracoesOrdensServicos';
import OrdensServicosCreate from '@/components/OrdensServicos/OrdensServicosCreate';
import OrdensServicosList from '@/components/OrdensServicos/OrdensServicosList';
import ConsultaLogs from '@/components/Log/ConsultaLog';
import ConfigCabecalho from '@/components/ConfigCabecalho/ConfigCabecalho';
import TelaDatas from '@/components/Telas/TelaDatas';
import TelaDatasCliente from '@/components/Telas/TelaDatasCliente';
import TelaDatasVendedor from '@/components/Telas/TelaDatasVendedor';
import TelaDatasConta from '@/components/Telas/TelaDatasConta';
import TelaDatasCartao from '@/components/Telas/TelaDatasCartao';
import FornecedorList from '@/components/Fornecedores/FornecedorList';
import FornecedorCreate from '@/components/Fornecedores/FornecedorCreate';
import TelaDatasFornecedor from '@/components/Telas/TelaDatasFornecedor';
import ContasPagarCreate from '@/components/ContasPagar/ContasPagarCreate';
import ContasPagarList from '@/components/ContasPagar/ContasPagarList';
import ContasPagarCreateVarios from '@/components/ContasPagar/ContasPagarCreateVarios';
import OrcamentosOrdensServicosCreate from '@/components/OrcamentosOrdensServicos/OrcamentosOrdensServicosCreate';
import OrcamentosOrdensServicosList from '@/components/OrcamentosOrdensServicos/OrcamentosOrdensServicosList';
import VendedorList from '@/components/Vendedores/VendedorList';
import VendedorCreate from '@/components/Vendedores/VendedorCreate';
import PagamentoVendedorList from '@/components/PagamentoVendedor/PagamentoVendedorList';
import PagamentoVendedorCreate from '@/components/PagamentoVendedor/PagamentoVendedorCreate';
import DadosEmpresa from '@/components/DadosEmpresa/DadosEmpresa';
import ComissaoList from '@/components/Comissoes/ComissaoList';
import BancoList from '@/components/Bancos/BancoList';
import BancoCreate from '@/components/Bancos/BancoCreate';
import ContaList from '@/components/Contas/ContaList';
import ContaCreate from '@/components/Contas/ContaCreate';
import LancamentoContaList from '@/components/LancamentosContas/LancamentoContaList';
import LancamentoContaCreate from '@/components/LancamentosContas/LancamentoContaCreate';
import ChequeEmitidoList from '@/components/ChequesEmitidos/ChequeEmitidoList';
import ChequeEmitidoCreate from '@/components/ChequesEmitidos/ChequeEmitidoCreate';
import ChequeRecebidoList from '@/components/ChequesRecebidos/ChequeRecebidoList';
import ChequeRecebidoCreate from '@/components/ChequesRecebidos/ChequeRecebidoCreate';
import CartaoList from '@/components/Cartoes/CartaoList';
import CartaoCreate from '@/components/Cartoes/CartaoCreate';
import LancamentoCartaoList from '@/components/LancamentosCartoes/LancamentoCartaoList';
import GrupoProdutosList from '@/components/GruposProdutos/GrupoProdutosList';
import GrupoProdutosCreate from '@/components/GruposProdutos/GrupoProdutosCreate';
import SubgrupoProdutosList from '@/components/SubgruposProdutos/SubgrupoProdutosList';
import SubgrupoProdutosCreate from '@/components/SubgruposProdutos/SubgrupoProdutosCreate';
import GrupoServicosList from '@/components/GruposServicos/GrupoServicosList';
import GrupoServicosCreate from '@/components/GruposServicos/GrupoServicosCreate';
import GrupoFornecedoresList from '@/components/GruposFornecedores/GrupoFornecedoresList';
import GrupoFornecedoresCreate from '@/components/GruposFornecedores/GrupoFornecedoresCreate';
import SubgrupoServicosList from '@/components/SubgruposServicos/SubgrupoServicosList';
import SubgrupoServicosCreate from '@/components/SubgruposServicos/SubgrupoServicosCreate';
import GrupoClientesList from '@/components/GruposClientes/GrupoClientesList';
import GrupoClientesCreate from '@/components/GruposClientes/GrupoClientesCreate';
import GrupoContasPagarList from '@/components/GruposContasPagar/GrupoContasPagarList';
import GrupoContasPagarCreate from '@/components/GruposContasPagar/GrupoContasPagarCreate';
import SubgrupoContasPagarList from '@/components/SubgruposContasPagar/SubgrupoContasPagarList';
import SubgrupoContasPagarCreate from '@/components/SubgruposContasPagar/SubgrupoContasPagarCreate';
import GrupoContasReceberList from '@/components/GruposContasReceber/GrupoContasReceberList';
import GrupoContasReceberCreate from '@/components/GruposContasReceber/GrupoContasReceberCreate';
import SubgrupoContasReceberList from '@/components/SubgruposContasReceber/SubgrupoContasReceberList';
import SubgrupoContasReceberCreate from '@/components/SubgruposContasReceber/SubgrupoContasReceberCreate';
import AgendamentoVisitaCreate from '@/components/AgendamentosVisitas/AgendamentoVisitaCreate';
import AgendamentoVisitaList from '@/components/AgendamentosVisitas/AgendamentoVisitaList';
import PedidosComprasCreate from '@/components/PedidosCompras/PedidosComprasCreate';
import PedidosComprasList from '@/components/PedidosCompras/PedidosComprasList';
import Relatorios from '@/components/Relatorios/Relatorios';
// Vue.use(Router);

export default createRouter({
    history: createWebHistory(),
    routes: [
        {
          path: '/',
          name: 'Home',
          component: Home
        },
        {
            path: '/Login',
            name: 'Login',
            component: Login
        },
        {
            path: '/NovoCadastro',
            name: 'NovoCadastro.NovoCadastro',
            component: NovoCadastro
        },

        // Cadastros
        {
            path: '/Cliente',
            name: 'Cliente.List',
            component: ClienteList
        },
        {
            path: '/Cliente/Create',
            name: 'Cliente.Create',
            component: ClienteCreate
        },
        {
            path: '/Cliente/:id/Update',
            name: 'Cliente.Update',
            component: ClienteCreate
        },
        {
            path: '/Cliente/:id',
            name: 'Cliente.Show',
            component: ClienteCreate
        },
        {
            path: '/Servico',
            name: 'Servico.List',
            component: ServicoList
        },
        {
            path: '/Servico/Create',
            name: 'Servico.Create',
            component: ServicoCreate
        },
        {
            path: '/Servico/:id/Update',
            name: 'Servico.Update',
            component: ServicoCreate
        },
        {
            path: '/Servico/:id',
            name: 'Servico.Show',
            component: ServicoCreate
        },

        {
            path: '/Produto',
            name: 'Produto.List',
            component: ProdutoList
        },
        {
            path: '/Produto/Create',
            name: 'Produto.Create',
            component: ProdutoCreate
        },
        {
            path: '/Produto/:id/Update',
            name: 'Produto.Update',
            component: ProdutoCreate
        },
        {
            path: '/Produto/:id',
            name: 'Produto.Show',
            component: ProdutoCreate
        },

        {
            path: '/TipoDocumento',
            name: 'TipoDocumento.List',
            component: TipoDocumentoList
        },
        {
            path: '/TipoDocumento/Create',
            name: 'TipoDocumento.Create',
            component: TipoDocumentoCreate
        },
        {
            path: '/TipoDocumento/:id/Update',
            name: 'TipoDocumento.Update',
            component: TipoDocumentoCreate
        },
        {
            path: '/Fornecedor',
            name: 'Fornecedor.List',
            component: FornecedorList
        },
        {
            path: '/Fornecedor/Create',
            name: 'Fornecedor.Create',
            component: FornecedorCreate
        },
        {
            path: '/Fornecedor/:id/Update',
            name: 'Fornecedor.Update',
            component: FornecedorCreate
        },
        {
            path: '/Fornecedor/:id',
            name: 'Fornecedor.Show',
            component: FornecedorCreate
        },
        {
            path: '/Vendedor',
            name: 'Vendedor.List',
            component: VendedorList
        },
        {
            path: '/Vendedor/Create',
            name: 'Vendedor.Create',
            component: VendedorCreate
        },
        {
            path: '/Vendedor/:id/Update',
            name: 'Vendedor.Update',
            component: VendedorCreate
        },
        {
            path: '/Vendedor/:id',
            name: 'Vendedor.Show',
            component: VendedorCreate
        },
        {
            path: '/Banco',
            name: 'Banco.List',
            component: BancoList
        },
        {
            path: '/Banco/Create',
            name: 'Banco.Create',
            component: BancoCreate
        },
        {
            path: '/Banco/:id/Update',
            name: 'Banco.Update',
            component: BancoCreate
        },
        {
            path: '/Conta',
            name: 'Conta.List',
            component: ContaList
        },
        {
            path: '/Conta/Create',
            name: 'Conta.Create',
            component: ContaCreate
        },
        {
            path: '/Conta/:id/Update',
            name: 'Conta.Update',
            component: ContaCreate
        },
        {
            path: '/Conta/:id',
            name: 'Conta.Show',
            component: ContaCreate
        },
        {
            path: '/Cartao',
            name: 'Cartao.List',
            component: CartaoList
        },
        {
            path: '/Cartao/Create',
            name: 'Cartao.Create',
            component: CartaoCreate
        },
        {
            path: '/Cartao/:id/Update',
            name: 'Cartao.Update',
            component: CartaoCreate
        },
        {
            path: '/Cartao/:id',
            name: 'Cartao.Show',
            component: CartaoCreate
        },
        {
            path: '/GrupoProdutos',
            name: 'GrupoProdutos.List',
            component: GrupoProdutosList
        },
        {
            path: '/GrupoProdutos/Create',
            name: 'GrupoProdutos.Create',
            component: GrupoProdutosCreate
        },
        {
            path: '/GrupoProdutos/:id/Update',
            name: 'GrupoProdutos.Update',
            component: GrupoProdutosCreate
        },
        {
            path: '/SubgrupoProdutos',
            name: 'SubgrupoProdutos.List',
            component: SubgrupoProdutosList
        },
        {
            path: '/SubgrupoProdutos/Create',
            name: 'SubgrupoProdutos.Create',
            component: SubgrupoProdutosCreate
        },
        {
            path: '/SubgrupoProdutos/:id/Update',
            name: 'SubgrupoProdutos.Update',
            component: SubgrupoProdutosCreate
        },
        {
            path: '/GrupoServicos',
            name: 'GrupoServicos.List',
            component: GrupoServicosList
        },
        {
            path: '/GrupoServicos/Create',
            name: 'GrupoServicos.Create',
            component: GrupoServicosCreate
        },
        {
            path: '/GrupoServicos/:id/Update',
            name: 'GrupoServicos.Update',
            component: GrupoServicosCreate
        },
        {
            path: '/SubgrupoServicos',
            name: 'SubgrupoServicos.List',
            component: SubgrupoServicosList
        },
        {
            path: '/SubgrupoServicos/Create',
            name: 'SubgrupoServicos.Create',
            component: SubgrupoServicosCreate
        },
        {
            path: '/SubgrupoServicos/:id/Update',
            name: 'SubgrupoServicos.Update',
            component: SubgrupoServicosCreate
        },
        {
            path: '/GrupoClientes',
            name: 'GrupoClientes.List',
            component: GrupoClientesList
        },
        {
            path: '/GrupoClientes/Create',
            name: 'GrupoClientes.Create',
            component: GrupoClientesCreate
        },
        {
            path: '/GrupoClientes/:id/Update',
            name: 'GrupoClientes.Update',
            component: GrupoClientesCreate
        },
        {
            path: '/GrupoContasPagar',
            name: 'GrupoContasPagar.List',
            component: GrupoContasPagarList
        },
        {
            path: '/GrupoContasPagar/Create',
            name: 'GrupoContasPagar.Create',
            component: GrupoContasPagarCreate
        },
        {
            path: '/GrupoContasPagar/:id/Update',
            name: 'GrupoContasPagar.Update',
            component: GrupoContasPagarCreate
        },
        {
            path: '/SubgrupoContasPagar',
            name: 'SubgrupoContasPagar.List',
            component: SubgrupoContasPagarList
        },
        {
            path: '/SubgrupoContasPagar/Create',
            name: 'SubgrupoContasPagar.Create',
            component: SubgrupoContasPagarCreate
        },
        {
            path: '/SubgrupoContasPagar/:id/Update',
            name: 'SubgrupoContasPagar.Update',
            component: SubgrupoContasPagarCreate
        },
        {
            path: '/GrupoContasReceber',
            name: 'GrupoContasReceber.List',
            component: GrupoContasReceberList
        },
        {
            path: '/GrupoContasReceber/Create',
            name: 'GrupoContasReceber.Create',
            component: GrupoContasReceberCreate
        },
        {
            path: '/GrupoContasReceber/:id/Update',
            name: 'GrupoContasReceber.Update',
            component: GrupoContasReceberCreate
        },

        {
            path: '/SubgrupoContasReceber',
            name: 'SubgrupoContasReceber.List',
            component: SubgrupoContasReceberList
        },
        {
            path: '/SubgrupoContasReceber/Create',
            name: 'SubgrupoContasReceber.Create',
            component: SubgrupoContasReceberCreate
        },
        {
            path: '/SubgrupoContasReceber/:id/Update',
            name: 'SubgrupoContasReceber.Update',
            component: SubgrupoContasReceberCreate
        },
        {
            path: '/GrupoFornecedores',
            name: 'GrupoFornecedores.List',
            component: GrupoFornecedoresList
        },
        {
            path: '/GrupoFornecedores/Create',
            name: 'GrupoFornecedores.Create',
            component: GrupoFornecedoresCreate
        },
        {
            path: '/GrupoFornecedores/:id/Update',
            name: 'GrupoFornecedores.Update',
            component: GrupoFornecedoresCreate
        },
        {
            path: '/AgendamentoVisita',
            name: 'AgendamentoVisita.List',
            component: AgendamentoVisitaList
        },
        {
            path: '/AgendamentoVisista/Create',
            name: 'AgendamentoVisita.Create',
            component: AgendamentoVisitaCreate
        },
        {
            path: '/AgendamentoVisita/:id/Update',
            name: 'AgendamentoVisita.Update',
            component: AgendamentoVisitaCreate
        },
        {
            path: '/AgendamentoVisita/:id',
            name: 'AgendamentoVisita.Show',
            component: AgendamentoVisitaCreate
        },

        // Movimentação de Estoque
        {
            path: '/EntradaEstoque',
            name: 'EntradaEstoque',
            component: EntradaEstoque
        },
        {
            path: '/SaidaEstoque',
            name: 'SaidaEstoque',
            component: SaidaEstoque
        },
        {
            path: '/ExcluirReverter',
            name: 'ExcluirReverterMovimentacaoEstoque',
            component: ExcluirReverter
        },


        // Faturamento
        {
            path: '/Caixa',
            name: 'Caixa.List',
            component: CaixaList
        },
        {
            path: '/Caixa/Create',
            name: 'Caixa.Create',
            component: CaixaCreate
        },

        {
            path: '/ContasReceber',
            name: 'ContasReceber.List',
            component: ContasReceberList
        },
        {
            path: '/ContasReceber/Create',
            name: 'ContasReceber.Create',
            component: ContasReceberCreate
        },
        {
            path: '/ContasReceber/:id/Update',
            name: 'ContasReceber.Update',
            component: ContasReceberCreate
        },
        {
            path: '/ContasReceber/:id/Quita',
            name: 'ContasReceber.Quita',
            component: ContasReceberCreate
        },
        {
            path: '/ContasReceber/CreateVarios',
            name: 'ContasReceber.CreateVarios',
            component: ContasReceberCreateVarios
        },
        {
            path: '/ContasReceber/:id/ExcluirRecebimentos',
            name: 'ContasReceber.ExcluirRecebimento',
            component: ContasReceberRecebimentos
        },

        {
            path: '/ContasReceber/:id/Show',
            name: 'ContasReceber.Show',
            component: ContasReceberCreate
        },

        {
            path: '/OrdensServicos',
            name: 'OrdensServicos.List',
            component: OrdensServicosList
        },
        {
            path: '/OrdensServicos/Create',
            name: 'OrdensServicos.Create',
            component: OrdensServicosCreate
        },
        {
            path: '/OrdensServicos/:id/Continuar',
            name: 'OrdensServicos.Continuar',
            component: OrdensServicosCreate
        },
        {
            path: '/OrdensServicos/:id',
            name: 'OrdensServicos.Show',
            component: OrdensServicosCreate
        },
        {
            path: '/ContasPagar',
            name: 'ContasPagar.List',
            component: ContasPagarList
        },
        {
            path: '/ContasPagar/Create',
            name: 'ContasPagar.Create',
            component: ContasPagarCreate
        },
        {
            path: '/ContasPagar/:id/Update',
            name: 'ContasPagar.Update',
            component: ContasPagarCreate
        },
        {
            path: '/ContasPagar/:id/Quita',
            name: 'ContasPagar.Quita',
            component: ContasPagarCreate
        },
        {
            path: '/ContasPagar/CreateVarios',
            name: 'ContasPagar.CreateVarios',
            component: ContasPagarCreateVarios
        },
        {
            path: '/ContasPagar/:id/Show',
            name: 'ContasPagar.Show',
            component: ContasPagarCreate
        },

        {
            path: '/OrcamentosOrdensServicos',
            name: 'OrcamentosOrdensServicos.List',
            component: OrcamentosOrdensServicosList
        },
        {
            path: '/OrcamentosOrdensServicos/Create',
            name: 'OrcamentosOrdensServicos.Create',
            component: OrcamentosOrdensServicosCreate
        },
        {
            path: '/OrcamentosOrdensServicos/:id/Continuar',
            name: 'OrcamentosOrdensServicos.Continuar',
            component: OrcamentosOrdensServicosCreate
        },
        {
            path: '/OrcamentosOrdensServicos/:id',
            name: 'OrcamentosOrdensServicos.Show',
            component: OrcamentosOrdensServicosCreate
        },
        {
            path: '/PagamentosVendedores',
            name: 'PagamentosVendedores.List',
            component: PagamentoVendedorList
        },
        {
            path: '/PagamentosVendedores/Create',
            name: 'PagamentosVendedores.Create',
            component: PagamentoVendedorCreate
        },
        {
            path: '/PagamentosVendedores/:id',
            name: 'PagamentosVendedores.Show',
            component: PagamentoVendedorCreate
        },
        {
            path: '/Comissoes',
            name: 'Comissoes.List',
            component: ComissaoList
        },
        {
            path: '/LancamentosContas',
            name: 'LancamentosContas.List',
            component: LancamentoContaList
        },
        {
            path: '/LancamentosContas/Create/:idConta',
            name: 'LancamentosContas.Create',
            component: LancamentoContaCreate
        },

        {
            path: '/ChequesEmitidos',
            name: 'ChequesEmitidos.List',
            component: ChequeEmitidoList
        },
        {
            path: '/ChequesEmitidos/Create',
            name: 'ChequesEmitidos.Create',
            component: ChequeEmitidoCreate
        },
        {
            path: '/ChequesEmitidos/:id/Update',
            name: 'ChequesEmitidos.Update',
            component: ChequeEmitidoCreate
        },
        {
            path: '/ChequesEmitidos/:id/Baixa',
            name: 'ChequesEmitidos.Baixa',
            component: ChequeEmitidoCreate
        },
        {
            path: '/ChequesEmitidos/:id',
            name: 'ChequesEmitidos.Show',
            component: ChequeEmitidoCreate
        },
        {
            path: '/ChequesRecebidos',
            name: 'ChequesRecebidos.List',
            component: ChequeRecebidoList
        },
        {
            path: '/ChequesRecebidos/Create',
            name: 'ChequesRecebidos.Create',
            component: ChequeRecebidoCreate
        },
        {
            path: '/ChequesRecebidos/:id/Update',
            name: 'ChequesRecebidos.Update',
            component: ChequeRecebidoCreate
        },
        {
            path: '/ChequesRecebidos/:id/Baixa',
            name: 'ChequesRecebidos.Baixa',
            component: ChequeRecebidoCreate
        },
        {
            path: '/ChequesRecebidos/:id',
            name: 'ChequesRecebidos.Show',
            component: ChequeRecebidoCreate
        },
        {
            path: '/LancamentosCartoes',
            name: 'LancamentosCartoes.List',
            component: LancamentoCartaoList
        },
        {
            path: '/PedidosCompras',
            name: 'PedidosCompras.List',
            component: PedidosComprasList
        },
        {
            path: '/PedidosCompras/Create',
            name: 'PedidosCompras.Create',
            component: PedidosComprasCreate
        },
        {
            path: '/PedidosCompras/:id/Continuar',
            name: 'PedidosCompras.Continuar',
            component: PedidosComprasCreate
        },
        {
            path: '/PedidosCompras/:id',
            name: 'PedidosCompras.Show',
            component: PedidosComprasCreate
        },


        // Utilitários
        {
            path: '/Usuario',
            name: 'Usuario.List',
            component: UsuarioList
        },
        {
            path: '/Usuario/Create',
            name: 'Usuario.Create',
            component: UsuarioCreate
        },
        {
            path: '/Usuario/:id/Update',
            name: 'Usuario.Update',
            component: UsuarioCreate
        },
        {
            path: '/Usuario/:id',
            name: 'Usuario.Show',
            component: UsuarioCreate
        },
        {
            path: '/CasasDecimais',
            name: 'CasasDecimais',
            component: CasasDecimais
        },
        {
            path: '/ConfiguracoesContasReceber',
            name: 'ConfiguracoesContasReceber',
            component: ConfiguracoesContasReceber
        },
        {
            path: '/ConfiguracoesOrdensServicos',
            name: 'ConfiguracoesOrdensServicos',
            component: ConfiguracoesOrdensServicos
        },
        {
            path: '/ConsultaLogs',
            name: 'ConsultaLogs',
            component: ConsultaLogs
        },
        {
            path: '/ConfigCabecalho',
            name: 'ConfigCabecalho',
            component: ConfigCabecalho
        },
        {
            path: '/Permissoes',
            name: 'Permissoes',
            component: Permissoes
        },
        {
            path: '/DadosEmpresa',
            name: 'DadosEmpresa',
            component: DadosEmpresa
        },


        // Telas
        {
            path: '/TelaDatas',
            name: 'TelaDatas',
            component: TelaDatas
        },
        {
            path: '/TelaDatasCliente',
            name: 'TelaDatasCliente',
            component: TelaDatasCliente
        },
        {
            path: '/TelaDatasFornecedor',
            name: 'TelaDatasFornecedor',
            component: TelaDatasFornecedor
        },
        {
            path: '/TelaDatasVendedor',
            name: 'TelaDatasVendedor',
            component: TelaDatasVendedor
        },
        {
            path: '/TelaDatasConta',
            name: 'TelaDatasConta',
            component: TelaDatasConta
        },
        {
            path: '/TelaDatasCartao',
            name: 'TelaDatasCartao',
            component: TelaDatasCartao
        },
        {
            path: '/Relatorios',
            name: 'Relatorios',
            component: Relatorios
        }

    ]
})
