import config from '../services/config';
import { defineStore } from 'pinia';

export const useOrcamentosOrdensServicos = defineStore('orcamentosOrdensServicos', {
    state: () => {
        return {
            start:0,
            limit: config.maxPerPage,
            currentPage: 1,
            abertos: true,
            cancelados: true,
            transformados:true,
            dataInicial: new Date().toLocaleDateString("pt-BR"), 
            dataFinal: new Date().toLocaleDateString("pt-BR"), 
            idCliente:null,
            sort: [{
                'property':'data',
                'direction':'ASC'
            }],
            filtrarPor:'data',
            codigo:'',
            codigoCliente:'',
            nomeCliente:'',
            tipoFiltro: 'geral',
            campo: 'campo1',
            buscaCampo:'',
        }
    },
    actions : {
        setStart(start) {
            this.start = start;
        },
        setCurrentPage(currentPage) {
            this.currentPage = currentPage;
        },
        setAbertos(abertos) {
            this.abertos = abertos;
        },
        setCancelados(cancelados) {
            this.cancelados = cancelados;
        },
        setTransformados(transformados) {
            this.transformados = transformados;
        },
        setDataInicial(dataInicial) {
            this.dataInicial = dataInicial;
        },
        setDataFinal(dataFinal) {
            this.dataFinal = dataFinal;
        },
        setIdCliente(idCliente) {
            this.idCliente = idCliente;
        },
        setSort(sort) {
            this.sort = sort;
        },        
        setFiltrarPor(filtrarPor) {
            this.filtrarPor = filtrarPor;
        },
        setCodigo(codigo) {
            this.codigo = codigo;
        },
        setCodigoCliente(codigoCliente) {
            this.codigoCliente = codigoCliente;
        },
        setNomeCliente(nomeCliente) {
            this.nomeCliente = nomeCliente
        },
        setTipoFiltro(tipoFiltro) {
            this.tipoFiltro = tipoFiltro;
        },
        setCampo(campo) {
            this.campo = campo;
        },
        setBuscaCampo(buscaCampo) {
            this.buscaCampo = buscaCampo;
        }
    }
});

// import config from '../services/config';
// import moment from 'moment';

// const state = {
//     start:0,
//     limit: config.maxPerPage,
//     currentPage: 1,
//     abertos: true,
//     cancelados: true,
//     transformados:true,
//     dataInicial: moment(),
//     dataFinal: moment(),
//     idCliente:null,
//     sort: [{
//         'property':'data',
//         'direction':'ASC'
//     }],
//     filtrarPor:'data',
//     codigo:'',
//     codigoCliente:'',
//     nomeCliente:'',
//     tipoFiltro: 'geral',
//     campo:'campo1',
//     buscaCampo:'',

// };

// const mutations = {
//     setStart(state, start) {
//         state.start = start;
//     },
//     setAbertos(state, abertos) {
//         state.abertos = abertos;
//     },
//     setCancelados(state, cancelados) {
//         state.cancelados = cancelados;
//     },
//     setTransformados(state, transformados) {
//         state.transformados = transformados;
//     },
//     setDataInicial(state, dataInicial) {
//         state.dataInicial = dataInicial;
//     },
//     setDataFinal(state, dataFinal) {
//         state.dataFinal = dataFinal;
//     },
//     setCurrentPage(state, currentPage) {
//         state.currentPage = currentPage;
//     },
//     setSort(state, sort) {
//         state.sort = sort;
//     },
//     setIdCliente(state, idCliente) {
//         state.idCliente = idCliente;
//     },
//     setFiltrarPor(state, filtrarPor) {
//         state.filtrarPor = filtrarPor;
//     },
//     setCodigo(state, codigo) {
//         state.codigo = codigo;
//     },
//     setCodigoCliente(state, codigoCliente) {
//         state.codigoCliente = codigoCliente;
//     },
//     setNomeCliente(state, nomeCliente) {
//         state.nomeCliente = nomeCliente;
//     },
//     setTipoFiltro(state, tipoFiltro) {
//         state.tipoFiltro = tipoFiltro;
//     },
//     setCampo(state, campo) {
//         state.campo = campo;
//     },
//     setBuscaCampo(state, buscaCampo) {
//         state.buscaCampo = buscaCampo;
//     },

// };

// const module =  {
//     namespaced:true,
//     state, mutations
// };
// export default module;


