<script setup>
    import { ref, onMounted, defineEmits, defineProps, inject } from 'vue';
    import { ContaPagar } from "../../resources/contaPagarResource";
    import { useRoute } from "vue-router";
    import { valorFormat} from "../../services/filtros";
    import MsgConfirm from "../Utils/MsgConfirm";

    let pagamentos = ref({});
    const route = useRoute();
    const msgConfirmPagamento = ref(null);
    const emit = defineEmits(['close-dialog', 'excluiu-pagamento']);
    const snackBarApp = inject("snackBarApp");
    
    onMounted(() => {
        getPagamentos();
    });

    const props = defineProps({
        idLancamento: {
            type: Number,
            
        },
    });


    async function getPagamentos() {
        let dados = new FormData();
        if(route.name == 'ContasPagar.List') {
            dados.append('idContaPagar', props.idLancamento);
        }
        else {
            dados.append('idContaPagar', route.params.id);
        }
        
        if(route.name == 'ContasPagar.Quita') {
            dados.append('codigoModulo', '03.04.04');
        }
        if(route.name == 'ContasPagar.Update') {
            dados.append('codigoModulo', '03.04.03');
        }
        if(route.name == 'ContasPagar.Show') {
            dados.append('codigoModulo', '03.04.09');
        }
        if(route.name == 'ContasPagar.List') {
            dados.append('codigoModulo', '03.04.08');
        }
        let response = await  ContaPagar.getPagamentosById(dados);
        if (response.status == 200) {
            pagamentos.value = response.data.data;
        }
    }

    function closeModalPagamentos() {
        emit('close-dialog');
    }

    function openModalExcluirPagamento(lancamento) {
        msgConfirmPagamento.value
            .open(
                "Confirma Exclusão ?",
                "Confirma a exclusão desse pagamento ?",
                { color: "primary", zIndex: 5000  }
            )
            .then((confirm) => {
                if (confirm) {
                    excluirPagamento(lancamento);
                }
        });
    }

    async function excluirPagamento(idPagamentoContaPagar) {
        let dados = new FormData();
        dados.append("idPagamentoContaPagar", JSON.stringify(idPagamentoContaPagar));

        try {
            const response = await ContaPagar.excluirPagamento(dados);
                if (response.status == 200) {
                    if (response.data.success == "true") {
                        snackBarApp.value.open("Pagamento de contas a pagar excluído com sucesso.");
                        getPagamentos();
                        emit('excluiu-pagamento');
                    }
                }
        } catch (err) {
            console.log(err);
        }
    }
</script>

<template>
    <v-card>
        <v-card-title>
            <h3 v-if="route.name !== 'ContasPagar.List'">Pagamentos de um Lançamento de Contas a Pagar</h3>
            <h3 v-if="route.name == 'ContasPagar.List'">Exclui Pagamentos de um Lançamento de Contas a Pagar</h3>
        </v-card-title>
        <v-card-text>
            <v-card  elevation="16" class="mb-5">
                <v-card-text>
                    <v-table density="compact">
                        <thead>
                        <tr>
                            <th v-if="route.name=='ContasPagar.List'">Ação</th>
                            <th>Data Pagamento</th>
                            <th>Valor Pago</th>
                            <th>Forma de Pagamento</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(o) in pagamentos" :key="o">
                                <td v-if="route.name=='ContasPagar.List'">
                                    <v-tooltip text="Exclui o pagamento.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn
                                                size="small"
                                                v-bind="props"
                                                variant="text"
                                                icon="mdi-trash-can"
                                                @click.prevent="openModalExcluirPagamento(o.idPagamentoContaPagar)">
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                </td>
                                <td>{{ o.data }}</td>
                                <td>{{ valorFormat(o.valor) }}</td>
                                <td>DINHEIRO</td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card-text>
                
            </v-card>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="elevated" @click.prevent='closeModalPagamentos'>Fechar</v-btn>
        </v-card-actions>
    </v-card>
    <MsgConfirm ref="msgConfirmPagamento"></MsgConfirm>
</template>