import Http from './http';

export class Usuario {

    static get = function(start, limit, filtro, sort, filtrarPor) {
        return Http.get('/Serv/Usuarios/Usuarios.php', {
            params: {
                'operacao': 'listar',
                'start':start,
                'limit':limit,
                'filtro':filtro,
                'sort':sort,
                'filtrarPor': filtrarPor,
            }
        });
    };

    static save = function(dados) {
        return Http.post('/Serv/Usuarios/Usuarios.php?operacao=gravar', dados);
    };

    static getById = function(dados) {
        return Http.post('/Serv/Usuarios/Usuarios.php?operacao=getbyid', dados);
    };

    static excluir = function(dados) {
        return Http.post('/Serv/Usuarios/Usuarios.php?operacao=excluir', dados);
    };

    static proximoCodigo = function() {
        return Http.get('/Serv/Usuarios/Usuarios.php?operacao=proximocodigo');
    };

    static codigoExiste = function(codigo, idUsuario) {
        let dados = new FormData();
        dados.append('codigo', codigo);
        dados.append('idUsuario', idUsuario);
        return Http.post('/Serv/Usuarios/Usuarios.php?operacao=codigoexiste', dados);
    };

    static loginExiste = function(login, idUsuario) {
        let dados = new FormData();
        dados.append('login', login);
        dados.append('idUsuario', idUsuario);
        return Http.post('/Serv/Usuarios/Usuarios.php?operacao=loginexiste', dados);
    };

    static getByCodigo = function(dados) {
        return Http.post('/Serv/Usuarios/Usuarios.php?operacao=getbycodigo', dados);
    };


}
