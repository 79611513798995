import config from '../services/config';
import { defineStore } from 'pinia';

export const useLog = defineStore('log', {
    state: () => {
        return {
            start:0,
            limit: config.maxPerPage,
            currentPage: 1,
            idUsuario: null,
            dataInicial: new Date().toLocaleDateString("pt-BR"), 
            dataFinal: new Date().toLocaleDateString("pt-BR"),   
        }
    },
    actions : {
        setStart(start) {
            this.start = start;
        },
        setDataInicial(dataInicial) {
            this.dataInicial = dataInicial;
        },
        setDataFinal(dataFinal) {
            this.dataFinal = dataFinal;
        },
        setCurrentPage(currentPage) {
            this.currentPage = currentPage;
        },
        setIdUsuario(idUsuario) {
            this.idUsuario = idUsuario;
        }
    }
});

