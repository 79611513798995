<script setup>
import { ref, onMounted, defineEmits } from "vue";
import { useRoute } from "vue-router";
import { Vendedor } from "../../resources/vendedorResource";
import SnackBar from "../Utils/SnackBar";
import { useField, useForm } from "vee-validate";
import { string, number, addMethod, object, date } from "yup";
import { usePermissoes } from "../../store/permissoes";
import { parse, format } from "date-fns";
import Moeda from "../Utils/moeda.vue";
import { Converte } from "../../utils/converte";
import Data from "../Utils/data.vue";

const isRealValidation = ref(null);
const validationCodigo = ref(null);
const snackBar = ref(null);
const route = useRoute();
let showBtnGravar = ref(true);
const emit = defineEmits(["close-dialog", "cadastrou-vendedor"]);
const isReadOnly = ref(false);

const permissoesStore = usePermissoes();
const itemsAtivoInativoBloqueado = ref([
    { text: "Ativo", value: "A" },
    { text: "Inativo", value: "I" },
    { text: "Bloqueado", value: "B" },
]);

addMethod(number, "codigo_vendedor_existe", function codigoVendedorExiste(message) {
    return number().test(
        "codigo-vendedor-existe", // Name
        message, // Msg
        function (codigo_vendedor) {
            if (isRealValidation.value == null) {
                return true;
            }
            if (!isRealValidation.value) {
                return validationCodigo.value;
            }
            isRealValidation.value = false;
            validationCodigo.value = false;

            return new Promise((resolve) => {
                Vendedor.codigoExiste(codigo_vendedor, vendedor.value.idVendedor).then((res) => {
                    if (res.data == false) {
                        validationCodigo.value = true;
                        resolve(true);
                    }

                    resolve(false);
                });
            });
        }
    );
});

const validationSchema = object({
    vendedor: object({
        idVendedor: undefined,
        codigo: number()
            .min(1)
            .max(999999999)
            .codigo_vendedor_existe("Esse código já está sendo usado por outro vendedor, tente outro código.")
            .typeError("O código deve ser um número")
            .label("O código"),
        nome: string().required("O nome é obrigatório").max(50).label("O nome do vendedor"),
        cpf: string().max(18).label("O CPF"),
        rg: string().max(20).label("O RG"),
        telefone: string().max(15).label("O telefone"),
        celular: string().max(15).label("O celular"),
        endereco: string().max(50).label("O endereço"),
        numero: string().max(10).label("O número"),
        complemento: string().max(30).label("O complemento"),
        bairro: string().max(30).label("O bairro"),
        cidade: string().max(30).label("A cidade"),
        estado: string().max(2).label("O estado"),
        cep: string().max(10).label("O CEP"),
        dataAdmissao: date()
            .nullable()
            .transform((value, originalValue) => {
                if (originalValue == "" || originalValue == null) {
                    return null;
                }
                return parse(originalValue, "dd/MM/yyyy", new Date());
            })
            .typeError("Data inválida. Deixe em branco ou digite uma data válida")
            .default(""),
        salario: undefined,
        email: string().email().max(100).label("O email"),
        observacoes: undefined,
        ativoInativoBloqueado: undefined,
        motivoInatividade: string().max(50).label("O motivo da inatividade"),
        motivoBloqueado: string().max(50).label("O motivo do bloqueio"),
    }).required(),
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const vendedor = ref({
    idVendedor: useField("vendedor.idVendedor").value,
    codigo: useField("vendedor.codigo").value,
    nome: useField("vendedor.nome").value,
    cpf: useField("vendedor.cpf").value,
    rg: useField("vendedor.rg").value,
    telefone: useField("vendedor.telefone").value,
    celular: useField("vendedor.celular").value,
    endereco: useField("vendedor.endereco").value,
    numero: useField("vendedor.numero").value,
    complemento: useField("vendedor.complemento").value,
    bairro: useField("vendedor.bairro").value,
    cidade: useField("vendedor.cidade").value,
    estado: useField("vendedor.estado").value,
    cep: useField("vendedor.cep").value,
    dataAdmissao: useField("vendedor.dataAdmissao").value,
    salario: useField("vendedor.salario").value,
    email: useField("vendedor.email").value,
    observacoes: useField("vendedor.observacoes").value,
    ativoInativoBloqueado: useField("vendedor.ativoInativoBloqueado").value,
    motivoInatividade: useField("vendedor.motivoInatividade").value,
    motivoBloqueado: useField("vendedor.motivoBloqueado").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    cleanForm();
    if (route.name == "Vendedor.Update" || route.name == "Vendedor.Show") {
        getVendedor(route.params.id);
    }

    if(route.name == "Vendedor.Show") {
        isReadOnly.value = true;
    }   

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    if (route.name == "Vendedor.Create") {
        vendedor.value.idVendedor = null;
        proximoCodigo();
    }

    vendedor.value.nome = "";
    vendedor.value.cpf = "";
    vendedor.value.rg = "";
    vendedor.value.telefone = "";
    vendedor.value.celular = "";
    vendedor.value.endereco = "";
    vendedor.value.numero = "";
    vendedor.value.complemento = "";
    vendedor.value.bairro = "";
    vendedor.value.cidade = "";
    vendedor.value.estado = "";
    vendedor.value.cep = "";
    vendedor.value.dataAdmissao = "";
    vendedor.value.salario = "R$ 0,00";
    vendedor.value.email = "";
    vendedor.value.observacoes = "";
    vendedor.value.ativoInativoBloqueado = "A";
    vendedor.value.motivoInatividade = "";
    vendedor.value.motivoBloqueado = "";
}

async function gravar(values, actions) {
    let dados = new FormData();
    if (values.vendedor.dataAdmissao == "") {
        values.vendedor.dataAdmissao = null;
    } else {
        values.vendedor.dataAdmissao = format(parse(values.vendedor.dataAdmissao, "dd/MM/yyyy", new Date()), "yyyy-MM-dd");
    }

    dados.append("data", JSON.stringify(values.vendedor));

    try {
        const response = await Vendedor.save(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBar.value.open("O vendedor não pode ser cadastrado, mensagem de erro: " + response.data.message, 6000);
                return;
            }
            if (route.name == "Vendedor.Update") {
                snackBar.value.open("Vendedor alterado com sucesso.");
            } else {
                snackBar.value.open("Novo vendedor cadastrado com sucesso.");
                actions.resetForm();
                cleanForm();
                document.getElementById("codigo").focus();
                emit("cadastrou-vendedor");
            }
        }
    } catch (err) {
        snackBar.value.open("Erro, mensagem de erro: " + err);
    }
}
function closeModalCadastroVendedor() {
    emit("close-dialog");
}

async function getVendedor(id) {
    let dados = new FormData();
    if(route.name== 'Vendedor.Show') {
        dados.append("codigoModulo", "01.06.04");
    }
    else { 
        dados.append("codigoModulo", "01.06.02");
    }
    
    dados.append("idVendedor", id);
    try {
        const response = await Vendedor.getById(dados);
        if (response.status == 200) {
            setValues({ vendedor: response.data.data });

            if (vendedor.value.dataAdmissao == null) {
                vendedor.value.dataAdmissao = "";
            } else {
                vendedor.value.dataAdmissao = format(parse(vendedor.value.dataAdmissao, "yyyy-MM-dd", new Date()), "dd/MM/yyyy");
            }

            vendedor.value.salario = Converte.numberToCurrency(response.data.data.salario);
            document.getElementById("codigo").focus();
        }
    } catch (err) {
        console.log(err);
    }
}

async function proximoCodigo() {
    try {
        const response = await Vendedor.proximoCodigo();
        if (response.status == 200) {
            vendedor.value.codigo = response.data;
        }
    } catch (err) {
        console.log(err);
    }
}
</script>

<template>
    <SnackBar ref="snackBar"></SnackBar>
    <v-card elevation="16">
        <v-card-title>
            <h2 v-show="$route.name !== 'Vendedor.Update' && $route.name !== 'Vendedor.Show'">Novo Vendedor/Funcionário</h2>
            <h2 v-show="$route.name == 'Vendedor.Update'">Alteração de Vendedor/Funcionário</h2>
            <h2 v-show="$route.name == 'Vendedor.Show'">Consulta de Vendedor/Funcionário</h2>
        </v-card-title>
        <form ref="formVendedor" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="2">
                        <v-text-field
                            label="Código"
                            id="codigo"
                            type="text"
                            v-model="vendedor.codigo"
                            :error-messages="errors['vendedor.codigo']"
                            maxlength="9"
                            @blur="(e) => { isRealValidation = true; vendedor.codigo = e.target.value; }"
                            :readonly="isReadOnly"
                        />
                    </v-col>

                    <v-col cols="10">
                        <v-text-field label="Nome" id="nome" type="text" v-model="vendedor.nome" maxlength="50" :error-messages="errors['vendedor.nome']" :readonly="isReadOnly" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-text-field label="CPF" id="cpf" type="text" v-model="vendedor.cpf" maxlength="18" :error-messages="errors['vendedor.cpf']" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="RG" id="rg" type="text" v-model="vendedor.rg" maxlength="20" :error-messages="errors['vendedor.rg']" :readonly="isReadOnly"  />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Telefone" id="telefone" type="text" v-model="vendedor.telefone" maxlength="15" :error-messages="errors['vendedor.telefone']" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Celular" id="celular" type="text" v-model="vendedor.celular" maxlength="15" :error-messages="errors['vendedor.celular']" :readonly="isReadOnly" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="10">
                        <v-text-field label="Endereço" id="endereco" type="text" v-model="vendedor.endereco" maxlength="50" :error-messages="errors['vendedor.endereco']" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="2">
                        <v-text-field label="Número" id="numero" type="text" v-model="vendedor.numero" maxlength="10" :error-messages="errors['vendedor.numero']" :readonly="isReadOnly" />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4">
                        <v-text-field
                            label="Complemento"
                            id="complemento"
                            type="text"
                            v-model="vendedor.complemento"
                            maxlength="30"
                            :error-messages="errors['vendedor.complemento']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field label="Bairro" id="bairro" type="text" v-model="vendedor.bairro" maxlength="30" :error-messages="errors['vendedor.bairro']" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field label="Cidade" id="cidade" type="text" v-model="vendedor.cidade" maxlength="30" :error-messages="errors['vendedor.cidade']" :readonly="isReadOnly" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="2">
                        <v-text-field label="Estado" id="estado" type="text" v-model="vendedor.estado" maxlength="2" :error-messages="errors['vendedor.estado']" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="CEP" id="cep" type="text" v-model="vendedor.cep" maxlength="10" :error-messages="errors['vendedor.cep']" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="3">
                        <Data
                            label="Data de Admissão"
                            id="dataAdmissao"
                            type="text"
                            v-model="vendedor.dataAdmissao"
                            maxlength="10"
                            :error-messages="errors['vendedor.dataAdmissao']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="4">
                        <Moeda label="Salário" id="salario" type="text" v-model="vendedor.salario" maxlength="18" :error-messages="errors['vendedor.salario']" :readonly="isReadOnly" />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-text-field label="Email" id="email" type="text" v-model="vendedor.email" maxlength="100" :error-messages="errors['vendedor.email']" :readonly="isReadOnly" />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-textarea label="Observações" id="observacoes" type="text" v-model="vendedor.observacoes" :readonly="isReadOnly" />
                    </v-col>
                </v-row>

                <v-row cols="2">
                    <v-col>
                        <v-select
                            :items="itemsAtivoInativoBloqueado"
                            label="Ativo/Inativo/Bloqueado"
                            v-model="vendedor.ativoInativoBloqueado"
                            item-title="text"
                            item-value="value"
                            :readonly="isReadOnly"
                        ></v-select>
                    </v-col>
                    <v-col cols="9" v-show="vendedor.ativoInativoBloqueado == 'I'">
                        <v-text-field
                            label="Motivo da inatividade"
                            id="motivoInatividade"
                            type="text"
                            maxlength="50"
                            v-model="vendedor.motivoInatividade"
                            :error-messages="errors['vendedor.motivoInatividade']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="9" v-show="vendedor.ativoInativoBloqueado == 'B'">
                        <v-text-field
                            label="Motivo do Bloqueio"
                            id="motivoBloqueado"
                            maxlength="50"
                            type="text"
                            v-model="vendedor.motivoBloqueado"
                            :error-messages="errors['vendedor.motivoBloqueado']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" type="submit" id="btnVendedorGravar" v-show="$route.name !== 'Vendedor.Show'">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="elevated"
                    @click.prevent="$router.go(-1)"
                    v-if="showBtnGravar"
                    v-show="$route.name == 'Vendedor.Create' || $route.name == 'Vendedor.Update' || $route.name == 'Vendedor.Show'"
                    >Voltar</v-btn
                >
                <v-btn
                    color="primary"
                    variant="elevated"
                    v-if="showBtnGravar"
                    v-show="$route.name !== 'Vendedor.Create' && $route.name !== 'Vendedor.Update' && $route.name !== 'Vendedor.Show'"
                    @click.prevent="closeModalCadastroVendedor()"
                    >Fechar</v-btn
                >
            </v-card-actions>
        </form>
    </v-card>
</template>
