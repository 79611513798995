<script setup>
    import { ref, computed, onMounted, defineEmits } from 'vue';
    import {Produto} from '../../resources/produtoResource';
    import config from '../../services/config';
    import { useProduto } from '../../store/produto';
    import { valorFormat, pCustoProdutoFormat, margemFormat, quantidadeProdutoFormat} from "../../services/filtros";
    
    const produtoStore = useProduto();
    let produtos = ref({});

    const emit = defineEmits(['selecionouProduto', 'close-dialog']);

    const total = ref(0);
    const pageSize = ref(config.maxPerPage);
    const currentPage = ref(produtoStore.currentPage);
    const filtro = ref(produtoStore.filtro);
    const filtrarPor = ref(produtoStore.filtrarPor)
    const itemsFiltrarPor = ref([
        { value: 'codigo', label: 'Código' },
        { value: 'descricao', label: 'Descrição' },
        { value: 'codigoBarras', label: 'Código de Barras' },
        { value: 'referencia', label: 'Referência' },
    ]);

    const direction = computed(() => {
        return produtoStore.sort[0].direction;
    });

    const orderBy = computed(() => {
        return produtoStore.sort[0].property;
    });
    
    onMounted(() => {
        search();
    });

    async function search() {
        let start = produtoStore.start;
        let limit = produtoStore.limit;
        let sort = JSON.stringify(produtoStore.sort);
        
        produtoStore.setFiltro(filtro.value);
        produtoStore.setFiltrarPor(filtrarPor.value);
        try {            
            const response = await Produto.get(start, limit, filtro.value, sort, filtrarPor.value);
            produtos.value = response.data.data;
            total.value = parseInt(response.data.total);
        } catch(err) {
            console.log(err);
        }
    }

    function handleCurrentChange(val) {
        let start = ( val - 1) * pageSize.value;
        produtoStore.setCurrentPage(val);
        produtoStore.setStart(start);
        search();
    }

    function setSort(property) {
        let sort = produtoStore.sort;
        let directionAtual = sort[0].direction;
        let direction = '';

        if (property !== sort[0].property) {
            direction = 'ASC';
        }
        else
        {
            if(directionAtual == 'ASC') {
                direction = 'DESC'
            }
            else
            {
                direction = 'ASC'
            }
        }

        let aSort = [{
            'property':property,
            'direction':direction
        }];
        produtoStore.setSort(aSort);
        search();
    }

    function selecionar(produto) {
        emit('selecionouProduto', produto);
    }

    function closeModalBuscaProdutos() {
        emit('close-dialog');
    }


</script>

<template>
    <v-card>
        <v-card-title>
            <h3>Busca de Produtos</h3>
        </v-card-title>
        <v-card-text>
            <v-card  elevation="16" class="mb-5">
                <v-card-title>
                    Filtro
                </v-card-title>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols=4>
                                <v-select
                                    v-model="filtrarPor"
                                    :items="itemsFiltrarPor"
                                    item-title="label"
                                    item-value="value"
                                    label="Filtrar Por"
                                ></v-select>
                            </v-col>
                            <v-col cols=6>
                                <v-text-field label="Filtro" type="text" v-model="filtro" prepend-inner-icon="mdi-magnify"></v-text-field>
                            </v-col>
                            <v-col cols=2>
                                <v-btn color="primary" @click.prevent="search()" prepend-icon="mdi-magnify">Filtrar</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
            
            
            <v-card  elevation="16" class="mb-5">
                <v-card-text>
                    <v-table density="compact">
                        <thead>
                        <tr>
                            <th class="text-center">Seleciona</th>

                            <th>
                                <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigo')">
                                    Código
                                    <v-icon v-if="orderBy == 'codigo'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                                </a>
                            </th>
                            <th>
                                <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigoBarras')">
                                    Código de Barras
                                    <v-icon v-if="orderBy == 'codigoBarras'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                                </a>
                            </th>
                            <th>
                                <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('referencia')">
                                    Referência
                                    <v-icon v-if="orderBy == 'referencia'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                                </a>
                            </th>
                            <th>
                                <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('descricao')">
                                    Descrição do Produto
                                    <v-icon v-if="orderBy == 'descricao'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                                </a>
                            </th>
                            <th>Unidade</th>
                            <th>Preço Custo</th>
                            <th>Margem Lucro</th>
                            <th>Preço Venda</th>
                            <th>Quantidade</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(o) in produtos" :key="o">
                                <td class="text-center">
                                    <v-tooltip text="Seleciona esse produto.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn 
                                                v-bind="props"
                                                variant="text" 
                                                icon="mdi-check-bold" 
                                                @click.prevent="selecionar(o)">
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                </td>
                                <td><a @click.prevent="selecionar(o)">{{ o.codigo }}</a></td>
                                <td><a @click.prevent="selecionar(o)">{{ o.codigoBarras }}</a></td>
                                <td><a @click.prevent="selecionar(o)">{{ o.referencia }}</a></td>
                                <td><a @click.prevent="selecionar(o)">{{ o.descricao }}</a></td>
                                <td><a @click.prevent="selecionar(o)">{{ o.unidade }}</a></td>
                                <td><a @click.prevent="selecionar(o)">{{ pCustoProdutoFormat(o.pCusto) }}</a></td>
                                <td><a @click.prevent="selecionar(o)">{{ margemFormat(o.margem)}}</a></td>
                                <td><a @click.prevent="selecionar(o)">{{ valorFormat(o.pVenda) }}</a></td>
                                <td><a @click.prevent="selecionar(o)">{{ quantidadeProdutoFormat(o.quantidade) }}</a></td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card-text>
            </v-card>

            <div class="block text-right">
                <v-pagination
                    @update:modelValue="handleCurrentChange"        
                    v-model="currentPage"
                    :length="Math.ceil(total / pageSize)"
                    :total-visible="7"
                    :show-first-last-page="true">
                </v-pagination>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="elevated" @click.prevent='closeModalBuscaProdutos'>Fechar</v-btn>
        </v-card-actions>
    </v-card>
</template>
