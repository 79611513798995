<script setup>
import { ref, computed, onMounted } from "vue";
import { Produto } from "@/resources/produtoResource";
import { ExcluirReverter } from "@/resources/excluirReverterResource";
import config from "../../services/config";
import { PermissaoService } from "../../services/permissaoService";
import MsgConfirm from "../Utils/MsgConfirm";
import SnackBar from "../Utils/SnackBar";
import { useExcluirReverter } from "../../store/excluirReverter";
import { quantidadeProdutoFormat, valorFormatDuasCasas } from "../../services/filtros";
import ProdutoBusca from "../Produtos/ProdutoBusca";
import Data from "../Utils/data.vue";
import CodigoProduto from "../Utils/codigoProduto.vue";
import { Converte } from "../../utils/converte";

let msgConfirm = ref(null);
let snackBar = ref(null);

let dialogProduto = ref(false);

const excluirReverterStore = useExcluirReverter();
let movimentacao = ref({});
let movimentacoes = ref({});
const total = ref(0);
const pageSize = ref(config.maxPerPage);
const currentPage = ref(excluirReverterStore.currentPage);
const idProduto = ref(excluirReverterStore.idProduto);
const codigoProduto = ref(excluirReverterStore.codigoProduto);
const codigoBarrasProduto = ref(excluirReverterStore.codigoBarrasProduto);
const unidadeProduto = ref(excluirReverterStore.unidadeProduto);
const descricaoProduto = ref(excluirReverterStore.descricaoProduto);
const dataInicial = ref(excluirReverterStore.dataInicial);
const dataFinal = ref(excluirReverterStore.dataFinal);

const direction = computed(() => {
    return excluirReverterStore.sort[0].direction;
});

const orderBy = computed(() => {
    return excluirReverterStore.sort[0].property;
});

onMounted(() => {
    filtrar(excluirReverterStore.filtrarPor);
});

async function destroy() {
    let dados = new FormData();
    dados.append("data", JSON.stringify(movimentacao.value));

    try {
        const response = await ExcluirReverter.excluir(dados);
        if (response.status == 200) {
            if (response.data.success == true) {
                snackBar.value.open("Movimentação de estoque excluída com sucesso.");
                filtrar();
            } else {
                snackBar.value.open(response.data.message, 6000);
            }
        }
    } catch (err) {
        console.log(err);
    }
}
function openModalDelete(mov) {
    movimentacao.value = mov;
    msgConfirm.value
        .open(
            "Confirma Exclusão ?",
            "Confirma a exclusão da movimentaçao de estoque ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                destroy();
            }
        });
}
async function filtrar() {
    if(codigoProduto.value.trim() == '') {
        idProduto.value = null;
    }
    let start = excluirReverterStore.start;
    let limit = excluirReverterStore.limit;
    let sort = JSON.stringify(excluirReverterStore.sort);

    excluirReverterStore.setIdProduto(idProduto.value);
    excluirReverterStore.setCodigoProduto(codigoProduto.value);
    excluirReverterStore.setCodigoBarrasProduto(codigoBarrasProduto.value);
    excluirReverterStore.setDescricaoProduto(descricaoProduto.value);
    excluirReverterStore.setUnidadeProduto(unidadeProduto.value);
    excluirReverterStore.setDataInicial(dataInicial.value);
    excluirReverterStore.setDataFinal(dataFinal.value);

    let codigoCodigoBarras = codigoProduto.value;
    let campo = 'codigo';

    let auxDataInicial = '';
    let auxDataFinal = '';

    if(dataInicial.value != '') {
        auxDataInicial = Converte.datePtBrToIso(dataInicial.value); 
    }

    if(dataFinal.value != '') {
        auxDataFinal = Converte.datePtBrToIso(dataFinal.value); 
    }

    try {
        const response = await ExcluirReverter.listar(start, limit, sort, idProduto.value, auxDataInicial, auxDataFinal, codigoCodigoBarras, campo  )
        movimentacoes.value = response.data.data;
        total.value = parseInt(response.data.total);
    } catch (err) {
        console.log(err);
    }    
}


function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    excluirReverterStore.setCurrentPage(val);
    excluirReverterStore.setStart(start);
    currentPage.value = val;
    filtrar();
}

function setSort(property) {
    let sort = excluirReverterStore.sort;
    let directionAtual = sort[0].direction;
    let direction = "";

    if (property !== sort[0].property) {
        direction = "ASC";
    } else {
        if (directionAtual == "ASC") {
            direction = "DESC";
        } else {
            direction = "ASC";
        }
    }

    let aSort = [
        {
            property: property,
            direction: direction,
        },
    ];

    excluirReverterStore.setSort(aSort);
    filtrar();
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}

function selecionouProduto(produto) {
    if (produto == null) {
        return;
    }
    
    codigoProduto.value = produto.codigo ;
    getProduto('codigo');

    dialogProduto.value = false;
  
}

async function getProduto(campo) {

    let valor = "";

    if(campo == 'codigoBarras') {
        valor = codigoBarrasProduto.value;
        if(valor == "") {
            return;
        }
    } else
    {
        valor = codigoProduto.value;
    }

    if(valor.trim() == '') {
        codigoBarrasProduto.value = '';
        descricaoProduto.value = '';
        unidadeProduto.value = '';
        if(campo == 'codigo') {
            idProduto.value =  null;
        }
        return;
    }

    if(campo == 'codigo') {
        valor = Converte.codigoProduto(valor);
    }

    idProduto.value = null;
    descricaoProduto.value = "";
    unidadeProduto.value = "";
    if(campo == "codigo") {
        codigoBarrasProduto.value = "";
    }
    else {
        codigoProduto.value = "";
    }


    let dados = new FormData();
    dados.append('codigoCodigoBarras', valor);
    dados.append('campo', campo);
    dados.append("codigoModulo", "02.03");

    try {
        let response = await Produto.getByCodigoCodigoBarras(dados);
        if (response.status == 200) {
            if(!response.data.data) {
                if(campo == 'codigo') {
                    document.getElementById("codigoProduto").focus();
                }
                if(campo == 'codigoBarras') {
                    document.getElementById("codigoBarrasProduto").focus();
                }
                snackBar.value.open("Produto não encontrado, tente outro código ou faça uma busca.", 6000);
                return;
            }

            idProduto.value = response.data.data.idProduto;
            codigoProduto.value = response.data.data.codigo;
            descricaoProduto.value = response.data.data.descricao;
            codigoBarrasProduto.value = response.data.data.codigoBarras;
            unidadeProduto.value = response.data.data.unidade;
        }
    } catch (err) {
        console.log(err);
    }
}
</script>

<template>
    <SnackBar ref="snackBar"></SnackBar>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>
    <v-row>
        <v-col>
            <h1>Excluir/Reverter Movimentação de Estoque</h1>
        </v-col>
    </v-row>

    <v-row>
        <v-col cols="12">
            <v-form>
                <v-row>
                    <v-col md="12">
                        <v-card elevation="16">
                            <v-card-title>Filtro</v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="3">
                                        <CodigoProduto
                                            v-model="codigoProduto"
                                            @blur="getProduto('codigo')"
                                            label="Código Produto"
                                            id="codigoProduto"
                                        ></CodigoProduto>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field
                                            label="Código de Barras"
                                            type="text"
                                            v-model="codigoBarrasProduto"
                                            @blur="getProduto('codigoBarras')"
                                            id="codigoBarrasProduto"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            label="Descrição do Produto"
                                            type="text"
                                            v-model="descricaoProduto"
                                            readonly
                                            tabindex="-1">
                                            
                                            <template v-slot:append>
                                                <v-tooltip text="Faz a busca de um produto, pela sua descrição, código, código de barras ou referência">
                                                    <template v-slot:activator="{ props }">
                                                        <v-btn v-bind="props" color="primary" @click="dialogProduto = true">
                                                            <v-icon>mdi-magnify</v-icon>
                                                        </v-btn>
                                                    </template>
                                                </v-tooltip>
                                                <v-dialog v-model="dialogProduto" transition="dialog-top-transition">
                                                    <ProdutoBusca
                                                        @selecionouProduto="selecionouProduto"
                                                        @close-dialog="dialogProduto = false"
                                                    />
                                                </v-dialog>
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="3">
                                        <v-text-field
                                            label="Unidade"
                                            type="text"
                                            v-model="unidadeProduto"
                                            readonly
                                            tabindex="-1"/>
                                    </v-col> 
                                    <v-col cols="3">
                                        <Data compact label="Data Inicial" type="text" v-model="dataInicial"></Data>
                                    </v-col>
                                    <v-col cols="3">
                                        <Data label="Data Final" type="text" v-model="dataFinal"></Data>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-btn color="primary" @click.prevent="filtrar()" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </v-row>

    <v-card elevation="16" class="mt-3">
        <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-center">Ações</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('data')">
                            Data
                            <v-icon v-if="orderBy == 'data'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigoProduto')">
                            Código
                            <v-icon v-if="orderBy == 'codigoProduto'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('descricaoProduto')">
                            Descrição do Produto
                            <v-icon v-if="orderBy == 'descricaoProduto'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>Unidade</th>
                    <th>Operação</th>
                    <th>Quantidade</th>
                    <th>Valor Total</th>
                    <th>Documento</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="o in movimentacoes" :key="o">
                    <td>
                        <v-tooltip text="Exclui e reverte a movimentação de estoque.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    v-show="temPermissao('02.03')"
                                    @click.prevent="openModalDelete(o)" icon="mdi-trash-can">
                                </v-btn>
                            </template>
                        </v-tooltip>
                    </td>
                    <td>{{ o.data }}</td>
                    <td>{{ o.codigoProduto }}</td>
                    <td>{{ o.descricaoProduto }}</td>
                    <td>{{ o.unidadeProduto }}</td>
                    <td>{{ o.operacaoExtenso }}</td>
                    <td>{{ quantidadeProdutoFormat(o.quantidade) }}</td>
                    <td>{{ valorFormatDuasCasas(o.valor) }}</td>
                    <td>{{ o.documento }}</td>
                </tr>
            </tbody>
        </v-table>
    </v-card>

    <div class="block text-right">
        <v-pagination @update:modelValue="handleCurrentChange" v-model="currentPage" :length="Math.ceil(total / pageSize)" :total-visible="7" :show-first-last-page="true">
        </v-pagination>
    </div>
</template>
