import Http from './http';

export class ExcluirReverter {

    static listar = function(start, limit,sort, idProduto, dataInicial, dataFinal, codigoCodigoBarras, campo) {
        return Http.get('/Serv/MovimentacoesEstoque/Exclusoes.php', {
            params: {
                'operacao': 'listar',
                'start':start,
                'limit':limit,
                'sort':sort,
                'idProduto': idProduto,
                'dataInicial': dataInicial,
                'dataFinal': dataFinal,
                'codigoCodigoBarras': codigoCodigoBarras,
                'campo': campo
            }
        });
    };

    static excluir = function(dados) {
        return Http.post('/Serv/MovimentacoesEstoque/Exclusoes.php?operacao=excluir', dados);
    };

}
