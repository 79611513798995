<script setup>
import { ref, onMounted, defineEmits } from "vue";
import { useRoute } from "vue-router";
import { Servico } from "../../resources/servicoResource";
import { PermissaoService } from "../../services/permissaoService";
import { GrupoServicos } from "../../resources/grupoServicosResource";
import { SubgrupoServicos } from "../../resources/subgrupoServicosResource";
import SnackBar from "../Utils/SnackBar";
import { useField, useForm } from "vee-validate";
import { string, number, addMethod, object } from "yup";
import { usePermissoes } from "../../store/permissoes";
import GrupoServicosCreate from "../GruposServicos/GrupoServicosCreate.vue";
import SubgrupoServicosCreate from "../SubgruposServicos/SubgrupoServicosCreate.vue";
import Moeda from "../Utils/moeda.vue";
import Porcentagem from "../Utils/porcentagem.vue";
import { Converte } from "../../utils/converte";

const isRealValidation = ref(null);
const validationCodigo = ref(null);
const snackBar = ref(null);
const isReadOnly = ref(false);
const route = useRoute();
let dialogGrupoServicos = ref(false);
let dialogSubgrupoServicos = ref(false);
let showBtnGravar = ref(true);
let primeiraCarga = true;
const emit = defineEmits(["close-dialog", "cadastrou-servico"]);

const permissoesStore = usePermissoes();
const itemsAtivoInativoBloqueado = ref([
    { text: "Ativo", value: "A" },
    { text: "Inativo", value: "I" },
    { text: "Bloqueado", value: "B" },
]);

addMethod(string, "max_value_comissao", function maxValue(message) {
    return string().test("max-value-comissao", message, function (value) {
        if (Converte.stringToFloat(value) > 100) {
            return false;
        }
        return true;
    });
});

addMethod(number, "codigo_servico_existe", function codigoServicoExiste(message) {
    return number().test(
        "codigo-servico-existe", // Name
        message, // Msg
        function (codigo_servico) {
            if (isRealValidation.value == null) {
                return true;
            }
            if (!isRealValidation.value) {
                return validationCodigo.value;
            }
            isRealValidation.value = false;
            validationCodigo.value = false;

            return new Promise((resolve) => {
                Servico.codigoExiste(codigo_servico, servico.value.idServico).then((res) => {
                    if (res.data == false) {
                        validationCodigo.value = true;
                        resolve(true);
                    }

                    resolve(false);
                });
            });
        }
    );
});

const validationSchema = object({
    servico: object({
        idServico: undefined,
        codigo: number()
            .min(1)
            .max(999999999)
            .codigo_servico_existe("Esse código já está sendo usado por outro serviço, tente outro código.")
            .typeError("O código deve ser um número")
            .label("O código"),
        descricao: string().required("A descrição do serviço é obrigatória").max(50).label("A descrição do serviço"),
        unidade: string().max(3).label("A unidade"),
        valor: string(),
        comissao: string().max_value_comissao("A comissão deve ser menor que 100%"),
        observacoes: undefined,
        idGrupoServicos: undefined,
        idSubgrupoServicos: undefined,
        ativoInativoBloqueado: undefined,
        motivoInatividade: string().max(50).label("O motivo da inatividade"),
        motivoBloqueado: string().max(50).label("O motivo do bloqueio"),
    }).required(),
});
const { handleSubmit, errors, setValues } = useForm({ validationSchema });

const gruposServicos = ref([]);
const subgruposServicos = ref([]);

const servico = ref({
    idServico: useField("servico.idServico").value,
    codigo: useField("servico.codigo").value,
    descricao: useField("servico.descricao").value,
    unidade: useField("servico.unidade").value,
    valor: useField("servico.valor").value,
    comissao: useField("servico.comissao").value,
    observacoes: useField("servico.observacoes").value,
    idGrupoServicos: useField("servico.idGrupoServicos").value,
    idSubgrupoServicos: useField("servico.idSubgrupoServicos").value,
    ativoInativoBloqueado: useField("servico.ativoInativoBloqueado").value,
    motivoInatividade: useField("servico.motivoInatividade").value,
    motivoBloqueado: useField("servico.motivoBloqueado").value,
});

const submit = handleSubmit((values, actions) => {
    gravar(values, actions);
});

onMounted(() => {
    cleanForm();
    isReadOnly.value = false;

    if (route.name == "Servico.Update" || route.name == "Servico.Show") {
        getServico(route.params.id);
    } else {
        getGruposServicos();
    }

    if (route.name == "Servico.Show") {
        isReadOnly.value = true;
    }

    let tipo = permissoesStore.tipo;
    let validade = permissoesStore.validade;

    if ((tipo == "D" || tipo == "P") && validade == "N") {
        showBtnGravar.value = false;
    }
});

function cleanForm() {
    if (route.name != "Servico.Show" || route.name != "Servico.Update") {
        servico.value.idServico = null;
        proximoCodigo();
    }
    servico.value.descricao = "";
    servico.value.unidade = "";
    servico.value.comissao = "0,0000 %";
    servico.value.valor = "R$ 0,00";
    servico.value.ativoInativoBloqueado = "A";
    servico.value.idGrupoServicos = null;
    servico.value.idSubgrupoServicos = null;
    servico.value.motivoInatividade = "";
    servico.value.motivoBloqueado = "";
    servico.value.observacoes = "";
}

async function gravar(values, actions) {
    let dados = new FormData();
    dados.append("data", JSON.stringify(values.servico));

    try {
        const response = await Servico.save(dados);

        if (response.status == 200) {
            if (response.data.success == false) {
                snackBar.value.open("O serviço não pode ser cadastrado, mensagem de erro: " + response.data.message, 6000);
                return;
            }
            if (route.name == "Servico.Update") {
                snackBar.value.open("Serviço alterado com sucesso.");
            } else {
                snackBar.value.open("Novo serviço cadastrado com sucesso.");
                emit("cadastrou-servico", servico.value.codigo);
                actions.resetForm();
                cleanForm();
                document.getElementById("codigo").focus();
            }
        }
    } catch (err) {
        snackBar.value.open("Erro, mensagem de erro: " + err);
    }
}
function closeModalCadastroServico() {
    emit("close-dialog");
}
async function getGruposServicos() {
    let start = 0;
    let limit = 999999;
    let sort = null;
    let primeiroRegistro = { idGrupoServicos: null, descricao: "" };

    const response = await GrupoServicos.get(start, limit, "", sort);
    if (response.status == 200) {
        gruposServicos.value = response.data.data;
        gruposServicos.value.unshift(primeiroRegistro);

        if (servico.value.idGrupoServicos == null) {
            servico.value.idGrupoServicos = response.data.data[0].idGrupoServicos;
            return;
        }

        getSubgruposServicos();
    }
}

async function getSubgruposServicos() {
    let dados = new FormData();
    dados.append("idGrupoServicos", servico.value.idGrupoServicos);
    subgruposServicos.value = [];
    if (primeiraCarga == false) {
        servico.value.idSubgrupoServicos = null;
    }

    const response = await SubgrupoServicos.getSubgrupos(dados);
    if (response.status == 200) {
        let primeiroRegistro = {
            idSubgrupoServicos: null,
            descricao: "",
            idGrupoServicos: null,
        };
        subgruposServicos.value = response.data.data;
        subgruposServicos.value.unshift(primeiroRegistro);
        if ((route.name !== "Servico.Update" && route.name !== "Servico.Show") || primeiraCarga == false) {
            servico.value.idSubgrupoServicos = response.data.data[0].idSubgrupoServicos;
        }

        primeiraCarga = false;
    }
}

async function getServico(id) {
    let dados = new FormData();
    if (route.name == "Servico.Update") {
        dados.append("codigoModulo", "01.02.02");
    } else if (route.name == "Servico.Show") {
        dados.append("codigoModulo", "01.02.04");
    }

    dados.append("idServico", id);
    try {
        const response = await Servico.getById(dados);
        if (response.status == 200) {
            setValues({ servico: response.data.data });

            servico.value.valor = Converte.numberToCurrency(response.data.data.valor);
            servico.value.comissao = Converte.numberToPorcentagem(response.data.data.comissao, 4);

            getGruposServicos();

            document.getElementById("codigo").focus();
        }
    } catch (err) {
        console.log(err);
    }
}

async function proximoCodigo() {
    try {
        const response = await Servico.proximoCodigo();
        if (response.status == 200) {
            servico.value.codigo = response.data;
        }
    } catch (err) {
        console.log(err);
    }
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}
</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2 v-show="$route.name !== 'Servico.Update' && $route.name !== 'Servico.Show'">Novo Serviço</h2>
            <h2 v-show="$route.name == 'Servico.Update'">Alteração de Serviço</h2>
            <h2 v-show="$route.name == 'Servico.Show'">Consulta de Serviço</h2>
        </v-card-title>
        <form ref="formServico" @submit.prevent="submit">
            <v-card-text>
                <SnackBar ref="snackBar"></SnackBar>
                <v-row>
                    <v-col cols="2">
                        <v-text-field
                            label="Código"
                            id="codigo"
                            type="text"
                            v-model="servico.codigo"
                            :error-messages="errors['servico.codigo']"
                            maxlength="9"
                            @blur="
                                (e) => {
                                    isRealValidation = true;
                                    servico.codigo = e.target.value;
                                }
                            "
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="10">
                        <v-text-field
                            label="Descrição"
                            id="descricao"
                            type="text"
                            v-model="servico.descricao"
                            maxlength="50"
                            :error-messages="errors['servico.descricao']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="2">
                        <v-text-field
                            label="Unidade"
                            id="unidade"
                            type="text"
                            maxlength="3"
                            v-model="servico.unidade"
                            :error-messages="errors['servico.unidade']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="5">
                        <moeda label="Valor Unitário" id="valor" type="text" v-model="servico.valor" :error-messages="errors['servico.valor']" :readonly="isReadOnly" />
                    </v-col>
                    <v-col cols="5">
                        <porcentagem
                            label="Comissão(%)"
                            id="comissao"
                            type="text"
                            tipo="comissao"
                            v-model="servico.comissao"
                            :error-messages="errors['servico.comissao']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea label="Observações" id="observacoes" type="text" v-model="servico.observacoes" :readonly="isReadOnly" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-select
                            :items="gruposServicos"
                            item-title="descricao"
                            item-value="idGrupoServicos"
                            label="Grupo de Serviços"
                            v-model="servico.idGrupoServicos"
                            @update:modelValue="getSubgruposServicos()"
                            :readonly="isReadOnly"
                        >
                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo grupo de serviços.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            v-bind="props"
                                            color="primary"
                                            @click="dialogGrupoServicos = true"
                                            id="btnNovoGrupoServicos"
                                            v-show="temPermissao('01.12.01') && route.name !== 'Servico.Show'"
                                        >
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogGrupoServicos" transition="dialog-top-transition">
                                    <GrupoServicosCreate @cadastrouGrupoServicos="getGruposServicos()" @close-dialog="dialogGrupoServicos = false" />
                                </v-dialog>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-select
                            :items="subgruposServicos"
                            item-title="descricao"
                            item-value="idSubgrupoServicos"
                            label="Subgrupo de Serviços"
                            v-model="servico.idSubgrupoServicos"
                            :readonly="isReadOnly"
                        >
                            <template v-slot:append>
                                <v-tooltip text="Cadastra um novo subgrupo de serviços.">
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            v-bind="props"
                                            color="primary"
                                            @click="dialogSubgrupoServicos = true"
                                            id="btnNovoSubgrupoServicos"
                                            v-show="temPermissao('01.13.01') && route.name !== 'Servico.Show'"
                                        >
                                            <v-icon>mdi-invoice-text-plus</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                                <v-dialog v-model="dialogSubgrupoServicos" transition="dialog-top-transition">
                                    <SubgrupoServicosCreate
                                        @cadastrouSubgrupoServicos="
                                            getGruposServicos();
                                            getSubgruposServicos();
                                        "
                                        @close-dialog="dialogSubgrupoServicos = false"
                                    />
                                </v-dialog>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row cols="2">
                    <v-col>
                        <v-select
                            :items="itemsAtivoInativoBloqueado"
                            label="Ativo/Inativo/Bloqueado"
                            v-model="servico.ativoInativoBloqueado"
                            item-title="text"
                            item-value="value"
                            :readonly="isReadOnly"
                        ></v-select>
                    </v-col>
                    <v-col cols="9" v-show="servico.ativoInativoBloqueado == 'I'">
                        <v-text-field
                            label="Motivo da inatividade"
                            id="motivoInatividade"
                            type="text"
                            maxlength="50"
                            v-model="servico.motivoInatividade"
                            :error-messages="errors['servico.motivoInatividade']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                    <v-col cols="9" v-show="servico.ativoInativoBloqueado == 'B'">
                        <v-text-field
                            label="Motivo do Bloqueio"
                            id="motivoBloqueado"
                            maxlength="50"
                            type="text"
                            v-model="servico.motivoBloqueado"
                            :error-messages="errors['servico.motivoBloqueado']"
                            :readonly="isReadOnly"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" v-show="$route.name !== 'Servico.Show'" variant="elevated" type="submit" id="btnServicoGravar">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="elevated"
                    @click.prevent="$router.go(-1)"
                    v-if="showBtnGravar"
                    v-show="$route.name == 'Servico.Create' || $route.name == 'Servico.Update' || $route.name == 'Servico.Show'"
                    >Voltar</v-btn
                >
                <v-btn
                    color="primary"
                    variant="elevated"
                    v-if="showBtnGravar"
                    v-show="$route.name !== 'Servico.Create' && $route.name !== 'Servico.Update' && $route.name !== 'Servico.Show'"
                    @click.prevent="closeModalCadastroServico()"
                    >Fechar</v-btn
                >
            </v-card-actions>
        </form>
    </v-card>
</template>
