<template>
    <div class="text-center">
      <v-snackbar
        v-model="snackbar"
        multi-line
        :timeout="timeout"
        :close-on-back="false"
      >
        {{ text }}
  
        <template v-slot:actions>
          <v-btn
            color="primary"
            variant="text"
            @click="snackbar = false"
            
          >
            Fechar
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </template>


<script>
  export default {
    data() {
      return {
        snackbar: false,
        text: '',
        timeout: 2500,
      }
    },
    methods: {
      open(message, timeout = 2500) {
        this.timeout = timeout;
        this.snackbar = true;
        this.text = message;
        
      }        
    }
  }
</script>
