import Http from './http';

export class ConfigCabecalho  {

    static get = function() {
        return Http.get('/Serv/Cabecalho/Cabecalho.php', {
            params: {
                'operacao': 'retornar',
            }
        });
    };

    static save = function(dados) {
        return Http.post('/Serv/Cabecalho/Cabecalho.php?operacao=gravar', dados);
    };

}
