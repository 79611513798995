import { defineStore } from 'pinia';

export const useRelatorio = defineStore('relatorio', {
    state: () => {
        return {
            relatorio:'',
        }        
    },
    actions : {
        setRelatorio(relatorio) {
            this.relatorio = relatorio;
        }
    }
});