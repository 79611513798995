<script setup>
import { ref, computed, onMounted, inject } from "vue";
import { ChequeRecebido } from "@/resources/chequeRecebidoResource";
import { Cliente } from "@/resources/clienteResource";
import config from "../../services/config";
import { PermissaoService } from "../../services/permissaoService";
import MsgConfirm from "../Utils/MsgConfirm";
import { useChequeRecebido } from "../../store/chequeRecebido";
import { valorFormat, dateFormat } from "../../services/filtros";
import ClienteBusca from "../Clientes/ClienteBusca.vue";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";

let msgConfirm = ref(null);
let snackBarApp = inject("snackBarApp");

let dialogCliente = ref(false);

const chequeRecebidoStore = useChequeRecebido();
let chequesRecebidos = ref({});
const total = ref(0);
const pageSize = ref(config.maxPerPage);
const currentPage = ref(chequeRecebidoStore.currentPage);
const abertos = ref(chequeRecebidoStore.abertos);
const cancelados = ref(chequeRecebidoStore.cancelados);
const baixados = ref(chequeRecebidoStore.baixados);
const voltaram = ref(chequeRecebidoStore.voltaram);
const dataInicial = ref(chequeRecebidoStore.dataInicial);
const dataFinal = ref(chequeRecebidoStore.dataFinal);
const idCliente = ref(chequeRecebidoStore.idCliente);
const codigoCliente = ref(chequeRecebidoStore.codigoCliente);
const nomeCliente = ref(chequeRecebidoStore.nomeCliente);
const codigo = ref(chequeRecebidoStore.codigo);
const titular = ref(chequeRecebidoStore.titular);
const filtrarPor = ref(chequeRecebidoStore.filtrarPor);


const itemsFiltrarPor = ref([
    { value: 'data', label: 'Data de Emissão' },
    { value: 'bomPara', label: 'Bom Para' },
    { value: 'dataBaixa', label: 'Data da Baixa' }
]);

const direction = computed(() => {
    return chequeRecebidoStore.sort[0].direction;
});

const orderBy = computed(() => {
    return chequeRecebidoStore.sort[0].property;
});

onMounted(() => {
    filtrar('geral');
});

async function destroy(idChequeRecebido) {
    let dados = new FormData();
    dados.append("idChequeRecebido", JSON.stringify(idChequeRecebido));

    try {
        const response = await ChequeRecebido.excluir(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open("Cheque recebido excluído com sucesso.");
                filtrar();
            }
        }
    } catch (err) {
        console.log(err);
    }
}

function openModalDelete(lancamento) {
    msgConfirm.value
        .open(
            "Confirma Exclusão ?",
            "Confirma a exclusão desse cheque recebido ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                destroy(lancamento.idChequeRecebido);
            }
        });
}

async function filtrar(tipoFiltro, mudouPagina = false) {
    let start = chequeRecebidoStore.start;
    let limit = chequeRecebidoStore.limit;
    let filtro = '';
    let sort = JSON.stringify(chequeRecebidoStore.sort);

    if(tipoFiltro == null || tipoFiltro == "" || tipoFiltro == undefined) {
        tipoFiltro = chequeRecebidoStore.tipoFiltro;
    }
    chequeRecebidoStore.setIdCliente(idCliente.value);
    chequeRecebidoStore.setCodigo(codigo.value);
    chequeRecebidoStore.setTitular(titular.value);
    chequeRecebidoStore.setCodigoCliente(codigoCliente.value);
    chequeRecebidoStore.setNomeCliente(nomeCliente.value);
    chequeRecebidoStore.setDataInicial(dataInicial.value);
    chequeRecebidoStore.setDataFinal(dataFinal.value);
    chequeRecebidoStore.setAbertos(abertos.value);
    chequeRecebidoStore.setCancelados(cancelados.value);
    chequeRecebidoStore.setBaixados(baixados.value);
    chequeRecebidoStore.setVoltaram(voltaram.value);
    chequeRecebidoStore.setTipoFiltro(tipoFiltro);
    chequeRecebidoStore.setFiltrarPor(filtrarPor.value);
                
    let auxDataInicial = null;
    let auxDataFinal = null;
    if (dataInicial.value != "") {
        auxDataInicial = Converte.datePtBrToIso(dataInicial.value); 
    }

    if (dataFinal.value != "") {
        auxDataFinal = Converte.datePtBrToIso(dataFinal.value); 
    }
    if(tipoFiltro == 'codigo') {
        filtro = codigo.value;
    }
    if(tipoFiltro == 'titular') {
        filtro = titular.value;
    }

    if(mudouPagina == false) {
        handleCurrentChange(1);
        return;
    }

    try {
        const response = await ChequeRecebido.listar(start, limit, sort, filtrarPor.value, auxDataInicial, auxDataFinal, abertos.value, 
            cancelados.value, baixados.value, voltaram.value, idCliente.value, filtro, tipoFiltro);
        chequesRecebidos.value = response.data.data;
        total.value = parseInt(response.data.total);
    } catch (err) {
        console.log(err);
    }
}
function handleCurrentChange(val) {
    let start = (val - 1) * pageSize.value;
    chequeRecebidoStore.setCurrentPage(val);
    chequeRecebidoStore.setStart(start);
    currentPage.value = val;
    filtrar(null, true);
}
function setSort(property) {
    let sort = chequeRecebidoStore.sort;
    let directionAtual = sort[0].direction;
    let direction = "";

    if (property !== sort[0].property) {
        direction = "ASC";
    } else {
        if (directionAtual == "ASC") {
            direction = "DESC";
        } else {
            direction = "ASC";
        }
    }

    let aSort = [
        {
            property: property,
            direction: direction,
        },
    ];

    chequeRecebidoStore.setSort(aSort);
    filtrar();
}

function temPermissao(codigoModulo) {
    return PermissaoService.temPermissao(codigoModulo);
}

function selecionouCliente(cliente) {
    if (cliente == null) {
        return;
    }

    codigoCliente.value = cliente.codigo;
    getCliente();
    dialogCliente.value = false;
}

async function getCliente() {
    idCliente.value = null;
    nomeCliente.value = "";

    if (codigoCliente.value.toString().trim() == "") {
        return;
    }

    let dados = new FormData();
    dados.append("codigo", codigoCliente.value);
    dados.append("getInativos", true);
    dados.append("codigoModulo", "03.11");

    try {
        let response = await Cliente.getByCodigo(dados);
        if (response.status == 200) {
            if (response.data.success == false) {
                document.getElementById("codigoCliente").focus();
                snackBarApp.value.open("O cliente não foi encontrado, tente outro código ou faça uma busca.");
                codigoCliente.value = "";
                return;
            }
            idCliente.value = response.data.data.idCliente;
            codigoCliente.value = response.data.data.codigo;
            nomeCliente.value = response.data.data.nome;
        }
    } catch (err) {
        console.log("Erro ao buscar o cliente: " + err);
    }
}

function openModalCancelar(lancamento) {
    msgConfirm.value
        .open(
            "Confirma Cancelamento ?",
                "Confirma o cancelamento desse cheque recebido ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                cancelar(lancamento.idChequeRecebido);
            }
        });
}

async function cancelar(idChequeRecebido) {
    let dados = new FormData();
    dados.append("idChequeRecebido", idChequeRecebido);

    try {
        const response = await ChequeRecebido.cancelar(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open("Cheque recebido cancelado.");
                filtrar();
            } else {
                snackBarApp.value.open("Não foi possível cancelar o cheque recebido.");
            }
        }
    } catch (err) {
        console.log(err);
    }
}

function openModalVoltou(lancamento) {
    msgConfirm.value
        .open(
            "Confirme ?",
                "Deseja alterar a situação do cheque recebido para 'Voltou' ?",
            { color: "primary" }
        )
        .then((confirm) => {
            if (confirm) {
                voltou(lancamento.idChequeRecebido);
            }
        });
}

async function voltou(idChequeRecebido) {
    let dados = new FormData();
    dados.append("idChequeRecebido", idChequeRecebido);

    try {
        const response = await ChequeRecebido.voltou(dados);
        if (response.status == 200) {
            if (response.data.success == "true") {
                snackBarApp.value.open("Cheque recebido colocado na situação 'voltou' com sucesso.");
                filtrar();
            } else {
                snackBarApp.value.open("Não foi possível alterar a situação do cheque para 'voltou'.");
            }
        }
    } catch (err) {
        console.log(err);
    }
}

</script>

<template>
    <MsgConfirm ref="msgConfirm"></MsgConfirm>
    <v-row>
        <v-col>
            <h1>Cheques Recebidos</h1>
        </v-col>
    </v-row>

    <v-row>
        <v-col>
            <v-btn v-show="temPermissao('03.11.01')" color="primary" :to="{ name: 'ChequesRecebidos.Create' }">Novo Cheque Recebido</v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="4">
            <v-card elevation="16" density="compact">
                <v-card-title>Filtrar Por</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-select density="compact" label="Filtrar Por" v-model="filtrarPor" :items="itemsFiltrarPor"
                                item-title="label" item-value="value"></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="8">
            <v-card elevation="16" density="compact">
                <v-card-title color="primary">Mostrar</v-card-title>
                <v-card-text density="compact">
                    <v-row>
                        <v-col cols="3">
                            <v-checkbox density="compact" v-model="abertos" label="Em Aberto" @change="filtrar()"></v-checkbox>
                        </v-col>
                        <v-col cols="3">
                            <v-checkbox density="compact" v-model="cancelados" label="Cancelados" @change="filtrar()"></v-checkbox>
                        </v-col>
                        <v-col cols="3">
                            <v-checkbox density="compact" v-model="baixados" label="Baixados" @change="filtrar()"></v-checkbox>
                        </v-col>
                        <v-col cols="3">
                            <v-checkbox density="compact" v-model="voltaram" label="Voltaram" @change="filtrar()"></v-checkbox>
                        </v-col>

                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-card elevation="16">
                <v-card-title>Filtro</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="2">
                            <Data compact label="Data Inicial" type="text" v-model="dataInicial"></Data>
                        </v-col>
                        <v-col cols="2">
                            <Data label="Data Final" type="text" v-model="dataFinal"></Data>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field id="codigoCliente" label="Cód.Cliente" type="text" v-model="codigoCliente" @blur="getCliente()"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Nome do Cliente" type="text" v-model="nomeCliente" readonly tabindex="-1">
                                <template v-slot:append>
                                    <v-tooltip text="Faz a busca de um cliente por nome apelido/nome fantasia/CPF.">
                                        <template v-slot:activator="{ props }">
                                            <v-btn v-bind="props" color="primary" @click="dialogCliente = true">
                                                <v-icon>mdi-magnify</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                    <v-dialog v-model="dialogCliente" transition="dialog-top-transition">
                                        <ClienteBusca @selecionouCliente="selecionouCliente" @close-dialog="dialogCliente = false" />
                                    </v-dialog>

                                    &nbsp;&nbsp;
                                    <v-btn color="primary" @click.prevent="filtrar('geral')" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-card elevation="16">
                <v-card-title>Busca Rápida</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="4">
                            <v-text-field label="Código" type="text" v-model="codigo">
                                <template v-slot:append>
                                    <v-btn color="primary" @click.prevent="filtrar('codigo')" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="8">
                            <v-text-field label="Titular do Cheque" type="text" v-model="titular">
                                <template v-slot:append>
                                    <v-btn color="primary" @click.prevent="filtrar('titular')" prepend-icon="mdi-magnify">Filtrar</v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <p></p>
    <v-card elevation="16" class="mt-4">
        <v-table density="compact">
            <thead>
                <tr>
                    <th class="text-center">Ações</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigo')">
                            Código
                            <v-icon v-if="orderBy == 'codigo'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>Situação</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('bomPara')">
                            Bom Para
                            <v-icon v-if="orderBy == 'bomPara'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('data')">
                            Data Emissão
                            <v-icon v-if="orderBy == 'data'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('dataBaixa')">
                            Data Baixa
                            <v-icon v-if="orderBy == 'dataBaixa'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>Valor</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('codigoCliente')">
                            Cód.Cliente
                            <v-icon v-if="orderBy == 'codigoCliente'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('nomeCliente')">
                            Nome do Cliente
                            <v-icon v-if="orderBy == 'nomeCliente'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                    <th>Banco</th>
                    <th>Agência</th>
                    <th>Conta</th>
                    <th>Nro.Cheque</th>
                    <th>Documento</th>
                    <th>
                        <a href="#" class="text-decoration-none text-grey-darken-3" @click.prevent="setSort('titular')">
                            Titular do Cheque
                            <v-icon v-if="orderBy == 'titular'" :icon="direction == 'ASC' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending'"></v-icon>
                        </a>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="o in chequesRecebidos" :key="o">
                    <td>
                        <v-tooltip text="Altera o cheque recebido.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-pencil"
                                    v-show="temPermissao('03.11.02') && o.situacao !== 'B' && o.situacao !== 'C' && o.situacao !== 'V'"
                                    :to="{ name: 'ChequesRecebidos.Update', params: { id: o.idChequeRecebido } }"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Baixa o cheque recebido.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-cash-check"
                                    v-show="temPermissao('03.11.04') && o.situacao == 'A'"
                                    :to="{ name: 'ChequesRecebidos.Baixa', params: { id: o.idChequeRecebido } }"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Cancela o cheque recebido.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-cancel"
                                    v-show="temPermissao('03.11.05') && (o.situacao !== 'C')"
                                    @click.prevent="openModalCancelar(o)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Exclui o cheque recebido.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-trash-can"
                                    v-show="temPermissao('03.11.06')"
                                    @click.prevent="openModalDelete(o)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Consulta o cheque recebido.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-tab-search"
                                    v-show="temPermissao('03.11.03')"
                                    :to="{ name: 'ChequesRecebidos.Show', params: { id: o.idChequeRecebido } }"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Cheque recebido voltou.">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="small"
                                    v-bind="props"
                                    variant="text"
                                    icon="mdi-cash-refund"
                                    v-show="temPermissao('03.11.07') && (o.situacao !== 'C' && o.situacao !== 'B' && o.situacao !== 'V')"
                                    @click.prevent="openModalVoltou(o)"
                                >
                                </v-btn>
                            </template>
                        </v-tooltip>
                    </td>
                    <td>{{ o.codigo }}</td>
                    <td>{{ o.situacaoExtenso }}</td>
                    <td>{{ dateFormat(o.bomPara) }}</td>
                    <td>{{ dateFormat(o.data) }}</td>
                    <td>{{ dateFormat(o.dataBaixa) }}</td>
                    <td>{{ valorFormat(o.valor) }}</td>
                    <td>{{ o.codigoCliente }}</td>
                    <td>{{ o.nomeCliente }}</td>
                    <td>{{ o.nomeBanco }}</td>
                    <td>{{ o.agencia }}</td>
                    <td>{{ o.conta }}</td>
                    <td>{{ o.numero }}</td>
                    <td>{{ o.documento }}</td>
                    <td>{{ o.titular }}</td>
                </tr>
            </tbody>
        </v-table>
    </v-card>

    <div class="block text-right">
        <v-pagination @update:modelValue="handleCurrentChange" v-model="currentPage" :length="Math.ceil(total / pageSize)" :total-visible="7" :show-first-last-page="true">
        </v-pagination>
    </div>
    <p></p>
    <v-row>
        <v-col>
            <v-btn v-show="temPermissao('03.11.01')" color="primary" :to="{ name: 'ChequesRecebidos.Create' }">Novo Cheque Recebido</v-btn>        
        </v-col>
    </v-row>
</template>