import { defineStore } from 'pinia';

export const useCasasDecimais = defineStore('casasDecimais', {
    state: () => {
        return {
            jaCarregado: false,
            quantidadeProduto:3,
            quantidateServico:3,
            pCustoProduto:2,
            pCustoProdutoZero:'R$ 0,00',
            quantidadeProdutoZero:'0,000',
            quantidadeProdutoUm:'1,000',
            quantidadeServicoZero:'0,000',
            quantidadeServicoUm:'1,000',
        }
    },
    actions : {
        setJaCarregado(jaCarregado) {
            this.jaCarregado = jaCarregado;
        },
        setQuantidadeProduto(quantidadeProduto) {
            this.quantidadeProduto = quantidadeProduto;
        },
        setQuantidadeServico(quantidadeServico) {
            this.quantidadeServico = quantidadeServico;
        },
        setPCustoProduto(pCustoProduto) {
            this.pCustoProduto = pCustoProduto;
        },
        setPCustoProdutoZero(pCustoProdutoZero) {
            this.pCustoProdutoZero = pCustoProdutoZero;
        },
        setQuantidadeProdutoZero(quantidadeProdutoZero) {
            this.quantidadeProdutoZero = quantidadeProdutoZero;
        },
        setQuantidadeProdutoUm(quantidadeProdutoUm) {
            this.quantidadeProdutoUm = quantidadeProdutoUm;
        },
        setQuantidadeServicoZero(quantidadeServicoZero) {
            this.quantidadeServicoZero = quantidadeServicoZero;
        },
        setQuantidadeServicoUm(quantidadeServicoUm) {
            this.quantidadeServicoUm = quantidadeServicoUm;
        },

    }
});