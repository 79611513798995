<script setup>
import { onMounted, inject, defineProps, defineEmits, ref} from "vue";

import Moeda from "../Utils/moeda.vue";
import Data from "../Utils/data.vue";
import { Converte } from "../../utils/converte";

const snackBarApp = inject("snackBarApp");

const props = defineProps({
    propLancamento: {
        type: Object,
    },
});

const lancamento = ref({});


const emit = defineEmits(['close-dialog', 'alterou-lancamento']);

onMounted(() => {

    lancamento.value = props.propLancamento;

    lancamento.value.valorParcela = Converte.numberToCurrency(lancamento.value.valorParcela);
    lancamento.value.desconto = Converte.numberToCurrency(lancamento.value.desconto);
    lancamento.value.acrescimo = Converte.numberToCurrency(lancamento.value.acrescimo);
    lancamento.value.valorAPagar = Converte.numberToCurrency(lancamento.value.valorAPagar);

    document.getElementById("dataVencimento").focus();
});

async function gravar() {
    if(Converte.stringToFloat(lancamento.value.valorParcela) <= 0) {
        snackBarApp.value.open("O valor da parcela tem que ser maior que zero.", 6000);
        return;
    }
    
    if(Converte.stringToFloat(lancamento.value.valorAPagar) < 0) {
        snackBarApp.value.open("O valor a pagar não pode ser negativo.", 6000);
        return;
    }
   
    snackBarApp.value.open("Lançamento alterado com sucesso.", 3000);

    emit('alterou-lancamento', lancamento.value);
}


function calculaValorAPagar() {
    let valorParcela = Converte.stringToFloat(lancamento.value.valorParcela);
    let acrescimo = Converte.stringToFloat(lancamento.value.acrescimo);
    let desconto = Converte.stringToFloat(lancamento.value.desconto);

    lancamento.value.valorAPagar = Converte.numberToCurrency(valorParcela + acrescimo - desconto);
}

</script>

<template>
    <v-card elevation="16">
        <v-card-title>
            <h2>Alteração de Parcela de Contas a Pagar</h2>
        </v-card-title>
        <form ref="formAlteraLancamento" @submit.prevent="submit">
            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <Data label="Data de Vencimento" id="dataVencimento" v-model="lancamento.dataVencimento" />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            label="Documento"
                            id="documento"
                            type="text"
                            v-model="lancamento.documento"
                            maxlength="10"
                        />
                    </v-col>
                    <v-col cols="4">
                        <Moeda label="Valor da Parcela" id="valorParcela" v-model="lancamento.valorParcela" @blur="calculaValorAPagar()" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <Moeda label="Descontos(R$)" id="desconto" v-model="lancamento.desconto" @blur="calculaValorAPagar()" />
                    </v-col>
                    <v-col cols="4">
                        <Moeda label="Acréscimo(R$)" id="acrescimo" v-model="lancamento.acrescimo" @blur="calculaValorAPagar()" />
                    </v-col>
                    <v-col cols="4">
                        <Moeda label="Valor a Pagar" id="valorAPagar" v-model="lancamento.valorAPagar" readonly />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" variant="elevated" @click.prevent="gravar()" id="btnAlteraLancamento">Salvar</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    variant="elevated"
                    @click.prevent="emit('close-dialog')">
                    Fechar
                    </v-btn>
            </v-card-actions>
        </form>
    </v-card>
</template>